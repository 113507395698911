import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'uiTag',
	standalone: true
})
export class UiTagPipe implements PipeTransform {
	transform(
		originalName: string,
		libraries: Map<string, { name: string; uiTag: string }[]>
	): string {
		let uiTag: string = originalName;
		for (const [, list] of libraries.entries()) {
			const found = list.find((library) => library.name === originalName);
			if (found) {
				uiTag = found.uiTag;
				break;
			}
		}
		return uiTag;
	}
}
