import {
	JsonConverter,
	JsonCustomConvert,
	JsonObject,
	JsonProperty
} from 'json2typescript';

@JsonConverter
class TagGroupsFieldConverter
	implements JsonCustomConvert<Map<string, Array<string>>>
{
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	serialize(fields: Map<string, Array<string>>): string {
		return '';
	}

	deserialize(
		fieldObjects: Record<string, string[]>
	): Map<string, Array<string>> {
		const fields = new Map<string, Array<string>>();

		for (const fieldName in fieldObjects) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			fields.set(fieldName, fieldObjects[fieldName]);
		}

		return fields;
	}
}

@JsonObject('Tag')
export class Tag {
	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('group', String)
	type: string = undefined;

	@JsonProperty('uiTag', String)
	uiTag: string = undefined;

	@JsonProperty('optionalFields', TagGroupsFieldConverter)
	optionalFields = new Map<string, Array<string>>();

	@JsonProperty('count', Number)
	count = 0;
}
