export enum NotificationType {
	Error,
	Success,
	Info,
	Warning
}

export class Notification {
	message: string;
	type: NotificationType;

	constructor(type: NotificationType, message: string) {
		this.message = message;
		this.type = type;
	}
}
