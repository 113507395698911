import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { cloneDeep, isEqual } from 'lodash-es';

export class State<T> {
	stateObj: T;
	stateId: number;
	expandedStateElements: string[];

	constructor(
		private originalStateObj: T,
		expandedElements: string[] = []
	) {
		if (!originalStateObj) {
			throw new Error('Invalid state!');
		}

		this.stateObj = cloneDeep(originalStateObj);
		this.expandedStateElements = [...expandedElements];
	}
}
/**
 * This interface is used for e.g. updating state objects
 */
export type StateVisitor<T> = (state: State<T>) => void;

/**
 * This class is used to store editor states in order to provide undo and redo functionality
 */
export class EditTracker<T> {
	canUndo = false;
	canRedo = false;

	private isDebugMode = false;
	private stateCount = 0;
	private undoStack: State<T>[];
	private redoStack: State<T>[];

	constructor(appConfigRepository: AppConfigRepository) {
		this.isDebugMode = appConfigRepository.config.debugMode;
	}

	/**
	 * Update status of expanded/collapsed elements after initial state of the editor.
	 * @param expandedElements array of expanded elements ids.
	 */
	updateStatus(expandedElements: string[]): void {
		this.undoStack[this.undoStack.length - 1].expandedStateElements = [
			...expandedElements
		];

		if (this.isDebugMode) {
			// eslint-disable-next-line no-console
			console.log('Undo update:', this.undoStack[this.undoStack.length - 1]);
		}
	}

	/** Clear the stacks */
	clear(): void {
		if (this.isDebugMode) {
			// eslint-disable-next-line no-console
			console.log('Undo: clear()');
		}
		this.undoStack = this.redoStack = undefined;
		this.canUndo = this.canRedo = false;
	}

	/**
	 * Whatever change is made in the editor, call this to store
	 * @param state the new state
	 */
	addState(state: State<T>): void {
		if (!this.undoStack || !this.redoStack) {
			this.init(state);
			return;
		}

		this.checkState(state);
		if (
			!isEqual(
				state.stateObj,
				this.undoStack[this.undoStack.length - 1].stateObj
			)
		) {
			this.undoStack = [
				...this.undoStack,
				{ ...state, stateId: ++this.stateCount } as State<T>
			];
			this.redoStack = [];
			this.canUndo = true;
			this.canRedo = false;

			if (this.isDebugMode) {
				// eslint-disable-next-line no-console
				console.log(`Undo: addState(${this.stateCount})`);
				// eslint-disable-next-line no-console
				console.log(state);
			}
		} else if (this.isDebugMode) {
			// eslint-disable-next-line no-console
			console.log(
				'Undo: addState() called with the exact same state we had last time. Ignored.'
			);
		}
	}

	/**
	 * Call this when undo button is clicked
	 * @returns The previous state of the editor to go back to
	 */
	undo(): State<T> {
		if (!this.canUndo) {
			throw new Error('Undo is not available!');
		}

		this.redoStack.push(this.undoStack.pop());
		const resultState = this.undoStack[this.undoStack.length - 1];

		this.canUndo = this.undoStack.length > 1;
		this.canRedo = true;

		if (this.isDebugMode) {
			// eslint-disable-next-line no-console
			console.log(`Undo: undo(${resultState.stateId})`, this.redoStack);
			// eslint-disable-next-line no-console
			console.log(resultState);
		}
		return cloneDeep(resultState); // previous state
	}

	/**
	 * Call this when redo button is clicked
	 * @returns The previous state of the editor to go back to
	 */
	redo(): State<T> {
		if (!this.canRedo) {
			throw new Error('Redo is not available!');
		}

		const prevState = this.redoStack.pop();
		this.undoStack.push(prevState);
		this.canUndo = true;
		this.canRedo = !!this.redoStack.length;

		if (this.isDebugMode) {
			// eslint-disable-next-line no-console
			console.log(`Undo: redo(${prevState.stateId})`);
			// eslint-disable-next-line no-console
			console.log(prevState);
		}
		return cloneDeep(prevState);
	}

	/**
	 * Visit all states in both undo and redo stacks
	 * @param callback called for each redo and undo state
	 */
	doForAllStates(callback: StateVisitor<T>): void {
		for (const state of this.undoStack) {
			callback(state);
		}
		for (const state of this.redoStack) {
			callback(state);
		}
	}

	/**
	 * Define the initial state of the editor, before any edit made. Undo can go back to this state if applyed maximum times
	 * @param initialState editor's initial state
	 */
	private init(initialState: State<T>): void {
		if (this.isDebugMode) {
			initialState.stateId = this.stateCount = 0;
			// eslint-disable-next-line no-console
			console.log('Undo: init()');
			// eslint-disable-next-line no-console
			console.log(initialState);
		}
		this.checkState(initialState);
		this.undoStack = [initialState];
		this.redoStack = [];
		this.canUndo = this.canRedo = false;
	}

	private checkState(state: State<T>): void {
		if (!state) {
			throw new Error('Undo Stack was updated with invalid state!');
		}
	}
}
