<div id="page-enabled-projects" *ngIf="projectsPage?.jiraProjectList?.length">
	<header class="page-custom-building-blocks">
		<div class="aui-page-header-inner">
			<div class="aui-page-header-main">
				<h1>Testifi Pitstop - Enabled Projects</h1>
			</div>
		</div>
	</header>

	<div class="config-container col-group">
		<div class="col-mb-24 col-24 col-dt-24 col-ld-24">
			<table aria-describedby="JIRA projects list to enable or disable plugin">
				<thead>
					<tr>
						<ng-container *ngFor="let th of tableHeaders | unordedkeyvalue">
							<th id="title-nosort" class="theader" [ngClass]="{'theader__center': th.value.center }">
								{{ th.value.title }}
							</th>
						</ng-container>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let project of projectsPage?.jiraProjectList">
						<tr>
							<td>
								<div class="div-col-name">
									<span class="projects-settings-cell-with-image col-name" fxLayout fxLayoutAlign="start center">
										<img
											class="projects-settings-cell-avatar"
											alt=""
											src="{{project.avatarUrls['24x24']}}"
											style="width: 24px; height: 24px; margin-right: 4px"
										/>{{ project.name }}
									</span>
								</div>
							</td>
							<td>
								<div class="div-col-key">{{ project.key }}</div>
							</td>
							<td>
								<div class="div-col-lead">
									<span class="projects-settings-cell-with-image col-name" fxLayout fxLayoutAlign="start center">
										<img
											class="projects-settings-cell-avatar"
											alt=""
											src="{{project.lead?.avatarUrls['24x24']}}"
											style="width: 24px; height: 24px; margin-right: 4px; border-radius: 50%"
										/>{{ project.lead?.displayName }}
									</span>
								</div>
							</td>
							<td class="checkbox" fxLayout fxLayoutAlign="center center">
								<ui-switch
									size="small"
									(change)="checkItem(project)"
									[checked]="checkedItems.has(project.id)"
									id="enable_project_{{
                  project.id
                }}"
								></ui-switch>
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
	</div>
	<mat-paginator
		appStylePaginator
		#paginator
		[length]="totalItems"
		[pageSize]="pageSize"
		[pageSizeOptions]="[5, 10, 20]"
		[pageIndex]="currentPage"
		(page)="handlePage($event)"
	>
	</mat-paginator>
</div>
