import { KeyValue, KeyValuePipe } from '@angular/common';
import { KeyValueDiffers, Pipe, PipeTransform } from '@angular/core';

const unordered = (a: any, b: any) => 0; // eslint-disable-line

@Pipe({
	name: 'unordedkeyvalue',
	standalone: true
})

// This pipe uses the angular keyvalue pipe. but defaults to unordered.
export class UnorderdKeyValuePipe implements PipeTransform {
	constructor(public differs: KeyValueDiffers) {}

	// eslint-disable-next-line
	public transform(value, compareFn = unordered): KeyValue<any, any>[] {
		const pipe = new KeyValuePipe(this.differs);
		return pipe.transform(value, compareFn);
	}
}
