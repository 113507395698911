import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TestStepLibrary')
export class TestStepLibrary {
	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('description', String, true)
	description: string = undefined;

	@JsonProperty('documentation', String, true)
	documentation: string = undefined;

	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('group', String)
	group: string = undefined;

	@JsonProperty('tag', String)
	tag: string = undefined;

	@JsonProperty('node', Boolean)
	isNode = false;

	@JsonProperty('mandatoryFields', Object)
	mandatoryFields: { [key: string]: string[] } = {};

	@JsonProperty('optionalFields', Object)
	optionalFields: { [key: string]: string[] } = {};

	@JsonProperty('standardValues', Object)
	standardValues: { [key: string]: string } = {};

	@JsonProperty('uiTag', String)
	uiTag: string = undefined;

	@JsonProperty('count', Number)
	count = 0;
}
