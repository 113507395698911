import { Injectable } from '@angular/core';
import { TagGroups } from '@testifi-models/tag-groups';
import { TestStepLibrary } from '@testifi-models/test-step-library';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrivateHttpClient } from '../https/instances/private.http';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class LibraryObjectService {
	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private privateHttp: PrivateHttpClient,
		private base: BaseApiService
	) {}

	// ======================================================================
	// public functions
	// ======================================================================

	libraries(projectId: string): Observable<Map<string, TestStepLibrary[]>> {
		return this.privateHttp
			.get<
				Map<string, TestStepLibrary[]>
			>(`libraryObject/castObjects/count/${projectId}`)
			.pipe(map((res) => this.base.modelsMapArray(res, TestStepLibrary)));
	}

	tags(projectId: string): Observable<TagGroups> {
		return this.privateHttp
			.get<TagGroups>(`libraryObject/pageObjects/count/${projectId}`)
			.pipe(
				map((res) => ({
					tags: res
				})),
				map((res) => this.base.model(res, TagGroups))
			);
	}
}
