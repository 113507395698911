// Angular CDK Drag Utils source: https://github.com/angular/components/blob/master/src/cdk/drag-drop/drag-utils.ts

import { cloneDeep } from 'lodash-es';

/**
 * Moves an item from one array to another.
 * @param currentArraySource Array from which to transfer the item.
 * @param targetArraySource Array into which to put the item.
 * @param currentIndex Index of the item in its current array.
 * @param targetIndex Index at which to insert the item.
 */
export function transferArrayItem<T = any>(
	currentArraySource: T[],
	targetArraySource: T[],
	currentIndex: number,
	targetIndex: number
): { currentArray: T[]; targetArray: T[] } {
	const currentArray = cloneDeep(currentArraySource);
	const targetArray = cloneDeep(targetArraySource);

	const from = clamp(currentIndex, currentArray.length - 1);
	const to = clamp(targetIndex, targetArray.length);

	if (currentArray.length) {
		targetArray.splice(to, 0, currentArray.splice(from, 1)[0]);
	}

	return {
		currentArray,
		targetArray
	};
}

/**
 * Copies an item from one array to another, leaving it in its
 * original position in current array.
 * @param currentArraySource Array from which to copy the item.
 * @param targetArraySource Array into which is copy the item.
 * @param currentIndex Index of the item in its current array.
 * @param targetIndex Index at which to insert the item.
 *
 */
export function copyArrayItem<T = any>(
	currentArraySource: T[],
	targetArraySource: T[],
	currentIndex: number,
	targetIndex: number
): { currentArray: T[]; targetArray: T[] } {
	const currentArray = cloneDeep(currentArraySource);
	const targetArray = cloneDeep(targetArraySource);

	const to = clamp(targetIndex, targetArray.length);

	if (currentArray.length) {
		targetArray.splice(to, 0, currentArray[currentIndex]);
	}

	return {
		currentArray,
		targetArray
	};
}

function clamp(value: number, max: number): number {
	return Math.max(0, Math.min(max, value));
}
