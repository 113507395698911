import { NgClass, NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '@testifi-services/modal.service';
import { cloneDeep } from 'lodash-es';
import { ModalCloseDirective } from '../../directives/modal-close.directive';

export interface ConfirmDialogData {
	title: string;
	text: string;
	text2?: string;
	confirmButton: string;
	cancelButton: string;
	type?: string;
	extButton?: HTMLButtonElement;
}

@Component({
	selector: 'app-modal-confirm',
	templateUrl: './modal-confirm.component.html',
	standalone: true,
	imports: [NgClass, ExtendedModule, NgIf, ModalCloseDirective]
})
export class ModalConfirmComponent implements OnInit {
	// ======================================================================
	// public properties
	// ======================================================================
	@Input() test = '';

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
	) {}

	// ======================================================================
	// inherit functions
	// ======================================================================
	ngOnInit(): void {
		this.data?.extButton?.click();
	}

	// ======================================================================
	// public functions
	// ======================================================================

	cancel(): void {
		this.modalService.close(null);
	}

	confirm(): void {
		this.modalService.close(cloneDeep(this.data));
	}
}
