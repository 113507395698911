import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	Renderer2
} from '@angular/core';
import { RouterService } from '../services/router.service';

@Directive({
	selector: '[appRouterName]',
	standalone: true
})
export class RouterNameDirective implements OnInit {
	@Input('appRouterName') name: string[];
	@Input() appRouterData: Record<string, unknown> = {};

	constructor(
		hostElement: ElementRef,
		renderer: Renderer2,
		private routerService: RouterService
	) {
		renderer.addClass(hostElement.nativeElement, 'clickable');
	}

	ngOnInit(): void {
		this.routerService.validate(this.name);
	}

	@HostListener('click')
	onClick(event: Event): void {
		event?.stopPropagation();
		this.routerService.navigate(this.name, this.appRouterData);
	}
}
