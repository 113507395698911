<div fxLayout class="pl__container" fxLayoutGap="8px">
	<div>
		<button type="button" id="addParameterButton" class="pl__add-button" title="Add Parameter" (click)="onAddClick()">
			+
		</button>
	</div>
	<div class="pl__parameter-list" fxLayout="row wrap">
		<div [ngClass]="{'pl__parameter-list-item': isAddNew}">
			<app-label-editor
				*ngIf="isAddNew"
				[existingKeys]="existingKeys"
				(submitEdit)="onSubmitEdit($event)"
				(cancel)="onCancelEdit()"
			></app-label-editor>
		</div>
		<ng-container *ngFor="let item of labelItemList">
			<div class="pl__parameter-list-item">
				<app-label-item
					*ngIf="editedKey !== item.key"
					[parameter]="item"
					(edit)="onEditItem($event)"
					(remove)="onRemoveItem($event)"
				></app-label-item>
				<app-label-editor
					*ngIf="editedKey === item.key"
					[parameter]="item"
					[existingKeys]="existingKeys"
					(submitEdit)="onSubmitEdit($event)"
					(cancel)="onCancelEdit()"
				></app-label-editor>
			</div>
		</ng-container>
	</div>
</div>
