import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[autofocus]', // eslint-disable-line
	standalone: true
})
export class AutofocusDirective implements AfterViewInit {
	@Input() autofocus = true;

	constructor(private el: ElementRef<HTMLElement>) {}

	ngAfterViewInit(): void {
		if (this.autofocus) {
			this.el.nativeElement.focus();
		}
	}
}
