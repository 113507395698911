import { NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	Input,
	QueryList
} from '@angular/core';
import { RelationValueComponent } from '../relation-value/relation-value.component';

@Component({
	selector: '[app-relation-values]',
	templateUrl: './relation-values.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf]
})
export class RelationValuesComponent {
	@Input() name = '';
	@Input() empty = false;

	@ContentChildren(RelationValueComponent, { descendants: true })
	values: QueryList<RelationValueComponent>;
}
