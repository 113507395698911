import { AsyncPipe } from '@angular/common';
import {
	AfterViewInit,
	Component,
	HostListener,
	OnDestroy
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule, UntypedFormGroup } from '@angular/forms';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { ActivatedRoute } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@testifi-services/notification.service';
import {
	GitSettingsPayload,
	ProjectService,
	ProjectStatusesPayload
} from '@testifi-services/project.service';
import { RouterService } from '@testifi-services/router.service';
import { PAGES } from '@testifi-shared/app-constants';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { SvgIconComponent } from 'angular-svg-icon';
import { Subscription } from 'rxjs';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { GitSettingsComponent } from '../page-credentials-settings/git-settings/git-settings.component';
import { StatusLevelsComponent } from '../page-credentials-settings/status-levels/status-levels.component';

@Component({
	selector: 'app-page-project-settings',
	templateUrl: './page-project-settings.component.html',
	styleUrls: ['./page-project-settings.component.less'],
	standalone: true,
	imports: [
		HideOnLoadingDirective,
		MatLegacyTabsModule,
		FormsModule,
		FlexModule,
		SvgIconComponent,
		NgbPopover,
		StatusLevelsComponent,
		GitSettingsComponent,
		AsyncPipe
	]
})
export class PageProjectSettingsComponent implements AfterViewInit, OnDestroy {
	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	// ======================================================================
	// public properties
	// ======================================================================

	formXray: UntypedFormGroup;
	formGIT: UntypedFormGroup;
	submitted = false;

	projectId = '';

	constructor(
		public appConfigRepository: AppConfigRepository,
		public projectRepository: ProjectRepository,
		private notificationService: NotificationService,
		private routerService: RouterService,
		private projectService: ProjectService,
		activatedRoute: ActivatedRoute
	) {
		this.disposableBag.add(
			activatedRoute.queryParamMap.subscribe((params) => {
				if (params.has('id')) {
					this.projectId = params.get('id');
				}
			})
		);
	}

	@HostListener('window:popstate', ['$event'])
	onPopState(): void {
		this.routerService.navigate([
			PAGES.LANDING,
			this.projectRepository.projectKey
		]);
	}

	ngAfterViewInit(): void {
		if (!this.projectId) {
			setTimeout(() => {
				this.routerService.navigate([
					PAGES.LANDING,
					this.projectRepository.projectKey
				]);
			}, 500);
		}
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	onStatusLevelFormData(form: UntypedFormGroup): void {
		this.formXray = form;
		const {
			todoStatus,
			executingStatus,
			passStatus,
			failStatus,
			abortedStatus,
			mismatchStatus,
			todoStepStatus,
			executingStepStatus,
			passStepStatus,
			failStepStatus,
			abortedStepStatus,
			mismatchStepStatus
		} = this.projectRepository.projectSettings;

		this.formXray.get('scenarioTodo').setValue(todoStatus);
		this.formXray.get('scenarioExecuting').setValue(executingStatus);
		this.formXray.get('scenarioPass').setValue(passStatus);
		this.formXray.get('scenarioFail').setValue(failStatus);
		this.formXray.get('scenarioAborted').setValue(abortedStatus);
		this.formXray.get('scenarioMismatch').setValue(mismatchStatus);
		this.formXray.get('stepTodo').setValue(todoStepStatus);
		this.formXray.get('stepExecuting').setValue(executingStepStatus);
		this.formXray.get('stepPass').setValue(passStepStatus);
		this.formXray.get('stepFail').setValue(failStepStatus);
		this.formXray.get('stepAborted').setValue(abortedStepStatus);
		this.formXray.get('stepMismatch').setValue(mismatchStepStatus);
	}

	onGitSettingsForm(form: UntypedFormGroup): void {
		this.formGIT = form;
	}

	saveXray(): void {
		const statuses: ProjectStatusesPayload = {
			projectId: this.projectId,
			todoStatus: this.formXray.get('scenarioTodo').value as string,
			executingStatus: this.formXray.get('scenarioExecuting').value as string,
			passStatus: this.formXray.get('scenarioPass').value as string,
			failStatus: this.formXray.get('scenarioFail').value as string,
			abortedStatus: this.formXray.get('scenarioAborted').value as string,
			mismatchStatus: this.formXray.get('scenarioMismatch').value as string,
			todoStepStatus: this.formXray.get('stepTodo').value as string,
			executingStepStatus: this.formXray.get('stepExecuting').value as string,
			passStepStatus: this.formXray.get('stepPass').value as string,
			failStepStatus: this.formXray.get('stepFail').value as string,
			abortedStepStatus: this.formXray.get('stepAborted').value as string,
			mismatchStepStatus: this.formXray.get('stepMismatch').value as string
		};

		this.disposableBag.add(
			this.projectService
				.updateProjectStatuses(statuses)
				.subscribe((projectSettings) => {
					this.notificationService.info(
						'Project Settings - Xray has been saved'
					);
					this.projectRepository.setProjectSettings(projectSettings);
					this.close();
				})
		);
	}

	saveGIT(): void {
		const gitSettings: GitSettingsPayload = {
			projectId: this.projectId,
			gitUrl: this.formGIT.get('url').value as string,
			gitBranch: this.formGIT.get('branch').value as string
		};

		this.disposableBag.add(
			this.projectService
				.updateGitSettings(gitSettings)
				.subscribe((projectSettings) => {
					this.projectRepository.setProjectSettings(projectSettings);
					this.notificationService.info(
						'Project Settings - GIT has been saved'
					);
					this.close();
				})
		);
	}

	close(): void {
		if (this.projectRepository.projectKey) {
			this.routerService.navigate([PAGES.LANDING]);
		} else {
			this.routerService.navigate([PAGES.ENABLED_PROJECTS]);
		}
	}
}
