<section
	id="modal-git-information"
	class="aui-dialog2 aui-dialog2-medium"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-git-information--heading"
>
	<form class="aui" [formGroup]="form" (ngSubmit)="send()">
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-test-object-parameter--heading">GIT Parameters</h2>
		</header>
		<!-- Main dialog content -->
		<div class="aui-dialog2-content">
			<div class="field-group">
				<label class="label" for="username">GIT Username</label>
				<input class="input-text" type="text" formControlName="username" id="username" placeholder="GIT Username" />
				<app-validation-errors [control]="form.get('username')"></app-validation-errors>
			</div>
			<div class="field-group">
				<label class="label" for="password">GIT Token</label>
				<input
					class="input-text"
					type="text"
					[ngClass]="{'input-text-security': isTokenHidden}"
					formControlName="password"
					id="password"
					placeholder="GIT Token"
					onCopy="return false"
					onCut="return false"
					onselectstart="return false"
					onDrag="return false"
					autocomplete="off"
				/>
				<svg-icon
					name="{{ isTokenHidden ? 'eye-on' : 'eye-off' }}"
					class="input-text-icon"
					title="Show token"
					[svgStyle]="{'width.px': 16, 'height.px': 16}"
					(click)="isTokenHidden = !isTokenHidden"
				></svg-icon>
				<app-validation-errors [control]="form.get('password')"></app-validation-errors>
			</div>
			<div class="field-group">
				<label class="label" for="url">GIT URL</label>
				<input class="input-text" type="text" formControlName="url" id="url" placeholder="GIT URL" />
				<app-validation-errors [control]="form.get('url')"></app-validation-errors>
			</div>
			<div class="field-group">
				<label class="label" for="url">GIT branch</label>
				<input class="input-text" type="text" formControlName="branch" id="branch" placeholder="GIT branch" />
				<app-validation-errors [control]="form.get('branch')"></app-validation-errors>
			</div>
			<div class="field-group" fxLayout fxLayoutAlign="start center" fxLayoutGap="16px">
				<label class="label" for="skipSSL">Skip SSL verification</label>
				<input
					class="checkbox"
					type="checkbox"
					name="skipSSL"
					id="skipSSL"
					formControlName="skipSSL"
					[checked]="form.get('skipSSL').value"
				/>
			</div>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<div class="aui-dialog2-footer-actions">
				<button type="submit" class="aui-button aui-button-primary" [disabled]="form.invalid">Send</button>
				<button type="button" class="aui-button aui-button-subtle" modalClose>Cancel</button>
			</div>
		</footer>
	</form>
</section>
