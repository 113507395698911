import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@testifi-utils/utils';

@Pipe({
	name: 'itemName',
	standalone: true
})
export class ItemNamePipe implements PipeTransform {
	transform(item: string): string {
		return Utils.getItemName(item);
	}
}

@Pipe({
	name: 'itemDefaultValue',
	standalone: true
})
export class ItemDefaultValuePipe implements PipeTransform {
	transform(item: string): string {
		return Utils.getItemDefaultValue(item);
	}
}
