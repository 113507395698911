export class FilterCacheManager {
	static getFilterFromCache(key: string, localStorageKey: string): string {
		const filters = window.localStorage.getItem(localStorageKey) ?? '{}';
		return (JSON.parse(filters) as Record<string, string>)[key];
	}

	static saveFilterToCache(
		filterKey: string,
		filterValue: string,
		localStorageKey: string
	) {
		const storedFilters = window.localStorage.getItem(localStorageKey) ?? '{}';
		let cachedFilters = JSON.parse(storedFilters) as Record<string, string>;

		cachedFilters = { ...cachedFilters, [filterKey]: filterValue };
		window.localStorage.setItem(localStorageKey, JSON.stringify(cachedFilters));
	}
}
