<div
	class="ltd__container"
	fxLayout="row nowrap"
	fxLayoutAlign="center center"
	fxLayoutGap="2px"
	(click)="itemClicked()"
	[ngClass]="{ 'ltd__container--mandatory': item.type === Type.MANDATORY, 'pointer': clickable }"
>
	<label
		id="label-{{ this.label }}"
		class="ltd__label"
		title="{{ this.title }}"
		[ngClass]="{ 'ltd__label--mandatory': item.type === Type.MANDATORY, 'pointer': clickable }"
		>{{ this.label }}
	</label>
	<button
		type="button"
		class="ltd__remove-btn"
		(click)="onRemove($event)"
		[ngClass]="{ 'ltd__remove-btn--mandatory': item.type === Type.MANDATORY }"
	>
		×
	</button>
</div>
