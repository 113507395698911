<ul
	[ngClass]="{ intersected: isIntersected, root: root, singleChild: item.children.length <= 1 }"
	cdkDropList
	cdkScrollable
	class="item-dropzone"
	[id]="item.structureId"
	[cdkDropListData]="item"
	[cdkDropListEnterPredicate]="canDrop(item, intersectedItemIds)"
	[cdkDropListConnectedTo]="filteredConnectedListIds(item)"
	(cdkDropListDropped)="onDragDrop($event)"
>
	<li
		class="item-drag sui-element test-object"
		*ngFor="let child of item.children"
		cdkDrag
		[id]="child.structureId"
		[cdkDragData]="child"
		(cdkDragEnded)="onDragEnded($event)"
		(cdkDragMoved)="onDragMove($event)"
		(cdkDragStarted)="onDragStarted($event)"
		(click)="validExpandable(child) && onToggleExpanded($event)"
	>
		<mat-expansion-panel
			[@.disabled]="isDragging"
			[expanded]="isExpanded(child)"
			[disabled]="true"
			[hideToggle]="true"
			class="background-N10 draggable sui-element-inner test-object-inner"
			[togglePosition]="'before'"
		>
			<mat-expansion-panel-header
				collapsedHeight="*"
				expandedHeight="*"
				appendDragThumb
				[html]="'<span class=\'drag-thumb\'></span>'"
			>
				<div class="rel">
					<div class="main">
						<div class="aui-group">
							<div class="aui-item">
								<div id="parent-of-child-type-and-change-button">
									<div class="type">
										<span class="color-N800"> {{ child.type | uiTag : tagGroups }} </span>
										<mat-icon
											*ngIf="validExpandable(child)"
											class="transition"
											[ngClass]="isExpanded(child) ? ['rotate180'] : ['rotate0']"
										>
											<svg-icon src="icon-arrow-down"></svg-icon>
										</mat-icon>
									</div>
									<div id="change-button">
										<span
											[modalData]="{
												suiElementStructureId: child.structureId,
												suiPageId: suiPage.id,
												oldSuiPage: suiPage,
												tagGroups: tagGroups,
												suiRootElement: rootItem,
												elementGroup: child.group,
												editTracker: editTracker
											}"
											[modalRouterName]="['sui_elements_change', child.structureId]"
											[modalOnClose]="onRefreshOnModalClose.bind(this)"
										>
											<svg-icon name="icon-edit" [svgStyle]="{'width.px':14,'height.px':14}"></svg-icon>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="parameters">
							<ul [id]="child.type">
								<li
									*ngFor="let parameter of child.parameter | filteroutkeyvalue: {value: ['schema', 'screenshot']}; let i = index"
									class="parameter validation-{{ parameter.displayValidationType }}"
									[modalData]="{
										suiElement: child,
										suiPageId: suiPage.id,
										suiElementParameters: child.optionalFields,
										suiElementParameterIndex: i,
										suiRootElement: rootItem
									}"
									[modalOnClose]="onRefreshOnModalClose.bind(this)"
									[modalRouterName]="['sui_elements_parameter', child.id]"
								>
									<span class="element__parameter">{{ parameter.key }}="{{ parameter.value }}"</span>
								</li>
								<li
									[modalData]="{
										suiElement: child,
										suiPageId: suiPage.id,
										suiElementParameters: child.optionalFields,
										suiRootElement: rootItem
									}"
									[modalOnClose]="onRefreshOnModalClose.bind(this)"
									[modalRouterName]="['sui_elements_parameter', child.id]"
									class="add-parameter parameter validation-optional"
								>
									+
								</li>
							</ul>
						</div>
					</div>
					<div class="suffix">
						<div class="zone-screenshot" *ngIf="child.type === 'sui:zone'">
							<ng-template
								*ngIf="child.parameter['screenshot'] && screenshots.get(child.parameter['screenshot']); then thumbnail; else placeholder"
							></ng-template>
							<ng-template #thumbnail>
								<img
									alt="{{ screenshots.get(child.parameter['screenshot'])?.name }}"
									[src]="screenshots.get(child.parameter['screenshot'])?.safeUrl"
								/>
								<div class="show-on-hover">
									<input
										type="button"
										class="aui-button small-button"
										value="View"
										(click)="screenshotToPreview = screenshots.get(child.parameter['screenshot'])"
									/>
									<input
										type="button"
										class="aui-button small-button"
										value="Select"
										[modalData]="{
											suiElement: child,
											suiPageId: suiPage.id,
											suiElementParameters: child.optionalFields,
											suiRootElement: rootItem,
											projectId: suiPage.projectId
										}"
										[modalOnClose]="onRefreshOnModalClose.bind(this)"
										[modalRouterName]="['sui_elements_screenshot', child.id]"
									/>
								</div>
							</ng-template>
							<ng-template #placeholder>
								<div class="color-N800">Screenshot</div>
								<div>
									<input
										type="button"
										class="aui-button small-button"
										value="Select"
										[modalData]="{
											suiElement: child,
											suiPageId: suiPage.id,
											suiElementParameters: child.optionalFields,
											suiRootElement: rootItem,
											projectId: suiPage.projectId
										}"
										[modalOnClose]="onRefreshOnModalClose.bind(this)"
										[modalRouterName]="['sui_elements_screenshot', child.id]"
									/>
								</div>
							</ng-template>
						</div>
						<ul class="row-actions">
							<li>
								<span
									class="svg"
									[modalData]="{
										suiElementStructureId: child.structureId,
										suiPageId: suiPage.id,
										suiRootElement: rootItem,
										type: 'warning',
										title: 'Delete SUI Page Element',
										text: 'This element will be removed from the system!',
										confirmButton: 'Delete',
										cancelButton: 'Cancel'
									}"
									[modalOnClose]="onElementDelete.bind(this)"
									[modalRouterName]="['confirm-popup', child.structureId]"
								>
									<svg-icon name="icon-delete"></svg-icon>
								</span>
							</li>
						</ul>
					</div>
				</div>
			</mat-expansion-panel-header>
			<div class="draggable">
				<app-modal-edit-sui-page-elements-element
					[parameters]="child.optionalFields"
					[item]="child"
					[suiPage]="suiPage"
					[rootItem]="rootItem"
					[connectedLists]="connectedLists"
					[intersectedItemIds]="intersectedItemIds"
					[expandedItemIds]="expandedItemIds"
					[isDragging]="isDragging"
					[suiPage]="suiPage"
					[tagGroups]="tagGroups"
					[editTracker]="editTracker"
					(dragDrop)="onDragDrop($event)"
					(dragEnded)="onDragEnded($event)"
					(dragStarted)="onDragStarted($event)"
					(dragMove)="onDragMove($event)"
					(expandedClick)="onToggleExpanded($event)"
					(refreshOnModalClose)="onRefreshOnModalClose($event)"
					(editTracker)="editTracker"
				></app-modal-edit-sui-page-elements-element>
			</div>
		</mat-expansion-panel>
		<div class="item-drag-preview ps-4" *cdkDragPreview>
			<span class="drag-thumb"></span>
			<h4 class="mt-2">{{ child.type }}</h4>
		</div>
		<div class="item-placeholder" *cdkDragPlaceholder></div>
	</li>
</ul>
<div id="screenshotView" *ngIf="screenshotToPreview" (click)="screenshotToPreview = null">
	<img id="screenshotViewImg" alt="" [src]="screenshotToPreview.safeUrl" [title]="screenshotToPreview.name" />
</div>
