import { KeyValue, KeyValuePipe } from '@angular/common';
import { KeyValueDiffers, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filteroutkeyvalue',
	standalone: true
})

// This pipe uses the angular keyvalue pipe. but defaults to unordered.
export class FilterOutKeyValuePipe implements PipeTransform {
	constructor(public differs: KeyValueDiffers) {}

	public transform(
		value: unknown,
		filter: Record<string, string | string[]>
	): KeyValue<unknown, unknown>[] {
		const pipe = new KeyValuePipe(this.differs);

		return pipe
			.transform(value, () => 0)
			.filter((value) => {
				if (filter.value instanceof Array) {
					return filter.value.filter((val) => val === value.key).length
						? false
						: true;
				} else {
					return value.key !== filter.value;
				}
			});
	}
}
