/* eslint-disable functional/immutable-data */
import {
	CdkDragDrop,
	CdkDragEnd,
	CdkDragMove,
	CdkDragStart
} from '@angular/cdk/drag-drop';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { SuiElement } from '@testifi-models/sui-element';
import { Tag } from '@testifi-models/tag';
import { TestObject } from '@testifi-models/test-object';
import { TestStepLibrary } from '@testifi-models/test-step-library';
import { Utils } from '@testifi-utils/utils';
import { produce } from 'immer';
import { BehaviorSubject } from 'rxjs';

export interface LevelItemElement {
	structureId: string;
	children: LevelItemElement[];
}

interface DragDropElementLike<T> {
	structureId: string;
	children: DragDropElementLike<T>[];
}

export class LevelItem {
	level: number;
	item: LevelItemElement;

	constructor(options: { item: LevelItemElement; level: number }) {
		this.level = options.level;
		this.item = options.item;
	}
}

export const STRUCTURE_ID_PREFIX = new InjectionToken<string>(
	'Drag and drop item structure id prefix'
);

@Injectable()
export class NestedDragNDropService {
	intersectedItemIds: string[] = [];
	// expandedItemIds: string[] = [];
	lastExpandedItemIds: string[];
	isDragging = false;
	elements: LevelItem[];
	parentItem: LevelItemElement;
	tags: Tag[] = [];
	libraries = new Map<string, TestStepLibrary[]>();
	private expandedItemIdsBS$ = new BehaviorSubject<string[]>([]);
	expandedItemIds$ = this.expandedItemIdsBS$.asObservable();

	constructor(@Inject(STRUCTURE_ID_PREFIX) private structureIdPrefix: string) {}

	private _isNested = false;

	get isNested(): boolean {
		return this._isNested;
	}

	set isNested(value: boolean) {
		this._isNested = value;
	}

	get expandedItemIds(): string[] {
		return this.expandedItemIdsBS$.getValue();
	}

	set expandedItemIds(value: string[]) {
		this.expandedItemIdsBS$.next(value);
	}

	get connectedDropListsIds(): string[] {
		// We reverse ids here to respect items nesting hierarchy
		return [...this.getIdsRecursive(this.parentItem)].reverse();
	}

	onToggleExpanded(id: string): void {
		if (this.expandedItemIds.includes(id)) {
			this.expandedItemIds = this.expandedItemIds.filter(
				(expandedId) => expandedId !== id
			);
		} else {
			this.expandedItemIds = produce(this.expandedItemIds, (draft) => {
				draft.push(id);
			});
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onDragEnded(event: CdkDragEnd<LevelItemElement>): void {
		this.intersectedItemIds = [];
		this.expandedItemIds = this.lastExpandedItemIds;
		this.isDragging = false;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onDragStarted(event: CdkDragStart<LevelItemElement>): void {
		this.elements = [...this.getRecursive(this.parentItem, 0)].reverse();
		this.lastExpandedItemIds = this.expandedItemIds;
		this.expandedItemIds = this.getIdsRecursive(this.parentItem);

		this.isDragging = true;
	}

	onDragMove(event: CdkDragMove<LevelItemElement>): void {
		this.handleDrag(event);
	}

	onLibDragEnded(): void {
		this.expandedItemIds = this.lastExpandedItemIds;
		this.intersectedItemIds = [];
		this.isDragging = false;
	}

	onLibDragMove(event: CdkDragMove): void {
		this.handleDrag(event);
	}

	onLibDragStarted(event: CdkDragStart): void {
		this.onDragStarted(event);
	}

	checkParentageFromLibrary(tagName: string): boolean {
		for (const libraryName of Array.from(this.libraries.keys())) {
			for (const library of this.libraries.get(libraryName)) {
				if (library.tag === tagName) {
					return library.isNode;
				}
			}
		}

		return true;
	}

	handleExpandOnMove<T extends DragDropElementLike<T>>(
		event: CdkDragDrop<T, T, T>,
		structureIdsTransitions: string[][]
	): void {
		this.updateExpandedIds(structureIdsTransitions);

		// collapse prev container if get empty
		const containerChanged = event.container !== event.previousContainer;
		if (containerChanged && !event.previousContainer.data.children.length) {
			this.expandedItemIds = this.expandedItemIds.filter((id) => {
				const updatedStructureId = Utils.findUpdatedStructureId(
					event.previousContainer.data.structureId,
					structureIdsTransitions
				);
				if (updatedStructureId) {
					return updatedStructureId !== id;
				} else {
					return event.previousContainer.data.structureId !== id;
				}
			});
		}

		// expand dropped item parents
		this.expandParents(
			Utils.getStructureIdList(
				Utils.findUpdatedStructureId(
					event.item.data.structureId,
					structureIdsTransitions
				)
			)
		);
	}

	handleExpandOnAdd(
		updatedStructureId: string,
		structureIdsTransitions: string[][]
	): void {
		this.updateExpandedIds(structureIdsTransitions);

		this.expandParents(Utils.getStructureIdList(updatedStructureId));
	}

	handleExpandOnRemove<T>(
		removedItemStructureId: string,
		rootChildren: DragDropElementLike<T>[],
		structureIdsTransitions: string[][] = []
	): void {
		// remove deletedItem structureId and all children structureIds from expanded
		this.expandedItemIds = this.expandedItemIds.filter(
			(structureId) => !structureId.includes(removedItemStructureId)
		);

		this.updateExpandedIds(structureIdsTransitions);

		// remove parent structureId from expanded if parent does not have children anymore
		const parentStructureIdList = Utils.getStructureIdList(
			removedItemStructureId
		)
			.slice(0, -1)
			.join('.');
		const parentStructureId = `${this.structureIdPrefix}${parentStructureIdList}`;
		const parentIsRoot = parentStructureIdList.length === 1;
		let parentChildren: DragDropElementLike<T>[];
		if (parentIsRoot) {
			parentChildren = rootChildren;
		} else {
			const parent = this.findParent(rootChildren, parentStructureId);
			parentChildren = parent.children;
		}
		if (!parentChildren.length) {
			this.expandedItemIds = this.expandedItemIds.filter(
				(structureId) => structureId !== parentStructureId
			);
		}
	}

	expand(structureIds: string[]) {
		this.expandedItemIds = structureIds;
	}

	collapse(allStructureIds: string[]) {
		this.expandedItemIds = this.expandedItemIds.filter(
			(structureId) => !allStructureIds.includes(structureId)
		);
	}

	private updateExpandedIds(structureIdsTransitions: string[][]): void {
		if (!structureIdsTransitions.length) {
			return;
		}
		// keep old expanded items. update old structureId with new ones
		const oldPositions = structureIdsTransitions.map(
			(transition) => transition[0]
		);
		const updatedPositions = structureIdsTransitions.map(
			(transition) => transition[1]
		);
		this.expandedItemIds = this.expandedItemIds.map((expandedItemId) => {
			const oldPositionIndex = oldPositions.indexOf(expandedItemId);
			return oldPositionIndex !== -1
				? updatedPositions[oldPositionIndex]
				: expandedItemId;
		});
	}

	private expandParents(updatedStructureIdList: number[]) {
		const parentStructureIds: string[] = [];
		updatedStructureIdList.forEach((id) => {
			const previousLevel =
				parentStructureIds[parentStructureIds.length - 1] ?? '';
			const pushed = `${previousLevel}${previousLevel ? '.' : ''}${id}`;
			parentStructureIds.push(pushed);
		});
		parentStructureIds.pop(); // item itself doesn't need to expand, only parents
		parentStructureIds.shift(); // root element doesn't need to expand
		const newExpandedIds = parentStructureIds.map(
			(structureId) => `${this.structureIdPrefix}${structureId}`
		);
		newExpandedIds.forEach((id) => {
			if (!this.expandedItemIds.includes(id)) {
				this.expandedItemIds = [...this.expandedItemIds, id];
			}
		});
	}

	private findParent<T>(
		children: DragDropElementLike<T>[],
		structureId: string
	): DragDropElementLike<T> {
		for (const child of children) {
			if (child.structureId === structureId) {
				return child;
			} else {
				const parent = this.findParent(child.children, structureId);
				if (parent) {
					return parent;
				}
			}
		}

		return undefined;
	}

	private previewElement(): Element {
		return document.getElementsByClassName('cdk-drag-preview')[0];
	}

	private getNestedLevel(event: CdkDragMove): number {
		const previewElement = this.previewElement();
		let nestedLevel = 0;

		this.elements.forEach((element) => {
			if (
				event.source.data === undefined ||
				(event.source.data !== undefined &&
					event.source.data.structureId !== element.item.structureId) // eslint-disable-line
			) {
				const nativeElement = document.getElementById(element.item.structureId);

				if (
					element.level > nestedLevel &&
					this.inside(previewElement, nativeElement)
				) {
					nestedLevel = element.level;
				}
			}
		});
		return nestedLevel;
	}

	private handleDrag(event: CdkDragMove): void {
		let newIntersectedIds: string[] = [];
		const previewElement = this.previewElement();
		const nestedLevel = this.getNestedLevel(event);

		this.elements.forEach((element) => {
			if (
				event.source.data === undefined ||
				(event.source.data !== undefined &&
					event.source.data.structureId !== element.item.structureId && // eslint-disable-line
					element.level >= nestedLevel - 1)
			) {
				const nativeElement = document.getElementById(element.item.structureId);

				if (
					this.collide(previewElement, nativeElement) ||
					this.inside(previewElement, nativeElement)
				) {
					newIntersectedIds = produce(newIntersectedIds, (draft) => {
						draft.push(element.item.structureId);
					});
				}
			}
		});

		this.intersectedItemIds = newIntersectedIds;
		this.isNested = nestedLevel > 0;
	}

	private collide(el1: Element, el2: Element): boolean {
		if (!el1 || !el2) {
			return false;
		}

		const rect1 = el1.getBoundingClientRect();
		const rect2 = el2.getBoundingClientRect();
		const rect1HeightOffset = 0;

		return !(
			rect1.top - rect1HeightOffset > rect2.bottom ||
			rect1.right < rect2.left ||
			rect1.bottom - rect1HeightOffset < rect2.top ||
			rect1.left > rect2.right
		);
	}

	private inside(el1: Element, el2: Element): boolean {
		if (!el1 || !el2) {
			return false;
		}

		const rect1 = el1.getBoundingClientRect();
		const rect2 = el2.getBoundingClientRect();
		const rect1HeightOffset = 0;

		return (
			rect2.top <= rect1.top - rect1HeightOffset &&
			rect1.top - rect1HeightOffset <= rect2.bottom &&
			rect2.top <= rect1.bottom - rect1HeightOffset &&
			rect1.bottom - rect1HeightOffset <= rect2.bottom &&
			rect2.left <= rect1.left &&
			rect1.left <= rect2.right &&
			rect2.left <= rect1.right &&
			rect1.right <= rect2.right
		);
	}

	private getIdsRecursive(item: LevelItemElement): string[] {
		let structureIds = [item.structureId];
		item.children.forEach((childItem) => {
			if (
				childItem instanceof SuiElement ||
				(childItem instanceof TestObject &&
					this.checkParentageFromLibrary(childItem.name))
			) {
				structureIds = structureIds.concat(this.getIdsRecursive(childItem));
			}
		});
		return structureIds;
	}

	private getRecursive(item: LevelItemElement, level: number): LevelItem[] {
		const newLevel = level + 1;
		let structureIds = [new LevelItem({ item, level })];
		item.children.forEach((childItem) => {
			structureIds = structureIds.concat(
				this.getRecursive(childItem, newLevel)
			);
		});
		return structureIds;
	}
}
