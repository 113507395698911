import {
	Directive,
	ElementRef,
	forwardRef,
	HostListener,
	Renderer2
} from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
	selector: 'input[trimOnPaste],textarea[trimOnPaste]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => TrimOnPasteDirective)
		}
	],
	standalone: true
})
export class TrimOnPasteDirective extends DefaultValueAccessor {
	constructor(
		private element: ElementRef<HTMLInputElement>,
		private renderer: Renderer2
	) {
		super(renderer, element, false);
	}
	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
		const target = this.element.nativeElement;
		const currentValue = target.value;
		const selectionStart = target.selectionStart;
		const selectionEnd = target.selectionEnd;
		const pastedValue = event.clipboardData?.getData('text') || '';

		const validForTrim =
			!currentValue ||
			(selectionStart === 0 && selectionEnd === currentValue.length);

		if (validForTrim) {
			// eslint-disable-next-line functional/immutable-data
			target.value = pastedValue.trim();
			this.onChange(pastedValue.trim());
			event.preventDefault();
		}
	}
}
