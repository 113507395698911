import { JsonObject, JsonProperty } from 'json2typescript';
import { SuiElement } from './sui-element';

@JsonObject('SuiPage')
export class SuiPage {
	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('name', String, true)
	name: string = undefined;

	@JsonProperty('projectId', String)
	projectId: string = undefined;

	@JsonProperty('children', [SuiElement])
	children: SuiElement[] = [];
}
