import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup
} from '@angular/forms';
import { ScenarioParameter } from '@testifi-models/scenario-parameter';
import {
	ID_VALIDATORS,
	VALUE_VALIDATORS,
	duplicateStringValidator,
	reservedParameterNameValidator
} from '@testifi-shared/app-constants';
import { SvgIconComponent } from 'angular-svg-icon';
import { AutofocusDirective } from '../../../directives/autofocus.directive';
import { ValidationErrorsComponent } from '../../validation-errors/validation-errors.component';

@Component({
	selector: 'app-parameter-editor',
	templateUrl: './parameter-editor.component.html',
	styleUrls: ['./parameter-editor.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		AutofocusDirective,
		ValidationErrorsComponent,
		SvgIconComponent
	]
})
export class ParameterEditorComponent implements OnInit {
	@Input() parameter: ScenarioParameter;
	@Input() existingKeys: string[];

	@Output() submitEdit = new EventEmitter<ScenarioParameter>();
	@Output() cancel = new EventEmitter();

	form: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder) {}

	ngOnInit(): void {
		if (!this.existingKeys) {
			this.existingKeys = [];
		}

		this.form = this.formBuilder.group({
			parameterKey: [
				'',
				[
					...ID_VALIDATORS,
					reservedParameterNameValidator,
					duplicateStringValidator(this.existingKeys)
				]
			],
			parameterValue: ['', [...VALUE_VALIDATORS]]
		});

		if (this.parameter) {
			this.form.get('parameterKey').setValue(this.parameter.key);
			this.form.get('parameterValue').setValue(this.parameter.value);
		}
	}

	onSubmit(): void {
		if (this.form.invalid) {
			return;
		} else if (!this.parameter) {
			this.parameter = new ScenarioParameter(
				this.form.get('parameterKey').value as string,
				this.form.get('parameterValue').value as string
			);
		} else {
			this.parameter.key = this.form.get('parameterKey').value as string;
			this.parameter.value = this.form.get('parameterValue').value as string;
		}
		this.submitEdit.emit(this.parameter);
	}
}
