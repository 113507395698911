import { ChangeDetectorRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
	private loadingBS$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private silent = false;

	get(): Observable<boolean> {
		return this.loadingBS$.asObservable();
	}

	set(loading: boolean): void {
		this.loadingBS$.next(loading);
	}

	get isSilent(): boolean {
		return this.silent;
	}

	get isActive(): boolean {
		return this.loadingBS$.getValue();
	}

	active(silent = false): void {
		this.silent = silent;
		this.set(true);
	}

	deactive(cd: ChangeDetectorRef): void {
		this.set(false);
		cd.detectChanges();
	}
}
