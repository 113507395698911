/* eslint-disable functional/immutable-data */
import { RestructureResponseInterface } from '@testifi-models/restructure-response.interface';
import { immerable, produce } from 'immer';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Parameter } from './parameter';
import { ValidationType, ValidationTypeConverter } from './validation-type';

@JsonObject('TestObject')
export class TestObject {
	static readonly STRUCTURE_ID_PREFIX = 'testobject_';
	[immerable] = true;
	structureId: string = undefined;
	nested = false;

	@JsonProperty('index', Number, true)
	index: number = undefined;

	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('description', String, true)
	description: string = undefined;

	@JsonProperty('parameter', [Parameter], true)
	parameters: Parameter[] = [];

	libid = '';
	libgroup = '';
	parent: TestObject;

	@JsonProperty('validation', ValidationTypeConverter, true)
	validationType: ValidationType = ValidationType.notValidated;

	@JsonProperty('childTestObjects', [TestObject])
	children: TestObject[] = [];

	get displayValidationType(): string {
		return ValidationType[this.validationType]?.toString();
	}

	removeChild(structureId: string): RestructureResponseInterface<TestObject> {
		this.children = this.findAndRemoveChild(structureId, this.children);
		return this.restructureAndGetStructureIdTransitions();
	}

	updateParameter(
		structureId: string,
		parameters: Record<string, string>
	): TestObject[] {
		return this.findAndUpdateParameter(structureId, parameters, this.children);
	}

	restructure(): TestObject {
		return this.applyObjectStructureIds(this, 0, '0', []).updated;
	}

	restructureAndGetStructureIdTransitions(): RestructureResponseInterface<TestObject> {
		return this.applyObjectStructureIds(this, 0, '0', []);
	}

	private findAndUpdateParameter(
		structureId: string,
		parameters: Record<string, string>,
		items: TestObject[]
	): TestObject[] {
		return items.map((item) => {
			if (item.structureId === structureId) {
				return produce(item, (draft) => {
					draft.parameters = this.createParametersList(parameters, item);
				});
			} else {
				return produce(item, (draft) => {
					draft.children = this.findAndUpdateParameter(
						structureId,
						parameters,
						item.children
					);
				});
			}
		});
	}

	private createParametersList(
		parameters: Record<string, string>,
		child: TestObject
	): Parameter[] {
		return Object.keys(parameters).map((key) => {
			const oldParameter = child.parameters.find(
				(parameter) => parameter.name === key
			);

			return new Parameter(
				key,
				parameters[key],
				oldParameter ? oldParameter.validationType : ValidationType.notValidated
			);
		});
	}

	private findAndRemoveChild(
		structureId: string,
		items: TestObject[]
	): TestObject[] {
		let filteredItems = items.filter((el) => el.structureId !== structureId);
		if (filteredItems.length === items.length) {
			filteredItems = filteredItems.map((item) =>
				produce(item, (draft) => {
					draft.children = this.findAndRemoveChild(structureId, item.children);
				})
			);
		}

		return filteredItems;
	}

	private applyObjectStructureIds(
		item: TestObject,
		index: number,
		structureId: string,
		structureIdTransitions: string[][]
	): RestructureResponseInterface<TestObject> {
		let subId = 0;
		const updated = produce(item, (draft) => {
			draft.index = index;
			const updatedStructureId = `${TestObject.STRUCTURE_ID_PREFIX}${structureId}`;
			if (draft.structureId !== updatedStructureId) {
				structureIdTransitions = [
					...structureIdTransitions,
					[draft.structureId, updatedStructureId]
				];
				draft.structureId = updatedStructureId;
			}
			draft.children = item.children.map((child) => {
				const {
					updated,
					structureIdTransitions: updatedStructureIdTransitions
				} = this.applyObjectStructureIds(
					child,
					subId++,
					`${structureId}.${subId - 1}`,
					structureIdTransitions
				);
				structureIdTransitions = updatedStructureIdTransitions;
				return produce(updated, (childDraft) => {
					childDraft.parent = item;
					childDraft.nested = structureId.indexOf('.') >= 0;
				});
			});
		});
		return { updated, structureIdTransitions };
	}
}

export interface ICreateTestScenarioWithStepsObjectRequest {
	children: ICreateTestScenarioWithStepsObjectRequest[];
	index: number;
	parameter: Record<string, string>;
	tag: string;
	uiTag: string;
}
