/* eslint-disable functional/immutable-data */
import { NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
	MatLegacyAutocompleteModule
} from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import {
	NgOption,
	NgSelectComponent,
	NgSelectModule
} from '@ng-select/ng-select';
import { SuiElement } from '@testifi-models/sui-element';
import { XmlElements } from '@testifi-models/xml-elements';
import { ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import { ID_VALIDATORS, VALUE_VALIDATORS } from '@testifi-shared/app-constants';
import { produce } from 'immer';
import { has } from 'lodash-es';
import { Subscription } from 'rxjs';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';
import { SuiPageService } from './../../services/sui-page.service';

interface ModalSuiPageElementParameterComponentData {
	suiElement?: SuiElement;
	suiPageId?: string;
	suiElementParameterIndex?: number;
	suiElementParameters?: Map<string, Array<string>>;
	suiRootElement?: SuiElement;
}

@Component({
	selector: 'app-modal-sui-page-element-parameter',
	templateUrl: './modal-sui-page-element-parameter.component.html',
	styleUrls: ['./modal-sui-page-element-parameter.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		NgFor,
		ValidationErrorsComponent,
		NgIf,
		MatLegacyAutocompleteModule,
		MatLegacyOptionModule
	]
})
export class ModalSuiPageElementParameterComponent
	implements OnInit, OnDestroy
{
	// ======================================================================
	// public properties
	// ======================================================================

	form: UntypedFormGroup;
	submitted = false;
	values: string[] = [];
	element: SuiElement;
	rootItem: SuiElement;
	suiPageId = '';
	parameterIndex = 0;
	parameters = new Map<string, Array<string>>();
	parameterTypes: string[] = [];
	attributeTypes: string[] = [];
	isAttributeActive = false;
	@ViewChild('attributeTypeSelect') attributeTypeSelect: NgSelectComponent;
	@ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
	autocompleteText: MatAutocompleteTrigger;
	@ViewChild('typeSelection') typeSelection: NgSelectComponent;
	@ViewChild('valueInput') valueInput: ElementRef;

	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private cd: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private notificationService: NotificationService,
		private modalService: ModalService,
		private suiPageService: SuiPageService,
		@Inject(MAT_DIALOG_DATA)
		public data: ModalSuiPageElementParameterComponentData
	) {}

	// ======================================================================
	// getter
	// ======================================================================

	get f(): { [key: string]: AbstractControl } {
		return this.form.controls;
	}

	get isNew(): boolean {
		return this.parameterIndex === null || this.parameterIndex === undefined;
	}

	// ======================================================================
	// inherit functions
	// ======================================================================

	ngOnInit(): void {
		this.element = this.data.suiElement;
		this.parameterIndex = this.data.suiElementParameterIndex;

		if (!this.isNew && has(this.element.parameter, 'schema')) {
			this.parameterIndex = this.data.suiElementParameterIndex + 1;
		}

		this.rootItem = this.data.suiRootElement;

		if (this.data.suiElementParameters) {
			this.parameters = this.data.suiElementParameters;
		}

		this.parameterTypes = this.filterTypes(Array.from(this.parameters.keys()));
		this.attributeTypes = Array.from(this.parameters.keys())
			.filter((param) => param.startsWith('attribute:'))
			.map((param) => param.split(':')[1])
			.sort((a, b) => a.localeCompare(b))
			.map((attribute) => this.normalizeAttributeAutocompleteValue(attribute));

		this.initParameterForm();

		setTimeout(() => this.setDefaultFocus());
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	// ======================================================================
	// change functions
	// ======================================================================

	onAutocompleteArrowClick(event: Event): void {
		event.stopPropagation();
		this.autocompleteText.panelOpen
			? this.autocompleteText.closePanel()
			: this.autocompleteText.openPanel();
	}

	onTypeChange(value: string): void {
		if (value) {
			// if attribute was selected previously reset it
			if (this.isAttributeActive) {
				this.f.attribute.reset();
				this.f.attribute.setValidators([]);
				this.f.attribute.updateValueAndValidity();
			}

			this.isAttributeActive = value === 'attribute';

			if (this.isAttributeActive) {
				this.f.attribute.setValidators([Validators.required]);
				this.f.value.setValidators([...VALUE_VALIDATORS, Validators.required]);
			} else {
				this.values = [...this.parameters.get(value)].sort((a, b) =>
					a.localeCompare(b)
				);
				this.f.value.setValue(this.values[0]);
				if (value === 'id') {
					this.f.value.setValidators(ID_VALIDATORS);
				} else {
					this.f.value.setValidators([
						...VALUE_VALIDATORS,
						Validators.required
					]);
				}
				this.f.attribute.setValidators([]);
				this.setDefaultFocus();
			}
		}
	}

	onSearch(value: { term: string; items: [] }): void {
		if (!value.term.length) {
			const emptyOption: NgOption = {
				index: -1,
				value: '',
				label: ''
			};
			this.typeSelection.select(emptyOption);
		}
	}

	onDelete(): void {
		let parameters = this.createParameters();

		parameters = produce(parameters, (draft) => {
			delete draft[Object.keys(parameters)[this.parameterIndex]];
		});

		this.updateElement(parameters);
	}

	submit(): void {
		this.submitted = true;

		if (this.form.pristine) {
			this.modalService.close(0);
		} else {
			this.updateElement(this.createParameters());
		}
	}

	focused(trg: MatAutocompleteTrigger): void {
		setTimeout(() => {
			trg.closePanel();
		});
	}

	onCancel(): void {
		this.modalService.close(0);
	}

	// ======================================================================
	// private functions
	// ======================================================================
	private initParameterForm(): void {
		let type =
			this.parameterTypes.length === this.parameters.size
				? 'id'
				: this.parameterTypes[0];
		let value: string | null = null;
		let attribute: string | null = null;

		if (!this.isNew) {
			let index = 0;
			for (const parameter in this.element.parameter) {
				if (index === this.parameterIndex) {
					value = this.element.parameter[parameter];

					if (parameter.includes('attribute:')) {
						type = 'attribute';
						attribute = parameter.replace('attribute:', '');
					} else {
						type = parameter;
					}
				}
				++index;
			}
		}

		this.isAttributeActive = type === 'attribute';

		if (this.parameters.has(type) && this.parameters.get(type).length) {
			this.values = [...this.parameters.get(type)].sort((a, b) =>
				a.localeCompare(b)
			);
		}

		this.suiPageId = this.data.suiPageId;

		this.form = this.initForm(value, type, attribute);
	}

	private initForm(
		value: string,
		type: string,
		attribute: string
	): UntypedFormGroup {
		return this.formBuilder.group({
			value: [
				value ?? '',
				type === 'id'
					? ID_VALIDATORS
					: [...VALUE_VALIDATORS, Validators.required]
			],
			type: [type ?? '', [Validators.required]],
			attribute: [
				attribute ?? '',
				this.isAttributeActive ? [Validators.required] : []
			]
		});
	}

	private setDefaultFocus(): void {
		this.cd.detectChanges();

		const input = this.valueInput?.nativeElement as HTMLInputElement;
		input?.focus();
	}

	private updateElement(parameters: Record<string, string>) {
		this.rootItem = produce(this.rootItem, (draft) => {
			draft.children = this.rootItem.updateParameter(
				this.element.structureId,
				parameters
			);
		});

		this.disposableBag.add(
			this.suiPageService
				.updateElements(
					this.suiPageId,
					new XmlElements().fromSuiElement(this.rootItem)
				)
				.subscribe(
					() => {
						this.notificationService.info('Parameter changed');
						this.modalService.close(200);
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
						this.modalService.close(err.status);
					}
				)
		);
	}

	private createParameters(): Record<string, string> {
		const elementParameters = this.element.parameter;

		let counter = 0;
		let newElementParameters: Record<string, string> = {};

		// existing parameters
		for (const parameter in elementParameters) {
			if (counter === this.parameterIndex) {
				newElementParameters = this.assignNewParameter(newElementParameters);
			} else {
				newElementParameters = produce(newElementParameters, (draft) => {
					draft[parameter] = elementParameters[parameter];
				});
			}
			++counter;
		}

		if (this.isNew) {
			newElementParameters = this.assignNewParameter(newElementParameters);
		}

		return newElementParameters;
	}

	private assignNewParameter(
		parameters: Record<string, string>
	): Record<string, string> {
		const attribute = this.f.attribute.value as string;
		const value = this.f.value.value as string;
		const type = this.f.type.value as string;

		return produce(parameters, (draft) => {
			if (this.isAttributeActive) {
				draft['attribute:' + attribute] = value;
			} else {
				draft[type] = value;
			}
		});
	}

	private normalizeAttributeAutocompleteValue(value: string): string {
		return (value ?? '').replace(/\s/g, '');
	}

	private filterTypes(types: string[]): string[] {
		let parameters: string[] = [];
		for (const parameterName in this.element.parameter) {
			parameters = produce(parameters, (draft) => {
				draft.push(parameterName);
			});
		}

		let attributePresent = false;
		const attributeTypesRemoved = types.filter((type) => {
			if (!attributePresent && type.startsWith('attribute:')) {
				attributePresent = true;
			}
			return !parameters.includes(type) && !type.startsWith('attribute:');
		});

		return attributePresent
			? [...attributeTypesRemoved, 'attribute'].sort((a, b) =>
					a.localeCompare(b)
				)
			: [...attributeTypesRemoved].sort((a, b) => a.localeCompare(b));
	}
}
