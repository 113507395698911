import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	ControlValueAccessor,
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.less'],
	standalone: true,
	imports: [
		FlexModule,
		NgIf,
		FormsModule,
		ReactiveFormsModule,
		NgClass,
		ExtendedModule
	]
})
export class CheckboxComponent implements ControlValueAccessor {
	// ======================================================================
	// public properties
	// ======================================================================

	@Input() name: string;
	@Input() label = '';
	@Input() formControlName: string = null;
	@Input() checked = false;
	@Input() isCenter = true;

	@Output() stateChanged = new EventEmitter<boolean>();

	// ======================================================================
	// constructor
	// ======================================================================

	// ======================================================================
	// inherit functions
	// ======================================================================

	writeValue(value: boolean): void {
		this.checked = value;
	}

	registerOnChange(fn: any): void {} // eslint-disable-line

	registerOnTouched(fn: any): void {} // eslint-disable-line

	setDisabledState(isDisabled: boolean): void {} // eslint-disable-line

	// ======================================================================
	// public functions
	// ======================================================================

	change(): void {
		this.checked = !this.checked;
		this.stateChanged.emit(this.checked);
	}
}
