import {
	JsonConvert,
	JsonConverter,
	JsonCustomConvert,
	JsonObject,
	JsonProperty
} from 'json2typescript';
import { Tag } from './tag';

@JsonConverter
class TagGroupsTagsConverter
	implements JsonCustomConvert<Map<string, Array<Tag>>> {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	serialize(tags: Map<string, Array<Tag>>): string {
		return '';
	}

	deserialize(tagObjects: any): Map<string, Array<Tag>> {
		const tagGroups = new Map<string, Array<Tag>>();
		const jsonConvert: JsonConvert = new JsonConvert();

		for (const tagGroupName in tagObjects) {
			const tags = new Array<Tag>();

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			for (const tagObject of tagObjects[tagGroupName]) {
				tags.push(jsonConvert.deserializeObject(tagObject, Tag));
			}

			tagGroups.set(tagGroupName, tags);
		}
		return tagGroups;
	}
}

@JsonObject('TagGroups')
export class TagGroups {
	@JsonProperty('tags', TagGroupsTagsConverter)
	tagGroups = new Map<string, Array<Tag>>();
}
