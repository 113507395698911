import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { TData, TDataSelector } from '@testifi-models/data.model';
import { TDataRepository } from '@testifi-store/data/data.repository.type';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DataRepository {
	static readonly INITIAL_VALUE: TDataRepository = {
		loading: false,
		loaded: false,
		data: {}
	};

	private store = createStore(
		{ name: 'data' },
		withProps<TDataRepository>(DataRepository.INITIAL_VALUE)
	);

	loading$ = this.store.pipe(select((state) => state.loading));
	loaded$ = this.store.pipe(select((state) => state.loaded));

	state$ = this.store.pipe(select((state) => state));
	dataFiles$ = this.store.pipe(select((state) => state.data));

	get state(): TDataRepository {
		return this.store.getValue();
	}

	get loaded(): boolean {
		return this.state.loaded;
	}

	values(dataFileName: string, selector: string) {
		return this.store.query(values(dataFileName, selector));
	}

	values$(dataFileName: string, selector: string): Observable<string[]> {
		return this.store.pipe(select(values(dataFileName, selector)));
	}

	getDataFromId(dataFileId: string): TData {
		return this.store.query(getDataFromId(dataFileId));
	}

	getDataFromId$(dataFileId: string): Observable<TData> {
		return this.store.pipe(select(getDataFromId(dataFileId)));
	}

	getDataFromName(dataFileName: string): TData {
		return this.store.query(getDataFromName(dataFileName));
	}

	getDataFromName$(dataFileName: string): Observable<TData> {
		return this.store.pipe(select(getDataFromName(dataFileName)));
	}

	getDataFileId(dataFileName: string) {
		return this.store.query((state) => {
			const dataFile = Object.values(state.data).find(
				(d) => d.name === dataFileName
			);
			return dataFile?.id;
		});
	}

	setData(newData: Record<string, TData>): void {
		this.store.update((state) => ({
			...state,
			loaded: true,
			data: {
				...state.data,
				...newData
			}
		}));
	}

	setSelectors(dataFileName: string, selectors: TDataSelector[]): void {
		this.store.update((state) => ({
			...state,
			data: {
				...state.data,
				[dataFileName]: {
					...state.data[dataFileName],
					selectors
				}
			}
		}));
	}

	setLoading(loading: boolean): void {
		this.store.update((state) => ({
			...state,
			loading
		}));
	}

	setValues(dataFileName: string, selector: string, values: string[]) {
		this.store.update((state) => {
			let allSelectors = state?.data?.[dataFileName]?.selectors ?? [];
			const selectorToUpdateIndex = allSelectors.findIndex(
				(s) => s.selector === selector
			);
			if (selectorToUpdateIndex === -1) {
				allSelectors = [
					...allSelectors,
					{
						selector,
						defaultValue: '',
						values
					}
				];
			} else {
				allSelectors[selectorToUpdateIndex] = {
					...allSelectors[selectorToUpdateIndex],
					values
				};
			}
			return {
				...state,
				data: {
					...state.data,
					[dataFileName]: {
						...state?.data?.[dataFileName],
						selectors: allSelectors
					}
				}
			};
		});
	}
}

function values(
	dataFileName: string,
	selector: string
): (state: TDataRepository) => string[] | undefined {
	return (state) =>
		state.data?.[dataFileName]?.selectors.find((s) => s.selector === selector)
			?.values;
}

function getDataFromId(dataFileId: string): (state: TDataRepository) => TData {
	return (state) => Object.values(state.data).find((d) => d.id === dataFileId);
}

function getDataFromName(
	dataFileName: string
): (state: TDataRepository) => TData {
	return (state) => state.data[dataFileName];
}
