import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import { SuiPageService } from '@testifi-services/sui-page.service';
import { NAME_VALIDATORS } from '@testifi-shared/app-constants';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ModalCloseDirective } from '../../directives/modal-close.directive';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';

@Component({
	selector: 'app-modal-add-sui-page',
	templateUrl: './modal-add-sui-page.component.html',
	styleUrls: ['./modal-add-sui-page.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ValidationErrorsComponent,
		ModalCloseDirective
	]
})
export class ModalAddSuiPageComponent implements OnInit, OnDestroy {
	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	// ======================================================================
	// public properties
	// ======================================================================

	form: UntypedFormGroup;
	submitted = false;

	// ======================================================================
	// getter
	// ======================================================================

	get f(): { [key: string]: AbstractControl } {
		return this.form.controls;
	}

	constructor(
		private cd: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private suiPageService: SuiPageService,
		private notificationService: NotificationService,
		private loadingService: LoadingService,
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA) public data: { projectId: string }
	) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			name: new UntypedFormControl('', NAME_VALIDATORS)
		});
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	// ======================================================================
	// public functions
	// ======================================================================

	add(): void {
		this.submitted = true;

		// stop here if form is invalid
		if (this.form.invalid) {
			return;
		}

		this.loadingService.active();

		this.disposableBag.add(
			this.suiPageService
				.add(this.f.name.value, this.data.projectId)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(suiPage) => {
						this.notificationService.info('SuiPage was created');
						this.modalService.close(suiPage);
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}
}
