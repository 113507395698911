<div id="page-project-settings-component" *hideOnLoading>
	<mat-tab-group color="transparent">
		<mat-tab label="Project Settings - Xray" [disabled]="!projectId">
			<form class="aui" (ngSubmit)="saveXray()">
				<div fxLayout fxLayoutAlign="start center">
					<h2 class="title-margin">Add your status settings for Project</h2>
					<svg-icon class="pointer" name="icon-help" [ngbPopover]="popContent" popoverClass="help"></svg-icon>
				</div>
				<div class="form-box form-area" fxLayout="column">
					<app-status-levels (formData)="onStatusLevelFormData($event)"></app-status-levels>
					<div class="buttons mt-4">
						<button
							type="submit"
							class="aui-button aui-button-primary aui-button-link submit"
							[disabled]="formXray.invalid || submitted"
						>
							<img src="assets/icons/check.svg" alt="cancel" />
							Save changes
						</button>
						<button type="button" class="aui-button aui-button-link cancel" (click)="close()">
							<img src="assets/icons/cancel.svg" alt="cancel" />Cancel
						</button>
					</div>
				</div>
			</form>
		</mat-tab>

		<mat-tab label="Project Settings - GIT" [disabled]="!projectId">
			<form class="aui" (ngSubmit)="saveGIT()">
				<h2 class="title-margin">Add your GIT settings</h2>
				<div class="form-box" fxLayout="column">
					<app-git-settings
						[gitUrl]="(projectRepository.projectSettings$ | async)?.gitUrl"
						[gitBranch]="(projectRepository.projectSettings$ | async)?.gitBranch"
						(formData)="onGitSettingsForm($event)"
					></app-git-settings>
					<div class="buttons mtop">
						<button
							type="submit"
							class="aui-button aui-button-primary aui-button-link submit"
							[disabled]="submitted || formGIT.invalid"
						>
							<img src="assets/icons/check.svg" alt="cancel" />
							Save changes
						</button>
						<button type="button" class="aui-button aui-button-link cancel" (click)="close()">
							<img src="assets/icons/cancel.svg" alt="cancel" />Cancel
						</button>
					</div>
				</div>
			</form>
		</mat-tab>
	</mat-tab-group>
</div>

<ng-template #popContent>
	<div class="help-text" fxLayout="column" fxLayoutAlign="start baseline" fxLayoutGap="8px">
		<div>
			Custom statuses indicate the state of the scenario after execution. Step statuses refer to the state of each test
			step contained in a given scenario.
		</div>
		<div>
			<b>Supported statuses:</b>
			<ul>
				<li>todo: not yet executed</li>
				<li>executing: currently executing</li>
				<li>pass: no errors during execution</li>
				<li>fail: there was at least one error during execution</li>
				<li>aborted: test/test step is skipped eg. with if=”false”</li>
				<li>mismatch: the number of test steps is different in CAST and in XRay</li>
			</ul>
		</div>
	</div>
</ng-template>
