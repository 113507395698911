import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrorsComponent } from '@testifi-shared/validation-errors/validation-errors.component';

export interface IMultiLineEditorComponentMetadata {
	inputLabel: string;
	height?: number;
	maxWidth?: number;
	defaultText?: string;
	textColor?: string;
	breakWords?: boolean;
}

@Component({
	selector: 'app-multi-line-editor',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, ValidationErrorsComponent],
	templateUrl: './multi-line-editor.component.html',
	styleUrls: ['./multi-line-editor.component.less']
})
export class MultiLineEditorComponent
	implements Omit<IMultiLineEditorComponentMetadata, 'inputLabel'>
{
	@Input({ required: true }) control: FormControl<string>;
	@Input() height = 250;
	@Input() maxWidth = 0;
	@Input() defaultText: string;
	@Input() textColor = '#6B778C';
	@Input() breakWords = false;
	@Input() clickable = true;

	@Input() editMode = false;

	@ViewChild('textAreaElement')
	textAreaElement: ElementRef<HTMLTextAreaElement>;

	toggleEditMode() {
		if (this.clickable) {
			this.editMode = !this.editMode;
		}
	}

	focusOnTextArea() {
		setTimeout(() => {
			this.textAreaElement.nativeElement.focus();
		});
	}
}
