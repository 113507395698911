<section
	id="modal-add-sui-page"
	class="aui-dialog2 aui-dialog2-small"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-add-sui-page--heading"
>
	<form class="aui" [formGroup]="form" (ngSubmit)="add()">
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-add-sui-page--heading">New SUI Page</h2>
		</header>
		<!-- Main dialog content -->
		<div class="aui-dialog2-content">
			<div class="field-group">
				<label for="name">Name <span class="aui-icon icon-required">(required)</span></label>
				<input
					title="Please use: (letters numbers -_) only! Must start with letter, but not with 'XML'"
					class="text medium-field"
					type="text"
					formControlName="name"
					id="name"
					placeholder="Name"
				/>
				<app-validation-errors [control]="f.name"></app-validation-errors>
				<div class="description">Max. 200 characters.</div>
			</div>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<div class="aui-dialog2-footer-actions">
				<button type="submit" id="create-submit" class="aui-button aui-button-primary" [disabled]="!form.valid">
					Create
				</button>
				<button type="button" id="cancel" class="aui-button aui-button-subtle" modalClose>Cancel</button>
			</div>
		</footer>
	</form>
</section>
