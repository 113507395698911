/* eslint-disable functional/immutable-data */
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { ValidatorFn } from '@angular/forms';
import { ScenarioParameter } from '@testifi-models/scenario-parameter';
import { produce } from 'immer';
import { has } from 'lodash-es';
import { ParameterEditorComponent } from './parameter-editor/parameter-editor.component';
import { ParameterLabelComponent } from './parameter-label/parameter-label.component';

@Component({
	selector: 'app-parameter-list',
	templateUrl: './parameter-list.component.html',
	styleUrls: ['./parameter-list.component.less'],
	standalone: true,
	imports: [
		FlexModule,
		NgClass,
		ExtendedModule,
		NgIf,
		ParameterEditorComponent,
		NgFor,
		ParameterLabelComponent
	]
})
export class ParameterListComponent implements OnChanges {
	@Input() parameterList: ScenarioParameter[] = [];
	@Input() validators: ValidatorFn[];
	@Input() labelBgColor = '';

	@Output() contentChanged = new EventEmitter<ScenarioParameter[]>();

	editedKey = ''; // empty string when not editing, and the key of the edited property otherwise

	existingKeys: string[] = [];

	isAddNew = false;

	ngOnChanges(changes: SimpleChanges): void {
		if (has(changes, 'parameterList')) {
			this.sortParams();
		}
	}

	onAddClick(): void {
		this.isAddNew = true;
		this.editedKey = null;
	}

	onEditItem(sParam: ScenarioParameter): void {
		this.isAddNew = false;
		this.editedKey = sParam.key;
		this.existingKeys = this.getExistingKeys(this.editedKey);
	}

	onCancelEdit(): void {
		this.isAddNew = false;
		this.editedKey = null;
		this.existingKeys = this.getExistingKeys('');
	}

	onSubmitEdit(sParam: ScenarioParameter): void {
		const parameterList = produce(this.parameterList, (draft) => {
			if (this.isAddNew) {
				draft.push(sParam);
			} else {
				for (let index = 0; index < draft.length; index++) {
					if (draft[index].key === this.editedKey) {
						draft[index] = sParam;
					}
				}
			}
		});
		this.onCancelEdit();
		this.contentChanged.emit(parameterList);
	}

	onRemoveItem(event: ScenarioParameter): void {
		this.parameterList = this.parameterList.filter(
			(parameter) => parameter.key !== event.key
		);
		this.contentChanged.emit(this.parameterList);
	}

	private sortParams(): void {
		this.parameterList = produce(this.parameterList, (draft) => {
			draft.sort((a: ScenarioParameter, b: ScenarioParameter) =>
				a.key.toLowerCase() < b.key.toLowerCase() ? -1 : 1
			);
		});

		this.existingKeys = this.getExistingKeys('');
	}

	private getExistingKeys(except: string): string[] {
		let result: string[] = [];
		for (const parameter of this.parameterList) {
			if (parameter.key !== except) {
				result = [...result, parameter.key];
			}
		}
		return result;
	}
}
