import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withInterceptorsFromDi
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { APPLICATION_INITIALIZER } from '@testifi-services/app.initializer';
import { OAuthModule } from 'angular-oauth2-oidc';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { environment } from '../environments/environment';
import { APP_ROUTES } from './app.routes';
import {
	BASE_URL_INTERCEPTOR,
	PRIVATE_INTERCEPTOR
} from './https/interceptors-injection-tokens';
import { BaseUrlInterceptor } from './https/interceptors/base-url.interceptor';
import { MainInterceptor } from './https/interceptors/main.interceptor';
import { PrivateInterceptor } from './https/interceptors/private.interceptor';

export const APPLICATION_CONFIG: ApplicationConfig = {
	providers: [
		importProvidersFrom(
			BrowserModule,
			FlexLayoutModule, // used everywhere
			NgbModule, // needed for opening dialog for asking sw updated
			MatDialogModule, // needed for notification dialog
			OAuthModule.forRoot({
				resourceServer: {
					allowedUrls: ['/product-catalog/api/'],
					sendAccessToken: false
				}
			}),
			ServiceWorkerModule.register('ngsw-worker.js', {
				enabled: environment.production,
				// Register the ServiceWorker as soon as the application is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: 'registerWhenStable:30000'
			})
		),
		provideAngularSvgIcon(),
		provideRouter(
			APP_ROUTES,
			withRouterConfig({
				onSameUrlNavigation: 'reload'
			})
		),
		APPLICATION_INITIALIZER,
		{
			provide: PRIVATE_INTERCEPTOR,
			useClass: PrivateInterceptor,
			multi: true
		},
		{
			provide: BASE_URL_INTERCEPTOR,
			useClass: BaseUrlInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MainInterceptor,
			multi: true
		},
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi())
	]
};
