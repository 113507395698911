import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbService } from '@testifi-services/breadcrumb.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { RouterNameDirective } from '../../directives/router-name.directive';
import { TrimPipe } from '../../pipes/trim.pipe';
import { UnorderdKeyValuePipe } from '../../pipes/unorder-key-value.pipe';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgFor,
		NgClass,
		ExtendedModule,
		NgIf,
		RouterNameDirective,
		AsyncPipe,
		UnorderdKeyValuePipe,
		TrimPipe,
		SvgIconComponent,
		ClipboardModule,
		MatTooltipModule
	]
})
export class BreadcrumbComponent {
	breadcrumbs$ = this.breadcrumbService.get();

	constructor(
		private breadcrumbService: BreadcrumbService,
		private clipboard: Clipboard
	) {}

	copyLinkToClipboard(
		$event: MouseEvent,
		copyLink: string,
		tooltip: MatTooltip
	) {
		$event.stopPropagation();
		this.clipboard.copy(copyLink);
		this.showCopiedTooltip(tooltip);
	}

	showCopiedTooltip(tooltip: MatTooltip) {
		tooltip.disabled = false;
		tooltip.show();
		setTimeout(() => {
			tooltip.hide();
			tooltip.disabled = true;
		}, 1000);
	}
}
