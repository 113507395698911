import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SuiPageElementIds')
export class SuiPageElementIds {
	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('projectId', String)
	projectId: string = undefined;

	@JsonProperty('elementIdList', [String])
	ids: Array<string> = [];
}
