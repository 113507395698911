<div
	class="test-step"
	[class.draggable]="draggable"
	[ngClass]="{
    'type-building-block': type === types.BuildingBlock
  }"
>
	<mat-expansion-panel
		(click)="onExpand()"
		[expanded]="expanded"
		[disabled]="true"
		[hideToggle]="true"
		class="background-N10"
		[class.draggable]="draggable"
		[togglePosition]="'before'"
	>
		<mat-expansion-panel-header
			collapsedHeight="*"
			expandedHeight="*"
			appendDragThumb
			[html]="draggable ? '<span class=\'drag-thumb\'></span>' : '<span class=\'drag-thumb not-draggable\'></span>'"
			[readOnly]="!showValidation"
		>
			<div class="rel clearfix" [class.d-flex]="!showIndex">
				<div class="prefix">
					<h1 class="title" *ngIf="showIndex">{{ name }}</h1>
					<mat-icon
						[style.visibility]="testObjects.length ? 'visible' : 'hidden'"
						class="transition"
						[ngClass]="expanded ? ['rotate180'] : ['rotate0']"
					>
						<svg-icon class="fill-primary01" name="icon-arrow-down"></svg-icon>
					</mat-icon>
				</div>
				<div class="main" [class.ms-3]="!showIndex" [title]="description">
					<h3>{{ description | quotes }}</h3>
				</div>
				<div class="suffix" *ngIf="editable || deletable">
					<ul class="row-actions inline-right">
						<li
							*ngIf="editable"
							[modalData]="editModalData"
							[modalOnClose]="editModalOnClose"
							[modalRouterName]="editModalRouterName"
							id="editStepIcon"
						>
							<span class="svg">
								<svg-icon class="fill-primary01" name="icon-edit"></svg-icon>
							</span>
						</li>
						<li
							*ngIf="deletable"
							[modalData]="deleteModalData"
							[modalOnClose]="deleteModalOnClose"
							[modalRouterName]="deleteModalRouterName"
							id="deleteStepIcon"
						>
							<span class="svg">
								<svg-icon class="fill-primary01" name="icon-delete"></svg-icon>
							</span>
						</li>
					</ul>
				</div>
			</div>
		</mat-expansion-panel-header>
		<div *ngIf="testObjects.length" class="test-objects">
			<div class="structure-label color-N800" *ngIf="type === types.BuildingBlock">Structure</div>
			<div class="box box-background">
				<ul>
					<li
						*ngFor="let testObject of testObjects; let last = last"
						class="validation-{{ testObject.displayValidationType }}"
					>
						<div class="description">
							<p class="{{ testObject.validationType === validationType.success ? 'color-N200' : 'color-Y300' }}">
								{{ testObject.description | quotes }}
							</p>
						</div>
						<div class="arrows-down" *ngIf="!last">
							<svg-icon class="fill-medium03" name="icon-double-arrow-down"></svg-icon>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</mat-expansion-panel>
</div>
