import { Pipe, PipeTransform } from '@angular/core';
import { Parameter } from '@testifi-models/parameter';
import { TestStepLibrary } from '@testifi-models/test-step-library';
import { Utils } from '@testifi-utils/utils';

@Pipe({
	name: 'sortTestObjectParameter',
	standalone: true
})
export class SortTestObjectParameterPipe implements PipeTransform {
	transform(
		parameters: Parameter[],
		libraryTag: string,
		libraries: Map<string, TestStepLibrary[]>
	): Parameter[] {
		if (
			libraryTag === undefined ||
			libraries === undefined ||
			parameters.length === 0
		)
			return parameters;
		const library = Utils.getLibrary(libraryTag, libraries);
		const libraryMandatoryFields = Object.keys(library.mandatoryFields).map(
			(field) => Utils.getItemName(field)
		);
		const array = [...parameters];
		return array.sort((a, b) => {
			const aIsMandatory = libraryMandatoryFields.includes(a.name);
			const bIsMandatory = libraryMandatoryFields.includes(b.name);
			if (aIsMandatory && !bIsMandatory) {
				return -1;
			} else if (bIsMandatory && !aIsMandatory) {
				return 1;
			}
			return Utils.compareStrings(a.name, b.name, true);
		});
	}
}
