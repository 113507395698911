import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sort',
	standalone: true
})
export class SortPipe implements PipeTransform {
	transform<T>(array: T[], field: keyof T): T[] {
		const arrayCopy = [...array];
		if (!Array.isArray(array)) {
			return;
		}
		// eslint-disable-next-line functional/immutable-data
		return arrayCopy.sort((a: T, b: T) => {
			const fieldA = a[field];
			const fieldB = b[field];

			if (typeof fieldA === 'string' && typeof fieldB === 'string') {
				// Case-insensitive comparison for strings
				return fieldA.toLowerCase().localeCompare(fieldB.toLowerCase());
			}

			if (fieldA < fieldB) {
				return -1;
			} else if (fieldA > fieldB) {
				return 1;
			} else {
				return 0;
			}
		});
	}
}
