<form class="aui top-label" [formGroup]="form">
	<header class="aui-page-header">
		<div class="aui-page-header-inner">
			<div class="aui-page-header-main">
				<h3>Jira/Xray credentials</h3>
			</div>
		</div>
	</header>
	<div class="module form-body">
		<div class="field-group">
			<label class="label" for="email">Email</label>
			<input class="input-text" type="text" formControlName="email" id="email" placeholder="Email" />
			<app-validation-errors [control]="form.get('email')"></app-validation-errors>
		</div>
		<div class="field-group">
			<label class="label" for="token">Token</label>
			<input
				class="input-text"
				[class.input-text-security]="isTokenHidden"
				type="text"
				formControlName="token"
				id="token"
				placeholder="Token"
				onCopy="return false"
				onCut="return false"
				onselectstart="return false"
				onDrag="return false"
				autocomplete="off"
			/>
			<svg-icon
				name="{{ isTokenHidden ? 'eye-on' : 'eye-off' }}"
				class="input-text-icon"
				title="Show token"
				[svgStyle]="{'width.px': 16, 'height.px': 16}"
				(click)="isTokenHidden = !isTokenHidden"
			></svg-icon>
			<app-validation-errors [control]="form.get('token')"></app-validation-errors>
		</div>
		<div class="field-group">
			<label class="label" for="xrayClientId">Xray Client Id</label>
			<input
				class="input-text"
				[class.input-text-security]="isXrayIdHidden"
				type="text"
				formControlName="xrayClientId"
				id="xrayClientId"
				placeholder="Xray Client Id"
				onCopy="return false"
				onCut="return false"
				onselectstart="return false"
				onDrag="return false"
				autocomplete="off"
				trimOnPaste
			/>
			<svg-icon
				name="{{ isXrayIdHidden ? 'eye-on' : 'eye-off' }}"
				class="input-text-icon"
				title="Show Xray Client Id"
				[svgStyle]="{'width.px': 16, 'height.px': 16}"
				(click)="isXrayIdHidden = !isXrayIdHidden"
			></svg-icon>
			<app-validation-errors [control]="form.get('xrayClientId')"></app-validation-errors>
		</div>
		<div class="field-group">
			<label for="xrayClientSecret">Xray Client Secret</label>
			<input
				class="input-text"
				[class.input-text-security]="isXraySecretHidden"
				type="text"
				formControlName="xrayClientSecret"
				id="xrayClientSecret"
				placeholder="Xray Client Secret"
				onCopy="return false"
				onCut="return false"
				onselectstart="return false"
				onDrag="return false"
				autocomplete="off"
				trimOnPaste
			/>
			<svg-icon
				name="{{ isXraySecretHidden ? 'eye-on' : 'eye-off' }}"
				class="input-text-icon"
				title="Show Xray Client Secret"
				[svgStyle]="{'width.px': 16, 'height.px': 16}"
				(click)="isXraySecretHidden = !isXraySecretHidden"
			></svg-icon>
			<app-validation-errors [control]="form.get('xrayClientSecret')"></app-validation-errors>
		</div>
		<div class="test-connection">
			<app-test-connection [state]="testConnectionState" (testing)="testConnection()"></app-test-connection>
		</div>
	</div>
</form>
