import { JsonObject, JsonProperty } from 'json2typescript';
import { BuildingBlock } from './building-block';
import { Screenshot } from './Screenshot';
import { SuiPage } from './sui-page';

@JsonObject('BuildingBlockPage')
export class BuildingBlockPage {
	@JsonProperty('totalNumberofElements', Number)
	totalNumberOfElements: number = undefined;

	@JsonProperty('totalNumberofPages', Number)
	totalNumberOfPages: number = undefined;

	@JsonProperty('buildingBlockList', [BuildingBlock], true)
	content: Array<BuildingBlock> = [];
}

@JsonObject('SUIPagesPage')
export class SUIPagesPage {
	@JsonProperty('totalNumberofElements', Number)
	totalNumberOfElements: number = undefined;

	@JsonProperty('totalNumberofPages', Number)
	totalNumberOfPages: number = undefined;

	@JsonProperty('suiPageList', [SuiPage], true)
	content: Array<SuiPage> = [];
}

@JsonObject('ScreenshotsPage')
export class ScreenshotsPage {
	@JsonProperty('totalNumberofElements', Number)
	totalNumberOfElements: number = undefined;

	@JsonProperty('totalNumberofPages', Number)
	totalNumberOfPages: number = undefined;

	@JsonProperty('screenShotViewList', [Screenshot], true)
	screenShotViewList: Array<Screenshot> = [];
}
