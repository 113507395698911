import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrivateHttpClient } from '../https/instances/private.http';

@Injectable({
	providedIn: 'root'
})
export class LogoutService {
	private readonly privateHttp = inject(PrivateHttpClient);

	logout(email: string, jwt: string): Observable<boolean> {
		window.localStorage.clear();
		return this.privateHttp.post<boolean>('user/logout', {
			email,
			jwt
		});
	}
}
