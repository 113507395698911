import { inject, Injectable } from '@angular/core';
import {
	MOCK_SCENARIO_BMW_1,
	MOCK_SCENARIO_BMW_2,
	MOCK_SCENARIO_BMW_3
} from '@testifi-mocks/test-scenario.mock';
import { TestStep } from '@testifi-models/test-step';
import { Utils } from '@testifi-utils/utils';
import { delay, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrivateHttpClient } from '../https/instances/private.http';
import {
	ICreateTestScenarioWithStepsRequest,
	TestScenario
} from '../models/test-scenario';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class TestScenarioService {
	private readonly privateHttp = inject(PrivateHttpClient);
	private readonly base = inject(BaseApiService);

	// ======================================================================
	// public functions
	// ======================================================================

	add(
		name: string,
		projectId: string,
		projectKey: string,
		description = ''
	): Observable<TestScenario> {
		return this.privateHttp
			.post<TestScenario>('scenario', {
				name,
				projectId,
				projectKey,
				description
			})
			.pipe(map((res) => this.base.model(res, TestScenario)));
	}

	index(projectId: string): Observable<TestScenario[]> {
		return this.privateHttp
			.get<Array<TestScenario>>(`project/${projectId}/scenarios`)
			.pipe(map((res) => this.base.models(res, TestScenario)));
	}

	changePosition(
		id: string,
		testStepId: string,
		index: number
	): Observable<TestScenario> {
		return this.privateHttp
			.post<TestScenario>(`scenario/${id}/changePositionOfTestStep`, {
				testStepId,
				index
			})
			.pipe(map((res) => this.base.model(res, TestScenario)));
	}

	edit(scenario: TestScenario): Observable<TestScenario> {
		return this.privateHttp
			.put<TestScenario>(`scenario/${scenario.id}`, scenario)
			.pipe(map((res) => this.base.model(res, TestScenario)));
	}

	delete(id: string): Observable<boolean> {
		return this.privateHttp
			.delete<boolean>(`scenario/${id}`)
			.pipe(map((res) => res));
	}

	get(id: string): Observable<TestScenario> {
		return this.privateHttp.get<TestScenario>(`scenario/${id}`).pipe(
			map((res) => this.base.model(res, TestScenario)),
			map(this.adaptScenarioResult())
		);
	}

	createInXray(id: string): Observable<void> {
		return this.privateHttp.post<void>(`scenario/${id}/createInXray`, {});
	}

	createWithSteps(
		createTestWithStepsPayload: ICreateTestScenarioWithStepsRequest
	): Observable<TestScenario> {
		return this.privateHttp.post<TestScenario>(
			'scenario/createWithSteps',
			createTestWithStepsPayload
		);
	}

	private adaptScenarioResult(): (scenario: TestScenario) => TestScenario {
		return (scenario: TestScenario) => {
			let index = 0;
			const steps = scenario.testSteps.map((step) => {
				const testObjects = step.testObjects.map((object) => {
					let parameters: Record<string, string> = {};
					object.parameters.forEach((param) => {
						parameters = { ...parameters, [param.name]: param.value };
					});

					return {
						...object,
						description: Utils.replaceTemplateString(
							parameters,
							object.description
						)
					};
				});
				return {
					...step,
					index: index++,
					testObjects,
					displayValidationType: step.displayValidationType
				} as TestStep;
			});

			return { ...scenario, testSteps: steps };
		};
	}

	getLastExecutedScenarios(): Observable<TestScenario[]> {
		return of([
			MOCK_SCENARIO_BMW_1,
			MOCK_SCENARIO_BMW_2,
			MOCK_SCENARIO_BMW_3
		]).pipe(delay(1000));
	}
}
