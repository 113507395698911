import { NgIf, NgStyle } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { LabelItemView } from '@testifi-models/label-item-view';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
	selector: 'app-label-item',
	templateUrl: './label-item.component.html',
	styleUrls: ['./label-item.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FlexModule, NgStyle, ExtendedModule, NgIf, SvgIconComponent]
})
export class LabelItemComponent implements OnInit {
	@Input() parameter: LabelItemView;

	@Output() remove = new EventEmitter<LabelItemView>();
	@Output() edit = new EventEmitter<LabelItemView>();

	label: string;
	divId: string;

	ngOnInit(): void {
		this.label = `${this.parameter.labelText}`;
		this.divId = this.parameter.idLabelItem.replace('\\W', '_');
	}

	onRemove(): void {
		this.remove.emit(this.parameter);
	}
}
