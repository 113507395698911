import { JsonObject, JsonProperty } from 'json2typescript';
import { ValidationType, ValidationTypeConverter } from './validation-type';

@JsonObject('Parameter')
export class Parameter {
	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('value', String)
	value: string = undefined;

	@JsonProperty('validation', ValidationTypeConverter)
	validationType: ValidationType = undefined;

	get displayValidationType(): string {
		return ValidationType[this.validationType].toString();
	}

	constructor(name?: string, value?: string, validationType?: ValidationType) {
		this.name = name;
		this.value = value;
		this.validationType = validationType;
	}
}
