<div class="library-drag-drop-page">
	<as-split direction="horizontal" unit="percent">
		<as-split-area
			[size]="LEFT_SECTION_DEFAULT_WIDTH"
			[minSize]="LEFT_SECTION_DEFAULT_WIDTH"
			[maxSize]="LEFT_SECTION_MAX_WIDTH"
		>
			<div class="split-sidebar">
				<div class="inner" *hideOnLoading>
					<div class="filter">
						<form class="aui">
							<div class="field">
								<select title="Select type" class="select" (change)="onLibraryChange($event)">
									<option value="all">All</option>
									<option
										*ngFor="let library of libraries | keyvalue | librariesSort; let i = index"
										[attr.selected]="activeLibrary === library.key ? '' : null"
										value="{{ library.key }}"
									>
										{{ library.uiTag }}
									</option>
								</select>
							</div>
							<div class="field">
								<input
									placeholder="Search"
									type="search"
									class="text"
									[value]="libraryFilterSearch"
									(input)="onSearchLibrary($event)"
									id="libraryFilter"
									[autofocus]="true"
								/>
							</div>
						</form>
					</div>
					<div class="modules">
						<div class="module-items">
							<div
								class="module-items-inner"
								cdkDropListSortingDisabled
								cdkDropList
								[cdkDropListData]="filteredLibraries"
								[cdkDropListConnectedTo]="connectedDropListsIds"
							>
								<cdk-virtual-scroll-viewport itemSize="50">
									<app-library-drag-drop-element
										*cdkVirtualFor="let module of filteredLibraries; templateCacheSize: 0"
										[name]="module.uiTag"
										[tag]="module.tag"
										[group]="module.group"
										(dragEnded)="onLibDragEnded()"
										(dragMove)="onLibDragMove($event)"
										(dragStarted)="onLibDragStarted($event)"
										(shortcutClick)="onShortcutClick(module)"
										[usePlaceholder]="true"
									>
									</app-library-drag-drop-element>
								</cdk-virtual-scroll-viewport>
							</div>
						</div>
					</div>
				</div>
			</div>
		</as-split-area>
		<as-split-area
			[size]="RIGHT_SECTION_DEFAULT_WIDTH"
			[minSize]="RIGHT_SECTION_MIN_WIDTH"
			[maxSize]="RIGHT_SECTION_DEFAULT_WIDTH"
		>
			<div class="split-main">
				<header class="aui-dialog2-header background-N0">
					<h2 class="aui-dialog2-header-main pad-t-10" *ngIf="loaded && type === testObjectType.TestObject">
						Edit Step
					</h2>
					<h2 class="aui-dialog2-header-main" *ngIf="loaded && type === testObjectType.BuildingBlock">
						<app-long-text [text]="item?.name" [topPadding]="true"></app-long-text>
					</h2>
					<div id="top-right-buttons" *hideOnLoading>
						<button
							type="button"
							id="undo"
							class="aui-button"
							title="Roll back last change"
							(click)="onClickUndo()"
							[disabled]="!editTracker.canUndo"
						>
							<img src="assets/icons/icon-undo.png" alt="Undo" />Undo
						</button>
						<button
							type="button"
							id="redo"
							class="aui-button"
							title="Re-do last reverted change"
							(click)="onClickRedo()"
							[disabled]="!editTracker.canRedo"
						>
							<img src="assets/icons/icon-undo.png" class="flipHorizontally" alt="Redo" />Redo
						</button>
					</div>
				</header>
				<div class="content background-N0 scrollable" [ngClass]="{'collapsed-sidebar': sidebarCollapsed}">
					<div id="stepsDiv" *hideOnLoading>
						<div class="aui-tabs horizontal-tabs">
							<ul class="tabs-menu">
								<li class="menu-item active-tab"></li>
								<button
									id="expandAllObjectsButton"
									class="expand-all-button"
									mat-icon-button
									[disabled]="expandAllButtonDisabled"
									aria-label="Expand all"
									(click)="expandAll()"
									matTooltip="Expand all"
								>
									<mat-icon svgIcon="expandAll"></mat-icon>
								</button>
								<button
									id="collapseAllObjectsButton"
									class="collapse-all-button"
									mat-icon-button
									[disabled]="collapseAllButtonDisabled"
									aria-label="Collapse all"
									(click)="collapseAll()"
									matTooltip="Collapse all"
								>
									<mat-icon svgIcon="collapseAll"></mat-icon>
								</button>
							</ul>
							<div class="tabs-pane active-pane">
								<app-library-drag-drop-page-element
									[isDragging]="isDragging"
									[item]="parentItem"
									[rootItem]="parentItem"
									[connectedLists]="connectedDropListsIds"
									[intersectedItemIds]="intersectedItemIds"
									[expandedItemIds]="expandedItemIds"
									[root]="true"
									[rootId]="testStepId || blockId"
									[type]="type"
									[libraries]="libraries"
									(dragDrop)="onDragDrop($event)"
									(dragEnded)="onDragEnded($event)"
									(dragStarted)="onDragStarted($event)"
									(dragMove)="onDragMove($event)"
									(parameterClick)="onParameterClick($event)"
									(expandedClick)="onToggleExpanded($event, true)"
									(refreshOnModalClose)="onRefreshOnModalClose($event)"
								></app-library-drag-drop-page-element>
							</div>
						</div>
					</div>
					<div id="hideSidebar" *ngIf="!sidebarCollapsed" [hidden]="!loaded">
						<button type="button" title="Hide side-bar" class="collapse-button" (click)="toggleCollapsedSideBar()">
							<svg-icon name="icon-arrow-right"></svg-icon>
						</button>
					</div>
					<div id="sidebarDiv" [style.display]="!sidebarCollapsed ? 'block' : 'none'">
						<div fxLayout="column" fxLayoutAlign="space-between" *hideOnLoading>
							<div class="top" fxLayout="column nowrap" fxLayoutAlign="start start" fxLayoutGap="16px">
								<div *ngIf="loaded && type === testObjectType.BuildingBlock">
									<div class="csl">
										<h4>Description</h4>
										<app-multi-line-editor-legacy
											#blockDescriptionEditor
											class="mod-content"
											[height]="80"
											[maxWidth]="280"
											[breakWords]="true"
											[text]="item?.description"
											[defaultText]="'Add description...'"
											[validators]="DESCRIPTION_VALIDATORS"
											(changedValue)="onCBBDescriptionChange($event)"
										></app-multi-line-editor-legacy>
									</div>
								</div>
								<div *ngIf="loaded && type === testObjectType.TestObject">
									<app-multi-line-editor-group
										#testObjectMetadataFormElement
										[group]="testObjectMetadataForm"
										[properties]="testObjectMetadataFormProperties"
										(submitted)="onEditTestStepMetadata()"
									></app-multi-line-editor-group>
								</div>
								<div *ngIf="loaded && type === testObjectType.BuildingBlock">
									<div class="csl">
										<h4>Modules</h4>
										<app-comma-separated-list
											[typedItems]="item.getTypedParameterList(item.modules, ParameterType.MODULES)"
											(addClicked)="onAddModuleClicked()"
											(deleteClicked)="onDeleteModuleClicked($event)"
										></app-comma-separated-list>
									</div>
									<div class="csl">
										<h4>Parameters</h4>
										<app-comma-separated-list
											[clickable]="true"
											[typedItems]="item.getTypedParameterList(item.mandatoryParameters, ParameterType.MANDATORY).concat(item.getTypedParameterList(item.optionalParameters, ParameterType.OPTIONAL))"
											(addClicked)="onAddParameterClicked()"
											(deleteClicked)="onDeleteParameterClicked($event)"
											(clicked)="onParameterClicked($event)"
										></app-comma-separated-list>
									</div>
									<div class="csl">
										<h4>Output parameters</h4>
										<app-comma-separated-list
											[typedItems]="item.getTypedParameterList(item.outputParameters, ParameterType.OUTPUT)"
											(addClicked)="onAddOutputParameterClicked()"
											(deleteClicked)="onDeleteOutputParameterClicked($event)"
										></app-comma-separated-list>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="showSidebar">
						<button
							type="button"
							title="Show side-bar"
							class="expand-button flipHorizontally"
							(click)="toggleCollapsedSideBar()"
							*ngIf="sidebarCollapsed"
						>
							<svg-icon name="icon-arrow-right"></svg-icon>
						</button>
					</div>
				</div>
				<div class="bottom-button">
					<footer class="aui-dialog2-footer">
						<div class="aui-dialog2-footer-actions btnCloseHolder">
							<button
								type="button"
								id="{{ isChanged && !blockId ? 'close-sync-submit' : 'close-submit' }}"
								class="aui-button"
								title="{{ isChanged && !blockId ? 'Sync changes and c' : 'C' }}lose editor"
								(click)="closeDialog()"
							>
								<span *ngIf="isChanged && !blockId">Sync & </span>Close
							</button>
						</div>
					</footer>
				</div>
			</div>
		</as-split-area>
	</as-split>
</div>
