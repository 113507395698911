import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UrlDao } from '@testifi-daos/url.dao';
import { PAGES } from '@testifi-shared/app-constants';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { Subscription } from 'rxjs';
import { NotificationService } from './notification.service';

const BROWSER_REQUESTED_ROUTE_LOCAL_STORAGE_KEY = 'browserRequestedRoute';

export class RouterNames {
	public static paths = {};
	public static components = {};
}

@Injectable({
	providedIn: 'root'
})
export class RouterService implements OnDestroy {
	private readonly disposableBag = new Subscription();
	private browserRequestedRoute: string = null;

	// ======================================================================
	// constructor
	// ======================================================================
	constructor(
		private readonly router: Router,
		private readonly urlDao: UrlDao,
		private readonly appConfigRepository: AppConfigRepository,
		private readonly projectRepository: ProjectRepository,
		private readonly notificationService: NotificationService,
		private readonly zone: NgZone
	) {
		//go through each route
		router.config.forEach((eachRoute) => {
			const name = eachRoute.data?.name as string;
			RouterNames.paths[name] = eachRoute.path; // now all route paths are added to this prop
			RouterNames.components[name] = eachRoute.component;
		});
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	// ======================================================================
	// public functions
	// ======================================================================

	validate(paths: Array<string>): boolean {
		if (!Array.isArray(paths) || (Array.isArray(paths) && !paths.length)) {
			throw new Error('routerName must be an array and not empty');
		}

		if (!(paths[0] in RouterNames.paths)) {
			throw new Error('Invalid routerName: ' + paths[0]);
		}

		return true;
	}

	resolve(paths: Array<string>): string[] {
		this.validate(paths);

		let route = RouterNames.paths[paths[0]] as string;

		//get all parameters
		const parameters = route.match(/:([a-zA-Z]+)*/g);

		if (parameters) {
			//replace parameters with actual values
			parameters.forEach((item, index) => {
				route = route.replace(item, paths[index + 1]);
			});
		}

		return [route];
	}

	getComponentByPathName(name: Array<string>): string {
		return RouterNames.components[name[0]] as string;
	}

	navigate(path: Array<PAGES> | Array<string>, data: unknown = null): void {
		this.zone.run(() => {
			this.router
				.navigate(this.resolve(path), { queryParams: data })
				.catch((reason: string | HttpErrorResponse) => {
					reason instanceof HttpErrorResponse
						? this.notificationService.httpError(reason)
						: this.notificationService.error(reason);
				});
		});
	}

	setBrowserRequestedRoute(route: string): void {
		this.browserRequestedRoute = route;
		localStorage.setItem(BROWSER_REQUESTED_ROUTE_LOCAL_STORAGE_KEY, route);
	}

	getBrowserRequestedRoute(): string {
		return (
			this.browserRequestedRoute ??
			localStorage.getItem(BROWSER_REQUESTED_ROUTE_LOCAL_STORAGE_KEY)
		);
	}

	clearBrowserRequestedRoute(): void {
		this.browserRequestedRoute = null;
		localStorage.removeItem(BROWSER_REQUESTED_ROUTE_LOCAL_STORAGE_KEY);
	}
}
