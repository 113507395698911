import { Type, TypedParameter } from '@testifi-models/typed-Item';
import {
	MANDATORY_PARAMETERS_KEY,
	OPTIONAL_PARAMETERS_KEY
} from '@testifi-shared/app-constants';
import { immerable } from 'immer';
import { JsonObject, JsonProperty } from 'json2typescript';
import { TestObject } from './test-object';

@JsonObject('BuildingBlock')
export class BuildingBlock {
	[immerable] = true;

	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('documentation', String, true)
	documentation: string = undefined;

	@JsonProperty('description', String, true)
	description: string = undefined;

	@JsonProperty('projectId', String)
	projectId: string = undefined;

	@JsonProperty('childTestObjects', [TestObject], true)
	testObjects: TestObject[] = [];

	@JsonProperty('modules', [String])
	modules: string[] = [];

	@JsonProperty(MANDATORY_PARAMETERS_KEY, [String])
	mandatoryParameters: string[] = [];

	@JsonProperty(OPTIONAL_PARAMETERS_KEY, [String])
	optionalParameters: string[] = [];

	@JsonProperty('outputParameters', [String])
	outputParameters: string[] = [];

	/**
	 * Warning: This method is used in angular template. Performance problem is there.
	 */
	getTypedParameterList(list: string[], type: Type): TypedParameter[] {
		return list.map((param) => new TypedParameter(param, type));
	}
}
