<div
	class="ltd__container"
	[style.background]="parameter.bgColor"
	fxLayout="row nowrap"
	fxLayoutAlign="center center"
	fxLayoutGap="2px"
>
	<div
		id="label-{{ divId }}"
		class="ltd__label"
		[ngStyle]="{'cursor': isReadOnly ? 'default' : 'pointer'}"
		(click)="onEdit()"
		title="{{ isReadOnly ? 'Read-only parameter.' : 'Click to edit.' }}"
	>
		{{ label }}
	</div>
	<button
		type="button"
		class="ltd__remove-btn"
		(click)="onRemove()"
		*ngIf="!isReadOnly && !parameter.mandatory && !parameter.optional"
		title="Click to delete."
	>
		<svg-icon name="icon-x-light"></svg-icon>
	</button>
</div>
