<section
	id="modal-add-test-step"
	class="aui-dialog2 aui-dialog2-medium"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-add-test-step--heading"
>
	<form class="aui" [formGroup]="form" (ngSubmit)="add()">
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-add-test-step--heading">Create Step</h2>
		</header>
		<!-- Main dialog content -->
		<div class="aui-dialog2-content">
			<div class="field-group mb-3">
				<label for="test-step-description">
					Action (Step Description) <span class="aui-icon icon-required ms-1">(required)</span></label
				>
				<textarea
					class="textarea"
					formControlName="description"
					id="test-step-description"
					placeholder="Action (Step Description) here..."
				></textarea>
				<app-validation-errors class="m-0" [control]="f.description"></app-validation-errors>
				<div class="description">Max. 1.000 characters</div>
			</div>
			<div class="field-group mb-3">
				<label for="test-step-data"> Data </label>
				<textarea class="textarea" formControlName="data" id="test-step-data" placeholder="Data here..."></textarea>
				<app-validation-errors class="m-0" [control]="f.data"></app-validation-errors>
				<div class="description">Max. 1.000 characters</div>
			</div>
			<div class="field-group">
				<label for="test-step-result">Expected Result</label>
				<textarea
					class="textarea"
					formControlName="result"
					id="test-step-result"
					placeholder="Expected Result here..."
				></textarea>
				<app-validation-errors class="m-0" [control]="f.result"></app-validation-errors>
				<div class="description">Max. 1.000 characters</div>
			</div>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<div class="aui-dialog2-footer-actions">
				<button type="submit" id="create-submit" class="aui-button aui-button-primary" [disabled]="!form.valid">
					Create
				</button>
				<button type="button" id="cancel" class="aui-button aui-button-subtle" modalClose>Cancel</button>
			</div>
		</footer>
	</form>
</section>
