import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	Output,
	ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TypedChanges } from '@testifi-models/typed-changes.type';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Component({
	selector: 'app-table-cell-show-more',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './table-cell-show-more.component.html',
	styleUrls: ['./table-cell-show-more.component.less']
})
export class TableCellShowMoreComponent implements AfterViewInit, OnChanges {
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input({ alias: 'width.px' }) widthPx: number;
	@Input({ required: true }) text = '';

	@Output() moreChanged = new EventEmitter<boolean>();

	@ViewChild('textSpan') textElement: ElementRef<HTMLElement>;

	private calculatedOverflowBS$ = new BehaviorSubject<number>(undefined);
	private calculatedOverflow$ = this.calculatedOverflowBS$.asObservable();
	private cdr = inject(ChangeDetectorRef);
	overflowed = false;
	more = false;

	constructor() {
		this.calculatedOverflow$
			.pipe(takeUntilDestroyed(), debounceTime(500))
			.subscribe((widthPx) => {
				this.overflowed =
					this.more === true
						? true
						: this.textElement.nativeElement.scrollWidth > widthPx;
				this.cdr.markForCheck();
			});
	}

	ngAfterViewInit() {
		this.calculatedOverflowBS$.next(this.widthPx);
	}

	ngOnChanges(changes: TypedChanges<TableCellShowMoreComponent>) {
		if (changes.widthPx && !changes.widthPx.firstChange) {
			this.calculatedOverflowBS$.next(changes.widthPx.currentValue);
		}
	}

	lessClicked($event: MouseEvent) {
		$event.stopPropagation();
		this.more = false;
		this.moreChanged.emit(this.more);
	}

	showLess() {
		if (this.more) {
			this.more = false;
		}
	}

	moreClicked($event: MouseEvent) {
		$event.stopPropagation();
		this.more = true;
		this.moreChanged.emit(this.more);
	}

	showMore() {
		if (!this.more && this.overflowed) {
			this.more = true;
		}
	}
}
