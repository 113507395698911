import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
	IMultiLineEditorComponentMetadata,
	MultiLineEditorComponent
} from '@testifi-shared/multi-line-editor-2/multi-line-editor.component';
import { MultiLineEditorLegacyComponent } from '@testifi-shared/multi-line-editor/multi-line-editor-legacy.component';
import { SubmitEvent } from '@testifi-shared/singleline-editor/single-line-editor.component';

@Component({
	selector: 'app-multi-line-editor-group',
	standalone: true,
	imports: [
		CommonModule,
		MultiLineEditorLegacyComponent,
		MultiLineEditorComponent,
		ReactiveFormsModule
	],
	templateUrl: './multi-line-editor-group.component.html',
	styleUrls: ['./multi-line-editor-group.component.less']
})
export class MultiLineEditorGroupComponent implements OnInit {
	@Input({ required: true }) properties: Record<
		string,
		IMultiLineEditorComponentMetadata
	>;
	@Input({ required: true }) group: FormGroup;
	@Output() submitted = new EventEmitter<SubmitEvent>();

	editMode = false;

	ngOnInit() {
		this.checkIfFormItemsKeysAreSameAsGroupKeys();
	}

	controlClicked(multiLineEditor: MultiLineEditorComponent) {
		this.editMode = true;
		multiLineEditor.focusOnTextArea();
	}

	cancel() {
		this.group.reset();
		this.editMode = false;
	}

	private checkIfFormItemsKeysAreSameAsGroupKeys() {
		const formItemKeys = Object.keys(this.properties);
		const groupKeys = Object.keys(this.group.controls);
		if (
			formItemKeys.length !== groupKeys.length ||
			!formItemKeys.every((key) => groupKeys.includes(key))
		) {
			throw new Error('Form items and group keys are not the same');
		}
	}
}
