import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TestStepLibrary } from '@testifi-models/test-step-library';

type PipeReturnType = KeyValue<string, TestStepLibrary[]> & { uiTag: string };

@Pipe({
	name: 'librariesSort',
	standalone: true
})
export class LibrariesSortPipe implements PipeTransform {
	transform(
		value: Array<KeyValue<string, TestStepLibrary[]>>
	): Array<PipeReturnType> {
		const result: PipeReturnType[] = [];
		value.forEach((library) => {
			result.push({
				...library,
				uiTag: library.key === 'sui' ? 'UI' : library.key
			});
		});
		return result.sort((libraryA, libraryB) =>
			libraryA.uiTag.localeCompare(libraryB.uiTag)
		);
	}
}
