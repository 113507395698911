import { NgIf } from '@angular/common';
import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import { XRAY_STATUS_VALIDATORS } from '@testifi-shared/app-constants';
import { Subscription } from 'rxjs';
import { ValidationErrorsComponent } from '../../../shared/validation-errors/validation-errors.component';

@Component({
	selector: 'app-status-levels',
	templateUrl: './status-levels.component.html',
	styleUrls: ['./status-levels.component.less'],
	standalone: true,
	imports: [
		NgIf,
		FormsModule,
		ReactiveFormsModule,
		FlexModule,
		ValidationErrorsComponent
	]
})
export class StatusLevelsComponent implements OnInit, OnDestroy {
	@Output() formData = new EventEmitter<UntypedFormGroup>();

	form: UntypedFormGroup;

	private disposableBag = new Subscription();

	constructor(private formBuilder: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			scenarioTodo: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			scenarioExecuting: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			scenarioPass: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			scenarioFail: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			scenarioAborted: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			scenarioMismatch: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			stepTodo: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			stepExecuting: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			stepPass: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			stepFail: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			stepAborted: new UntypedFormControl('', XRAY_STATUS_VALIDATORS),
			stepMismatch: new UntypedFormControl('', XRAY_STATUS_VALIDATORS)
		});
		this.formData.emit(this.form);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}
}
