<div *ngIf="control.invalid">
	<ul *ngIf="control.hasError('required')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>This field is required
		</li>
	</ul>
	<ul *ngIf="control.hasError('minlength')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>Minimum length required
		</li>
	</ul>
	<ul *ngIf="control.hasError('maxlength')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>Maximum length reached
		</li>
	</ul>
	<ul *ngIf="control.hasError('pattern')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>Required pattern is wrong
		</li>
	</ul>
	<ul *ngIf="control.hasError('forbiddenString')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>Forbidden string
		</li>
	</ul>
	<ul *ngIf="control.hasError('invalidURL')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>Invalid URL
		</li>
	</ul>
	<ul *ngIf="control.hasError('reservedName')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>Reserved name
		</li>
	</ul>
	<ul *ngIf="control.hasError('existingName')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>Already exist
		</li>
	</ul>
	<ul *ngIf="control.hasError('positiveWholeInt')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>Only positive whole numbers
		</li>
	</ul>
	<ul *ngIf="control.hasError('existingParameter')">
		<li [class.smallerFont]="smallerFont">
			<span class="aui-icon aui-icon-small aui-iconfont-error aui-icon-notification"></span>Parameter already exists
		</li>
	</ul>
</div>
