import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MatLegacyAutocompleteModule,
	MatLegacyAutocompleteSelectedEvent,
	MatLegacyAutocompleteTrigger
} from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { LabelItemView } from '@testifi-models/label-item-view';
import { ProjectService } from '@testifi-services/project.service';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { SvgIconComponent } from 'angular-svg-icon';
import { Observable, Subscription, map } from 'rxjs';
import { AutofocusDirective } from '../../../directives/autofocus.directive';
import { ValidationErrorsComponent } from '../../validation-errors/validation-errors.component';

@Component({
	selector: 'app-label-editor',
	templateUrl: './label-editor.component.html',
	styleUrls: ['./label-editor.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		AutofocusDirective,
		ValidationErrorsComponent,
		SvgIconComponent,
		AsyncPipe,
		MatLegacyOptionModule,
		MatLegacyAutocompleteModule,
		NgFor,
		NgIf
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LabelEditorComponent implements OnInit, OnDestroy {
	private disposableBag = new Subscription();
	@ViewChild(MatLegacyAutocompleteTrigger)
	autocompleteTrigger!: MatLegacyAutocompleteTrigger;
	parameter: LabelItemView;
	labelItemList: LabelItemView[] = [];
	showAddButton = false;
	prompt = 'Add "';
	islabelSelected = false;

	@Input() existingKeys: string[];

	@Output() submitEdit = new EventEmitter<LabelItemView>();
	@Output() cancel = new EventEmitter();

	searchLabelItemsControl = new FormControl('');
	filteredLabelItemList$: Observable<LabelItemView[]> =
		this.searchLabelItemsControl.valueChanges.pipe(
			map((value) => this.filterLabelItem(value))
		);

	constructor(
		private projectService: ProjectService,
		private projectRepository: ProjectRepository
	) {}

	ngOnInit(): void {
		this.disposableBag.add(
			this.projectService
				.getLabelItemList(this.projectRepository.project.id)
				.subscribe((response: LabelItemView[]) => {
					this.labelItemList = response.filter(
						(items) => !this.existingKeys.includes(items.idLabelItem)
					);
				})
		);
	}

	onSubmit(value: string): void {
		if (value) {
			this.parameter = new LabelItemView(
				value,
				this.removePromptFromOption(this.getTitle(value)) ??
					this.removePromptFromOption(value)
			);
			this.submitEdit.emit(this.parameter);
		}
	}

	getTitle(idLabelItem: string): string {
		return this.labelItemList.find((label) => label.idLabelItem === idLabelItem)
			?.labelText;
	}

	onLabelItemOptionSelected(event: MatLegacyAutocompleteSelectedEvent): void {
		this.islabelSelected = true;
		this.onSubmit(event.option.value as string);
	}

	removePromptFromOption(option: string) {
		if (option?.startsWith(this.prompt)) {
			option = option.substring(this.prompt.length, option.length - 1);
		}
		if (option?.length > 1) {
			option = option.substring(0, option.length - 1);
		}
		return option;
	}

	onLabelItemClear(): void {
		this.autocompleteTrigger.closePanel();
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	private filterLabelItem(value: string): LabelItemView[] {
		if (!this.islabelSelected) {
			const filterValue = this.normalizeValue(value);
			let results = this.labelItemList.filter((street) =>
				this.normalizeValue(street.labelText).includes(filterValue)
			);
			this.showAddButton = results.length === 0;
			if (this.showAddButton) {
				results = [
					...results,
					new LabelItemView(value, this.prompt + value + '"?')
				];
			}
			return results;
		} else {
			const customResult: LabelItemView[] = [
				new LabelItemView(value, this.getTitle(value))
			];
			this.islabelSelected = false;
			return customResult;
		}
	}

	private normalizeValue(value: string): string {
		return value.toLowerCase().replace(/\s/g, '');
	}
}
