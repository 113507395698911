<div class="router-main-wrapper full-height-page">
	<div class="qa-warning ps-1 pe-1" *ngIf="appConfigRepository.qaWarning$ | async">
		This is a QA environment, changes won't effect Production
	</div>
	<router-outlet></router-outlet>
</div>

<div id="gui-loading-overlay" class="page-loading" *ngIf="pageLoading">
	<aui-spinner size="large"></aui-spinner>
</div>

<ng-template #updateconfirm let-modal>
	<div class="modal-header app__confirm-header">
		<span class="modal-title app__confirm-header-title" id="modal-cart-title">Application Update</span>
	</div>
	<div class="app__confirm-body">
		<span class="d-inline-block text-center p-4 w-100">
			New version of the application has been detected.<br />
			You can always update it later, by refreshing the page.<br /><br />
			Do you want to update it now?
		</span>
	</div>
	<div class="app__confirm-footer" fxLayout fxLayoutAlign="end center" fxLayoutGap="16px">
		<button
			type="button"
			class="btn app__confirm-button app__confirm-button-primary shadow-none"
			(click)="modal.close()"
		>
			Yes
		</button>
		<span
			type="button"
			class="btn btn-link app__confirm-button app__confirm-button-secondary text-decoration-none"
			(click)="modal.dismiss()"
			>No</span
		>
	</div>
</ng-template>
