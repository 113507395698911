import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { excludeKeys, persistState } from '@ngneat/elf-persist-state';
import { ISettings } from '@testifi-models/settings.interface';
import {
	CREDENTIALS_REPOSITORY_STORAGE_KEY,
	INDEXED_DB_STORAGE
} from '@testifi-shared/app-constants';
import {
	TCredentialRepository,
	TCredentials
} from '@testifi-store/credentials/credential.repository.type';
import { Observable, filter } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CredentialRepository {
	static readonly INITIAL_VALUE: TCredentialRepository = {
		gitUsername: '',
		gitToken: '',
		token: false,
		xrayClientId: '',
		xrayClientSecret: '',
		jiraApiToken: '',
		loading: false,
		loaded: false
	};

	private store = createStore(
		{ name: 'credential' },
		withProps<TCredentialRepository>(CredentialRepository.INITIAL_VALUE)
	);

	private persist = persistState(this.store, {
		key: CREDENTIALS_REPOSITORY_STORAGE_KEY,
		storage: INDEXED_DB_STORAGE,
		source: () => this.store.pipe(excludeKeys(['loading', 'loaded']))
	});

	loading$ = this.store.pipe(select((state) => state.loading));

	state$ = this.store.pipe(select((state) => state));

	credentials$: Observable<TCredentials> = this.store.pipe(
		select(({ loading, loaded, ...credentials }) => credentials)
	);
	persistInitialized$ = this.persist.initialized$.pipe(filter(Boolean));

	get state(): TCredentialRepository {
		return this.store.getValue();
	}

	setCredentials(credentials: TCredentials): void {
		this.store.update((state) => ({
			...state,
			...credentials
		}));
	}

	removeCredentials(): void {
		this.store.update((state) => ({
			...state,
			gitUsername: CredentialRepository.INITIAL_VALUE.gitUsername,
			gitToken: CredentialRepository.INITIAL_VALUE.gitToken,
			token: CredentialRepository.INITIAL_VALUE.token,
			xrayClientId: CredentialRepository.INITIAL_VALUE.xrayClientId,
			xrayClientSecret: CredentialRepository.INITIAL_VALUE.xrayClientSecret,
			jiraApiToken: CredentialRepository.INITIAL_VALUE.jiraApiToken
		}));
	}

	setBackendCachedSettings(settings: ISettings): void {
		this.store.update((state) => ({
			...state,
			jiraApiToken: settings.jiraApiToken,
			xrayClientId: settings.xrayClientId,
			xrayClientSecret: settings.xrayClientSecret,
			gitUsername: settings.gitUsername,
			gitToken: settings.gitToken,
			loaded: true,
			error: ''
		}));
	}

	setLoading(loading: boolean): void {
		this.store.update((state) => ({
			...state,
			loading
		}));
	}
}
