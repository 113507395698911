<div
	[attr.data-tag]="tag"
	[attr.data-library]="group"
	class="module-item"
	cdkDrag
	(cdkDragEnded)="onDragEnded($event)"
	(cdkDragMoved)="onDragMove($event)"
	(cdkDragStarted)="onDragStarted($event)"
>
	<div class="draggable">
		<span class="drag-thumb"></span>
		<h4 class="name" title="{{name}}">{{ name }}</h4>
		<button type="button" class="shortcut-button" (click)="onShortcutClick($event)">+</button>
	</div>
	<div *ngIf="usePlaceholder">
		<div class="item-placeholder" *cdkDragPlaceholder></div>
	</div>
</div>
