import { inject, Injectable } from '@angular/core';
import { HomeApiService } from '@testifi-services/home-api.service';
import { TestPlanApiService } from '@testifi-services/test-plan.api.service';
import { TestScenarioService } from '@testifi-services/test-scenario.service';
import { HomeRepository } from '@testifi-store/home/home.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { take } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class HomeEffect {
	private readonly homeApiService = inject(HomeApiService);
	private readonly testPlanApiService = inject(TestPlanApiService);
	private readonly homeRepository = inject(HomeRepository);
	private readonly testScenarioService = inject(TestScenarioService);
	private readonly projectRepository = inject(ProjectRepository);

	getCounts() {
		this.homeRepository.setCountsLoading(true);
		const projectId = this.projectRepository.project.id;
		this.homeApiService
			.getCounts(projectId)
			.pipe(
				take(1),
				finalize(() => {
					this.homeRepository.setCountsLoading(false);
				})
			)
			.subscribe((counts) => {
				this.homeRepository.setCounts({
					cbbs: counts.cbbCount,
					scenarios: counts.scenarioCount,
					testPlans: counts.testPlanCount,
					suiPages: counts.suiPageCount
				});
			});
	}

	getLastBrowsedBusinessJourneys() {
		const projectId = this.projectRepository.project.id;
		this.homeRepository.setLastBrowsedBusinessJourneysLoading(true);
		this.testPlanApiService
			.getLastBrowsedBusinessJourneys(projectId)
			.pipe(
				take(1),
				finalize(() => {
					this.homeRepository.setLastBrowsedBusinessJourneysLoading(false);
				})
			)
			.subscribe((businessJourneys) => {
				this.homeRepository.setLastBrowsedBusinessJourneys(businessJourneys);
			});
	}

	getLastExecutedScenarios() {
		this.homeRepository.setLastExecutedScenariosLoading(true);
		this.testScenarioService
			.getLastExecutedScenarios()
			.pipe(
				take(1),
				finalize(() => {
					this.homeRepository.setLastExecutedScenariosLoading(false);
				})
			)
			.subscribe((testScenarios) => {
				this.homeRepository.setLastExecutedScenarios(testScenarios);
			});
	}
}
