import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbService } from '@testifi-services/breadcrumb.service';
import { LogoutService } from '@testifi-services/logout.service';
import { NotificationService } from '@testifi-services/notification.service';
import { RouterService } from '@testifi-services/router.service';
import { SsoAuthenticationService } from '@testifi-services/sso-authentication.service';
import { PAGES } from '@testifi-shared/app-constants';
import { BreadcrumbComponent } from '@testifi-shared/breadcrumb/breadcrumb.component';
import { LastBrowsedBusinessJourneysComponent } from '@testifi-shared/last-browsed-bjs/last-browsed-business-journeys.component';
import { LastExecutedScenariosComponent } from '@testifi-shared/last-executed-scenarios/last-executed-scenarios.component';
import { LibraryNavigationItemComponent } from '@testifi-shared/library-navigation-item/library-navigation-item.component';
import { ProToggleButtonComponent } from '@testifi-shared/pro-toggle-button/pro-toggle-button.component';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { AppStatusRepository } from '@testifi-store/app-status/app-status.repository';
import { AuthRepository } from '@testifi-store/auth/auth.repository';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { HomeEffect } from '@testifi-store/home/home.effect';
import { HomeRepository } from '@testifi-store/home/home.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { SvgIconComponent } from 'angular-svg-icon';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { ModalRouterNameDirective } from '../../directives/modal-router-name.directive';
import { RouterNameDirective } from '../../directives/router-name.directive';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		LibraryNavigationItemComponent,
		BreadcrumbComponent,
		HideOnLoadingDirective,
		ProToggleButtonComponent,
		MatIconModule,
		LastBrowsedBusinessJourneysComponent,
		LastExecutedScenariosComponent,
		RouterNameDirective,
		ModalRouterNameDirective,
		MatButtonModule,
		SvgIconComponent
	],
	templateUrl: './page-home.component.html',
	styleUrls: ['./page-home.component.less']
})
export class PageHomeComponent implements OnInit {
	readonly projectRepository = inject(ProjectRepository);
	readonly appConfigRepository = inject(AppConfigRepository);
	private readonly breadcrumbService = inject(BreadcrumbService);
	private readonly appStatusRepository = inject(AppStatusRepository);
	private readonly homeRepository = inject(HomeRepository);
	private readonly homeEffect = inject(HomeEffect);
	private readonly ssoAuthenticationService = inject(SsoAuthenticationService);
	private readonly authRepository = inject(AuthRepository);
	private readonly credentialRepository = inject(CredentialRepository);
	private readonly logoutService = inject(LogoutService);
	private readonly routerService = inject(RouterService);
	private readonly notificationService = inject(NotificationService);
	private readonly destroyRef = inject(DestroyRef);

	PAGES = PAGES;

	proActive$ = this.appStatusRepository.proActive$;
	testPlansCount$ = this.homeRepository.counts$.pipe(
		map((counts) =>
			this.libraryNavigationCountDescription(
				counts.testPlans,
				'Business Journey'
			)
		)
	);
	scenariosCount$ = this.homeRepository.counts$.pipe(
		map((counts) =>
			this.libraryNavigationCountDescription(counts.scenarios, 'scenario')
		)
	);
	suiPagesCount$ = this.homeRepository.counts$.pipe(
		map((counts) =>
			this.libraryNavigationCountDescription(counts.suiPages, 'SUI Page')
		)
	);
	cbbsCount$ = this.homeRepository.counts$.pipe(
		map((counts) => this.libraryNavigationCountDescription(counts.cbbs, 'CBB'))
	);
	greetingTitle: string;
	projectId$ = this.projectRepository.project$.pipe(
		map((project) => project.id)
	);

	ngOnInit() {
		this.homeEffect.getCounts();
		this.initBreadcrumb();
		this.initGreetingTitle();
	}

	proClicked() {
		this.appStatusRepository.enablePro();
	}

	essentialClicked() {
		this.appStatusRepository.disablePro();
	}

	signOut(): void {
		combineLatest([this.authRepository.jwt$, this.authRepository.email$])
			.pipe(
				tap(([token, email]) => {
					this.logoutService.logout(email, token);
					this.authRepository.removeJWT();
					this.credentialRepository.removeCredentials();
				}),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe({
				next: () => {
					const isSSO = this.appConfigRepository.isSSO;
					if (isSSO) {
						this.ssoAuthenticationService.logout();
					} else {
						this.routerService.navigate([PAGES.CREDENTIALS]);
					}
				},
				error: (err: HttpErrorResponse) =>
					this.notificationService.httpError(err)
			});
	}

	private initBreadcrumb() {
		this.breadcrumbService.set(
			new Map([
				['Projects', null],
				[this.projectRepository.project.name, {}]
			])
		);
	}

	private libraryNavigationCountDescription(
		count: number,
		type: string
	): string {
		return `${count} ${type}${count > 1 ? 's' : ''} in the project`;
	}

	private initGreetingTitle() {
		if (!this.ssoAuthenticationService?.givenName) {
			this.greetingTitle = 'Hello,';
		} else {
			this.greetingTitle = 'Hello, ' + this.ssoAuthenticationService.givenName;
		}
	}
}
