import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { SvgIconComponent } from 'angular-svg-icon';

export enum TestConnectionState {
	Disabled = 'disabled',
	NotTested = 'not-tested',
	TestedOk = 'tested-ok',
	TestedWrong = 'tested-wrong'
}

@Component({
	selector: 'app-test-connection',
	templateUrl: './test-connection.component.html',
	styleUrls: ['./test-connection.component.less'],
	standalone: true,
	imports: [FlexModule, SvgIconComponent]
})
export class TestConnectionComponent {
	@Input() state: TestConnectionState;

	@Output() testing = new EventEmitter<void>();
}
