<section
	id="modal-test-object-parameter"
	class="aui-dialog2 aui-dialog2-small"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-test-object-parameter--heading"
>
	<form class="aui" [formGroup]="form" (ngSubmit)="submit()">
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-test-object-parameter--heading">
				{{ isNew ? 'Add' : 'Change' }} Parameter
			</h2>
		</header>
		<!-- Main dialog content -->
		<div class="aui-dialog2-content">
			<div
				class="field-group"
				*ngIf="{hasDefaultValue: form.get('type')?.value?.includes('!'), dataFile: form.get('type')?.value | dataFileParser} as vars"
			>
				<label for="type">Name</label>
				<div ngSelectEvents>
					<ng-select
						*ngIf="isNew"
						[clearable]="false"
						class="dropdown"
						id="type"
						formControlName="type"
						[editableSearchTerm]="true"
						notFoundText="No matches"
						(change)="onTypeChange($event)"
						[ngClass]="{ 'is-invalid': submitted && f.type.errors }"
						[inputAttrs]="{ 'class': 'text', 'onClick': 'this.setSelectionRange(0, this.value.length)' }"
					>
						<ng-container *ngFor="let type of types">
							<ng-option [value]="type">{{ type | itemName }}</ng-option>
						</ng-container>
					</ng-select>
					<input
						*ngIf="!isNew"
						class="text large-field"
						type="text"
						required
						disabled
						[value]="form.get('type')?.value | itemName"
					/>
				</div>
				<app-validation-errors [control]="f.type"></app-validation-errors>
				<span class="extra-info" *ngIf="vars.hasDefaultValue">
					Default value = {{ form.get('type')?.value | itemDefaultValue }}
				</span>
				<span class="extra-info" *ngIf="vars.dataFile">Data file = {{vars.dataFile.dataFileName}}</span>
				<span class="extra-info" *ngIf="vars.dataFile">Selector = {{vars.dataFile.selectorName}}</span>
			</div>

			<div class="field-group">
				<label for="value">Value</label>
				<ng-template *ngIf="searchableDropdown; then dropdown; else textfield"></ng-template>
				<ng-template #dropdown>
					<div ngSelectEvents class="mb-3">
						<ng-select
							[clearable]="false"
							id="value"
							class="dropdown"
							formControlName="value"
							[loading]="dataRepository.loading$ | async"
							[editableSearchTerm]="true"
							notFoundText="No matches"
							[ngClass]="{ 'is-invalid': submitted && f.value.errors }"
							[inputAttrs]="{ 'class': 'text', 'onClick': 'this.setSelectionRange(0, this.value.length)' }"
						>
							<ng-container *ngFor="let value of values">
								<ng-option [value]="value">{{ value }}</ng-option>
							</ng-container>
						</ng-select>
					</div>
				</ng-template>
				<ng-template #textfield>
					<input
						placeholder="Enter value"
						*ngIf="valueType!=='pageId'"
						class="text large-field"
						type="text"
						formControlName="value"
						[autofocus]="true"
						[ngClass]="{ 'is-invalid': submitted && f.value.errors }"
						required
					/>

					<input
						placeholder="Page ID"
						*ngIf="valueType==='pageId'"
						class="text large-field"
						type="text"
						formControlName="value"
						required
					/>
					<div *ngIf="valueType==='pageId'" class="error">
						Please add a page file(s) before, to get a list of available page ids
					</div>
				</ng-template>
				<app-validation-errors [control]="f.value"></app-validation-errors>
			</div>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<div class="aui-dialog2-footer-actions">
				<button
					id="create-submit"
					type="submit"
					class="aui-button aui-button-primary"
					[disabled]="!form.valid || (!searchableDropdown && valueType==='pageId') || (searchableDropdown && valueType==='pageId' && parameter.value === f.value.value)"
				>
					{{ isNew ? 'Add' : 'Update' }}
				</button>
				<button type="button" id="cancel" class="aui-button aui-button-subtle" modalClose>Cancel</button>
			</div>
			<div class="aui-dialog2-footer-hint">
				<button
					id="parameter-delete"
					type="button"
					class="aui-button aui-button-link color-R300"
					*ngIf="!isNew && !isMandatoryField"
					(click)="onDelete()"
				>
					Delete Parameter
				</button>
			</div>
		</footer>
	</form>
</section>
