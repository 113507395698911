import { HttpInterceptor } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

export const PRIVATE_INTERCEPTOR = new InjectionToken<HttpInterceptor>(
	'An abstraction on Private Interceptor'
);

export const BASE_URL_INTERCEPTOR = new InjectionToken<HttpInterceptor>(
	'An abstraction on BASE_URL HttpInterceptor'
);
