import { BusinessJourney } from '@testifi-models/business-journey.model';
import { TestPlan } from '@testifi-models/test-plan';

export const MOCK_TEST_PLAN = new TestPlan();
MOCK_TEST_PLAN.summary = 'Test Plan 1';
MOCK_TEST_PLAN.description = 'Description 1';
MOCK_TEST_PLAN.xrayTestPlanKey = 'DEVELOP-1';
MOCK_TEST_PLAN.isWithoutTestPlan = false;

export const MOCK_TEST_PLAN_1 = new TestPlan();
MOCK_TEST_PLAN_1.summary =
	'Test Plan 1 Long Text Test Plan 1 Long Text Test Plan 1 Long Text Test Plan 1 Long Text';
MOCK_TEST_PLAN_1.description =
	'Description 1 Description 1 Description 1 Description 1 Description 1 Description 1 Description 1 Description 1';
MOCK_TEST_PLAN_1.xrayTestPlanKey = 'DEVELOP-14321';
MOCK_TEST_PLAN_1.isWithoutTestPlan = false;

export const MOCK_BUSINESS_JOURNEY = new BusinessJourney();
MOCK_BUSINESS_JOURNEY.name = 'Business Journey 1';
MOCK_BUSINESS_JOURNEY.description = 'Description 1';
MOCK_BUSINESS_JOURNEY.reporter = 'User 1';
MOCK_BUSINESS_JOURNEY.updateDate = '2021-09-01T00:00:00.000Z';
MOCK_BUSINESS_JOURNEY.status = 'PASSED';
MOCK_BUSINESS_JOURNEY.statusColor = '#00ff03';

export const MOCK_BUSINESS_JOURNEY_1 = new BusinessJourney();
MOCK_BUSINESS_JOURNEY_1.name =
	'Business Journey 1 Long Text Business Journey 1 Long Text Business Journey 1 Long Text Business Journey 1 Long Text';
MOCK_BUSINESS_JOURNEY_1.description =
	'Description 1 Description 1 Description 1 Description 1 Description 1 Description 1 Description 1 Description 1';
MOCK_BUSINESS_JOURNEY_1.reporter = 'User 1 Last Name Long';
MOCK_BUSINESS_JOURNEY_1.updateDate = '2021-09-01T00:00:00.000Z';
MOCK_BUSINESS_JOURNEY_1.status = 'PASSED';
MOCK_BUSINESS_JOURNEY_1.statusColor = '#ff00cc';

export const MOCK_BUSINESS_JOURNEY_BMW = new BusinessJourney();
MOCK_BUSINESS_JOURNEY_BMW.name = 'Business Journey - Appointment schedule';
MOCK_BUSINESS_JOURNEY_BMW.description = 'Journey: Drehmoment tests';
MOCK_BUSINESS_JOURNEY_BMW.reporter = 'Unassigned';
MOCK_BUSINESS_JOURNEY_BMW.createDate = '2024-07-17T13:31:00.000Z';
MOCK_BUSINESS_JOURNEY_BMW.updateDate = '2024-10-07T12:00:00.000Z';
MOCK_BUSINESS_JOURNEY_BMW.status = 'FAIL';
MOCK_BUSINESS_JOURNEY_BMW.statusColor = '#D45D52FF';
