import { NgClass, NgIf } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import {
	GIT_USERNAME_VALIDATORS,
	VALUE_VALIDATORS
} from '@testifi-shared/app-constants';
import { TestConnectionState } from '@testifi-shared/test-connection/test-connection.component';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { SvgIconComponent } from 'angular-svg-icon';
import { Subscription } from 'rxjs';
import { TestConnectionComponent } from '../../../shared/test-connection/test-connection.component';
import { ValidationErrorsComponent } from '../../../shared/validation-errors/validation-errors.component';

@Component({
	selector: 'app-git-credentials',
	templateUrl: './git-credentials.component.html',
	styleUrls: ['./git-credentials.component.less'],
	standalone: true,
	imports: [
		NgIf,
		FormsModule,
		ReactiveFormsModule,
		FlexModule,
		NgClass,
		ExtendedModule,
		ValidationErrorsComponent,
		SvgIconComponent,
		TestConnectionComponent
	]
})
export class GitCredentialsComponent implements OnInit, OnDestroy {
	@Input() auxSpace = false;
	@Input() testConnectionState = TestConnectionState.Disabled;

	@Output() testConnection = new EventEmitter<void>();
	@Output() formData = new EventEmitter<UntypedFormGroup>();

	form: UntypedFormGroup;
	isTokenHidden = true;

	private disposableBag = new Subscription();

	constructor(
		private formBuilder: UntypedFormBuilder,
		private credentialRepository: CredentialRepository
	) {}

	ngOnInit(): void {
		this.disposableBag.add(
			this.credentialRepository.credentials$.subscribe((credentials) => {
				this.form = this.formBuilder.group({
					username: new UntypedFormControl(
						credentials.gitUsername || '',
						GIT_USERNAME_VALIDATORS
					),
					token: new UntypedFormControl(
						credentials.gitToken || '',
						VALUE_VALIDATORS
					)
				});
				this.subscribeToFormChanges();

				this.testConnectionState = TestConnectionState.NotTested;
				this.formData.emit(this.form);
			})
		);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	testConnectionClick(): void {
		if (this.testConnectionState !== TestConnectionState.Disabled) {
			this.testConnection.emit();
		}
	}

	private subscribeToFormChanges(): void {
		this.disposableBag.add(
			this.form.statusChanges.subscribe(() => {
				if (this.form.get('username').value && this.form.get('token').value) {
					this.testConnectionState = TestConnectionState.NotTested;
				} else {
					this.testConnectionState = TestConnectionState.Disabled;
				}
			})
		);
	}
}
