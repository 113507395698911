/* eslint-disable functional/immutable-data */
import { CdkDropList } from '@angular/cdk/drag-drop';
import {
	CdkFixedSizeVirtualScroll,
	CdkVirtualForOf,
	CdkVirtualScrollViewport
} from '@angular/cdk/scrolling';
import { KeyValuePipe, NgFor } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuiElement } from '@testifi-models/sui-element';
import { SuiPage } from '@testifi-models/sui-page';
import { Tag } from '@testifi-models/tag';
import { XmlElements } from '@testifi-models/xml-elements';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import { SuiPageService } from '@testifi-services/sui-page.service';
import { EditTracker, State } from '@testifi-utils/edit.tracker';
import { Utils } from '@testifi-utils/utils';
import { produce } from 'immer';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { LibraryDragDropElementComponent } from '../../shared/library-drag-drop-element/library-drag-drop-element.component';

@Component({
	selector: 'app-modal-edit-sui-page-elements-element-change-name',
	templateUrl:
		'./modal-edit-sui-page-elements-element-change-name.component.html',
	styleUrls: [
		'./modal-edit-sui-page-elements-element-change-name.component.less'
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		HideOnLoadingDirective,
		FormsModule,
		NgFor,
		AutofocusDirective,
		CdkDropList,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		LibraryDragDropElementComponent,
		FlexModule,
		KeyValuePipe
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalEditSuiPageElementsElementChangeNameComponent
	implements OnInit, OnDestroy
{
	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	// ======================================================================
	// properties
	// ======================================================================

	tagGroups = new Map<string, Tag[]>();
	filteredTagGroups = new Map<string, Tag[]>();
	newTagName = '';
	oldTagName = '';
	suiElementId = 0;
	activeTagGroup = '';
	tagGroupFilter = '';
	familyTree: number[] = [];
	suiPageId: string;
	suiPage: SuiPage;
	parentItem: SuiElement;
	libraries: SuiElement;
	showTabs = true;

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private cd: ChangeDetectorRef,
		private suiPageService: SuiPageService,
		private notificationService: NotificationService,
		private loadingService: LoadingService,
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			suiPageId: string;
			suiElementStructureId: string;
			oldSuiPage: SuiPage;
			tagGroups: Map<string, Tag[]>;
			elementGroup: string;
			editTracker: EditTracker<SuiPage>;
		}
	) {}

	ngOnInit(): void {
		this.suiPageId = this.data.suiPageId;

		const tagGroups = this.data.tagGroups;
		const suiPage = this.data.oldSuiPage;
		this.tagGroups = tagGroups;
		this.activeTagGroup = this.data.elementGroup;
		this.filteredTagGroups = this.filterTagGroups(this.tagGroups);
		this.setupSuiPage(suiPage);
		this.onTagGroupChange('html');
	}

	private updateChildType(
		state: SuiElement[],
		structureId: string
	): SuiElement[] {
		return produce(state, (draft) => {
			const element = draft.find((el) => el.structureId === structureId);
			if (element) {
				element.type = this.newTagName;
			}
		});
	}

	private findElement(children: SuiElement[], structureId: string): SuiElement {
		let element = children.find((child) => child.structureId === structureId);
		if (!element) {
			children.forEach((child) => {
				const found = this.findElement(child.children, structureId);
				if (found) {
					element = found;
				}
			});
		}

		return element;
	}

	save(): void {
		let lastChild = this.parentItem.children[this.familyTree[0]];
		let parentLevel = this.parentItem;

		for (let index = 1; index < this.familyTree.length; index++) {
			parentLevel = lastChild;
			lastChild = parentLevel.children[this.familyTree[index]];
		}

		parentLevel = produce(parentLevel, (draft) => {
			draft.children = this.updateChildType(
				parentLevel.children,
				lastChild.structureId
			);
		});

		if (parentLevel.structureId === this.parentItem.structureId) {
			this.parentItem = parentLevel;
		} else {
			this.parentItem = produce(this.parentItem, (draft) => {
				const level = this.findElement(
					draft.children as SuiElement[],
					parentLevel.structureId
				);
				level.children = parentLevel.children;
			});
		}

		this.loadingService.active();
		this.data.editTracker.addState(new State<SuiPage>(this.suiPage));

		this.disposableBag.add(
			this.suiPageService
				.updateElements(
					this.suiPage.id,
					new XmlElements().fromSuiElement(this.parentItem)
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					() => {
						this.notificationService.info('The element type has been changed.');
						this.modalService.close({ structureId: lastChild.structureId });
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
					}
				)
		);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	// ======================================================================
	// functions
	// ======================================================================

	onTagGroupChange(event: string): void {
		this.activeTagGroup = event;
		this.tagGroupFilter = '';
		this.filteredTagGroups = this.filterTagGroups(this.tagGroups);
		this.cd.detectChanges();
	}

	onSearchTagGroup(query: string): void {
		this.tagGroupFilter = query;
		this.filteredTagGroups = this.filterTagGroups(this.tagGroups);
	}

	filteredTagsForGroup(group: string): Array<Tag> {
		if (this.filteredTagGroups.has(group)) {
			return this.filteredTagGroups.get(group);
		} else {
			return [];
		}
	}

	onShortcutClick(tag: Tag): void {
		this.newTagName = tag.name;
	}

	close(): void {
		this.modalService.close(null);
	}

	// ======================================================================
	// private functions
	// ======================================================================
	private filterTagGroups(
		tagGroups: Map<string, Array<Tag>>
	): Map<string, Array<Tag>> {
		const filteredTagGroups = new Map<string, Array<Tag>>();

		for (const [key, value] of tagGroups) {
			let tags = value.concat([]);

			if (this.tagGroupFilter) {
				tags = tags.filter((tag) =>
					tag.name.toLowerCase().includes(this.tagGroupFilter.toLowerCase())
				);
			}

			tags = produce(tags, (draft) =>
				draft.sort((a, b) =>
					Utils.compare(a.name.toLowerCase(), b.name.toLowerCase(), true)
				)
			);

			filteredTagGroups.set(key, tags);
		}

		return filteredTagGroups;
	}

	private setupSuiPage(suiPage: SuiPage) {
		this.suiPage = suiPage;

		const len = this.data.suiElementStructureId.split('.').length - 1;
		let pos = 0;
		let listNest: number[] = [];

		for (let index = 0; index < len; index++) {
			pos = this.data.suiElementStructureId.indexOf('.', pos) + 1;
			listNest = produce(listNest, (draft) => {
				draft.push(pos);
			});
		}

		let subStr = '';

		for (let index = 0; index < listNest.length; index++) {
			if (index + 1 < listNest.length) {
				subStr = this.data.suiElementStructureId.substring(
					listNest[index + 1] - 1,
					listNest[index]
				);
			} else {
				subStr = this.data.suiElementStructureId.substring(listNest[index]);
			}

			this.familyTree = produce(this.familyTree, (draft) => {
				draft.push(parseInt(subStr));
			});
		}

		let lastChild = this.suiPage.children[this.familyTree[0]];

		for (let index = 1; index < this.familyTree.length; index++) {
			lastChild = lastChild.children[this.familyTree[index]];
		}

		this.oldTagName = lastChild.type;
		let parentItem = new SuiElement();
		parentItem = produce(parentItem, (draft) => {
			draft.id = 0;
			draft.children = suiPage.children;
			draft.parameter = {};
			draft.type = 'sui-page';
		});

		this.parentItem = parentItem.restructure();
	}
}
