import { AsyncPipe, DOCUMENT, NgIf } from '@angular/common';
import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	DoCheck,
	Inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	inject,
	isDevMode
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import { MAT_SVG_ICONS, SVG_ICONS } from '@testifi-shared/app-constants';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { AppStatusRepository } from '@testifi-store/app-status/app-status.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { Subscription, combineLatest, distinct } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
	standalone: true,
	imports: [NgIf, RouterOutlet, FlexModule, AsyncPipe],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppComponent implements AfterViewInit, DoCheck, OnInit, OnDestroy {
	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();
	private destroyRef = inject(DestroyRef);
	pageLoading = false;

	// ======================================================================
	// public properties
	// ======================================================================
	@ViewChild('updateconfirm') updateConfirm!: TemplateRef<HTMLDivElement>;

	constructor(
		private cdRef: ChangeDetectorRef,
		@Inject(DOCUMENT) private document: Document,
		private iconReg: SvgIconRegistryService,
		private loadingService: LoadingService,
		private modalService: ModalService,
		private ngbModal: NgbModal,
		private swUpdate: SwUpdate,
		public appConfigRepository: AppConfigRepository,
		private projectRepository: ProjectRepository,
		private title: Title,
		private appStatusRepository: AppStatusRepository,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) {
		this.initIcons();
	}

	ngOnInit(): void {
		this.disposableBag.add(
			this.loadingService.get().subscribe((loading) => {
				this.pageLoading = loading && !this.loadingService.isSilent;
			})
		);

		this.disposableBag.add(
			this.modalService
				.get()
				.subscribe((isOpen) =>
					isOpen
						? this.document.body.classList.add('modal-open')
						: this.document.body.classList.remove('modal-open')
				)
		);

		void navigator.serviceWorker?.getRegistration().then((reg) => {
			// There's an active SW, but no controller for this tab.
			if (reg?.active && !navigator.serviceWorker.controller) {
				// Perform a soft reload to load everything from the SW and get
				// a consistent set of resources.
				this.document.defaultView.location.reload();
			} else {
				this.checkForUpdates();
			}
		});
		this.initializePageTitle();
	}

	ngDoCheck(): void {
		if (document.getElementsByClassName('cdk-drag-preview').length > 0) {
			this.document.body.classList.add('drag-n-drop');
		} else {
			this.document.body.classList.remove('drag-n-drop');
		}
	}

	ngAfterViewInit(): void {
		this.cdRef.detectChanges();
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	private initIcons(): void {
		for (const icon of SVG_ICONS) {
			this.iconReg.loadSvg(`assets/icons/${icon.fileName}.svg`, icon.iconName);
		}
		for (const icon of MAT_SVG_ICONS) {
			this.matIconRegistry.addSvgIcon(
				icon.iconName,
				this.domSanitizer.bypassSecurityTrustResourceUrl(
					`assets/icons/${icon.fileName}.svg`
				)
			);
		}
	}

	private checkForUpdates(): void {
		this.disposableBag.add(
			this.swUpdate.versionUpdates
				.pipe(
					filter(
						(evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
					)
				)
				.subscribe(() =>
					isDevMode() ? this.document.location.reload() : this.ask()
				)
		);
	}

	private ask(): void {
		this.ngbModal
			.open(this.updateConfirm, {
				backdrop: 'static',
				centered: true
			})
			.result.then(
				() => this.document.location.reload(),
				() => this.appStatusRepository.ignoreUpdate()
			);
	}

	private initializePageTitle() {
		combineLatest([
			this.appConfigRepository.state$,
			this.projectRepository.state$
		])
			.pipe(
				filter(([appConfig, project]) => !!appConfig && !!project),
				map(([appConfig, project]) => ({
					qaWarning: appConfig.config.qaWarning,
					projectName: project.project.name
				})),
				distinct(({ qaWarning, projectName }) => qaWarning || projectName),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(({ qaWarning, projectName }) => {
				const title = `CAST:GUI ${qaWarning ? 'QA' : ''}${
					projectName ? ` - ${projectName}` : ''
				}`;
				this.title.setTitle(title);
			});
	}
}
