import { Injectable } from '@angular/core';
import { createStore, select, Store, StoreValue, withProps } from '@ngneat/elf';
import { persistState } from '@ngneat/elf-persist-state';
import { IAuth } from '@testifi-models/auth.interface';
import {
	AUTH_REPOSITORY_STORAGE_KEY,
	INDEXED_DB_STORAGE
} from '@testifi-shared/app-constants';
import { TAuthRepository } from '@testifi-store/auth/auth.repository.type';
import { filter, Observable, OperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

export function onlyKeys<S extends Store, State extends StoreValue<S>>(
	keys: Array<keyof State>
): OperatorFunction<State, Partial<State>> {
	return pipe(
		map((state) =>
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			Object.keys(state).reduce<State>((toSave, key) => {
				if (keys.includes(key)) {
					toSave[key] = state[key];
				}

				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				return toSave;
			}, {} as State)
		)
	);
}

@Injectable({
	providedIn: 'root'
})
export class AuthRepository {
	static readonly INITIAL_VALUE: TAuthRepository = {
		email: '',
		jwt: '',
		loading: false,
		autoSSOLogin: true
	};

	private store = createStore(
		{ name: 'auth' },
		withProps<TAuthRepository>(AuthRepository.INITIAL_VALUE)
	);
	private persist = persistState(this.store, {
		key: AUTH_REPOSITORY_STORAGE_KEY,
		storage: INDEXED_DB_STORAGE,
		source: () => this.store.pipe(onlyKeys(['jwt', 'email']))
	});

	loading$: Observable<boolean> = this.store.pipe(
		select((state) => state.loading)
	);
	jwt$ = this.store.pipe(select((state) => state.jwt));
	email$ = this.store.pipe(select((state) => state.email));
	exists$ = this.store.pipe(select((state) => !!state.jwt));
	auth$: Observable<IAuth> = this.store.pipe(
		select((state) => ({ jwt: state.jwt, email: state.email }) as IAuth)
	);
	persistInitialized$ = this.persist.initialized$.pipe(filter(Boolean));

	get state(): TAuthRepository {
		return this.store.getValue();
	}

	get exists(): boolean {
		return !!this.state.jwt;
	}

	setAuth(auth: IAuth): void {
		this.store.update((state) => ({
			...state,
			...auth
		}));
	}

	removeJWT(): void {
		this.store.update((state) => ({
			...state,
			jwt: ''
		}));
	}

	setLoading(loading: boolean): void {
		this.store.update((state) => ({
			...state,
			loading
		}));
	}

	setAutoSSOLogin(autoSSOLogin: boolean): void {
		this.store.update((state) => ({
			...state,
			autoSSOLogin
		}));
	}
}
