import { Injectable } from '@angular/core';
import { XmlElements } from '@testifi-models/xml-elements';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrivateHttpClient } from '../https/instances/private.http';
import { TestStep } from '../models/test-step';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class TestStepService {
	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private privateHttp: PrivateHttpClient,
		private base: BaseApiService
	) {}

	// ======================================================================
	// public functions
	// ======================================================================

	add(
		testScenarioId: string,
		description: string,
		data: string,
		expectedResult: string,
		index: number
	): Observable<TestStep> {
		return this.privateHttp
			.post<TestStep>('test-step', {
				scenarioId: testScenarioId,
				description,
				data,
				index,
				result: expectedResult
			})
			.pipe(map((res) => this.base.model(res, TestStep)));
	}

	edit(
		id: string,
		description: string,
		result: string,
		data: string,
		testScenarioId: string,
		index: number
	): Observable<TestStep> {
		return this.privateHttp
			.put<TestStep>(`test-step/${id}`, {
				description,
				result,
				data,
				scenarioId: testScenarioId,
				index
			})
			.pipe(map((res) => this.base.model(res, TestStep)));
	}

	updateTestObjects(
		id: string,
		elementList: XmlElements
	): Observable<TestStep> {
		return this.privateHttp
			.post<TestStep>(`test-step/${id}/updateTestObject`, elementList)
			.pipe(map((res) => this.base.model(res, TestStep)));
	}

	removeObjects(id: string, testObjectIds: string[]): Observable<boolean> {
		return this.privateHttp
			.post<boolean>(`/test-object/${id}/test-objects-remove`, {
				testObjectIds
			})
			.pipe(map((res) => res));
	}

	delete(id: string): Observable<boolean> {
		return this.privateHttp
			.delete<boolean>(`test-step/${id}`)
			.pipe(map(() => true));
	}

	view(id: string): Observable<TestStep> {
		return this.privateHttp
			.get<TestStep>(`test-step/${id}`)
			.pipe(map((res) => this.base.model(res, TestStep)));
	}
}
