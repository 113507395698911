<div id="page-home" class="page-container" *hideOnLoading>
	<header class="with-pro-toggle-button with-more-button">
		<app-breadcrumb></app-breadcrumb>
		<app-pro-toggle-button
			(essentialClicked)="essentialClicked()"
			(proClicked)="proClicked()"
			[proActive]="proActive$ | async"
		></app-pro-toggle-button>
		<button
			#buttonMore
			mat-icon-button
			type="button"
			id="more-actions"
			class="more-actions-button aui-dropdown2-trigger aui-dropdown2-trigger-arrowless"
			aria-controls="compact-button-dropdown"
		>
			<mat-icon svgIcon="hamburger" />
		</button>

		<aui-dropdown-menu id="compact-button-dropdown">
			<aui-section>
				<aui-item-link [appRouterName]="['settings_credentials']" [appRouterData]="{ id: (projectId$ | async) }"
					>Credentials</aui-item-link
				>
				<aui-item-link [appRouterName]="['project_settings']" [appRouterData]="{ id: (projectId$ | async) }"
					>Project Settings</aui-item-link
				>
			</aui-section>
			<aui-section>
				<aui-item-link
					[modalData]="{
								title: 'Plugin Version Information',
								text: 'UI: ' + (appConfigRepository.uiVersion$ | async),
								text2: 'API: ' + (appConfigRepository.apiVersion$ | async),
								confirmButton: 'Close',
								cancelButton: '',
								extButton: buttonMore
							}"
					[modalRouterName]="['confirm-popup']"
					>Version Info</aui-item-link
				>
			</aui-section>
			<aui-section>
				<aui-item-link (keyup.space)="signOut()" (keyup.enter)="signOut()" (click)="signOut()">Sign out</aui-item-link>
			</aui-section>
		</aui-dropdown-menu>
	</header>
	<main class="full-height-main">
		<h1 class="greeting">{{greetingTitle}}</h1>
		<p class="gui-description">
			Welcome to the GUI!
			<br />Manage your testing with ease using Business Journeys, Scenarios, and Steps — all organized within Jira
			environment to ease your workflow.
		</p>

		<div class="boxes">
			<ng-container *ngIf="!(proActive$ | async);else proActiveNavigationItems">
				<app-library-navigation-item
					[disabled]="true"
					itemTitle="Business Journeys"
					[description]="testPlansCount$ | async"
				/>
				<div class="box-switch-pro">
					<div class="box-switch-pro-description">Switch to Pro mode to browse the complete set</div>
				</div>
			</ng-container>
			<ng-template #proActiveNavigationItems>
				<app-library-navigation-item
					[disabled]="true"
					itemTitle="Business Journeys"
					[description]="testPlansCount$ | async"
				/>
				<app-library-navigation-item
					[route]="['projects/' + (projectId$ | async) + '/test-scenarios']"
					itemTitle="Scenarios"
					[description]="scenariosCount$ | async"
				/>
				<app-library-navigation-item
					[route]="['projects/' + (projectId$ | async) + '/sui-pages']"
					itemTitle="SUI pages"
					[description]="suiPagesCount$ | async"
				/>
				<app-library-navigation-item
					[route]="['projects/' + (projectId$ | async) + '/custom-building-blocks']"
					itemTitle="CBBs"
					[description]="cbbsCount$ | async"
				/>
			</ng-template>
		</div>
		<div class="compact-table">
			<ng-container *ngIf="!(proActive$ | async);else proActiveCompactTable">
				<h3>Last browsed Business Journeys</h3>
				<app-last-browsed-business-journeys></app-last-browsed-business-journeys>
			</ng-container>
			<ng-template #proActiveCompactTable>
				<h3>Last executed scenarios</h3>
				<app-last-executed-scenarios></app-last-executed-scenarios>
			</ng-template>
		</div>
	</main>
</div>
