import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { TAppConfiguration } from '@testifi-models/app-configuration.interface';
import { EEnvironment } from '@testifi-models/environment.enum';
import { NotificationService } from '@testifi-services/notification.service';
import { RouterService } from '@testifi-services/router.service';
import { UserService } from '@testifi-services/user.service';
import { PAGES } from '@testifi-shared/app-constants';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { AuthRepository } from '@testifi-store/auth/auth.repository';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { TCredentials } from '@testifi-store/credentials/credential.repository.type';
import {
	catchError,
	combineLatest,
	Observable,
	switchMap,
	take,
	throwError,
	withLatestFrom
} from 'rxjs';

@Injectable()
export class PrivateInterceptor implements HttpInterceptor {
	private readonly authRepository = inject(AuthRepository);
	private readonly notificationService = inject(NotificationService);
	private readonly routerService = inject(RouterService);
	private readonly appConfigRepository = inject(AppConfigRepository);
	private readonly credentialsRepository = inject(CredentialRepository);
	private readonly userService = inject(UserService);

	intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return combineLatest([
			this.authRepository.jwt$.pipe(take(1)),
			this.appConfigRepository.environment$.pipe(take(1))
		]).pipe(
			take(1),
			switchMap(([jwt, environment]) => {
				const updatedRequest = this.cloneRequestWithAuthorizationHeader(
					req,
					jwt
				);
				return next.handle(updatedRequest).pipe(
					catchError((err: HttpErrorResponse) => {
						// In every environment, the user should be redirected to the login page if the status is 403.
						if (
							err.status === 403 &&
							(environment === EEnvironment.SERVER ||
								environment === EEnvironment.SERVER_SSO)
						) {
							// Later on, we need to remove JWT TOKEN. But for now it is commented out.
							// this.authRepository.removeJWT(); // It will remove the JWT token from the store as well, because persistState is used
							return this.navigateToCredentialsPage(err, 'Please login again.');
						} else if (
							err.status === 403 &&
							environment === EEnvironment.CLOUD
						) {
							// if we have the credentials in credentialsDao, then relogin again and if the relogin was successful, run the original request again. If not successful, redirect to the login page. If credentials are not available, redirect to the login page.
							return this.credentialsRepository.credentials$.pipe(
								withLatestFrom(
									this.appConfigRepository.config$,
									this.authRepository.email$
								),
								switchMap(([credentials, config, email]) => {
									if (!credentials) {
										return this.navigateToCredentialsPage(err);
									} else {
										return this.reLoginAndRetryRequest({
											credentials,
											config,
											email,
											req,
											next
										});
									}
								}),
								catchError((error) => this.navigateToCredentialsPage(error))
							);
						} else {
							return throwError(() => err);
						}
					})
				);
			})
		);
	}

	private cloneRequestWithAuthorizationHeader(
		req: HttpRequest<unknown>,
		jwt: string
	): HttpRequest<unknown> {
		return req.clone({
			setHeaders: {
				authorization: `Bearer ${jwt}`
			}
		});
	}

	private navigateToCredentialsPage(error, errorMessage?: string) {
		if (errorMessage) {
			this.notificationService.error(errorMessage);
		}
		this.routerService.navigate([PAGES.CREDENTIALS]);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return throwError(() => error);
	}

	private reLoginAndRetryRequest({
		email,
		config,
		credentials,
		req,
		next
	}: {
		credentials: TCredentials;
		config: TAppConfiguration;
		email: string;
		req: HttpRequest<unknown>;
		next: HttpHandler;
	}) {
		return this.userService
			.login({
				...credentials,
				jiraUrl: config.jiraURL,
				email
			})
			.pipe(
				switchMap((newAuth) => {
					this.authRepository.setAuth(newAuth);
					const retriedRequest = this.cloneRequestWithAuthorizationHeader(
						req,
						newAuth.jwt
					);
					return next.handle(retriedRequest);
				}),
				catchError((error) => this.navigateToCredentialsPage(error))
			);
	}
}
