import { Component, Inject, OnInit } from '@angular/core';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup,
	ValidatorFn
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from '@testifi-services/modal.service';
import { VALUE_VALIDATORS } from '@testifi-shared/app-constants';
import { ModalCloseDirective } from '../../directives/modal-close.directive';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';

export interface IModalFreeTextData {
	validators?: ValidatorFn[];
	modalTitle: string;
	fieldLabel: string;
}

@Component({
	templateUrl: './modal-free-text.component.html',
	styleUrls: ['./modal-free-text.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ValidationErrorsComponent,
		ModalCloseDirective
	]
})
export class ModalFreeTextComponent implements OnInit {
	form: UntypedFormGroup;
	submitted = false;

	get f(): { [key: string]: AbstractControl } {
		return this.form.controls;
	}

	constructor(
		private formBuilder: UntypedFormBuilder,
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA)
		public data: IModalFreeTextData
	) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			text: ['', this.data.validators ?? VALUE_VALIDATORS]
		});
	}

	addFreeText(): void {
		this.submitted = true;
		this.modalService.close(this.f.text.value);
	}
}
