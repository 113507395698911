/* eslint-disable functional/immutable-data */
import { Component, OnInit } from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	Validators
} from '@angular/forms';
import { ICredentialsPayload } from '@testifi-models/credentials.interface';
import { NotificationService } from '@testifi-services/notification.service';
import { UserService } from '@testifi-services/user.service';
import { TestConnectionState } from '@testifi-shared/test-connection/test-connection.component';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { AuthRepository } from '@testifi-store/auth/auth.repository';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { SvgIconComponent } from 'angular-svg-icon';
import { combineLatest } from 'rxjs';
import { TrimOnPasteDirective } from '../../../directives/trim-on-paste.directive';
import { TestConnectionComponent } from '../../../shared/test-connection/test-connection.component';
import { ValidationErrorsComponent } from '../../../shared/validation-errors/validation-errors.component';
import { BaseCredentials } from '../base-credentials';

@Component({
	selector: 'app-jira-xray-credentials',
	templateUrl: './jira-xray-credentials.component.html',
	styleUrls: ['./jira-xray-credentials.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ValidationErrorsComponent,
		SvgIconComponent,
		TrimOnPasteDirective,
		TestConnectionComponent
	]
})
export class JiraXrayCredentialsComponent
	extends BaseCredentials
	implements OnInit
{
	isTokenHidden = true;
	isXraySecretHidden = true;
	isXrayIdHidden = true;

	constructor(
		private formBuilder: UntypedFormBuilder,
		private appConfigRepository: AppConfigRepository,
		private credentialRepository: CredentialRepository,
		private authRepository: AuthRepository,
		userService: UserService,
		notificationService: NotificationService
	) {
		super(userService, notificationService);
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required]],
			token: ['', [Validators.required]],
			xrayClientId: ['', [Validators.required]],
			xrayClientSecret: ['', [Validators.required]]
		});

		this.disposableBag.add(
			combineLatest([
				this.credentialRepository.credentials$,
				this.authRepository.email$
			]).subscribe(([credentials, email]) => {
				if (email) {
					this.form.get('email').setValue(email);
				}
				this.form.get('token').setValue(credentials.jiraApiToken);
				this.form.get('xrayClientId').setValue(credentials.xrayClientId);
				this.form
					.get('xrayClientSecret')
					.setValue(credentials.xrayClientSecret);
				this.testConnectionState = TestConnectionState.NotTested;

				this.subscribeToFormChanges();

				this.formData.emit(this.form);
				this.dataState.emit(this.testConnectionState);
			})
		);
	}

	testConnection(): void {
		const credentials: ICredentialsPayload = {
			email: this.form.get('email').value as string,
			jiraApiToken: this.form.get('token').value as string,
			jiraUrl: this.appConfigRepository.config.jiraURL,
			xrayClientId: this.form.get('xrayClientId').value as string,
			xrayClientSecret: this.form.get('xrayClientSecret').value as string,
			gitUsername: '',
			gitToken: '',
			token: false
		};

		super.testConnection(credentials);
	}
}
