import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'quotes',
	standalone: true
})
export class QuotesPipe implements PipeTransform {
	transform(value: string): string {
		return value;
	}
}
