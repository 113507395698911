import {
	AfterViewInit,
	Directive,
	Host,
	Input,
	OnDestroy,
	Optional,
	Renderer2,
	Self,
	ViewContainerRef
} from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Subscription } from 'rxjs';

interface PageObject {
	length: number;
	pageIndex: number;
	pageSize: number;
	previousPageIndex: number;
}

@Directive({
	selector: '[appStylePaginator]',
	standalone: true
})
export class StylePaginatorDirective implements AfterViewInit, OnDestroy {
	private _pageGapTxt = '...';
	private _rangeStart: number;
	private _rangeEnd: number;
	private _buttons: MatButton[] = [];
	private _pageNumbers: number[] = [];
	private _curPageObj: PageObject = {
		length: 0,
		pageIndex: 0,
		pageSize: 0,
		previousPageIndex: 0
	};
	private _showTotalPages = 2;
	private disposableBag = new Subscription();

	@Input()
	get pageNumbersToShow(): number {
		return this._showTotalPages;
	}
	set pageNumbersToShow(value: number) {
		this._showTotalPages = value % 2 === 0 ? value + 1 : value;
	}

	get inc(): number {
		return this._showTotalPages % 2 === 0
			? this.pageNumbersToShow / 2
			: (this.pageNumbersToShow - 1) / 2;
	}

	get numOfPages(): number {
		return this.matPag.getNumberOfPages();
	}

	get lastPageIndex(): number {
		return this.matPag.getNumberOfPages() - 1;
	}

	constructor(
		@Host() @Self() @Optional() private readonly matPag: MatPaginator,
		private vr: ViewContainerRef,
		private ren: Renderer2
	) {
		//to rerender buttons on items per page change and first, last, next and prior buttons
		this.disposableBag.add(
			this.matPag.page.subscribe((e: PageObject) => {
				this.checkHowMuchPageNumbersToShow();

				if (
					this._curPageObj.pageSize !== e.pageSize &&
					this._curPageObj.pageIndex
				) {
					e.pageIndex = 0;
					this._rangeStart = 0;
					this._rangeEnd = this._showTotalPages - 1;
				}
				this._curPageObj = e;

				this.initPageRange();
			})
		);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	//Initialize default state after view init
	ngAfterViewInit(): void {
		this.checkHowMuchPageNumbersToShow();

		this._rangeStart = 0;
		this._rangeEnd = this._showTotalPages - 1;
		this.initPageRange();
	}

	private checkHowMuchPageNumbersToShow(): void {
		if (this.pageNumbersToShow > this.matPag.getNumberOfPages()) {
			this.pageNumbersToShow = this.matPag.getNumberOfPages() - 1;
		} else {
			this.pageNumbersToShow = 4;
		}
	}

	private removeButtons(actionContainer: HTMLDivElement): void {
		if (this._buttons.length > 0) {
			this._buttons.forEach((button) => {
				this.ren.removeChild(actionContainer, button);
			});
			//Empty state array
			this._buttons.length = 0;
		}
	}

	private initButtons(): void {
		if (!this._buttons.length) {
			// eslint-disable-next-line
			const nodeArray = this.vr.element.nativeElement.childNodes[0]
				.childNodes[0].childNodes[2].childNodes as HTMLButtonElement[];
			setTimeout(() => {
				nodeArray.forEach((node) => {
					if (node.nodeName === 'BUTTON') {
						if (node.innerHTML.length > 100 && node.disabled) {
							this.ren.setStyle(node, 'background-color', 'transparent');
							this.ren.setStyle(node, 'color', 'rgba(200, 200, 200, 1)');
						} else if (node.innerHTML.length > 100 && !node.disabled) {
							this.ren.setStyle(node, 'background-color', 'rgba(255, 0, 0, 0)');
							this.ren.setStyle(node, 'color', '#172b4d');
						} else if (node.disabled) {
							this.ren.setStyle(node, 'color', '#172b4d');
						}
					}
				});
			});
		}
	}

	private createButtons(
		actionContainer: HTMLDivElement,
		nextPageNode: HTMLButtonElement
	): void {
		this._pageNumbers = [];

		for (let i = 0; i < this.numOfPages + 1; i++) {
			if (
				i === 0 &&
				this._curPageObj.pageIndex > 3 &&
				this.numOfPages !== this.pageNumbersToShow + 1
			) {
				this.addButton(
					i,
					actionContainer,
					nextPageNode,
					this._curPageObj.pageIndex
				);
				this.addButton(-1, actionContainer, nextPageNode, this._rangeEnd);
			} else if (i === 0 && this.matPag.pageIndex > 2) {
				this.addButton(
					i,
					actionContainer,
					nextPageNode,
					this._curPageObj.pageIndex
				);
			}

			if (i >= this._rangeStart && i <= this._rangeEnd) {
				this.addButton(
					i,
					actionContainer,
					nextPageNode,
					this._curPageObj.pageIndex
				);
			}

			if (i === this._rangeEnd && this.numOfPages - 1 !== i) {
				if (i !== this.numOfPages - 2) {
					this.addButton(
						-1,
						actionContainer,
						nextPageNode,
						this._curPageObj.pageIndex
					);
				}
				this.addButton(
					this.numOfPages - 1,
					actionContainer,
					nextPageNode,
					this._rangeEnd
				);
			}
		}
	}

	private buildPageNumbers() {
		// eslint-disable-next-line
		const actionContainer = this.vr.element.nativeElement.querySelector(
			'div.mat-paginator-range-actions'
		) as HTMLDivElement;
		// eslint-disable-next-line
		const nextPageNode = this.vr.element.nativeElement.querySelector(
			'button.mat-paginator-navigation-next'
		) as HTMLButtonElement;
		// remove buttons before creating new ones
		this.removeButtons(actionContainer);
		//initialize next page and last page buttons
		this.initButtons();

		if (this._rangeEnd === this.numOfPages) {
			this._rangeEnd--;
		}

		this.createButtons(actionContainer, nextPageNode);
	}

	private addButton(
		i: number,
		actionContainer: HTMLDivElement,
		nextPageNode: HTMLButtonElement,
		index: number
	): void {
		if (!this._pageNumbers.includes(i) || i < 0) {
			this.ren.insertBefore(
				actionContainer,
				this.createButton(i, index),
				nextPageNode
			);
			this._pageNumbers.push(i);
		}
	}

	private createButton(i: number, pageIndex: number): MatButton {
		const linkBtn = this.ren.createElement('button') as MatButton;
		this.ren.setStyle(linkBtn, 'border', '0');
		this.ren.setStyle(linkBtn, 'background-color', 'white');
		this.ren.setStyle(linkBtn, 'color', '#0052CC');
		this.ren.setStyle(linkBtn, 'font-size', '14px');
		this.ren.setAttribute(linkBtn, 'id', `page${i + 1}`);

		const pagingTxt = i === -1 ? this._pageGapTxt : `${i + 1}`;
		const text = this.ren.createText(pagingTxt) as string;

		this.ren.addClass(linkBtn, 'mat-custom-page');

		switch (i) {
			case pageIndex:
				this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
				this.ren.setStyle(linkBtn, 'cursor', 'default');
				break;
			case -1: {
				if (pageIndex === this.lastPageIndex) {
					this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
				}

				this.ren.setAttribute(linkBtn, 'id', 'pageGap');
				break;
			}
			default:
				this.ren.setStyle(linkBtn, 'cursor', 'pointer');
				this.ren.listen(linkBtn, 'click', () => {
					this.switchPage(i);
				});
				break;
		}

		this.ren.appendChild(linkBtn, text);
		//Add button to private array for state
		this._buttons.push(linkBtn);

		return linkBtn;
	}

	//calculates the button range based on class input parameters and based on current page index value. Used to render new buttons after event.
	private initPageRange(): void {
		const middleIndex = (this._rangeStart + this._rangeEnd) / 2;

		this._rangeStart = this.calcRangeStart(middleIndex);
		this._rangeEnd = this.calcRangeEnd(middleIndex);

		this.buildPageNumbers();
	}

	//Helper function To calculate start of button range
	private calcRangeStart(middleIndex: number): number {
		switch (true) {
			case (this._curPageObj.pageIndex === 0 && this._rangeStart !== 0) ||
				this.numOfPages === this.pageNumbersToShow + 1:
				return 0;
			case this._curPageObj.pageIndex > this._rangeEnd:
				return this._curPageObj.pageIndex + this.inc > this.lastPageIndex
					? this.lastPageIndex - this.inc * 2
					: this._curPageObj.pageIndex - this.inc;
			case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
				this._curPageObj.pageIndex > middleIndex &&
				this._rangeEnd < this.lastPageIndex:
				return this._rangeStart + 1;
			case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
				this._curPageObj.pageIndex < middleIndex &&
				this._rangeStart > 0:
				return this._rangeStart - 1;
			default:
				return this._rangeStart;
		}
	}

	//Helpter function to calculate end of button range
	private calcRangeEnd(middleIndex: number): number {
		switch (true) {
			case this._curPageObj.pageIndex === 0 &&
				this._rangeEnd !== this._showTotalPages:
				return this._showTotalPages - 1 > this.numOfPages
					? this.numOfPages
					: this._showTotalPages - 1;
			case this._curPageObj.pageIndex > this._rangeEnd:
				return this._curPageObj.pageIndex + this.inc > this.lastPageIndex
					? this.lastPageIndex
					: this._curPageObj.pageIndex + 1;
			case this._curPageObj.pageIndex > this._curPageObj.previousPageIndex &&
				this._curPageObj.pageIndex > middleIndex &&
				this._rangeEnd < this.lastPageIndex:
				return this._rangeEnd + 1;
			case this._curPageObj.pageIndex < this._curPageObj.previousPageIndex &&
				this._curPageObj.pageIndex < middleIndex &&
				this._rangeStart >= 0 &&
				this._rangeEnd > this._showTotalPages - 1:
				return this._rangeEnd - 1;
			default:
				return this._rangeEnd;
		}
	}

	//Helper function to switch page on non first, last, next and previous buttons only.
	private switchPage(i: number): void {
		const previousPageIndex = this.matPag.pageIndex;
		this.matPag.pageIndex = i;
		// eslint-disable-next-line
		this.matPag['_emitPageEvent'](previousPageIndex);
		this.initPageRange();
	}
}
