/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { devTools } from '@ngneat/elf-devtools';
import 'zone.js';
import { AppComponent } from './app/app.component';
import { APPLICATION_CONFIG } from './app/application.config';
import { environment } from './environments/environment';

declare const AJS, AP;

const bootstrap = () => {
	bootstrapApplication(AppComponent, APPLICATION_CONFIG)
		.then(() => {
			devTools();
		})
		.catch((err) => console.error(err));
};

if (environment.production) {
	enableProdMode();
}

if (AP) {
	// eslint-disable-next-line
	AJS.toInit(() => {
		bootstrap();
	});
} else {
	bootstrap();
}
