import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { Observable, switchMap, take } from 'rxjs';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
	private readonly appConfigRepository = inject(AppConfigRepository);

	intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return this.appConfigRepository.apiUrl$.pipe(
			take(1),
			switchMap((apiUrl) => {
				const modifiedApiUrl = req.clone({
					url: `${apiUrl}${req.url}`
				});
				return next.handle(modifiedApiUrl);
			})
		);
	}
}
