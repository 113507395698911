<div #textWrapper class="text-wrapper">
	<span
		class="text-span"
		[ngClass]="{'ellipsis': overflowed && !more, 'full': more}"
		[class.ellipsis]="overflowed"
		[style.width]="overflowed ? (widthPx - 75) + 'px' : '100%'"
		#textSpan
		>{{text}}
		<span
			*ngIf="more"
			class="show-more"
			(click)="lessClicked($event)"
			(keyup.enter)="lessClicked($event)"
			(keyup.space)="lessClicked($event)"
			>Show less</span
		>
	</span>
	<span
		*ngIf="overflowed && !more"
		class="show-more"
		(click)="moreClicked($event)"
		(keyup.enter)="moreClicked($event)"
		(keyup.space)="moreClicked($event)"
		>Show more</span
	>
</div>
