import { Directive, HostListener } from '@angular/core';
import { ModalService } from '../services/modal.service';

@Directive({
	selector: '[modalClose]', // eslint-disable-line
	standalone: true
})
export class ModalCloseDirective {
	constructor(private modalService: ModalService) {}

	@HostListener('click', ['$event'])
	onClick($event: Event): void {
		$event.stopPropagation();
		this.modalService.close(null);
	}
}
