import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TestObject } from '@testifi-models/test-object';
import { ValidationType } from '@testifi-models/validation-type';
import { ModalData } from '@testifi-services/modal.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { AppendDragThumbDirective } from '../../directives/append-drag-thumb.directive';
import { ModalRouterNameDirective } from '../../directives/modal-router-name.directive';
import { QuotesPipe } from '../../pipes/quotes.pipe';

export enum ExpandableLibraryPanelType {
	General,
	BuildingBlock
}
@Component({
	selector: 'app-expandable-library-panel',
	templateUrl: './expandable-library-panel.component.html',
	styleUrls: ['./expandable-library-panel.component.less'],
	standalone: true,
	imports: [
		NgClass,
		ExtendedModule,
		MatExpansionModule,
		AppendDragThumbDirective,
		NgIf,
		MatIconModule,
		SvgIconComponent,
		ModalRouterNameDirective,
		NgFor,
		QuotesPipe
	]
})
export class ExpandableLibraryPanelComponent {
	@Input() identifier: string;
	@Input() type = ExpandableLibraryPanelType.General;
	@Input() name: string;
	@Input() description: string;
	@Input() testObjects: TestObject[];
	@Input() editModalData: ModalData;
	@Input() editModalRouterName: string;
	@Input() editModalOnClose: (data: ModalData) => void;
	@Input() deleteModalData: ModalData;
	@Input() deleteModalRouterName: string;
	@Input() deleteModalOnClose: (data: ModalData) => void;
	@Input() expanded: boolean;
	@Input() prefix: boolean;
	@Input() draggable = false;
	@Input() editable = false;
	@Input() deletable = false;
	@Input() showIndex = false;
	@Input() showValidation = false;

	@Output() expand = new EventEmitter<boolean>();

	types = ExpandableLibraryPanelType;
	validationType = ValidationType;
	thumb = '<span class="drag-thumb"></span>';

	onExpand(): void {
		this.expand.emit(this.expanded);
	}
}
