/* eslint-disable functional/immutable-data */
import { AsyncPipe, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	HostListener,
	OnDestroy
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { ICredentialsPayload } from '@testifi-models/credentials.interface';
import { GitConnection } from '@testifi-services/git.service';
import { LoadingService } from '@testifi-services/loading.service';
import { NotificationService } from '@testifi-services/notification.service';
import { ProjectService } from '@testifi-services/project.service';
import { SsoAuthenticationService } from '@testifi-services/sso-authentication.service';
import { SSOPayload, UserService } from '@testifi-services/user.service';
import { PAGES } from '@testifi-shared/app-constants';
import { TestConnectionState } from '@testifi-shared/test-connection/test-connection.component';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { AuthRepository } from '@testifi-store/auth/auth.repository';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { RouterService } from './../../services/router.service';
import { GitCredentialsComponent } from './git-credentials/git-credentials.component';
import { JiraXrayCredentialsComponent } from './jira-xray-credentials/jira-xray-credentials.component';
import {
	APIMode,
	ServerCredentialsComponent
} from './server-credentials/server-credentials.component';
import { SsoCredentialsComponent } from './sso-credentials/sso-credentials.component';

@Component({
	selector: 'app-page-credentials-settings',
	templateUrl: './page-credentials-settings.component.html',
	styleUrls: ['./page-credentials-settings.component.less'],
	standalone: true,
	imports: [
		HideOnLoadingDirective,
		MatLegacyTabsModule,
		FlexModule,
		NgIf,
		JiraXrayCredentialsComponent,
		ServerCredentialsComponent,
		SsoCredentialsComponent,
		GitCredentialsComponent,
		AsyncPipe
	]
})
export class PageCredentialsSettingsComponent
	implements AfterViewInit, OnDestroy
{
	// ======================================================================
	// public properties
	// ======================================================================

	formPersonal: UntypedFormGroup;
	formGIT: UntypedFormGroup;
	serverMode = APIMode.Password;
	projectId = '';
	testConnectionStateJira = TestConnectionState.Disabled;
	testConnectionStateGit = TestConnectionState.Disabled;

	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	constructor(
		public appConfigRepository: AppConfigRepository,
		public ssoAuthService: SsoAuthenticationService,
		private credentialRepository: CredentialRepository,
		private notificationService: NotificationService,
		private userService: UserService,
		private routerService: RouterService,
		private projectService: ProjectService,
		private loadingService: LoadingService,
		private cdr: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute,
		private authRepository: AuthRepository,
		private projectRepository: ProjectRepository,
		private router: Router
	) {
		this.disposableBag.add(
			this.activatedRoute.queryParamMap.subscribe(
				(params) => (this.projectId = params.get('id') ?? '')
			)
		);
	}

	@HostListener('window:popstate', ['$event'])
	onPopState(): void {
		this.routerService.navigate([
			PAGES.LANDING,
			this.appConfigRepository.config.jiraURL
		]);
	}

	ngAfterViewInit(): void {
		// NOTE: existence of projectId shows if the user logged in or not. This component is used for both loggedIn and loggedOut cases
		if (!this.projectId) {
			const existAuth = this.authRepository.exists;
			if (existAuth) {
				setTimeout(() => {
					this.routerService.navigate([PAGES.LANDING]);
				}, 500);
			}
		}
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	onJiraFormData(form: UntypedFormGroup): void {
		this.formPersonal = form;
	}

	onJiraDataState(state: TestConnectionState): void {
		this.testConnectionStateJira = state;
	}

	onGitFormData(form: UntypedFormGroup): void {
		this.formGIT = form;
	}

	testConnectionGit(): void {
		const { gitUrl, gitBranch } = this.projectRepository.projectSettings;
		if (!gitUrl || !gitBranch) {
			this.notificationService.warning(
				'GIT URL and GIT branch must be set. Please check Project settings page.'
			);
			return;
		}

		this.loadingService.active();

		const payload: GitConnection = {
			username: this.formGIT.get('username').value as string,
			password: this.formGIT.get('token').value as string,
			repoURL: gitUrl,
			branch: gitBranch,
			skipSSLVerify: true
		};

		this.disposableBag.add(
			this.projectService
				.validateGitSettings(payload)
				.pipe(finalize(() => this.loadingService.deactive(this.cdr)))
				.subscribe(
					(valid) =>
						(this.testConnectionStateGit = valid
							? TestConnectionState.TestedOk
							: TestConnectionState.TestedWrong),
					() => (this.testConnectionStateGit = TestConnectionState.TestedWrong)
				)
		);
	}

	onServerMode(mode: APIMode): void {
		this.serverMode = mode;
	}

	savePersonal(): void {
		if (this.appConfigRepository.isSSO) {
			return this.savePersonalSSO();
		}

		const credentials: ICredentialsPayload = {
			email: this.formPersonal.get(
				this.appConfigRepository.isCloud ? 'email' : 'username'
			).value as string,
			jiraApiToken: this.formPersonal.get(
				this.appConfigRepository.isCloud ||
					(!this.appConfigRepository.isCloud &&
						this.serverMode === APIMode.Token)
					? 'token'
					: 'password'
			).value as string,
			jiraUrl: this.appConfigRepository.config.jiraURL,
			xrayClientId: this.appConfigRepository.isCloud
				? (this.formPersonal.get('xrayClientId').value as string)
				: 'null',
			xrayClientSecret: this.appConfigRepository.isCloud
				? (this.formPersonal.get('xrayClientSecret').value as string)
				: 'null',
			gitUsername: (this.formGIT?.get('username').value as string) || '',
			gitToken: (this.formGIT?.get('token').value as string) || '',
			token: this.serverMode === APIMode.Token
		};

		this.loadingService.active();

		this.disposableBag.add(
			this.userService
				.login(credentials)
				.pipe(
					tap((auth) => {
						const { email, jiraUrl, ...savingCredentials } = credentials;
						this.authRepository.setAuth(auth);
						this.credentialRepository.setCredentials(savingCredentials);
					}),
					finalize(() => this.loadingService.deactive(this.cdr))
				)
				.subscribe(
					() => {
						this.notificationService.info('Login successful');
						this.close();
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
					}
				)
		);
	}

	close(): void {
		if (this.routerService.getBrowserRequestedRoute()) {
			this.redirectToBrowserRequestedRoute();
		} else if (this.appConfigRepository.config.jiraURL) {
			this.routerService.navigate([PAGES.LANDING]);
		} else {
			this.routerService.navigate([PAGES.ENABLED_PROJECTS]);
		}
	}

	private savePersonalSSO(): void {
		const ssoToken = this.ssoAuthService.token;
		const payload: SSOPayload = {
			ssoJwtToken: this.ssoAuthService.idToken,
			jiraToken: this.formPersonal.get('token').value as string,
			jiraUrl: this.appConfigRepository.config.jiraURL,
			groups: ssoToken?.groups ?? []
		};

		const credentials: ICredentialsPayload = {
			email: '',
			jiraApiToken: payload.jiraToken,
			jiraUrl: payload.jiraUrl,
			xrayClientId: 'null',
			xrayClientSecret: 'null',
			gitUsername: (this.formGIT?.get('username').value as string) || '',
			gitToken: (this.formGIT?.get('token').value as string) || '',
			token: true
		};

		this.loadingService.active();

		this.disposableBag.add(
			this.userService
				.loginSSO(payload)
				.pipe(
					tap((auth) => {
						this.authRepository.setAuth(auth);
						credentials.email = auth.email;
						const { email, jiraUrl, ...savingCredentials } = credentials;
						this.credentialRepository.setCredentials(savingCredentials);
					}),
					finalize(() => this.loadingService.deactive(this.cdr))
				)
				.subscribe(
					() => {
						this.notificationService.info('Login successful');
						if (this.routerService.getBrowserRequestedRoute()) {
							this.redirectToBrowserRequestedRoute();
						} else {
							this.routerService.navigate([PAGES.LANDING]);
						}
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}

	private redirectToBrowserRequestedRoute(): void {
		this.router.navigate([this.routerService.getBrowserRequestedRoute()]);
		this.routerService.clearBrowserRequestedRoute();
	}
}
