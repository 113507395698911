import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import {
	GIT_BRANCH_VALIDATORS,
	URLStringValidator
} from '@testifi-shared/app-constants';
import { ValidationErrorsComponent } from '../../../shared/validation-errors/validation-errors.component';

@Component({
	selector: 'app-git-settings',
	templateUrl: './git-settings.component.html',
	styleUrls: ['./git-settings.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		FlexModule,
		ValidationErrorsComponent
	]
})
export class GitSettingsComponent implements OnInit {
	@Input() gitUrl = '';
	@Input() gitBranch = '';

	@Output() formData = new EventEmitter<UntypedFormGroup>();

	form: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			url: new UntypedFormControl(this.gitUrl, [
				URLStringValidator,
				Validators.maxLength(1000)
			]),
			branch: new UntypedFormControl(this.gitBranch, GIT_BRANCH_VALIDATORS)
		});

		this.formData.emit(this.form);
	}
}
