/* eslint-disable functional/immutable-data */
import { DOCUMENT, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { ICredentialsPayload } from '@testifi-models/credentials.interface';
import { NotificationService } from '@testifi-services/notification.service';
import { SsoAuthenticationService } from '@testifi-services/sso-authentication.service';
import { UserService } from '@testifi-services/user.service';
import { TestConnectionState } from '@testifi-shared/test-connection/test-connection.component';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { SvgIconComponent } from 'angular-svg-icon';
import { TestConnectionComponent } from '../../../shared/test-connection/test-connection.component';
import { ValidationErrorsComponent } from '../../../shared/validation-errors/validation-errors.component';
import { BaseCredentials } from '../base-credentials';

@Component({
	selector: 'app-sso-credentials',
	templateUrl: './sso-credentials.component.html',
	styleUrls: ['./sso-credentials.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		FlexModule,
		NgIf,
		SvgIconComponent,
		ValidationErrorsComponent,
		TestConnectionComponent
	]
})
export class SsoCredentialsComponent extends BaseCredentials implements OnInit {
	form: UntypedFormGroup;
	testConnectionState = TestConnectionState.Disabled;
	isHidden = true;

	constructor(
		public ssoAuthService: SsoAuthenticationService,
		private formBuilder: UntypedFormBuilder,
		private appConfigRepository: AppConfigRepository,
		private credentialRepository: CredentialRepository,
		@Inject(DOCUMENT) private document: Document,
		userService: UserService,
		notificationService: NotificationService
	) {
		super(userService, notificationService);
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			token: ['', [Validators.required]]
		});

		this.disposableBag.add(
			this.credentialRepository.credentials$.subscribe((credentials) => {
				this.form.get('token').setValue(credentials?.jiraApiToken ?? '');

				if (this.ssoAuthService.loggedIn) {
					this.testConnectionState = TestConnectionState.NotTested;
				} else {
					this.testConnectionState = TestConnectionState.Disabled;
				}

				this.subscribeToFormChanges();
				this.formData.emit(this.form);
				this.dataState.emit(this.testConnectionState);
			})
		);
	}

	testConnection(): void {
		const credentials: ICredentialsPayload = {
			email: this.ssoAuthService.idToken,
			jiraApiToken: this.form.get('token').value as string,
			jiraUrl: this.appConfigRepository.config.jiraURL,
			xrayClientId: 'null',
			xrayClientSecret: 'null',
			token: true,
			gitToken: '',
			gitUsername: ''
		};

		super.testConnection(credentials);
	}

	openLink(): void {
		this.document.defaultView.open(
			this.appConfigRepository.config.tokenConfluenceUrl,
			'_blank'
		);
	}
}
