import { ETestStepType } from '@testifi-models/test-step-type.enum';
import { Utils } from '@testifi-utils/utils';
import { immerable } from 'immer';
import {
	JsonConverter,
	JsonCustomConvert,
	JsonObject,
	JsonProperty
} from 'json2typescript';
import {
	ICreateTestScenarioWithStepsObjectRequest,
	TestObject
} from './test-object';
import { ValidationType, ValidationTypeConverter } from './validation-type';

@JsonConverter
class StepTagConverter implements JsonCustomConvert<ETestStepType> {
	// not implemented as we don't need to serialize
	serialize(): string {
		return '';
	}

	deserialize(xml: string): ETestStepType {
		if (!xml) {
			return ETestStepType.NO_XML_TEST_STEP;
		}

		const extractedTag = Utils.extractTagName(xml);

		const correspondingEnum = Object.values(ETestStepType).includes(
			extractedTag as ETestStepType
		)
			? extractedTag
			: undefined;

		if (correspondingEnum) {
			return correspondingEnum as ETestStepType;
		} else {
			throw new Error(`Invalid tag: ${extractedTag}`);
		}
	}
}

@JsonObject('TestStep')
export class TestStep {
	[immerable] = true;

	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('description', String)
	description: string = undefined;

	@JsonProperty('expectedResult', String)
	expectedResult: string = undefined;

	@JsonProperty('data', String)
	data: string = undefined;

	@JsonProperty('scenarioId', String, true)
	scenarioId: string = undefined;

	@JsonProperty('index', Number, true)
	index: number = undefined;

	@JsonProperty('childTestObjects', [TestObject], true)
	testObjects: TestObject[] = [];

	@JsonProperty('validation', ValidationTypeConverter, true)
	validationType: ValidationType = undefined;

	@JsonProperty('xml', StepTagConverter)
	tag = ETestStepType.TEST_STEP;

	get displayValidationType(): string {
		if (this.validationType === undefined) {
			return ValidationType[ValidationType.failedValidation].toString();
		}
		return ValidationType[this.validationType].toString();
	}

	updateType = UpdateType.Update;

	setUpdateType(type: UpdateType): void {
		this.updateType = type;
	}
}

export enum UpdateType {
	Create,
	Update
}

export interface ICreateTestScenarioWithStepsStepRequest {
	childData: string;
	data: string;
	description: string;
	elementList: {
		elementList: ICreateTestScenarioWithStepsObjectRequest[];
	};
	index: number;
	result: string;
	scenarioId: string;
	tag: string;
}
