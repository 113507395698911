<div class="checkbox" (click)="$event.stopPropagation()" fxLayout fxLayoutAlign="{{ isCenter ? 'center center': ''}}">
	<ng-container *ngIf="!formControlName">
		<input
			class="checkbox"
			type="checkbox"
			[name]="name"
			[id]="name"
			[checked]="checked"
			(change)="change()"
			title="checkbox"
		/>
	</ng-container>
	<ng-container *ngIf="formControlName">
		<input
			class="checkbox"
			type="checkbox"
			[name]="name"
			[id]="name"
			[formControlName]="formControlName"
			[checked]="checked"
			(change)="change()"
			title="checkbox"
		/>
	</ng-container>
	<label [ngClass]="{ empty: label.length <= 0 }" [for]="name">{{ label }}</label>
</div>
