<form class="aui top-label" [formGroup]="form">
	<div fxLayout="column">
		<header class="aui-page-header">
			<div class="aui-page-header-inner">
				<div class="aui-page-header-main">
					<h3>Jira Credentials</h3>
				</div>
			</div>
		</header>
		<div class="module form-body">
			<app-settings-toggle
				[options]="toggleOptions"
				[activeFirst]="basicAuthentication"
				(firstActivated)="onActivate($event)"
			></app-settings-toggle>
			<div class="field-group" *ngIf="!basicAuthentication">
				<label class="label" for="token">Token</label>
				<input
					class="input-text"
					[class.input-text-security]="isHidden"
					type="text"
					formControlName="token"
					id="token"
					placeholder="Token"
					onCopy="return false"
					onCut="return false"
					onselectstart="return false"
					onDrag="return false"
					autocomplete="off"
				/>
				<svg-icon
					name="{{ isHidden ? 'eye-on' : 'eye-off' }}"
					class="input-text-icon"
					title="Show token"
					[svgStyle]="{'width.px': 16, 'height.px': 16}"
					(click)="isHidden = !isHidden"
				></svg-icon>
				<app-validation-errors [control]="form.get('token')"></app-validation-errors>
			</div>
			<div class="field-group" *ngIf="basicAuthentication">
				<div class="field-group">
					<label class="label" for="username">Username</label>
					<input class="input-text" type="text" formControlName="username" id="username" placeholder="Username" />
					<app-validation-errors [control]="form.get('username')"></app-validation-errors>
				</div>
				<label class="label" for="password">Password</label>
				<input
					class="input-text"
					type="{{ isHidden ? 'password' : 'text'}}"
					formControlName="password"
					id="password"
					placeholder="Password"
				/>
				<svg-icon
					name="{{ isHidden ? 'eye-on' : 'eye-off' }}"
					class="input-text-icon"
					title="Show password"
					[svgStyle]="{'width.px': 16, 'height.px': 16}"
					(click)="isHidden = !isHidden"
				></svg-icon>
				<app-validation-errors [control]="form.get('password')"></app-validation-errors>
			</div>
		</div>
		<div class="test-connection mt-4">
			<app-test-connection [state]="testConnectionState" (click)="testConnection()"></app-test-connection>
		</div>
	</div>
</form>
