import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[appendDragThumb]', // eslint-disable-line
	standalone: true
})
export class AppendDragThumbDirective implements AfterViewInit {
	@Input() html: string;
	@Input() readOnly = false;

	constructor(private elementRef: ElementRef<HTMLDivElement>) {}

	ngAfterViewInit(): void {
		if (!this.readOnly) {
			this.elementRef.nativeElement.insertAdjacentHTML('beforeend', this.html);
		}
	}
}
