import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import {
	DESCRIPTION_VALIDATORS,
	NAME_VALIDATORS
} from '@testifi-shared/app-constants';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ModalCloseDirective } from '../../directives/modal-close.directive';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';
import { BuildingBlockService } from './../../services/building-block.service';

interface ModalAddBuildingBlockComponentData {
	projectId: string;
}

@Component({
	selector: 'app-modal-add-building-block',
	templateUrl: './modal-add-building-block.component.html',
	styleUrls: ['./modal-add-building-block.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ValidationErrorsComponent,
		ModalCloseDirective
	]
})
export class ModalAddBuildingBlockComponent implements OnInit, OnDestroy {
	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	// ======================================================================
	// public properties
	// ======================================================================

	form: UntypedFormGroup;
	submitted = false;
	projectId: string;

	// ======================================================================
	// getter
	// ======================================================================

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	get f() {
		return this.form.controls;
	}

	constructor(
		private cd: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private buildingBlockService: BuildingBlockService,
		private notificationService: NotificationService,
		private loadingService: LoadingService,
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA) public data: ModalAddBuildingBlockComponentData
	) {}

	ngOnInit(): void {
		this.projectId = this.data.projectId;

		this.form = this.formBuilder.group({
			name: ['', NAME_VALIDATORS],
			description: ['', DESCRIPTION_VALIDATORS]
		});
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	// ======================================================================
	// public functions
	// ======================================================================

	add(): void {
		this.submitted = true;

		// stop here if form is invalid
		if (this.form.invalid) {
			return;
		}

		this.loadingService.active();

		this.disposableBag.add(
			this.buildingBlockService
				.add(this.projectId, this.f.description.value, this.f.name.value, '')
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(buildingBlock) => {
						this.notificationService.info('Building block was created');
						this.modalService.close(buildingBlock);
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}
}
