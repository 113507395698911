import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

interface SSOTokenInfo {
	iss: string;
	azp: string;
	aud: string;
	sub: string;
	at_hash: string;
	nonce: string;
	nbf: number;
	name: string;
	picture: string;
	given_name: string;
	family_name: string;
	locale: string;
	iat: number;
	exp: number;
	jti: string;
	groups: string[];
}

@Injectable({ providedIn: 'root' })
export class SsoAuthenticationService {
	constructor(private oAuthService: OAuthService) {}

	get token(): SSOTokenInfo {
		return this.oAuthService.getIdentityClaims() as SSOTokenInfo;
	}

	get givenName(): string {
		return this.token?.given_name ?? '';
	}

	get familyName(): string {
		return this.token?.family_name ?? '';
	}

	get loggedIn(): boolean {
		return !!this.token;
	}

	get idToken(): string {
		return this.oAuthService.getIdToken();
	}

	login(): void {
		this.oAuthService.initLoginFlow();
	}

	logout(): void {
		this.oAuthService.logOut();
	}
}
