import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('BusinessJourney')
export class BusinessJourney {
	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('description', String)
	description: string = undefined;

	@JsonProperty('reporter', String)
	reporter: string = undefined;

	@JsonProperty('updateDate', String)
	updateDate: string = undefined;

	@JsonProperty('status', String)
	status: string = undefined;

	@JsonProperty('statusColor', String)
	statusColor: string = undefined;

	@JsonProperty('createDate', String, true)
	createDate: string = undefined;
}
