import { inject, Injectable } from '@angular/core';
import { TData, TDataSelector } from '@testifi-models/data.model';
import { DataService } from '@testifi-services/data.service';
import { DataRepository } from '@testifi-store/data/data.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { Utils } from '@testifi-utils/utils';
import { take } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class DataEffect {
	private readonly dataService = inject(DataService);
	private readonly dataRepository = inject(DataRepository);
	private readonly projectRepository = inject(ProjectRepository);

	getDataFilesIfNotPresent() {
		if (this.dataRepository.loaded) {
			return;
		}

		this.dataService
			.getDataFiles(this.projectRepository.project.id)
			.pipe(take(1))
			.subscribe((res) => {
				const data: Record<string, TData> = res.reduce(
					(acc, curr) => {
						// eslint-disable-next-line functional/immutable-data
						acc[curr.name] = {
							id: curr.id,
							name: curr.name,
							selectors: undefined
						};
						return acc;
					},
					{} as Record<string, TData>
				);
				this.dataRepository.setData(data);
			});
	}

	getSelectorsInDataFile(dataFileId: string) {
		this.dataService
			.getSelectors(dataFileId)
			.pipe(take(1))
			.subscribe((selectors) => {
				let enhancedSelectors: TDataSelector[] = [];
				selectors.forEach((selector) => {
					const [selectorName, defaultValue] =
						Utils.deriveInitialValueFromSelector(selector);

					enhancedSelectors = [
						...enhancedSelectors,
						{
							selector: selectorName,
							defaultValue: defaultValue ?? '',
							values: undefined
						}
					];
				});
				const dataFileName = this.dataRepository.getDataFromId(dataFileId).name;
				this.dataRepository.setSelectors(dataFileName, enhancedSelectors);
			});
	}

	getValuesInSelector(dataFileName: string, selector: string) {
		this.dataRepository.setLoading(true);
		this.dataService
			.getValues(this.projectRepository.project.id, dataFileName, selector)
			.pipe(
				take(1),
				finalize(() => this.dataRepository.setLoading(false))
			)
			.subscribe((values) => {
				this.dataRepository.setValues(dataFileName, selector, values);
			});
	}
}
