import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@testifi-utils/utils';

@Pipe({
	name: 'dataFileParser',
	standalone: true
})
export class DataFileParserPipe implements PipeTransform {
	transform(parameterType?: string): {
		parameterName: string;
		dataFileName: string;
		selectorName: string;
		defaultValue?: string;
	} | null {
		if (!parameterType || !Utils.parameterHasDataFile(parameterType)) {
			return null;
		}
		return Utils.parseCBBParameterValueFromDataFile(parameterType);
	}
}
