import { immerable } from 'immer';
import { JsonObject, JsonProperty } from 'json2typescript';
import { LabelItemView } from './label-item-view';
import { ScenarioParameter } from './scenario-parameter';
import { TestPlan } from './test-plan';
import { TestRun } from './test-run';
import { ICreateTestScenarioWithStepsStepRequest, TestStep } from './test-step';

@JsonObject('JiraStatus')
class JiraStatus {
	@JsonProperty('colorName', String)
	color: string = undefined;

	@JsonProperty('name', String)
	name: string = undefined;
}

@JsonObject('JiraField')
class JiraField {
	@JsonProperty('assignee', String, true)
	assignee: string = undefined;

	@JsonProperty('components', [String], true)
	components: Array<string> = [];

	@JsonProperty('labels', [String], true)
	labels: Array<string> = [];

	@JsonProperty('reporter', String, true)
	reporter: string = undefined;

	@JsonProperty('status', JiraStatus, true)
	status: JiraStatus = undefined;
}

@JsonObject('TestScenario')
export class TestScenario {
	[immerable] = true;

	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('description', String)
	description: string = undefined;

	@JsonProperty('projectId', String, true)
	projectId: string = undefined;

	@JsonProperty('projectKey', String, true)
	projectKey: string = undefined;

	@JsonProperty('testStepCount', Number)
	testStepCount = 0;

	@JsonProperty('xrayTestKey', String, true)
	xrayTestKey: string = undefined;

	@JsonProperty('testPlans', [TestPlan])
	testPlans: TestPlan[] = [];

	@JsonProperty('testSteps', [TestStep], true)
	testSteps: TestStep[] = [];

	@JsonProperty('testRun', TestRun, true)
	testRun: TestRun = undefined;

	@JsonProperty('jiraField', JiraField)
	jira: JiraField = undefined;

	@JsonProperty('parameter', [ScenarioParameter], true)
	parameters: ScenarioParameter[] = [];

	@JsonProperty('createdAt', String, true)
	createdAt: string = undefined;

	@JsonProperty('updatedAt', String, true)
	updatedAt: string = undefined;

	@JsonProperty('labelItems', [LabelItemView])
	labelItems: LabelItemView[] = [];
}

export interface ICreateTestScenarioWithStepsRequest {
	description: string;
	name: string;
	parameters: ScenarioParameter[];
	path: string;
	projectId: string;
	projectKey: string;
	steps: ICreateTestScenarioWithStepsStepRequest[];
	xmlId: string;
	labelItems: LabelItemView[];
}
