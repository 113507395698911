<div id="modal-edit-sui-page-elements-element-change-name" *hideOnLoading>
	<div class="sidebar">
		<div class="inner" *hideOnLoading>
			<div class="filter">
				<form class="aui">
					<div class="field">
						<select title="Group" class="select" (change)="onTagGroupChange($event.target.value)">
							<option
								*ngFor="let tagGroup of tagGroups | keyvalue"
								[attr.selected]="activeTagGroup === tagGroup.key ? '' : null"
								value="{{ tagGroup.key }}"
							>
								{{ tagGroup.key }}
							</option>
						</select>
					</div>
					<div class="field">
						<input
							placeholder="Search"
							type="text"
							class="text"
							value="{{ tagGroupFilter }}"
							(input)="onSearchTagGroup($event.target.value)"
							id="libraryFilter"
							[autofocus]="true"
						/>
					</div>
				</form>
			</div>
			<div class="modules" #modules>
				<div class="module-items">
					<div
						class="module-items-inner"
						cdkDropListSortingDisabled
						cdkDropList
						id="module-items-tags {{ activeTagGroup }}"
						[cdkDropListData]="tags"
						[cdkDropListConnectedTo]="connectedDropListsIds"
					>
						<cdk-virtual-scroll-viewport itemSize="50">
							<app-library-drag-drop-element
								*cdkVirtualFor="let tag of filteredTagsForGroup(activeTagGroup); templateCacheSize: 0"
								[name]="tag.uiTag"
								[tag]="tag.name"
								[group]="activeTagGroup"
								(dragEnded)="onShortcutClick(tag)"
								(dragMove)="onShortcutClick(tag)"
								(dragStarted)="onShortcutClick(tag)"
								(shortcutClick)="onShortcutClick(tag)"
								[usePlaceholder]="true"
							>
							</app-library-drag-drop-element>
						</cdk-virtual-scroll-viewport>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="content background-N0">
		<div class="inner">
			<!-- 2px solid var(--aui-dialog-border-color) -->
			<header class="aui-dialog2-header title-change">
				<h2 class="aui-dialog2-header-main">Change Element Type</h2>
			</header>
			<div class="aui-dialog2-content">
				<div class="field-group field-group-change">
					<div class="setup">
						<label for="oldType" class="label-align-right">Old Type :</label>
						<input id="oldType" class="text-align-left" type="text" value="{{oldTagName}}" readonly />
					</div>
					<div class="setup">
						<label for="newType" class="label-align-right">New Type :</label>
						<input id="newType" class="text-align-left" type="text" value="{{newTagName}}" readonly />
					</div>
				</div>
			</div>
			<div class="col-group footer">
				<footer class="aui-dialog2-footer">
					<div class="aui-dialog2-footer-actions" fxLayout fxLayoutGap="8px">
						<button type="button" id="create-submit" class="aui-button" [disabled]="!newTagName" (click)="save()">
							Save
						</button>
						<button type="button" id="close-submit" class="aui-button" (click)="close()">Cancel</button>
					</div>
				</footer>
			</div>
		</div>
	</div>
</div>
