<div class="table-wrapper">
	<ul>
		<ng-container *ngIf="!(lastExecutedScenariosLoading$ | async);else loadingSkeleton">
			<li *ngFor="let scenario of lastExecutedScenarios | async">
				<div class="xray-id">{{scenario.xrayTestKey}}</div>
				<div class="created-by">{{scenario.jira?.reporter}}</div>
				<div class="created-at">{{scenario.createdAt | date : 'YYYY.MM.dd'}}</div>
				<div class="status" [style.color]="scenario.jira?.status?.color">{{scenario.jira?.status?.name}}</div>
				<div class="description">{{scenario.description}}</div>
			</li>
		</ng-container>
		<ng-template #loadingSkeleton>
      <li class="skeleton-item"></li>
      <li class="skeleton-item"></li>
      <li class="skeleton-item"></li>
		</ng-template>
	</ul>
</div>
