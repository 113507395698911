/* eslint-disable functional/immutable-data */
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
	AsyncPipe,
	DOCUMENT,
	DatePipe,
	NgClass,
	NgFor,
	NgIf,
	NgStyle,
	TranslationWidth
} from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	ElementRef,
	HostListener,
	Inject,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MatLegacyAutocompleteModule,
	MatLegacyAutocompleteSelectedEvent,
	MatLegacyAutocompleteTrigger
} from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import {
	MatLegacyPaginatorModule,
	MatLegacyPaginator as MatPaginator,
	MatLegacyPaginatorIntl as MatPaginatorIntl,
	LegacyPageEvent as PageEvent
} from '@angular/material/legacy-paginator';
import { MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import {
	NgbCalendar,
	NgbDateAdapter,
	NgbDateParserFormatter,
	NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { SortDao } from '@testifi-daos/sort.dao';
import { ModalExecuteTestPlanComponent } from '@testifi-modals/modal-execute-test-plan/modal-execute-test-plan.component';
import { LabelItemView } from '@testifi-models/label-item-view';
import { ProjectView, Statistic } from '@testifi-models/project-view';
import { ScenarioFilter } from '@testifi-models/scenario-filters';
import { ScenarioParameter } from '@testifi-models/scenario-parameter';
import { TestPlan } from '@testifi-models/test-plan';
import { TestScenario } from '@testifi-models/test-scenario';
import { instanceOfExecutionParameters } from '@testifi-pages/page-view-test-scenario/page-view-test-scenario.component';
import { BreadcrumbService } from '@testifi-services/breadcrumb.service';
import { CustomNgbDateParserFormatter } from '@testifi-services/custom-date-parser-formatter.service';
import { CustomDatePickerAdapter } from '@testifi-services/custom-date-picker-adapter.service';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import {
	ExecutionRequest,
	ExecutionResponse,
	ProjectService
} from '@testifi-services/project.service';
import { TestScenarioService } from '@testifi-services/test-scenario.service';
import {
	DATE_DELIMITER,
	MANDATORY_PARAMETERS_KEY,
	OPTIONAL_PARAMETERS_KEY,
	PAGES
} from '@testifi-shared/app-constants';
import { CheckboxComponent } from '@testifi-shared/checkbox/checkbox.component';
import {
	MultiSelectDropdownComponent,
	MultiSelectDropdownElementIds
} from '@testifi-shared/multi-select-dropdown/multi-select-dropdown.component';
import { OverlayComponent } from '@testifi-shared/overlay/overlay.component';
import { CustomPaginator } from '@testifi-shared/paginator/custom-paginator';
import { TableCellShowMoreComponent } from '@testifi-shared/table-cell-show-more/table-cell-show-more.component';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { FilterCacheManager } from '@testifi-utils/filter-cache-manager';
import { SvgIconComponent } from 'angular-svg-icon';
import { produce } from 'immer';
import { Observable, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import {
	concatAll,
	finalize,
	map,
	startWith,
	switchMap,
	tap
} from 'rxjs/operators';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { ModalRouterNameDirective } from '../../directives/modal-router-name.directive';
import { RouterNameDirective } from '../../directives/router-name.directive';
import { StylePaginatorDirective } from '../../directives/style-paginator.directive';
import { UnorderdKeyValuePipe } from '../../pipes/unorder-key-value.pipe';
import { BreadcrumbComponent } from '../../shared/breadcrumb/breadcrumb.component';
import { CheckboxComponent as CheckboxComponent_1 } from '../../shared/checkbox/checkbox.component';
import { ExecutionStatusComponent } from '../../shared/execution-status/execution-status.component';
import { KpiBoxComponent } from '../../shared/kpi-box/kpi-box.component';
import { LongTextComponent } from '../../shared/long-text/long-text.component';
import { MultiSelectDropdownComponent as MultiSelectDropdownComponent_1 } from '../../shared/multi-select-dropdown/multi-select-dropdown.component';

enum ColNames {
	SELECT = 'select',
	NAME = 'NAME',
	XRAY_ID = 'XRAY_KEY',
	JIRA_STATUS = 'jiraStatus',
	XRAY_STATUS = 'xrayStatus',
	DESCRIPTION = 'description',
	REPORTER = 'reporter',
	CREATE_DATE = 'createDate',
	UPDATE_DATE = 'updateDate'
}

const PAGE_SIZE_ITEM_NAME = 'project.pageSize';

@Component({
	selector: 'app-page-view-project',
	templateUrl: './page-view-project.component.html',
	styleUrls: ['./page-view-project.component.less'],
	providers: [
		{ provide: MatPaginatorIntl, useValue: CustomPaginator() },
		{ provide: NgbDateAdapter, useClass: CustomDatePickerAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		HideOnLoadingDirective,
		BreadcrumbComponent,
		ModalRouterNameDirective,
		RouterNameDirective,
		NgIf,
		FlexModule,
		KpiBoxComponent,
		NgFor,
		FormsModule,
		MultiSelectDropdownComponent_1,
		MatLegacyAutocompleteModule,
		ReactiveFormsModule,
		MatLegacyOptionModule,
		NgClass,
		ExtendedModule,
		SvgIconComponent,
		MatSortModule,
		CheckboxComponent_1,
		NgStyle,
		LongTextComponent,
		ExecutionStatusComponent,
		MatLegacyPaginatorModule,
		StylePaginatorDirective,
		AsyncPipe,
		UnorderdKeyValuePipe,
		NgbInputDatepicker,
		DatePipe,
		MatTableModule,
		TableCellShowMoreComponent,
		CdkOverlayOrigin,
		CdkConnectedOverlay,
		OverlayComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageViewProjectComponent implements OnInit, OnDestroy {
	// ======================================================================
	// private properties
	// ======================================================================

	private readonly disposableBag = new Subscription();

	// ======================================================================
	// public properties
	// ======================================================================
	@ViewChild('buttonMore') buttonMore: ElementRef<HTMLButtonElement>;

	@ViewChildren('descriptionCellShowMore')
	descriptionCellsShowMoreList: QueryList<TableCellShowMoreComponent>;

	@ViewChildren('nameCellShowMore')
	nameCellsShowMoreList: QueryList<TableCellShowMoreComponent>;

	@ViewChild('selectAllCheckbox')
	selectAllCheckbox: CheckboxComponent;
	@ViewChild('paginator') paginator: MatPaginator;
	@ViewChild('executionStatusesFilter')
	executionStatusesFilter: MultiSelectDropdownComponent;
	@ViewChild('jiraStatusesFilter')
	jiraStatusesFilter: MultiSelectDropdownComponent;
	@ViewChild(MatLegacyAutocompleteTrigger)
	autocompleteTrigger!: MatLegacyAutocompleteTrigger;

	ALL_REPORTERS_VALUE = '';
	ALL_LABELS_VALUE = '';

	searchReporterControl = new FormControl(
		FilterCacheManager.getFilterFromCache(
			ScenarioFilter.FilterFields.reporterFilter,
			ScenarioFilter.FILTER_STORAGE_KEY
		)
	);
	filteredReporterList$: Observable<string[]> =
		this.searchReporterControl.valueChanges.pipe(
			startWith(
				FilterCacheManager.getFilterFromCache(
					ScenarioFilter.FilterFields.reporterFilter,
					ScenarioFilter.FILTER_STORAGE_KEY
				) ?? ''
			),
			map((value) => {
				FilterCacheManager.saveFilterToCache(
					ScenarioFilter.FilterFields.reporterFilter,
					value,
					ScenarioFilter.FILTER_STORAGE_KEY
				);
				return this.filter(value);
			})
		);

	searchLabelItemsControl = new FormControl();
	filteredLabelItemList$: Observable<string[]> =
		this.searchLabelItemsControl.valueChanges.pipe(
			startWith(''),
			map((value) => this.filterLabelItem(value))
		);

	MultiSelectDropdownElementIds = MultiSelectDropdownElementIds;
	TranslationWidth = TranslationWidth;
	projectView: ProjectView;
	projectViewComponents: TestScenario[];
	testPlans: TestPlan[] = [];

	checkedTestScenarios = new Map<string, TestScenario>();

	searchText =
		FilterCacheManager.getFilterFromCache(
			ScenarioFilter.FilterFields.textFilter,
			ScenarioFilter.FILTER_STORAGE_KEY
		) ?? '';
	selectedReporter =
		FilterCacheManager.getFilterFromCache(
			ScenarioFilter.FilterFields.reporterFilter,
			ScenarioFilter.FILTER_STORAGE_KEY
		) ?? '';
	selectedLabel = '';

	lastSort: Sort;
	defaultSortedColumn = ColNames.NAME;
	ColNames = ColNames;
	defaultSort: SortDirection = 'asc';

	displayedColumns: string[] = [
		ColNames.SELECT,
		ColNames.XRAY_ID,
		ColNames.NAME,
		ColNames.REPORTER,
		ColNames.XRAY_STATUS,
		ColNames.CREATE_DATE,
		ColNames.UPDATE_DATE,
		ColNames.DESCRIPTION
	];

	pageSize = 5;
	totalItems = 0;
	totalTests = 0;
	currentPage = 0;

	jiraStatistics: Statistic[] = [];
	executionStatistics: Statistic[] = [];
	reporterList: string[] = [];
	labelItemList: LabelItemView[] = [];
	expandButtonState = new Map<string, boolean>();
	showJiraStatus = false;
	dateModelCreatedStart: string;
	dateModelCreatedEnd: string;
	dateModelUpdatedStart: string;
	dateModelUpdatedEnd: string;
	dateFormat = `yyyy${DATE_DELIMITER}MM${DATE_DELIMITER}dd`;
	currentDate = this.ngbCalendar.getToday();

	private readonly window: Window;

	private get selectedTestScenarios(): TestScenario[] {
		return [...this.checkedTestScenarios.values()];
	}

	private get isSSO(): boolean {
		return this.appConfigRepository.isSSO;
	}

	constructor(
		private readonly cd: ChangeDetectorRef,
		private readonly sortDao: SortDao,
		private readonly loadingService: LoadingService,
		private readonly breadcrumbService: BreadcrumbService,
		private readonly notificationService: NotificationService,
		private readonly projectService: ProjectService,
		private readonly testScenarioService: TestScenarioService,
		private readonly modalService: ModalService,
		private readonly ngbCalendar: NgbCalendar,
		public appConfigRepository: AppConfigRepository,
		private readonly projectRepository: ProjectRepository,
		private readonly destroyRef: DestroyRef,
		@Inject(DOCUMENT) document: Document
	) {
		this.window = document.defaultView;
	}

	ngOnInit(): void {
		if (this.window.localStorage.getItem(PAGE_SIZE_ITEM_NAME)) {
			this.pageSize = parseInt(
				this.window.localStorage.getItem(PAGE_SIZE_ITEM_NAME)
			);
		}

		this.disposableBag.add(
			this.sortDao
				.get(this.projectRepository.projectKey)
				.pipe(map((sort) => this.initSortOptions(sort)))
				.subscribe(() => {
					this.reload(false);
				})
		);
		this.disposableBag.add(
			combineLatest([
				this.projectService.getReporterList(this.projectRepository.project.id),
				this.projectService.getLabelItemList(this.projectRepository.project.id)
			]).subscribe((response) => {
				this.reporterList = response[0];
				this.labelItemList = response[1];
			})
		);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	@HostListener('window:resize', ['$event'])
	onResize(): void {
		this.expandButtonState.clear();
	}

	handlePage(event: PageEvent): void {
		const isPageSizeReallyChanged = this.pageSize !== event.pageSize;
		const isPageReallyChanged = this.currentPage !== event.pageIndex;
		this.selectAllCheckbox.checked = false;
		this.pageSize = event.pageSize;
		this.window.localStorage.setItem(PAGE_SIZE_ITEM_NAME, `${event.pageSize}`);
		this.currentPage = event.pageIndex;

		if (isPageSizeReallyChanged || isPageReallyChanged) {
			this.reload(false);
		}
	}

	onSort(sort: Sort): void {
		if (!sort.direction) {
			this.sortDao
				.delete(this.projectRepository.projectKey)
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe(() => {
					this.lastSort = null;
					this.currentPage = 0;
					this.reload(false);
				});
			return;
		}

		this.sortDao
			.save(this.projectRepository.projectKey, sort)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.lastSort = sort;
				this.currentPage = 0;
				this.reload(false);
			});
	}

	checkTestScenario(testScenario: TestScenario): void {
		if (this.checkedTestScenarios.has(testScenario.id)) {
			this.checkedTestScenarios.delete(testScenario.id);
		} else {
			this.checkedTestScenarios.set(testScenario.id, testScenario);
		}
		if (!this.checkedTestScenarios.size) {
			this.selectAllCheckbox.checked = false;
		}
		this.cd.detectChanges();
	}

	onSearchInput(searchText: string): void {
		this.selectAllCheckbox.checked = false;
		this.searchText = searchText;
		FilterCacheManager.saveFilterToCache(
			ScenarioFilter.FilterFields.textFilter,
			searchText,
			ScenarioFilter.FILTER_STORAGE_KEY
		);
	}

	onSearch(): void {
		this.currentPage = 0;
		this.refresh(() =>
			setTimeout(() => this.paginator?._changePageSize(this.pageSize))
		);
	}

	refresh(callback?: CallableFunction): void {
		this.reload(false, callback);
	}

	deleteOnModalConfirm(confirm: boolean): void {
		if (confirm) {
			this.loadingService.active();

			let observers: Observable<TestScenario>[] = [];

			this.selectedTestScenarios.forEach((scenario) => {
				observers = produce(observers, (draft) => {
					draft.push(
						this.testScenarioService
							.delete(scenario.id)
							.pipe(switchMap(() => of(scenario)))
					);
				});
			});

			const source = forkJoin(observers);
			const finalObserver = source.pipe(concatAll());

			this.disposableBag.add(
				finalObserver
					.pipe(finalize(() => this.loadingService.deactive(this.cd)))
					.subscribe(
						(scenario) => {
							this.selectAllCheckbox.checked = false;
							this.notificationService.info(
								`Test "${scenario.name}" deleted successfully`
							);
							this.reload(false);
						},
						(err) => this.notificationService.httpError(err)
					)
			);
		}
	}

	activateTestsExecution(): void {
		let selectedIds: string[] = [];
		const scenarioParameters: ScenarioParameter[] = [
			new ScenarioParameter(MANDATORY_PARAMETERS_KEY, ''),
			new ScenarioParameter(OPTIONAL_PARAMETERS_KEY, '')
		];

		this.selectedTestScenarios.forEach((scenario) => {
			selectedIds = produce(selectedIds, (draft) => {
				draft.push(scenario.id);
			});
		});

		let request: ExecutionRequest = {
			scenarioId: selectedIds
		};

		this.loadingService.active();

		this.disposableBag.add(
			this.projectService
				.initExecution(this.projectView.id, this.projectView.key, request)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe((response) => {
					response = produce(response, (draft) => {
						draft.unshift(new TestPlan('Without Test Plan (ad-hoc)', true));
					});

					this.modalService.open(
						ModalExecuteTestPlanComponent,
						(result) => {
							if (instanceOfExecutionParameters(result)) {
								request = produce(request, (draft) => {
									draft.testPlan = result.testPlan;
									draft.device = result.device;
									draft.gitConnection = result.gitConnection;
									draft.parameters = result.scenarioParameters;
								});

								this.callTestsExecution(request);

								if (this.selectAllCheckbox.checked) {
									this.selectAllCheckbox.change();
								} else {
									this.checkedTestScenarios.clear();
								}
							}
						},
						{
							title:
								selectedIds.length > 1
									? 'Test Scenarios Execution'
									: 'Test Scenario Execution',
							testPlans: response,
							adhocExist: true,
							projectId: this.projectView.id,
							scenarioParameters
						}
					);
				})
		);
	}

	onClickExecuteTestPlan(): void {
		let request: ExecutionRequest = {
			scenarioId: []
		};
		const scenarioParameters: ScenarioParameter[] = [
			new ScenarioParameter(MANDATORY_PARAMETERS_KEY, ''),
			new ScenarioParameter(OPTIONAL_PARAMETERS_KEY, '')
		];

		this.modalService.open(
			ModalExecuteTestPlanComponent,
			(result) => {
				if (instanceOfExecutionParameters(result)) {
					request = produce(request, (draft) => {
						draft.testPlan = result.testPlan;
						draft.device = result.device;
						draft.gitConnection = result.gitConnection;
						draft.parameters = result.scenarioParameters;
					});
					this.callTestsExecution(request);
				}
			},
			{
				title: 'Test Plan Execution',
				testPlans: this.testPlans,
				adhocExist: false,
				projectId: this.projectView.id,
				scenarioParameters
			}
		);
	}

	selectAll(selected: boolean): void {
		this.checkedTestScenarios.clear();
		if (selected) {
			this.projectViewComponents.forEach((scenario) =>
				this.checkedTestScenarios.set(scenario.id, scenario)
			);
		}
	}

	private reload(silent: boolean, callback?: CallableFunction): void {
		this.checkedTestScenarios.clear();

		this.loadingService.active(silent);

		this.disposableBag.add(
			this.projectService
				.getTestPlanList(this.projectRepository.project.id)
				.pipe(
					tap((testPlans) => {
						this.testPlans = testPlans;
					}),
					switchMap(() =>
						this.projectService.viewByKey({
							key: this.projectRepository.project.id,
							page: this.currentPage,
							limit: this.pageSize,
							sort: this.lastSort?.active,
							direction: this.lastSort?.direction.toUpperCase(),
							searchText: this.searchText,
							executionStatuses:
								this.executionStatusesFilter?.selectedValues ?? [],
							jiraStatuses:
								this.jiraStatusesFilter?.selectedValues ??
								this.jiraStatistics.map((stat) => stat.status),
							reporter: this.selectedReporter,
							labelItem: this.selectedLabel,
							createdStartDate: this.dateModelCreatedStart,
							createdEndDate: this.dateModelCreatedEnd,
							updatedStartDate: this.dateModelUpdatedStart,
							updatedEndDate: this.dateModelUpdatedEnd
						})
					),
					finalize(() => this.loadingService.deactive(this.cd))
				)
				.subscribe(
					(projectView) => {
						this.projectView = projectView;
						this.totalItems = this.projectView.totalNumberOfElements;
						this.totalTests = this.projectView.projectStatistics.reduce(
							(previousValue, statistic) => previousValue + statistic.count,
							0
						);
						this.projectViewComponents = this.projectView.testScenarios;

						this.breadcrumbService.set(
							new Map([
								['Projects', null],
								[
									this.projectView.name,
									{
										navigateTo: [PAGES.LANDING, `${this.projectView.id}`]
									}
								]
							])
						);

						if (!this.executionStatistics.length) {
							// Avoid multidropdown component reinitialization on every reload.
							// Statistics are also statical so we need to initialize them only once.
							this.executionStatistics = this.projectView.statistics;
							this.jiraStatistics = this.projectView.projectStatistics;
						}

						if (callback) {
							callback();
						}
					},
					(errorResponse: HttpErrorResponse) =>
						this.notificationService.httpError(errorResponse)
				)
		);
	}

	onOptionSelected(event: MatLegacyAutocompleteSelectedEvent): void {
		this.selectedReporter = event.option.value as string;
		this.refreshPaging();
	}

	onClear(): void {
		this.selectedReporter = this.ALL_REPORTERS_VALUE;
		this.autocompleteTrigger.closePanel();
		this.refreshPaging();
	}

	onLabelItemOptionSelected(event: MatLegacyAutocompleteSelectedEvent): void {
		this.selectedLabel = event.option.value as string;
		this.refreshPaging();
	}

	onLabelItemClear(): void {
		this.selectedLabel = this.ALL_LABELS_VALUE;
		this.autocompleteTrigger.closePanel();
		this.refreshPaging();
	}

	nameCellMoreChanged(more: boolean, rowIndex: number) {
		more
			? this.descriptionCellsShowMoreList.get(rowIndex).showMore()
			: this.descriptionCellsShowMoreList.get(rowIndex).showLess();
	}

	descriptionCellMoreChanged(more: boolean, rowIndex: number) {
		more
			? this.nameCellsShowMoreList.get(rowIndex).showMore()
			: this.nameCellsShowMoreList.get(rowIndex).showLess();
	}

	private refreshPaging() {
		this.refresh(() =>
			setTimeout(() => this.paginator?._changePageSize(this.pageSize))
		);
	}

	private callTestsExecution(request: ExecutionRequest): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.projectService
				.execute(this.projectView.id, this.projectView.key, request)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe((response) => {
					this.updateProjectViewComponentsAfterTestExecution(request, response);
				})
		);
	}

	private initSortOptions(sort: Sort): void {
		if (sort) {
			this.lastSort = sort;
			this.defaultSortedColumn = this.lastSort.active as ColNames;
			this.defaultSort = this.lastSort.direction;
		}
	}

	private filter(value: string): string[] {
		const filterValue = this.normalizeValue(value);
		return this.reporterList.filter((street) =>
			this.normalizeValue(street).includes(filterValue)
		);
	}

	private filterLabelItem(value: string): string[] {
		const filterValue = this.normalizeValue(value);
		return this.labelItemList
			.filter((street) =>
				this.normalizeValue(street.labelText).includes(filterValue)
			)
			.map((item) => item.labelText);
	}

	private normalizeValue(value: string): string {
		return value.toLowerCase().replace(/\s/g, '');
	}

	private updateProjectViewComponentsAfterTestExecution(
		request: ExecutionRequest,
		response: ExecutionResponse
	) {
		this.projectViewComponents = produce(
			this.projectViewComponents,
			(draft) => {
				request.scenarioId.forEach(
					(id) =>
						(draft.find((scenario) => scenario.id === id).testRun = {
							id: response.executionId,
							status: {
								name: response.status,
								color: '#000',
								description: ''
							},
							comment: '',
							startedOn: response.createdAt,
							finishedOn: null
						})
				);
			}
		);
	}
}
