<section
	id="modal-free-text"
	class="aui-dialog2 aui-dialog2-small"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-free-text--heading"
>
	<form class="aui" [formGroup]="form" (ngSubmit)="addFreeText()">
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-test-object-parameter--heading">
				{{data.modalTitle ?? 'Add Free Text'}}
			</h2>
		</header>
		<!-- Main dialog content -->
		<div class="aui-dialog2-content">
			<div class="field-group">
				<label for="freeText">{{data.fieldLabel ?? 'Free text'}}</label>
				<input id="freeText" class="text large-field" type="text" formControlName="text" required />
				<app-validation-errors [control]="f.text"></app-validation-errors>
			</div>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<div class="aui-dialog2-footer-actions">
				<button id="create-submit" type="submit" class="aui-button aui-button-primary" [disabled]="!form.valid">
					Add
				</button>
				<button id="close-submit" type="button" class="aui-button aui-button-subtle" modalClose>Cancel</button>
			</div>
		</footer>
	</form>
</section>
