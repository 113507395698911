/* eslint-disable functional/immutable-data */
import {
	CdkDragDrop,
	CdkDragEnd,
	CdkDragMove,
	CdkDragStart,
	CdkDropList,
	transferArrayItem
} from '@angular/cdk/drag-drop';
import {
	CdkFixedSizeVirtualScroll,
	CdkVirtualForOf,
	CdkVirtualScrollViewport
} from '@angular/cdk/scrolling';
import { DOCUMENT, KeyValuePipe, NgFor } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeScreenshot } from '@testifi-modals/modal-edit-sui-page-elements-element/modal-edit-sui-page-elements-element.component';
import { ModalSuiPageElementParameterComponent } from '@testifi-modals/modal-sui-page-element-parameter/modal-sui-page-element-parameter.component';
import { SCREENSHOT_PARAM_KEY } from '@testifi-modals/modal-sui-page-select-screenshot/modal-sui-page-select-screenshot.component';
import { SuiElement } from '@testifi-models/sui-element';
import { SuiPage } from '@testifi-models/sui-page';
import { Tag } from '@testifi-models/tag';
import { TagGroups } from '@testifi-models/tag-groups';
import { XmlElements } from '@testifi-models/xml-elements';
import { LibraryObjectService } from '@testifi-services/library-object.service';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import {
	NestedDragNDropService,
	STRUCTURE_ID_PREFIX
} from '@testifi-services/nested-drag-n-drop.service';
import { NotificationService } from '@testifi-services/notification.service';
import { ScreenshotService } from '@testifi-services/screenshot.service';
import { SuiPageService } from '@testifi-services/sui-page.service';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { EditTracker, State } from '@testifi-utils/edit.tracker';
import { Utils } from '@testifi-utils/utils';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { Subscription, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { LibraryDragDropElementComponent } from '../../shared/library-drag-drop-element/library-drag-drop-element.component';
import { LongTextComponent } from '../../shared/long-text/long-text.component';
import { ModalEditSuiPageElementsElementComponent } from '../modal-edit-sui-page-elements-element/modal-edit-sui-page-elements-element.component';

@Component({
	selector: 'app-modal-edit-sui-page-elements',
	templateUrl: './modal-edit-sui-page-elements.component.html',
	styleUrls: ['./modal-edit-sui-page-elements.component.less'],
	providers: [
		NestedDragNDropService,
		{
			provide: STRUCTURE_ID_PREFIX,
			multi: true,
			useValue: SuiElement.STRUCTURE_ID_PREFIX
		}
	],
	standalone: true,
	imports: [
		HideOnLoadingDirective,
		FormsModule,
		NgFor,
		AutofocusDirective,
		CdkDropList,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		LibraryDragDropElementComponent,
		LongTextComponent,
		ModalEditSuiPageElementsElementComponent,
		KeyValuePipe,
		MatIconModule,
		MatButtonModule,
		MatTooltipModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalEditSuiPageElementsComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	// ======================================================================
	// public properties
	// ======================================================================

	tagGroups = new Map<string, Tag[]>();
	filteredTags: Tag[] = [];
	screenshots = new Map<string, SafeScreenshot>();
	activeTagGroup = '';
	tagGroupFilter = '';
	suiPageId: string;
	suiPage: SuiPage;
	expandAllButtonDisabled = false;
	collapseAllButtonDisabled = false;
	_parentItem: SuiElement;

	get parentItem(): SuiElement {
		return this._parentItem;
	}

	set parentItem(parentItem: SuiElement) {
		this.allStructureIdsWithChildren =
			Utils.getChildrenStructureIdsIfChildHasChildren(parentItem.children);
		this.expandAllButtonDisabled = Utils.getExpandAllButtonDisabled(
			this.allStructureIdsWithChildren,
			this.expandedItemIds
		);
		this.collapseAllButtonDisabled = Utils.getCollapseAllButtonDisabled(
			this.allStructureIdsWithChildren,
			this.expandedItemIds
		);
		this._parentItem = parentItem;
	}

	editTracker = new EditTracker<SuiPage>(this.appConfigRepository);

	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();
	private isChanged = false;
	private allStructureIdsWithChildren: string[] = [];
	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private cd: ChangeDetectorRef,
		private screenshotService: ScreenshotService,
		private sanitizer: DomSanitizer,
		private suiPageService: SuiPageService,
		private notificationService: NotificationService,
		private loadingService: LoadingService,
		private modalService: ModalService,
		private libraryObjectService: LibraryObjectService,
		private nestedDragnDropService: NestedDragNDropService,
		private appConfigRepository: AppConfigRepository,
		private projectRepository: ProjectRepository,
		@Inject(DOCUMENT) private document: Document,
		@Inject(MAT_DIALOG_DATA) public data: { suiPageId: string }
	) {
		this.nestedDragnDropService.expandedItemIds$
			.pipe(takeUntilDestroyed())
			.subscribe((expandedItemIds) => {
				this.expandAllButtonDisabled = Utils.getExpandAllButtonDisabled(
					this.allStructureIdsWithChildren,
					expandedItemIds
				);
				this.collapseAllButtonDisabled = Utils.getCollapseAllButtonDisabled(
					this.allStructureIdsWithChildren,
					expandedItemIds
				);
			});
	}

	// ======================================================================
	// getters and setters
	// ======================================================================

	get connectedDropListsIds(): string[] {
		return this.nestedDragnDropService.connectedDropListsIds;
	}

	get intersectedItemIds(): string[] {
		return this.nestedDragnDropService.intersectedItemIds;
	}

	get expandedItemIds(): string[] {
		return this.nestedDragnDropService.expandedItemIds ?? [];
	}

	set expandedItemIds(ids: string[]) {
		this.nestedDragnDropService.expandedItemIds = [...ids];
	}

	get isDragging(): boolean {
		return this.nestedDragnDropService.isDragging;
	}

	// ======================================================================
	// public methods
	// ======================================================================

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	ngOnInit(): void {
		this.suiPageId = this.data.suiPageId;

		this.loadingService.active();
		forkJoin([
			this.libraryObjectService.tags(this.projectRepository.project.id),
			this.suiPageService.view(this.suiPageId)
		])
			.pipe(finalize(() => this.loadingService.deactive(this.cd)))
			.subscribe(
				([tagGroups, suiPage]: [TagGroups, SuiPage]) => {
					this.tagGroups = tagGroups.tagGroups;
					this.onTagGroupChange('html', false);

					this.nestedDragnDropService.tags = this.tagGroups.get(
						this.activeTagGroup
					);

					this.filterTags();
					this.editTracker.addState(
						new State<SuiPage>(
							suiPage,
							this.nestedDragnDropService.expandedItemIds
						)
					);
					this.setupSuiPage(suiPage);
				},
				(err: HttpErrorResponse) => {
					this.notificationService.httpError(err);
				}
			);

		this.disposableBag.add(
			this.screenshotService.screenshotDeleted().subscribe((screenshotId) => {
				this.screenshots.delete(screenshotId.toString());
			})
		);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	onTagGroupChange(event: string, detectChanges = true): void {
		this.activeTagGroup = event;
		this.tagGroupFilter = '';
		this.filterTags();
		this.nestedDragnDropService.tags = this.tagGroups.get(this.activeTagGroup);
		if (detectChanges) {
			this.cd.detectChanges();
		}
	}

	onSearchTagGroup(query: string): void {
		this.tagGroupFilter = query;
		this.filterTags();
	}

	onShortcutClick(tag: Tag): void {
		let suiElement = new SuiElement();
		suiElement = this.updateOptionalFieldsForAllChidren(
			cloneDeep([
				produce(suiElement, (draft) => {
					draft.structureId = `${SuiElement.STRUCTURE_ID_PREFIX}0.${this.parentItem.children.length}`;
					draft.id = this.parentItem.children.length;
					draft.type = tag.name;
					draft.group = tag.type;
					draft.parameter = { schema: tag.type };
					draft.children = [];
				})
			])
		)[0];

		this.parentItem = produce(this.parentItem, (draft) => {
			draft.children.push(suiElement);
		}).restructure();

		this.loadingService.active();

		this.disposableBag.add(
			this.suiPageService
				.updateElements(
					this.suiPage.id,
					new XmlElements().fromSuiElement(this.parentItem)
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					() => {
						this.notificationService.info('SuiElement was created');
						this.refreshWithDialog(suiElement);
					},
					(err: HttpErrorResponse) => {
						// refresh after error in order that order is correct
						this.notificationService.httpError(err);
						this.refresh();
					}
				)
		);
	}

	onDragDrop(event: CdkDragDrop<SuiElement>): void {
		const tag = event.item.element.nativeElement.dataset.tag;
		const containerChanged = event.container !== event.previousContainer;
		const positionChanged = event.currentIndex !== event.previousIndex;

		if (tag) {
			this.newElementDropped(event);
		} else if (positionChanged || containerChanged) {
			const parentItem = cloneDeep(this.parentItem);
			event.previousContainer.data = Utils.findChildByStructureId<SuiElement>(
				parentItem,
				event.previousContainer.data.structureId
			);
			event.container.data = Utils.findChildByStructureId<SuiElement>(
				parentItem,
				event.container.data.structureId
			);

			transferArrayItem<SuiElement>(
				event.previousContainer.data.children,
				event.container.data.children,
				event.previousIndex,
				event.currentIndex
			);

			const { updated, structureIdTransitions } = produce(
				this.parentItem,
				(draft) => {
					draft.children = parentItem.children;
				}
			).restructureAndGetStructureIdTransitions();
			this.parentItem = updated;

			this.updateSuiPage(() => {
				this.notificationService.info('Position was changed');
				this.isChanged = true;
				this.nestedDragnDropService.handleExpandOnMove(
					event,
					structureIdTransitions
				);
			});
		}
	}

	onToggleExpanded(id: string, isRealClick = false): void {
		this.nestedDragnDropService.onToggleExpanded(id);
		if (isRealClick) {
			this.editTracker.updateStatus(
				this.nestedDragnDropService.expandedItemIds
			);
		}
	}

	onLibDragEnded(): void {
		this.nestedDragnDropService.onLibDragEnded();
	}

	onLibDragMove(event: CdkDragMove): void {
		this.nestedDragnDropService.onLibDragMove(event);
	}

	onLibDragStarted(event: CdkDragStart): void {
		this.nestedDragnDropService.onLibDragStarted(event);
	}

	onDragEnded(event: CdkDragEnd<SuiElement>): void {
		this.nestedDragnDropService.onDragEnded(event);
	}

	onDragStarted(event: CdkDragStart<SuiElement>): void {
		this.nestedDragnDropService.onDragStarted(event);
	}

	onDragMove(event: CdkDragMove<SuiElement>): void {
		this.nestedDragnDropService.onDragMove(event);
	}

	onRefreshOnModalClose(event: {
		structureId: string;
		isChanged: boolean;
	}): void {
		if (event) {
			this.isChanged = this.isChanged || event.isChanged;
			this.refresh();
		}
	}

	close(): void {
		this.nestedDragnDropService.expandedItemIds = [];
		this.modalService.close(this.isChanged);
	}

	onClickUndo(): void {
		this.applyState(this.editTracker.undo(), true);
	}

	onClickRedo(): void {
		this.applyState(this.editTracker.redo(), false);
	}

	openPol(): void {
		this.document.defaultView.open('https://pol.dev.internal.testifi.io/');
	}

	expandAll() {
		this.nestedDragnDropService.expand(this.allStructureIdsWithChildren);
	}

	collapseAll() {
		this.nestedDragnDropService.collapse(this.allStructureIdsWithChildren);
	}

	// ======================================================================
	// private methods
	// ======================================================================
	private refreshWithDialog(suiElement: SuiElement): void {
		this.refresh(() => {
			this.modalService.open(
				ModalSuiPageElementParameterComponent,
				(updatedElement) => {
					if (updatedElement) {
						this.isChanged = true;
						this.refresh();
					}
				},
				{
					suiElement,
					suiPageId: this.suiPageId,
					suiElementParameters: suiElement.optionalFields,
					suiRootElement: this.parentItem
				}
			);
		});
	}

	private errorOnUpdate(err: HttpErrorResponse): void {
		// refresh after error in order that order is correct
		this.notificationService.httpError(err);
		this.refresh();
	}

	private updateSuiPage(callback: CallableFunction): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.suiPageService
				.updateElements(
					this.suiPage.id,
					new XmlElements().fromSuiElement(this.parentItem)
				)
				.pipe(
					finalize(() => {
						this.loadingService.deactive(this.cd);
						this.refresh();
					})
				)
				.subscribe(
					() => {
						callback();
					},
					(err: HttpErrorResponse) => this.errorOnUpdate(err)
				)
		);
	}

	private newElementDropped(event: CdkDragDrop<SuiElement>): void {
		const tag = event.item.element.nativeElement.dataset.tag;
		const group = event.item.element.nativeElement.dataset.library;
		let suiElement = new SuiElement();
		suiElement = this.updateOptionalFieldsForAllChidren(
			cloneDeep([
				produce(suiElement, (draft) => {
					draft.structureId = `${event.container.data.structureId}.${event.currentIndex}`;
					draft.id = event.currentIndex;
					draft.type = tag;
					draft.group = group;
					draft.parameter = { schema: group };
					draft.children = [];
				})
			])
		)[0];

		const parentItem = cloneDeep(this.parentItem);
		event.container.data = Utils.findChildByStructureId<SuiElement>(
			parentItem,
			event.container.data.structureId
		);

		transferArrayItem(
			[suiElement],
			event.container.data.children,
			0,
			event.currentIndex
		);

		const { updated, structureIdTransitions } = produce(
			this.parentItem,
			(draft) => {
				draft.children = parentItem.children;
			}
		).restructureAndGetStructureIdTransitions();
		this.parentItem = updated;

		this.updateSuiPage(() => {
			this.notificationService.info('SuiElement was created');
			this.nestedDragnDropService.handleExpandOnAdd(
				suiElement.structureId,
				structureIdTransitions
			);
			this.refreshWithDialog(suiElement);
		});
	}

	private applyState(nextState: State<SuiPage>, isUndo: boolean): void {
		// Update view
		this.setupSuiPage(nextState.stateObj);
		this.nestedDragnDropService.expandedItemIds =
			nextState.expandedStateElements;

		// Update backend
		this.disposableBag.add(
			this.suiPageService
				.updateElements(
					this.suiPageId,
					new XmlElements().fromSuiElement(this.parentItem)
				)
				.subscribe(
					() => {
						this.notificationService.info(
							`${isUndo ? 'Undo' : 'Redo'} successfully performed`
						);
					},
					(err: HttpErrorResponse) => {
						this.notificationService.error(
							`Failed to perform ${isUndo ? 'Undo' : 'Redo'}`
						);
						this.notificationService.httpError(err);
						this.modalService.close(err.status);
					}
				)
		);
	}

	private refresh(onSuccess: () => void = null) {
		this.loadingService.active();

		this.disposableBag.add(
			this.suiPageService
				.view(this.suiPageId)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(suiPage) => {
						this.editTracker.addState(
							new State(suiPage, this.nestedDragnDropService.expandedItemIds)
						);
						this.setupSuiPage(suiPage);

						if (onSuccess != null) {
							onSuccess();
						}
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
					}
				)
		);
	}

	private loadSelectedScreenshots(): void {
		if (this.suiPage?.children?.length) {
			let screenshotIds: string[] = [];
			this.suiPage.children
				.filter(
					(child) =>
						child.type === 'sui:zone' && child.parameter[SCREENSHOT_PARAM_KEY]
				)
				.forEach((child) => {
					screenshotIds = [
						...screenshotIds,
						child.parameter[SCREENSHOT_PARAM_KEY]
					];
				});

			if (screenshotIds.length) {
				this.loadingService.active();
				this.disposableBag.add(
					this.screenshotService
						.getScreenshotsByIds(screenshotIds)
						.pipe(finalize(() => this.loadingService.deactive(this.cd)))
						.subscribe(
							(screenshots) => {
								screenshots.forEach((screenshot) =>
									this.screenshots.set(
										screenshot.id.toString(),
										new SafeScreenshot(
											screenshot.name,
											Utils.getScreenshotImageSource(this.sanitizer, screenshot)
										)
									)
								);
							},
							(err: HttpErrorResponse) => {
								this.notificationService.httpError(err);
							}
						)
				);
			}
		}
	}

	private filterTags(): void {
		let tags = this.tagGroups.get(this.activeTagGroup);

		if (this.tagGroupFilter && this.tagGroupFilter !== '') {
			tags = Utils.searchUITagOrName(tags, this.tagGroupFilter);
		}

		tags = [...tags].sort((a, b) => Utils.compare(a.count, b.count, false));

		this.filteredTags = tags;
	}

	private setupSuiPage(suiPage: SuiPage) {
		this.suiPage = suiPage;
		let parentItem = new SuiElement();

		parentItem = produce(parentItem, (draft) => {
			draft.id = 0;
			draft.children = this.suiPage.children;
			draft.type = 'sui-page';
			draft.parameter = {};
		});

		this.parentItem = parentItem.restructure();

		this.parentItem = produce(this.parentItem, (draft) => {
			draft.children = this.updateOptionalFieldsForAllChidren(
				cloneDeep(this.parentItem.children)
			);
		});

		this.loadSelectedScreenshots();

		this.nestedDragnDropService.parentItem = this.parentItem;
	}

	private updateOptionalFieldsForAllChidren(
		children: SuiElement[]
	): SuiElement[] {
		for (const child of children) {
			this.updateOptionalFields(child);
			if (child.children.length) {
				this.updateOptionalFieldsForAllChidren(child.children);
			}
		}
		return children;
	}

	private updateOptionalFields(suiElement: SuiElement): void {
		this.tagGroups.forEach((value, key) => {
			let result: Tag[] = [];

			if (key === suiElement.group) {
				result = value.filter((tag) => tag.name === suiElement.type);
				if (result.length) {
					suiElement.optionalFields = result[0].optionalFields;
				}
			}
		});
	}
}
