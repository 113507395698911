<form [formGroup]="group" (ngSubmit)="submitted.emit($event)" class="aui">
	<ng-container *ngFor="let form of (group.controls | keyvalue);">
		<h4>{{properties[form.key].inputLabel}}</h4>
		<app-multi-line-editor
			#multiLineEditor
			[control]="form.value"
			[breakWords]="properties[form.key].breakWords"
			[maxWidth]="properties[form.key].maxWidth"
			[textColor]="properties[form.key].textColor"
			[defaultText]="properties[form.key].defaultText"
			[height]="properties[form.key].height"
			[clickable]="false"
			[editMode]="editMode"
			(click)="controlClicked(multiLineEditor)"
		></app-multi-line-editor>
	</ng-container>
	<div *ngIf="editMode" class="btn-containers">
		<button
			id="update-submit"
			class="aui-button aui-button-primary"
			name="Save"
			type="submit"
			[disabled]="group.invalid"
		>
			Save
		</button>
		<button id="cancel" class="aui-button aui-button-subtle" name="Cancel" (click)="cancel()">Cancel</button>
	</div>
</form>
