/* eslint-disable functional/immutable-data */
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LabelItemView } from '@testifi-models/label-item-view';
import { IProjectSettings } from '@testifi-models/project-settings.interface';
import { ScenarioParameter } from '@testifi-models/scenario-parameter';
import { TestPlan } from '@testifi-models/test-plan';
import { Utils } from '@testifi-utils/utils';
import { produce } from 'immer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrivateHttpClient } from '../https/instances/private.http';
import { Project, ProjectSettings } from '../models/project';
import { ProjectView } from '../models/project-view';
import { BaseApiService } from './base-api.service';
import { GitConnection } from './git.service';

export interface GitSettingsPayload {
	projectId: string;
	gitUrl: string;
	gitBranch: string;
}

export interface ProjectStatusesPayload {
	projectId: string;
	abortedStatus: string;
	abortedStepStatus: string;
	executingStatus: string;
	executingStepStatus: string;
	failStatus: string;
	failStepStatus: string;
	mismatchStatus: string;
	mismatchStepStatus: string;
	passStatus: string;
	passStepStatus: string;
	todoStatus: string;
	todoStepStatus: string;
}

export interface ExecutionResponse {
	createdAt: Date;
	error: string;
	executionId: string;
	id: string;
	projectId: string;
	status: string;
}

export interface ExecutionRequest {
	scenarioId: string[];
	testPlan?: TestPlan;
	device?: string;
	gitConnection?: GitConnection;
	parameters?: Array<ScenarioParameter>;
}

export const UNKNOWN_EXEC_STATUS = 'N/A';

export interface ProjectViewParameters {
	key: string;
	page: number;
	limit: number;
	sort: string;
	direction: string;
	searchText: string;
	executionStatuses: string[];
	jiraStatuses: string[];
	reporter: string;
	labelItem: string;
	createdStartDate: string | undefined;
	createdEndDate: string | undefined;
	updatedStartDate: string | undefined;
	updatedEndDate: string | undefined;
}

@Injectable({ providedIn: 'root' })
export class ProjectService {
	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private privateHttp: PrivateHttpClient,
		private base: BaseApiService
	) {}

	// ======================================================================
	// public functions
	// ======================================================================

	add(
		name: string,
		description: string,
		projectKey: string
	): Observable<Project> {
		return this.privateHttp
			.post<Project>('project', { name, description, projectKey })
			.pipe(map((res) => this.base.model(res, Project)));
	}

	get(): Observable<Project[]> {
		return this.privateHttp
			.get<Project[]>('projects')
			.pipe(map((res) => this.base.models(res, Project)));
	}

	getById(id: string): Observable<ProjectView> {
		return this.privateHttp
			.get<ProjectView>(`project/${id}`)
			.pipe(map((res) => this.base.model(res, ProjectView)));
	}

	viewByKey(viewParameters: ProjectViewParameters): Observable<ProjectView> {
		viewParameters = this.setDefaultValuesIfUndefined(viewParameters);

		let params = new HttpParams()
			.set('pageNumber', `${viewParameters.page}`)
			.set('limit', `${viewParameters.limit}`)
			.set('sortBy', viewParameters.sort.toUpperCase())
			.set('direction', viewParameters.direction.toUpperCase())
			.set('text', viewParameters.searchText)
			.set('executionStatuses', viewParameters.executionStatuses.join(','))
			.set('jiraStatuses', viewParameters.jiraStatuses.join(','))
			.set('reporter', viewParameters.reporter)
			.set('labelItem', viewParameters.labelItem);
		if (viewParameters.createdStartDate) {
			params = params.set('createdStartDate', viewParameters.createdStartDate);
		}
		if (viewParameters.createdEndDate) {
			params = params.set(
				'createdEndDate',
				Utils.fixSameDayDate(viewParameters.createdEndDate)
			);
		}
		if (viewParameters.updatedStartDate) {
			params = params.set('updatedStartDate', viewParameters.updatedStartDate);
		}
		if (viewParameters.updatedEndDate) {
			params = params.set(
				'updatedEndDate',
				Utils.fixSameDayDate(viewParameters.updatedEndDate)
			);
		}

		return this.privateHttp
			.get<ProjectView>(`project/key/${viewParameters.key}`, {
				params
			})
			.pipe(
				map((res) =>
					produce(this.base.model(res, ProjectView), (draft) => {
						let UNKNOWN_STATUS = {
							name: UNKNOWN_EXEC_STATUS,
							color: '',
							description: ''
						};

						draft.statistics.forEach((stat) => {
							if (stat.status === UNKNOWN_EXEC_STATUS) {
								UNKNOWN_STATUS = { ...UNKNOWN_STATUS, color: stat.color };
							}
						});
						for (const component of draft.testScenarios) {
							if (!component.testRun) {
								component.testRun = {
									id: '',
									status: UNKNOWN_STATUS,
									comment: '',
									startedOn: new Date(),
									finishedOn: null
								};
							} else if (!component.testRun.status) {
								component.testRun.status = UNKNOWN_STATUS;
							}
						}
					})
				)
			);
	}

	initExecution(
		projectId: string,
		projectKey: string,
		request: ExecutionRequest
	): Observable<TestPlan[]> {
		return this.privateHttp
			.post<TestPlan[]>(`execute/init`, {
				scenarioId: request.scenarioId,
				projectId,
				projectKey
			})
			.pipe(map((res) => this.base.models(res, TestPlan)));
	}

	execute(
		projectId: string,
		projectKey: string,
		request: ExecutionRequest
	): Observable<ExecutionResponse> {
		return this.privateHttp.post<ExecutionResponse>(`execute`, {
			scenarioId: request.scenarioId,
			testPlan: request.testPlan,
			device: request.device,
			gitConnection: request.gitConnection,
			parameters: request.parameters,
			projectId,
			projectKey
		});
	}

	getExecutionById(executionId: string): Observable<ExecutionResponse> {
		return this.privateHttp.get<ExecutionResponse>(`execution/${executionId}`);
	}

	getAllExecutionsStatus(
		projectId: string
	): Observable<Map<string, ExecutionResponse>> {
		return this.privateHttp
			.get<ExecutionResponse[]>(`executionAll/${projectId}`)
			.pipe(
				map((executionResponseArray) => {
					const _map = new Map<string, ExecutionResponse>();
					for (const executionResponse of executionResponseArray) {
						_map.set(executionResponse.projectId, executionResponse);
					}
					return _map;
				})
			);
	}

	getTestPlanList(projectId: string): Observable<TestPlan[]> {
		return this.privateHttp
			.get<TestPlan[]>(`project/${projectId}/testPlans`)
			.pipe(
				map((res) =>
					this.base
						.models(res, TestPlan)
						.filter((plan) => plan.xrayTestPlanKey !== undefined)
				)
			);
	}

	getProjectSettings(projectId: string): Observable<IProjectSettings> {
		return this.privateHttp
			.get<IProjectSettings>(`project/${projectId}/projectSettings`)
			.pipe(map((res) => this.base.model(res, ProjectSettings)));
	}

	updateProjectStatuses(
		statuses: ProjectStatusesPayload
	): Observable<IProjectSettings> {
		return this.privateHttp.post<IProjectSettings>(
			'project-settings',
			statuses
		);
	}

	updateGitSettings(
		gitSettings: GitSettingsPayload
	): Observable<IProjectSettings> {
		return this.privateHttp.post<IProjectSettings>(
			'project-settings/git',
			gitSettings
		);
	}

	validateGitSettings(payload: GitConnection): Observable<boolean> {
		return this.privateHttp.post<boolean>('project-settings/validate', payload);
	}

	info(
		applicationId: string,
		projectKey: string,
		jiraUrl: string
	): Observable<Project> {
		return this.privateHttp.post<Project>('project/properties', {
			jiraUrl,
			applicationId,
			projectKey
		});
	}

	getReporterList(projectId: string): Observable<string[]> {
		return this.privateHttp.get<string[]>(`project/${projectId}/reporterList`);
	}

	getLabelItemList(projectId: string): Observable<LabelItemView[]> {
		return this.privateHttp
			.get<LabelItemView[]>(`project/${projectId}/labelItemList`)
			.pipe(
				map((labelItems) =>
					[...labelItems].sort((a1: LabelItemView, a2: LabelItemView) => {
						if (a1.labelText < a2.labelText) return -1;
						if (a1.labelText > a2.labelText) return 1;
						return 0;
					})
				)
			);
	}

	private setDefaultValuesIfUndefined(
		viewParameters: ProjectViewParameters
	): ProjectViewParameters {
		return {
			...viewParameters,
			page: viewParameters.page ?? 0,
			limit: viewParameters.limit ?? 10,
			sort: viewParameters.sort ?? 'NAME',
			direction: viewParameters.direction ?? 'ASC'
		};
	}
}
