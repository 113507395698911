import {
	Directive,
	Input,
	OnInit,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../services/loading.service';

@Directive({
	selector: '[hideOnLoading]', // eslint-disable-line
	standalone: true
})
export class HideOnLoadingDirective implements OnInit {
	private obs: Subscription = new Subscription();
	private loading = true;
	private onlyFirst = true;

	constructor(
		private templateRef: TemplateRef<unknown>,
		private viewContainer: ViewContainerRef,
		private loadingService: LoadingService
	) {}

	ngOnInit(): void {
		this.viewContainer.clear();

		this.obs = this.loadingService.get().subscribe((loading) => {
			this.loading = loading;
			this.updateView();
		});
	}

	@Input()
	set hideOnLoading(onlyFirst: boolean) {
		this.onlyFirst = onlyFirst ?? true;
		this.updateView();
	}

	private updateView() {
		if (this.loading) {
			this.viewContainer.clear();
		} else {
			this.viewContainer.createEmbeddedView(this.templateRef);
			if (this.onlyFirst) {
				this.obs.unsubscribe();
			}
		}
	}
}
