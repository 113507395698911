/* eslint-disable functional/immutable-data */
import {
	CdkDrag,
	CdkDragDrop,
	CdkDropList,
	moveItemInArray
} from '@angular/cdk/drag-drop';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalEditTestStepComponent } from '@testifi-modals/modal-edit-test-step/modal-edit-test-step.component';
import { ModalExecuteTestPlanComponent } from '@testifi-modals/modal-execute-test-plan/modal-execute-test-plan.component';
import { ModalGitInformationComponent } from '@testifi-modals/modal-git-information/modal-git-information.component';
import { LabelItemView } from '@testifi-models/label-item-view';
import { ScenarioParameter } from '@testifi-models/scenario-parameter';
import { TestObject } from '@testifi-models/test-object';
import { TestPlan } from '@testifi-models/test-plan';
import { TestRunStatus } from '@testifi-models/test-run';
import { TestScenario } from '@testifi-models/test-scenario';
import { TestStep, UpdateType } from '@testifi-models/test-step';
import { ETestStepType } from '@testifi-models/test-step-type.enum';
import { ValidationType } from '@testifi-models/validation-type';
import { XmlElements } from '@testifi-models/xml-elements';
import { BreadcrumbService } from '@testifi-services/breadcrumb.service';
import {
	GitConnection,
	GitExportPayload,
	GitService
} from '@testifi-services/git.service';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalData, ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import {
	ExecutionRequest,
	ProjectService
} from '@testifi-services/project.service';
import { RouterService } from '@testifi-services/router.service';
import { ScenarioExecutionMonitorService } from '@testifi-services/scenario-execution-monitor/scenario-execution-monitor.service';
import { TestScenarioService } from '@testifi-services/test-scenario.service';
import { TestStepService } from '@testifi-services/test-step.service';
import {
	DESCRIPTION_VALIDATORS,
	MANDATORY_PARAMETERS_KEY,
	OPTIONAL_PARAMETERS_KEY,
	PAGES,
	VALUE_VALIDATORS_200
} from '@testifi-shared/app-constants';
import { LabelListComponent } from '@testifi-shared/label-list/label-list.component';
import {
	MultiLineEditorLegacyComponent as MultiLineEditorComponent_1,
	MultiLineEditorLegacyComponent
} from '@testifi-shared/multi-line-editor/multi-line-editor-legacy.component';
import { SingleLineEditorComponent } from '@testifi-shared/singleline-editor/single-line-editor.component';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { EditTracker, State } from '@testifi-utils/edit.tracker';
import { ScenarioUtils } from '@testifi-utils/scenario.utils';
import { Utils } from '@testifi-utils/utils';
import { produce } from 'immer';
import { cloneDeep, isEqual } from 'lodash-es';
import { OperatorFunction, Subscription, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { ModalRouterNameDirective } from '../../directives/modal-router-name.directive';
import { BreadcrumbComponent } from '../../shared/breadcrumb/breadcrumb.component';
import { ExpandableLibraryPanelGroupComponent } from '../../shared/expandable-library-panel-group/expandable-library-panel-group.component';
import { ExpandableLibraryPanelComponent } from '../../shared/expandable-library-panel/expandable-library-panel.component';
import { KpiBoxComponent } from '../../shared/kpi-box/kpi-box.component';
import { ParameterListComponent } from '../../shared/parameter-list/parameter-list.component';
import { RelationValuesComponent } from '../../shared/relation-values/relation-values.component';
import { SingleLineEditorComponent as SingleLineEditorComponent_1 } from '../../shared/singleline-editor/single-line-editor.component';

export interface ExecutionParameters {
	testPlan: TestPlan;
	device: string;
	gitConnection: GitConnection;
	scenarioParameters: ScenarioParameter[];
}

export function instanceOfExecutionParameters(
	data: any // eslint-disable-line
): data is ExecutionParameters {
	return data ? 'testPlan' in data : false;
}

@Component({
	selector: 'app-page-view-test-scenario',
	templateUrl: './page-view-test-scenario.component.html',
	styleUrls: ['./page-view-test-scenario.component.less'],
	standalone: true,
	imports: [
		HideOnLoadingDirective,
		BreadcrumbComponent,
		SingleLineEditorComponent_1,
		ModalRouterNameDirective,
		KpiBoxComponent,
		ExpandableLibraryPanelGroupComponent,
		CdkDropList,
		NgClass,
		ExtendedModule,
		NgFor,
		CdkDrag,
		ExpandableLibraryPanelComponent,
		NgIf,
		MultiLineEditorComponent_1,
		RelationValuesComponent,
		ParameterListComponent,
		LabelListComponent,
		DecimalPipe,
		AsyncPipe
	]
})
export class PageViewTestScenarioComponent implements OnInit, OnDestroy {
	// ======================================================================
	// private properties
	// ======================================================================

	private readonly disposableBag = new Subscription();
	private isReloadForUndoOrRedo = false;
	private isReloadForIdChangedInUrl = false;

	// ======================================================================
	// public properties
	// ======================================================================
	weightedScore = '-';
	testScenarioId: string;
	testScenario: TestScenario;
	testSteps: TestStep[] = [];
	expandedTestSteps: string[] = [];
	validationType = ValidationType;
	testObjectCount = 0;
	testStepCount = 0;
	summaryValidation = [...VALUE_VALIDATORS_200, Validators.required];
	descriptionValidation = DESCRIPTION_VALIDATORS;
	status: TestRunStatus;
	summaryUpdated = false;
	descriptionUpdated = false;
	editTracker = new EditTracker<TestScenario>(this.appConfigRepository);
	hasPutUpAndTearDown = false;
	readonly ETestStepType = ETestStepType;

	@ViewChild('summaryEditor')
	summaryEditorComponent: SingleLineEditorComponent;
	@ViewChild('descriptionEditor')
	descriptionEditorComponent: MultiLineEditorLegacyComponent;
	@ViewChild('buttonMore') buttonMore: ElementRef<HTMLButtonElement>;

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		public appConfigRepository: AppConfigRepository,
		private readonly cd: ChangeDetectorRef,
		private readonly route: ActivatedRoute,
		private readonly modalService: ModalService,
		private readonly loadingService: LoadingService,
		private readonly breadcrumbService: BreadcrumbService,
		private readonly notificationService: NotificationService,
		private readonly testScenarioService: TestScenarioService,
		private readonly testStepService: TestStepService,
		private readonly projectService: ProjectService,
		private readonly routerService: RouterService,
		private readonly scenarioExecutionMonitoringService: ScenarioExecutionMonitorService,
		private readonly gitService: GitService,
		private readonly projectRepository: ProjectRepository
	) {}

	// ======================================================================
	// inherit functions
	// ======================================================================

	ngOnInit(): void {
		this.disposableBag.add(
			this.route.paramMap.subscribe((params) => {
				const testScenarioId = params.get('testScenarioId');
				if (this.testScenarioId !== testScenarioId) {
					if (this.testScenarioId) {
						this.isReloadForIdChangedInUrl = true;
					}
					this.editTracker = new EditTracker<TestScenario>(
						this.appConfigRepository
					);
					this.testScenarioId = testScenarioId;
					this.reload();
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	@HostListener('window:popstate', ['$event'])
	onPopState(): void {
		this.breadcrumbService.set(
			new Map([
				['Projects', null],
				[
					this.testScenario.projectKey,
					{
						navigateTo: [PAGES.LANDING, `${this.testScenario.projectKey}`]
					}
				]
			])
		);
		this.routerService.navigate([
			PAGES.LANDING,
			`${this.testScenario.projectKey}`
		]);
	}

	// ======================================================================
	// public functions
	// ======================================================================
	toggleExpanded(testStepId: string): void {
		if (this.expandedTestSteps.includes(testStepId)) {
			this.expandedTestSteps = this.expandedTestSteps.filter(
				(id) => id !== testStepId
			);
		} else {
			this.expandedTestSteps.push(testStepId);
		}
	}

	isExpanded(testStepId: string): boolean {
		return this.expandedTestSteps.includes(testStepId);
	}

	popOnModalClose(data: ModalData): void {
		if (!data) {
			return;
		}

		this.loadingService.active();

		this.disposableBag.add(
			this.testScenarioService
				.delete(data.testScenarioId)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					() => {
						this.notificationService.info('Test (Scenario) has been deleted');
						this.routerService.navigate([PAGES.LANDING]);
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}

	editTestStepOnCreateModalClose(testStep: TestStep): void {
		if (testStep) {
			this.modalService.open(
				ModalEditTestStepComponent,
				(step) => {
					if (step) {
						this.editTracker.clear();
						this.synchronize();
					}
				},
				{
					testStepId: testStep.id,
					testScenarioId: this.testScenario.id,
					projectId: this.testScenario.projectId,
					isNew: testStep.updateType === UpdateType.Create
				}
			);
		}
	}

	onStepDeletion(): void {
		const data = this.modalService.onCloseData as ModalData;
		if (data) {
			this.deleteTestStep(data.testStepId);
		}
	}

	drop(event: CdkDragDrop<TestStep[]>): void {
		if (event.previousIndex === event.currentIndex) {
			return;
		}

		const stepsOrder = cloneDeep(this.testSteps);

		moveItemInArray(stepsOrder, event.previousIndex, event.currentIndex);

		if (
			(this.hasPutUpAndTearDown &&
				stepsOrder[0].tag === ETestStepType.PUT_UP &&
				stepsOrder[stepsOrder.length - 1].tag === ETestStepType.TEAR_DOWN) ||
			!this.hasPutUpAndTearDown
		) {
			this.testSteps = stepsOrder;
			const testStepId = event.item.element.nativeElement.dataset.id;
			this.changePosition(testStepId, event.currentIndex);
		}
	}

	onSummaryChange(value: string): void {
		if (value) {
			this.updateScenario(
				produce(this.testScenario, (draft) => {
					draft.name = value;
				}),
				'summary'
			);
		}
	}

	onDescriptionChange(value: string): void {
		if (typeof value === 'string') {
			this.updateScenario(
				produce(this.testScenario, (draft) => {
					draft.description = value;
				}),
				'description'
			);
		}
	}

	exportScenario(): void {
		this.modalService.open(
			ModalGitInformationComponent,
			(gitConnection: GitConnection) => {
				if (!gitConnection) {
					return;
				}

				const payload: GitExportPayload = {
					gitConnection,
					ids: [this.testScenario.id],
					projectKey: this.testScenario.projectId
				};

				this.disposableBag.add(
					this.gitService.pushAllFiles(payload).subscribe(
						() => {
							this.notificationService.info('Scenario exported successfully');
						},
						() =>
							this.notificationService.error('Scenario has not been exported')
					)
				);
			},
			{ projectId: this.testScenario.projectId }
		);
	}

	activateTestExecution(): void {
		let request: ExecutionRequest = {
			scenarioId: [this.testScenario.id]
		};
		const scenarioParameters: ScenarioParameter[] = [
			new ScenarioParameter(MANDATORY_PARAMETERS_KEY, ''),
			new ScenarioParameter(OPTIONAL_PARAMETERS_KEY, '')
		];

		this.loadingService.active();

		this.disposableBag.add(
			this.projectService
				.initExecution(
					this.testScenario.projectId,
					this.testScenario.projectKey,
					request
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe((response) => {
					this.modalService.open(
						ModalExecuteTestPlanComponent,
						(result) => {
							if (instanceOfExecutionParameters(result)) {
								request = produce(request, (draft) => {
									draft.testPlan = result.testPlan;
									draft.device = result.device;
									draft.gitConnection = result.gitConnection;
									draft.parameters = result.scenarioParameters;
								});
								this.callTestsExecution(request);
							}
						},
						{
							title: 'Test Scenario Execution',
							testPlans: produce(response, (draft) => {
								draft.unshift(new TestPlan('Without Test Plan (ad-hoc)', true));
							}),
							projectId: this.testScenario.projectId,
							scenarioParameters
						}
					);
				})
		);
	}

	onEditClose(testStep: TestStep): void {
		this.editTracker.clear();
		testStep ? this.synchronize() : this.reload();
	}

	onScenarioParamChanged(scenarioParams: ScenarioParameter[]): void {
		this.updateScenario(
			produce(this.testScenario, (draft) => {
				draft.parameters = scenarioParams;
			}),
			'parameters',
			false
		);
	}

	onLabelItemListChanged(labelItemList: LabelItemView[]): void {
		this.updateScenario(
			produce(this.testScenario, (draft) => {
				draft.labelItems = labelItemList;
			}),
			'labelItems',
			false
		);
	}

	onClickUndo(): void {
		this.isReloadForUndoOrRedo = true;
		this.applyState(this.editTracker.undo(), true);
	}

	onClickRedo(): void {
		this.isReloadForUndoOrRedo = true;
		this.applyState(this.editTracker.redo(), false);
	}

	cloneScenario() {
		this.loadingService.active();

		const createTestScenarioRequest =
			ScenarioUtils.convertToCreateTestScenarioWithTestStepsRequest(
				this.testScenario
			);
		createTestScenarioRequest.name = `CLONE - ${createTestScenarioRequest.name}`;

		this.disposableBag.add(
			this.testScenarioService
				.createWithSteps(createTestScenarioRequest)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe({
					next: (clonedScenario) => {
						this.routerService.navigate([
							PAGES.SCENARIO,
							`${clonedScenario.id}`
						]);
					}
				})
		);
	}

	// ======================================================================
	// private functions
	// ======================================================================
	private reload(): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.testScenarioService
				.get(this.testScenarioId)
				.pipe(
					this.updateScenarioWithEmptyParametersIfMissing(),
					finalize(() => {
						this.loadingService.deactive(this.cd);
						this.updateBreadcrumbs();
					})
				)
				.subscribe(
					(testScenario) => {
						if (this.isReloadForUndoOrRedo) {
							this.isReloadForUndoOrRedo = false;
						} else if (this.isReloadForIdChangedInUrl) {
							this.isReloadForIdChangedInUrl = false;
							this.editTracker.addState(new State<TestScenario>(testScenario));
						} else {
							this.editTracker.addState(new State<TestScenario>(testScenario));
						}

						this.testScenario = testScenario;
						this.testSteps = this.testScenario.testSteps;
						this.testSteps.forEach((step) => {
							this.hasPutUpAndTearDown ||=
								step.tag === ETestStepType.PUT_UP ||
								step.tag === ETestStepType.TEAR_DOWN;
						});

						this.updateProjectKeyIfProjectIsChanged();

						this.applyStatus();

						this.testStepCount = this.testSteps.length;
						this.testObjectCount = this.testSteps.reduce(
							(count, step) => count + step.testObjects.length,
							0
						);

						if (!this.testScenario.xrayTestKey) {
							this.monitorTestScenarioChanges('xrayTestKey');
						}
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
						this.routerService.navigate([PAGES.LANDING]);
					}
				)
		);
	}

	private updateScenarioWithEmptyParametersIfMissing(): OperatorFunction<
		TestScenario,
		TestScenario
	> {
		return switchMap((testScenario) => {
			const hasMandatory = testScenario.parameters.find(
				(parameter) => parameter.key === MANDATORY_PARAMETERS_KEY
			);
			const hasOptional = testScenario.parameters.find(
				(parameter) => parameter.key === OPTIONAL_PARAMETERS_KEY
			);
			if (!hasMandatory || !hasOptional) {
				return this.testScenarioService.edit(
					produce(testScenario, (draft) => {
						if (!hasMandatory) {
							draft.parameters.push(
								new ScenarioParameter(MANDATORY_PARAMETERS_KEY, '')
							);
						}
						if (!hasOptional) {
							draft.parameters.push(
								new ScenarioParameter(OPTIONAL_PARAMETERS_KEY, '')
							);
						}
					})
				);
			}
			return of(testScenario);
		});
	}

	private updateScenario(
		testScenario: TestScenario,
		flagName: string,
		shouldSync = true,
		isUndo?: boolean
	): void {
		this.loadingService.active();
		this.disposableBag.add(
			this.testScenarioService
				.edit(testScenario)
				.pipe(
					switchMap(() => {
						this.notificationService.info(
							this.createMessage('Test Scenario was updated', isUndo)
						);
						return shouldSync
							? this.testScenarioService.createInXray(this.testScenarioId)
							: of<void>(null);
					}),
					finalize(() => {
						this.loadingService.deactive(this.cd);
						setTimeout(() => this.setUpdateFlag(flagName, false), 250);
					})
				)
				.subscribe(
					() => {
						this.testScenario = testScenario;
						this.setUpdateFlag(flagName, true);
						this.testSynchronized(shouldSync);
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
						if (flagName === 'summary') {
							this.summaryEditorComponent.resetForm();
						} else if (flagName === 'description') {
							this.descriptionEditorComponent.resetForm();
						}
					}
				)
		);
	}

	private areStepsEquals(ts1: TestStep[], ts2: TestStep[]): boolean {
		if (ts1.length !== ts2.length) {
			return false;
		}

		for (let idx = 0; idx < ts1.length; idx++) {
			if (ts1[idx].id !== ts2[idx].id) {
				return false;
			}
		}
		return true;
	}

	private applyState(nextState: State<TestScenario>, isUndo: boolean): void {
		if (
			this.areStepsEquals(
				nextState.stateObj.testSteps,
				this.testScenario.testSteps
			)
		) {
			const diffs = Utils.differenceInObj<TestScenario>(
				nextState.stateObj,
				this.testScenario
			);
			const shouldSync = diffs.includes('parameters') && diffs.length > 1;

			this.testScenario = nextState.stateObj;
			this.updateScenario(this.testScenario, '', shouldSync, isUndo);
		} else {
			this.applyStateForStepChanges(nextState, isUndo);
		}
	}

	private applyStateForStepChanges(
		nextState: State<TestScenario>,
		isUndo: boolean
	) {
		if (
			nextState.stateObj.testSteps.length < this.testScenario.testSteps.length
		) {
			// one step needs to be deleted
			for (let idx = 0; idx < this.testScenario.testSteps.length; idx++) {
				if (
					idx === nextState.stateObj.testSteps.length ||
					nextState.stateObj.testSteps[idx].id !==
						this.testScenario.testSteps[idx].id
				) {
					this.deleteTestStep(this.testScenario.testSteps[idx].id, isUndo);
					break;
				}
			}
		} else if (nextState.stateObj.testSteps > this.testScenario.testSteps) {
			// one step needs to be created
			for (let idx = 0; idx < nextState.stateObj.testSteps.length; idx++) {
				if (
					!this.compareSteps(
						nextState.stateObj.testSteps[idx],
						this.testScenario.testSteps[idx]
					)
				) {
					this.restoreTestStep(nextState.stateObj.testSteps[idx], idx, isUndo);
					break;
				}
			}
		} else {
			// the order was changed, we need to find out how to match it with only one move
			this.restorePosition(nextState.stateObj.testSteps, isUndo);
		}
	}

	private compareSteps(step1: TestStep, step2: TestStep): boolean {
		if (!step1 || !step2) {
			return false;
		} else if (
			step1.description === step2.description &&
			step1.data === step2.data &&
			step1.expectedResult === step2.expectedResult &&
			isEqual(step1.testObjects, step2.testObjects)
		) {
			return true;
		}
		return false;
	}

	private restoreTestStep(
		testStep: TestStep,
		atIndex: number,
		isUndo: boolean
	): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.testStepService
				.add(
					this.testScenario.id,
					testStep.description,
					testStep.data,
					testStep.expectedResult,
					atIndex
				)
				.pipe(
					switchMap((testStepCreated) => {
						this.updateTestStepIdForTracker(testStep.id, testStepCreated.id);

						if (testStep.testObjects?.length) {
							let parentItem = new TestObject();
							parentItem = produce(parentItem, (draft) => {
								draft.name = 'Root';
								draft.description =
									'This element is just a placeholder and defines the root';
								draft.children = testStep.testObjects;
								draft.parameters = [];
								draft.validationType = testStep.validationType;
							}).restructure();

							return this.testStepService.updateTestObjects(
								testStepCreated.id,
								new XmlElements().fromTestObject(parentItem)
							);
						} else {
							return of(testStepCreated);
						}
					}),
					switchMap(() => {
						this.notificationService.info(
							`${isUndo ? 'Undo' : 'Redo'} successfully performed`
						);
						return this.testScenarioService.createInXray(this.testScenarioId);
					}),
					finalize(() => this.loadingService.deactive(this.cd))
				)
				.subscribe(
					() => this.testSynchronized(),
					(err) => this.notificationService.httpError(err)
				)
		);
	}

	private updateTestStepIdForTracker(oldId: string, newId: string): void {
		this.editTracker.doForAllStates(
			({ stateObj, expandedStateElements }) =>
				new State(
					{
						stateObj,
						testSteps: stateObj.testSteps.map((step) =>
							produce(step, (draft) => {
								draft.id = step.id === oldId ? newId : step.id;
							})
						)
					},
					expandedStateElements
				)
		);
	}

	private restorePosition(restoredSteps: TestStep[], isUndo?: boolean) {
		const currentTestSteps = this.testScenario.testSteps;

		let firstDiffIdx = 0;
		while (
			restoredSteps[firstDiffIdx].id === currentTestSteps[firstDiffIdx].id
		) {
			firstDiffIdx++;
		}

		let lastDiffIdx = currentTestSteps.length - 1;
		while (restoredSteps[lastDiffIdx].id === currentTestSteps[lastDiffIdx].id) {
			lastDiffIdx--;
		}

		restoredSteps[firstDiffIdx].id === currentTestSteps[lastDiffIdx].id
			? this.changePosition(
					restoredSteps[firstDiffIdx].id.toString(),
					firstDiffIdx,
					isUndo
				)
			: this.changePosition(
					restoredSteps[lastDiffIdx].id.toString(),
					lastDiffIdx,
					isUndo
				);
	}

	private changePosition(testStepId: string, index: number, isUndo?: boolean) {
		this.loadingService.active();

		this.disposableBag.add(
			this.testScenarioService
				.changePosition(this.testScenario.id, testStepId, index)
				.pipe(
					switchMap(() => {
						this.notificationService.info(
							this.createMessage('Step position was changed', isUndo)
						);
						return this.testScenarioService.createInXray(this.testScenarioId);
					}),
					finalize(() => this.loadingService.deactive(this.cd))
				)
				.subscribe(
					() => this.testSynchronized(),
					(err) => {
						// reload after error in order that the order is correct
						this.reload();
						this.notificationService.httpError(err);
					}
				)
		);
	}

	private deleteTestStep(testStepId: string, isUndo?: boolean): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.testStepService
				.delete(testStepId)
				.pipe(
					switchMap(() => {
						this.notificationService.info(
							this.createMessage('Step was deleted', isUndo)
						);
						return this.testScenarioService.createInXray(this.testScenarioId);
					}),
					finalize(() => this.loadingService.deactive(this.cd))
				)
				.subscribe(
					() => this.testSynchronized(),
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}

	private synchronize(): void {
		this.disposableBag.add(
			this.testScenarioService.createInXray(this.testScenarioId).subscribe(
				() => this.testSynchronized(),
				(err: HttpErrorResponse) => this.notificationService.httpError(err)
			)
		);
	}

	private setUpdateFlag(type: string, value: boolean): void {
		if (!type) {
			return;
		} else if (type === 'summary') {
			this.summaryUpdated = value;
		} else {
			this.descriptionUpdated = value;
		}
		this.cd.detectChanges();
	}

	private callTestsExecution(request: ExecutionRequest): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.projectService
				.execute(
					this.testScenario.projectId,
					this.testScenario.projectKey,
					request
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(() => {
					this.status = {
						color: '#000000',
						description: 'The test run queued',
						name: 'QUEUED'
					};

					this.monitorTestScenarioChanges();
				})
		);
	}

	private updateBreadcrumbs(): void {
		const appUrl = this.appConfigRepository.appUrl;
		const testScenarioUrl = `${appUrl}${
			this.routerService.resolve([PAGES.SCENARIO, this.testScenario.id])[0]
		}`;
		this.breadcrumbService.set(
			new Map([
				['Projects', null],
				[
					this.testScenario.projectKey,
					{
						navigateTo: [PAGES.LANDING, `${this.testScenario.projectKey}`]
					}
				],
				[
					this.testScenario.name,
					{
						copyLink: testScenarioUrl
					}
				]
			])
		);
	}

	private monitorTestScenarioChanges(reason = ''): void {
		if (!this.testScenario) {
			return null;
		}
		this.disposableBag.add(
			this.scenarioExecutionMonitoringService
				.start(this.testScenario.id)
				.subscribe((scenario) => {
					if (scenario) {
						this.testScenario = scenario;
						this.applyStatus();

						if (reason === 'xrayTestKey' && this.testScenario.xrayTestKey) {
							this.scenarioExecutionMonitoringService.stop();
						}
					}
				})
		);
	}

	private applyStatus(): void {
		this.status = this.testScenario.testRun?.status;

		if (!this.status) {
			this.status = {
				color: '#68778C',
				description: '',
				name: 'N/A'
			};
		}
		this.cd.detectChanges();
	}

	private testSynchronized(isSynched = true): void {
		if (isSynched) {
			this.notificationService.info('Synchronized with Xray...');
		}
		this.reload();
	}

	private createMessage(defaultMessage: string, isUndo?: boolean): string {
		if (isUndo === undefined) {
			return defaultMessage;
		}
		return `${isUndo ? 'Undo' : 'Redo'} successfully performed`;
	}

	private updateProjectKeyIfProjectIsChanged() {
		const configProjectKey = this.projectRepository.projectKey;
		if (configProjectKey !== this.testScenario.projectKey) {
			this.projectRepository.setProjectKey(this.testScenario.projectKey);
			this.projectRepository.setProject({
				name: this.testScenario.projectKey,
				id: this.testScenario.projectId,
				description: ''
			});
		}
	}
}
