/* eslint-disable functional/immutable-data */
import { NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	Validators
} from '@angular/forms';
import { ICredentialsPayload } from '@testifi-models/credentials.interface';
import { NotificationService } from '@testifi-services/notification.service';
import { UserService } from '@testifi-services/user.service';
import { TestConnectionState } from '@testifi-shared/test-connection/test-connection.component';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { AuthRepository } from '@testifi-store/auth/auth.repository';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { SvgIconComponent } from 'angular-svg-icon';
import { withLatestFrom } from 'rxjs';
import { TestConnectionComponent } from '../../../shared/test-connection/test-connection.component';
import { ValidationErrorsComponent } from '../../../shared/validation-errors/validation-errors.component';
import { BaseCredentials } from '../base-credentials';
import {
	SettingsToggleComponent,
	SettingsToggleOptions
} from '../settings-toggle/settings-toggle.component';

export enum APIMode {
	Password,
	Token
}
@Component({
	selector: 'app-server-credentials',
	templateUrl: './server-credentials.component.html',
	styleUrls: ['./server-credentials.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		FlexModule,
		SettingsToggleComponent,
		NgIf,
		SvgIconComponent,
		ValidationErrorsComponent,
		TestConnectionComponent
	]
})
export class ServerCredentialsComponent
	extends BaseCredentials
	implements OnInit
{
	@Output() mode = new EventEmitter<APIMode>();

	isHidden = true;
	basicAuthentication = true;
	toggleOptions: SettingsToggleOptions = {
		firstOption: 'Basic Auth.',
		secondOption: 'API Token'
	};

	constructor(
		private formBuilder: UntypedFormBuilder,
		private appConfigRepository: AppConfigRepository,
		private credentialRepository: CredentialRepository,
		private authRepository: AuthRepository,
		userService: UserService,
		notificationService: NotificationService
	) {
		super(userService, notificationService);
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			username: ['null', [Validators.required]],
			token: [''],
			password: ['']
		});

		this.disposableBag.add(
			this.credentialRepository.credentials$
				.pipe(withLatestFrom(this.authRepository.email$))
				.subscribe(([credentials, email]) => {
					if (credentials) {
						this.form.get('username').setValue(email);
						this.form.get('token').setValue(credentials.jiraApiToken);

						this.testConnectionState = TestConnectionState.NotTested;
						this.basicAuthentication = !credentials.token;
					}

					this.onActivate(this.basicAuthentication);
					this.subscribeToFormChanges();
					this.formData.emit(this.form);
					this.dataState.emit(this.testConnectionState);
				})
		);
	}

	onActivate(isFirstOptionSelected: boolean): void {
		if (isFirstOptionSelected && this.form.get('username').value === 'null') {
			this.form.get('username').setValue('');
		} else if (!isFirstOptionSelected) {
			this.form.get('username').setValue('null');
		}
		this.basicAuthentication = isFirstOptionSelected;
		this.updateValidators();
		this.mode.emit(this.basicAuthentication ? APIMode.Password : APIMode.Token);
	}

	testConnection(): void {
		const credentials: ICredentialsPayload = {
			email: this.form.get('username').value as string,
			jiraApiToken: this.form.get(
				this.basicAuthentication ? 'password' : 'token'
			).value as string,
			jiraUrl: this.appConfigRepository.config.jiraURL,
			xrayClientId: 'null',
			xrayClientSecret: 'null',
			token: !this.basicAuthentication,
			gitToken: '',
			gitUsername: ''
		};

		super.testConnection(credentials);
	}

	private updateValidators(): void {
		if (this.basicAuthentication) {
			this.form.get('token').removeValidators(Validators.required);
			this.form.get('password').addValidators(Validators.required);
		} else {
			this.form.get('password').removeValidators(Validators.required);
			this.form.get('token').addValidators(Validators.required);
		}

		this.form.get('password').updateValueAndValidity();
		this.form.get('token').updateValueAndValidity();
	}
}
