/* eslint-disable functional/immutable-data */
import { DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectorRef,
	Component,
	HostListener,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	MatLegacyPaginatorModule,
	MatLegacyPaginator as MatPaginator,
	MatLegacyPaginatorIntl as MatPaginatorIntl,
	LegacyPageEvent as PageEvent
} from '@angular/material/legacy-paginator';
import { DomSanitizer } from '@angular/platform-browser';
import { Screenshot } from '@testifi-models/Screenshot';
import { ScreenshotsPage } from '@testifi-models/paginated-responses';
import { SuiElement } from '@testifi-models/sui-element';
import { XmlElements } from '@testifi-models/xml-elements';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalData, ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import { ScreenshotService } from '@testifi-services/screenshot.service';
import { ModalConfirmComponent } from '@testifi-shared/modal-confirm/modal-confirm.component';
import { CustomPaginator } from '@testifi-shared/paginator/custom-paginator';
import { Utils } from '@testifi-utils/utils';
import { SvgIconComponent } from 'angular-svg-icon';
import { produce } from 'immer';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ModalRouterNameDirective } from '../../directives/modal-router-name.directive';
import { StylePaginatorDirective } from '../../directives/style-paginator.directive';
import { SuiPageService } from './../../services/sui-page.service';

export interface ModalSuiPageSelectScreenshotComponentData {
	suiElement?: SuiElement;
	suiPageId?: string;
	suiElementParameterIndex?: number;
	suiElementParameters?: Map<string, Array<string>>;
	suiRootElement?: SuiElement;
	projectId: string;
}

export const SCREENSHOT_PARAM_KEY = 'screenshot';
export const SCREENSHOT_PAGE_SIZE_ITEM_NAME = 'screenshot.pageSize';
export const SCREENSHOT_COLUMNS_ITEM_NAME = 'screenshot.columns';

@Component({
	selector: 'app-modal-sui-page-select-screenshot',
	templateUrl: './modal-sui-page-select-screenshot.component.html',
	styleUrls: ['./modal-sui-page-select-screenshot.component.less'],
	providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }],
	standalone: true,
	imports: [
		FormsModule,
		MatLegacyPaginatorModule,
		StylePaginatorDirective,
		NgIf,
		NgFor,
		NgClass,
		ExtendedModule,
		ModalRouterNameDirective,
		SvgIconComponent,
		ReactiveFormsModule,
		FlexModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalSuiPageSelectScreenshotComponent
	implements OnInit, OnDestroy
{
	// ======================================================================
	// public properties
	// ======================================================================

	@ViewChild('paginator') paginator: MatPaginator;
	tncForm: UntypedFormGroup = this.formBuilder.group({
		terms: [false]
	});
	submitted = false;
	Utils = Utils;
	window: Window;
	selectedScreenshot: Screenshot = null;
	screenshots: Screenshot[] = [];
	columns = 3;
	pageSize = 10;
	currentPage = 0;
	totalItems = 0;
	SCREENSHOT_PARAM_KEY = SCREENSHOT_PARAM_KEY;

	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private cd: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private notificationService: NotificationService,
		private modalService: ModalService,
		private suiPageService: SuiPageService,
		private screenshotService: ScreenshotService,
		public loadingService: LoadingService,
		public sanitizer: DomSanitizer,
		@Inject(MAT_DIALOG_DATA)
		public data: ModalSuiPageSelectScreenshotComponentData,
		@Inject(DOCUMENT) public document: Document
	) {
		this.window = this.document.defaultView;
	}

	// ======================================================================
	// inherit functions
	// ======================================================================

	ngOnInit(): void {
		const pageSizeStr = this.window.localStorage.getItem(
			SCREENSHOT_PAGE_SIZE_ITEM_NAME
		);
		if (pageSizeStr) {
			this.pageSize = parseInt(pageSizeStr);
		}

		const columnsStr = this.window.localStorage.getItem(
			SCREENSHOT_COLUMNS_ITEM_NAME
		);
		if (columnsStr) {
			this.columns = parseInt(columnsStr);
		}

		this.loadScreenshots();
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	blur(): void {
		// deactivate document active element (checkbox) so paste listener can work when user checks it
		(document.activeElement as HTMLElement).blur();
	}

	@HostListener('document: paste', ['$event.clipboardData.items'])
	onPaste(items: DataTransferItemList): void {
		if (!this.tncForm.get('terms').value) {
			return;
		}

		let imageFile = null;

		for (const item of items) {
			if (item.type.startsWith('image')) {
				imageFile = item.getAsFile();
			}
		}

		if (!imageFile) {
			return;
		}

		this.modalService.open(
			ModalConfirmComponent,
			(confirm) => {
				if (!confirm) {
					return;
				}

				this.uploadFiles(imageFile);
			},
			{
				title: 'Upload image from the clipboard',
				text: 'Are you sure you want to upload image from the clipboard?',
				confirmButton: 'Yes',
				cancelButton: 'No'
			}
		);
	}

	// ======================================================================
	// change functions
	// ======================================================================

	submit(): void {
		this.submitted = true;
		this.data.suiElement = produce(this.data.suiElement, (draft) => {
			draft.parameter[SCREENSHOT_PARAM_KEY] =
				this.selectedScreenshot.id.toString();
		});
		this.updateElement(this.data.suiElement.parameter);
	}

	removeSelection(): void {
		this.submitted = true;
		this.data.suiElement = produce(this.data.suiElement, (draft) => {
			delete draft.parameter[SCREENSHOT_PARAM_KEY];
		});
		this.updateElement(this.data.suiElement.parameter);
	}

	onCancel(): void {
		this.modalService.close(0);
	}

	onFilesSelected(event: Event): void {
		this.tncForm.controls['terms'].setValue(false); // The user has to check the chexbox every time
		const target = event.target as HTMLInputElement;
		this.uploadFiles(target.files);
	}

	onClickScreenshot(screenshot: Screenshot): void {
		this.selectedScreenshot = screenshot;
	}

	onDelete(data: ModalData): void {
		if (!data) {
			return;
		}

		this.loadingService.active();
		this.disposableBag.add(
			this.screenshotService
				.deleteScreenshot(data.screenshotId)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					() => {
						this.selectedScreenshot = null;
						this.loadScreenshots();
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
						this.modalService.close(err.status);
					}
				)
		);
	}

	handlePage(event: PageEvent): void {
		const isPageSizeReallyChanged = this.pageSize !== event.pageSize;
		const isPageReallyChanged = this.currentPage !== event.pageIndex;
		this.pageSize = event.pageSize;
		this.window.localStorage.setItem(
			SCREENSHOT_PAGE_SIZE_ITEM_NAME,
			`${event.pageSize}`
		);
		this.currentPage = event.pageIndex;
		if (isPageSizeReallyChanged || isPageReallyChanged) {
			this.loadScreenshots();
		}
	}

	onColumnsChanged(): void {
		this.window.localStorage.setItem(
			SCREENSHOT_COLUMNS_ITEM_NAME,
			`${this.columns}`
		);
	}

	// ======================================================================
	// private functions
	// ======================================================================
	private handleScreenshotsPage(screenshotsPage: ScreenshotsPage): void {
		this.data.suiElement = produce(this.data.suiElement, (draft) => {
			delete draft.parameter[SCREENSHOT_PARAM_KEY];
		});
		const plural = screenshotsPage.screenShotViewList.length > 1;
		this.notificationService.info(
			`Screenshot${plural ? 's are' : ' is'} uploaded`
		);
		this.selectedScreenshot = screenshotsPage.screenShotViewList[0];

		this.loadScreenshots();
	}

	private loadScreenshots(): void {
		this.loadingService.active();
		this.disposableBag.add(
			this.screenshotService
				.getScreenshotList(
					this.data.projectId,
					'',
					this.pageSize,
					this.currentPage
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(screenshotsPage) => {
						this.screenshots = screenshotsPage.screenShotViewList;
						this.totalItems = screenshotsPage.totalNumberOfElements;
						setTimeout(() => {
							this.paginator?._changePageSize(this.pageSize); // force updating the totalItems
						});
						// check if selection from the response is valid
						if (this.data.suiElement?.parameter[SCREENSHOT_PARAM_KEY]) {
							const selection = this.checkIfSelectedScreenshotStillExist(
								this.data.suiElement.parameter[SCREENSHOT_PARAM_KEY]
							);
							this.selectedScreenshot = selection.length ? selection[0] : null;
						}
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
						this.modalService.close(err.status);
					}
				)
		);
	}

	private checkIfSelectedScreenshotStillExist(
		selectedId: string
	): Screenshot[] {
		return this.screenshots.filter(
			(screenshot) => screenshot.id === selectedId
		);
	}

	private updateElement(parameters: { [key: string]: string }) {
		this.data.suiRootElement = produce(this.data.suiRootElement, (draft) => {
			draft.children = this.data.suiRootElement.updateParameter(
				this.data.suiElement.structureId,
				parameters
			);
		});

		this.disposableBag.add(
			this.suiPageService
				.updateElements(
					this.data.suiPageId,
					new XmlElements().fromSuiElement(this.data.suiRootElement)
				)
				.subscribe(
					() => {
						this.notificationService.info(
							'Screenshot parameter has been changed'
						);
						this.modalService.close(200);
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
						this.modalService.close(err.status);
					}
				)
		);
	}

	private uploadFiles(imageFile: FileList | File) {
		this.loadingService.active();

		this.disposableBag.add(
			this.screenshotService
				.uploadScreenshot(this.data.projectId, imageFile)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(screenshotsPage) => this.handleScreenshotsPage(screenshotsPage),
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
						this.modalService.close(err.status);
					}
				)
		);
	}
}
