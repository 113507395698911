import {
	AsyncPipe,
	DOCUMENT,
	NgClass,
	NgFor,
	NgIf,
	NgStyle
} from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { ScenarioParameter } from '@testifi-models/scenario-parameter';
import { DataService } from '@testifi-services/data.service';
import { GitConnection } from '@testifi-services/git.service';
import { ModalData, ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import {
	GIT_BRANCH_VALIDATORS,
	GIT_USERNAME_VALIDATORS,
	MANDATORY_PARAMETERS_KEY,
	OPTIONAL_PARAMETERS_KEY,
	URLStringValidator,
	VALUE_VALIDATORS
} from '@testifi-shared/app-constants';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { Utils } from '@testifi-utils/utils';
import { SvgIconComponent } from 'angular-svg-icon';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ModalCloseDirective } from '../../directives/modal-close.directive';
import { ParameterListComponent } from '../../shared/parameter-list/parameter-list.component';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';

export enum TestObjectType {
	BuildingBlock,
	TestObject
}

export const gitFormFields = {
	username: [
		{ value: '', disabled: true },
		[...GIT_USERNAME_VALIDATORS, Validators.required]
	],
	password: [
		{ value: '', disabled: true },
		[...VALUE_VALIDATORS, Validators.required]
	],
	url: [
		{ value: '', disabled: true },
		[Validators.required, URLStringValidator]
	],
	branch: [
		{ value: '', disabled: true },
		[...GIT_BRANCH_VALIDATORS, Validators.required]
	],
	skipSSL: [true]
};

const LAST_SELECTED_DEVICE_KEY = 'LAST_SELECTED_DEVICE';

@Component({
	selector: 'app-modal-execute-test-plan',
	templateUrl: './modal-execute-test-plan.component.html',
	styleUrls: ['./modal-execute-test-plan.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		SvgIconComponent,
		NgSelectModule,
		NgFor,
		NgStyle,
		ExtendedModule,
		NgIf,
		ValidationErrorsComponent,
		NgClass,
		FlexModule,
		ParameterListComponent,
		ModalCloseDirective,
		AsyncPipe
	]
})
export class ModalExecuteTestPlanComponent implements OnInit, OnDestroy {
	// ======================================================================
	// public properties
	// ======================================================================
	@ViewChild(NgSelectComponent)
	ngSelect: NgSelectComponent;
	form: UntypedFormGroup;
	submitted = false;
	Utils = Utils;
	parameters: ScenarioParameter[] = [];
	isTokenHidden = true;
	validParameters = true;

	// ======================================================================
	// private properties
	// ======================================================================
	private window: Window;
	private disposableBag = new Subscription();

	// ======================================================================
	// constructor
	// ======================================================================
	constructor(
		public appConfigRepository: AppConfigRepository,
		@Inject(MAT_DIALOG_DATA)
		public data: ModalData,
		private formBuilder: UntypedFormBuilder,
		private credentialRepository: CredentialRepository,
		private notificationService: NotificationService,
		private dataService: DataService,
		private modalService: ModalService,
		private cdr: ChangeDetectorRef,
		private projectRepository: ProjectRepository,
		@Inject(DOCUMENT) document: Document
	) {
		this.window = document.defaultView;
	}

	// ======================================================================
	// getter
	// ======================================================================
	get f(): { [key: string]: AbstractControl } {
		return this.form.controls;
	}

	// ======================================================================
	// inherit functions
	// ======================================================================
	ngOnInit(): void {
		this.form = this.formBuilder.group({
			value: ['', [Validators.required]],
			device: [''],
			...gitFormFields
		});

		if (this.data.scenarioParameters?.length) {
			this.parameters = this.data.scenarioParameters.filter(
				(p) =>
					p.key === MANDATORY_PARAMETERS_KEY ||
					p.key === OPTIONAL_PARAMETERS_KEY
			);
		}

		if (this.data.testPlans.length === 1) {
			setTimeout(() => {
				const item = this.ngSelect.itemsList.findItem(this.data.testPlans[0]);
				this.ngSelect.select(item);
			});
		}

		this.disposableBag.add(
			this.dataService
				.getDeviceList(this.data.projectId)
				.pipe(
					tap((deviceList) => {
						const selectedDevice = this.window.localStorage.getItem(
							LAST_SELECTED_DEVICE_KEY
						);

						if (selectedDevice && deviceList.indexOf(selectedDevice) >= 0) {
							this.f.device.setValue(selectedDevice);
						} else if (deviceList.length) {
							this.f.device.setValue(deviceList[0]);
						}

						this.data.deviceList = deviceList;
					}),
					switchMap(() => this.credentialRepository.credentials$),
					switchMap((credentials) => {
						this.form.get('username').setValue(credentials.gitUsername);
						this.form.get('password').setValue(credentials.gitToken);

						return this.projectRepository.projectSettings$;
					})
				)
				.subscribe(
					(settings) => {
						this.form.get('url').setValue(settings.gitUrl);
						this.form.get('branch').setValue(settings.gitBranch);
						Utils.enableInvalidFormControls(this.form);
						this.cdr.markForCheck();
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	// ======================================================================
	// change functions
	// ======================================================================
	change(): void {
		this.submitted = true;
		const payload: GitConnection = {
			username: this.f.username.value as string,
			password: this.f.password.value as string,
			skipSSLVerify: this.f.skipSSL.value as boolean,
			repoURL: this.f.url.value as string,
			branch: this.f.branch.value as string
		};
		this.modalService.close({
			testPlan: this.f.value.value as string,
			device: this.f.device.value as string,
			gitConnection: payload,
			scenarioParameters: this.parameters
		});

		this.window.localStorage.setItem(
			LAST_SELECTED_DEVICE_KEY,
			this.f.device.value
		);
	}

	isAnyMandatoryParameterInvalid(parameters: ScenarioParameter[]): void {
		this.parameters = parameters;

		for (const parameter of this.parameters) {
			if (parameter.mandatory && !parameter.value) {
				this.validParameters = true;
				return;
			}
		}
		this.validParameters = true;
	}
}
