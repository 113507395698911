import { TestScenario } from '@testifi-models/test-scenario';

export const MOCK_SCENARIO = new TestScenario();
MOCK_SCENARIO.id = '1';
MOCK_SCENARIO.name = 'Scenario 1';
MOCK_SCENARIO.description = 'Description 1';
MOCK_SCENARIO.projectId = '1';
MOCK_SCENARIO.projectKey = '1';
MOCK_SCENARIO.testSteps = [];
MOCK_SCENARIO.createdAt = '2021-09-01T00:00:00.000Z';
MOCK_SCENARIO.updatedAt = '2021-09-01T00:00:00.000Z';
MOCK_SCENARIO.xrayTestKey = 'DEVELOP-1';
MOCK_SCENARIO.jira = {
	reporter: 'Reporter 1',
	status: {
		name: 'PASSED',
		color: '#00FF00'
	},
	assignee: 'Assignee 1',
	components: ['Component 1'],
	labels: ['Label 1']
};
export const MOCK_SCENARIO_1 = new TestScenario();
MOCK_SCENARIO_1.id = '1';
MOCK_SCENARIO_1.name = 'Long Name Long Name Long Name Long Name Long Name ';
MOCK_SCENARIO_1.description =
	'Description 1 Description 1 Description 1 Description 1 Description 1';
MOCK_SCENARIO_1.projectId = '1';
MOCK_SCENARIO_1.projectKey = '1';
MOCK_SCENARIO_1.testSteps = [];
MOCK_SCENARIO_1.createdAt = '2021-09-01T00:00:00.000Z';
MOCK_SCENARIO_1.updatedAt = '2021-09-01T00:00:00.000Z';
MOCK_SCENARIO_1.xrayTestKey = 'DEVELOP-1321';
MOCK_SCENARIO_1.jira = {
	reporter: 'Reporter 1 Long Name Long Name Long Name Long Name Long Name',
	status: {
		name: 'FAILED',
		color: '#ff0000'
	},
	assignee: 'Assignee 1',
	components: ['Component 1'],
	labels: ['Label 1']
};

export const MOCK_SCENARIO_BMW_1 = new TestScenario();
MOCK_SCENARIO_BMW_1.id = '1';
MOCK_SCENARIO_BMW_1.name =
	'Call Center Agent reaches out to customer and schedule an appointment'; // not shown
MOCK_SCENARIO_BMW_1.description =
	'This test scenario describes the outbound process. A call center or service employee at the BMW dealership tries to reach the customer for a lead "assigned in the AWP lead list" and book a service appointment.';
MOCK_SCENARIO_BMW_1.createdAt = '2024-06-11T16:50:00.000Z';
MOCK_SCENARIO_BMW_1.xrayTestKey = 'ISPI-408960';
MOCK_SCENARIO_BMW_1.jira = {
	reporter: 'Unassigned',
	status: {
		name: 'N/A',
		color: '#68778CFF'
	},
	assignee: 'Unassigned',
	components: ['Component 1'],
	labels: ['Label 1']
};

export const MOCK_SCENARIO_BMW_2 = new TestScenario();
MOCK_SCENARIO_BMW_2.id = '2';
MOCK_SCENARIO_BMW_2.name =
	'Scenario: APPOINTMENT SCHEDULING OUTBOUND (LEAD-PROCESSING)';
MOCK_SCENARIO_BMW_2.description =
	'The Service Assistant checks whether new leads have been received and opens a lead. The Service Assistant opens a Service Case based on the lead. The Service Assistant views the contents of the lead. Due items from the lead are stored in the basket.';
MOCK_SCENARIO_BMW_2.createdAt = '2024-07-17T13:28:00.000Z';
MOCK_SCENARIO_BMW_2.xrayTestKey = 'ISPI-411857';
MOCK_SCENARIO_BMW_2.jira = {
	reporter: 'Unassigned',
	status: {
		name: 'FAIL',
		color: '#D45D52FF'
	},
	assignee: 'Unassigned',
	components: ['Component 1'],
	labels: ['Label 1']
};

export const MOCK_SCENARIO_BMW_3 = new TestScenario();
MOCK_SCENARIO_BMW_3.id = '3';
MOCK_SCENARIO_BMW_3.name =
	'SERVICE CONSULTATION (USUAL DOINGS) WITH ORDER OPENING IN THE DMS.';
MOCK_SCENARIO_BMW_3.description =
	'SERVICE CONSULTATION (USUAL DOINGS) WITH ORDER OPENING IN THE DMS.';
MOCK_SCENARIO_BMW_3.createdAt = '2024-07-17T13:29:00.000Z';
MOCK_SCENARIO_BMW_3.xrayTestKey = 'ISPI-411858';
MOCK_SCENARIO_BMW_3.jira = {
	reporter: 'Unassigned',
	status: {
		name: 'FAIL',
		color: '#D45D52FF'
	},
	assignee: 'Unassigned',
	components: ['Component 1'],
	labels: ['Label 1']
};
