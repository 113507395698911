import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestObjectType } from '@testifi-modals/modal-test-object-parameter/modal-test-object-parameter.component';
import { BuildingBlock } from '@testifi-models/building-block';
import { TestObject } from '@testifi-models/test-object';
import { XmlElements } from '@testifi-models/xml-elements';
import { BuildingBlockService } from '@testifi-services/building-block.service';
import { LoadingService } from '@testifi-services/loading.service';
import {
	NestedDragNDropService,
	STRUCTURE_ID_PREFIX
} from '@testifi-services/nested-drag-n-drop.service';
import { NotificationService } from '@testifi-services/notification.service';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { EditTracker, State } from '@testifi-utils/edit.tracker';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { LibraryDragDropPageComponent } from '../../shared/library-drag-drop-page/library-drag-drop-page.component';

interface ModalEditBuildingBlockComponentData {
	buildingBlockId: string;
}

@Component({
	selector: 'app-modal-edit-building-block',
	templateUrl: './modal-edit-building-block.component.html',
	styleUrls: ['./modal-edit-building-block.component.less'],
	providers: [
		NestedDragNDropService,
		{
			provide: STRUCTURE_ID_PREFIX,
			multi: true,
			useValue: TestObject.STRUCTURE_ID_PREFIX
		}
	],
	standalone: true,
	imports: [HideOnLoadingDirective, LibraryDragDropPageComponent]
})
export class ModalEditBuildingBlockComponent implements OnInit, OnDestroy {
	// ======================================================================
	// public properties
	// ======================================================================
	buildingBlockId: string;
	buildingBlock: BuildingBlock;
	testObjectType = TestObjectType;
	forbiddenEvent = new Subject<void>();
	editTracker = new EditTracker<BuildingBlock>(this.appConfigRepository);

	// ======================================================================
	// private properties
	// ======================================================================
	private disposableBag = new Subscription();

	// ======================================================================
	// constructor
	// ======================================================================
	constructor(
		private cd: ChangeDetectorRef,
		private buildingBlockService: BuildingBlockService,
		private notificationService: NotificationService,
		private nestedDnDService: NestedDragNDropService,
		private loadingService: LoadingService,
		private appConfigRepository: AppConfigRepository,
		@Inject(MAT_DIALOG_DATA) public data: ModalEditBuildingBlockComponentData
	) {}

	ngOnInit(): void {
		this.buildingBlockId = this.data.buildingBlockId;
		this.refresh();
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	onLibraryDrop(root: TestObject): void {
		this.updateBlock(root, 'Test Object was created');
	}

	onChangePosition(root: TestObject): void {
		this.updateBlock(root, 'The building block was updated');
	}

	// ======================================================================
	// private functions
	// ======================================================================
	private updateBlock(root: TestObject, notificationMessage: string): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.buildingBlockService
				.updateTestObjects(
					this.buildingBlockId,
					new XmlElements().fromTestObject(root)
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					() => {
						this.notificationService.info(notificationMessage);
						this.refresh();
					},
					(err: HttpErrorResponse) => {
						// refresh after error in order that order is correct
						this.notificationService.httpError(err);
						this.refresh();
					}
				)
		);
	}

	private refresh(): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.buildingBlockService
				.view(this.buildingBlockId)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(buildingBlock) => {
						this.buildingBlock = buildingBlock;

						const undoState = new State<BuildingBlock>(
							buildingBlock,
							this.nestedDnDService.expandedItemIds
						);
						this.editTracker.addState(undoState);
					},
					(err: HttpErrorResponse) => {
						this.notificationService.httpError(err);
					}
				)
		);
	}
}
