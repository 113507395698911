/* eslint-disable functional/immutable-data */
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { ValidatorFn } from '@angular/forms';
import { LabelItemView } from '@testifi-models/label-item-view';
import { produce } from 'immer';
import { has } from 'lodash-es';
import { LabelEditorComponent } from './label-editor/label-editor.component';
import { LabelItemComponent } from './label-item/label-item.component';

@Component({
	selector: 'app-label-list',
	templateUrl: './label-list.component.html',
	styleUrls: ['./label-list.component.less'],
	standalone: true,
	imports: [
		FlexModule,
		NgClass,
		ExtendedModule,
		NgIf,
		LabelEditorComponent,
		NgFor,
		LabelItemComponent
	]
})
export class LabelListComponent implements OnChanges {
	@Input() labelItemList: LabelItemView[] = [];
	@Input() validators: ValidatorFn[];
	@Input() labelBgColor = '';

	@Output() contentChanged = new EventEmitter<LabelItemView[]>();

	editedKey = ''; // empty string when not editing, and the key of the edited property otherwise

	existingKeys: string[] = [];

	isAddNew = false;

	ngOnChanges(changes: SimpleChanges): void {
		if (has(changes, 'labelItemList')) {
			this.sortParams();
		}
	}

	onAddClick(): void {
		this.isAddNew = true;
		this.editedKey = null;
	}

	onEditItem(sParam: LabelItemView): void {
		this.isAddNew = false;
		this.editedKey = sParam.idLabelItem;
		this.existingKeys = this.getExistingKeys(this.editedKey);
	}

	onCancelEdit(): void {
		this.isAddNew = false;
		this.editedKey = null;
		this.existingKeys = this.getExistingKeys('');
	}

	onSubmitEdit(sParam: LabelItemView): void {
		const labelItemList = produce(this.labelItemList, (draft) => {
			if (this.isAddNew) {
				draft.push(sParam);
			} else {
				for (let index = 0; index < draft.length; index++) {
					if (draft[index].idLabelItem === this.editedKey) {
						draft[index] = sParam;
					}
				}
			}
		});
		this.onCancelEdit();
		this.contentChanged.emit(labelItemList);
	}

	onRemoveItem(event: LabelItemView): void {
		this.labelItemList = this.labelItemList.filter(
			(parameter) => parameter.idLabelItem !== event.idLabelItem
		);
		this.contentChanged.emit(this.labelItemList);
	}

	private sortParams(): void {
		this.labelItemList = produce(this.labelItemList, (draft) => {
			draft.sort((a: LabelItemView, b: LabelItemView) =>
				a.labelText.toLowerCase() < b.labelText.toLowerCase() ? -1 : 1
			);
		});

		this.existingKeys = this.getExistingKeys('');
	}

	private getExistingKeys(except: string): string[] {
		let result: string[] = [];
		for (const parameter of this.labelItemList) {
			if (parameter.idLabelItem !== except) {
				result = [...result, parameter.idLabelItem];
			}
		}
		return result;
	}
}
