<div class="editor">
	<form class="aui">
		<div class="p-table">
			<div class="p-table-row">
				<div class="p-table-cell padding-right-8">
					<input
						#labelItemFilter
						class="search-entry text medium-field"
						id="labelItemList"
						type="search"
						(search)="onLabelItemClear()"
						[formControl]="searchLabelItemsControl"
						[matAutocomplete]="autoLabel"
						placeholder=""
					/>
					<mat-autocomplete
						class="autocomplete-panel"
						#autoLabel="matAutocomplete"
						[displayWith]="getTitle.bind(this)"
						(optionSelected)="onLabelItemOptionSelected($event)"
					>
						<mat-option
							class="autocomplete-option"
							*ngFor="let option of filteredLabelItemList$ | async"
							[value]="option.idLabelItem"
						>
							{{option.labelText}}
						</mat-option>
					</mat-autocomplete>
					<div class="p-table-cell">
						<button type="button" class="cancel-btn" (click)="cancel.emit()" title="Click to cancel edit.">
							<svg-icon name="icon-x-dark"></svg-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
