import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { HomeEffect } from '@testifi-store/home/home.effect';
import { HomeRepository } from '@testifi-store/home/home.repository';

@Component({
	selector: 'app-last-browsed-business-journeys',
	standalone: true,
	imports: [CommonModule, MatIconModule],
	templateUrl: './last-browsed-business-journeys.component.html',
	styleUrls: ['./last-browsed-business-journeys.component.less']
})
export class LastBrowsedBusinessJourneysComponent implements OnInit {
	private readonly homeRepository = inject(HomeRepository);
	private readonly homeEffect = inject(HomeEffect);

	lastBrowsedBusinessJourneys$ =
		this.homeRepository.lastBrowsedBusinessJourneys$;
	lastBrowsedBusinessJourneysLoading$ =
		this.homeRepository.lastBrowsedBusinessJourneysLoading$;

	ngOnInit() {
		this.homeEffect.getLastBrowsedBusinessJourneys();
	}
}
