<section
	id="modal-add-test-scenario"
	class="aui-dialog2 aui-dialog2-medium"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-add-test-scenario--heading"
>
	<form class="aui" [formGroup]="form" (ngSubmit)="add()">
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-add-test-scenario--heading">Create Test (Scenario)</h2>
		</header>
		<!-- Main dialog content -->
		<div class="aui-dialog2-content">
			<div class="field-group">
				<label for="test-scenario-name">Summary <span class="aui-icon icon-required">(required)</span></label>
				<input
					class="text medium-field"
					type="text"
					formControlName="name"
					id="test-scenario-name"
					placeholder="Summary"
				/>
				<app-validation-errors [control]="f.name"></app-validation-errors>
				<div class="description">Max. 200 characters.</div>
			</div>
			<div class="field-group">
				<label for="test-scenario-description">Description</label>
				<textarea
					class="textarea"
					formControlName="description"
					id="test-scenario-description"
					placeholder="Description here..."
				></textarea>
				<app-validation-errors [control]="f.description"></app-validation-errors>
				<div class="description">Max. 1000 characters.</div>
			</div>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<div class="aui-dialog2-footer-actions">
				<button type="submit" id="create-submit" class="aui-button aui-button-primary" [disabled]="!form.valid">
					Create
				</button>
				<button type="button" id="cancel" class="aui-button aui-button-subtle" modalClose>Cancel</button>
			</div>
		</footer>
	</form>
</section>
