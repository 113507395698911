import { Routes } from '@angular/router';
import { UserGuard } from '@testifi-guards/user.guard';
import { ModalAddBuildingBlockComponent } from '@testifi-modals/modal-add-building-block/modal-add-building-block.component';
import { ModalAddSuiPageComponent } from '@testifi-modals/modal-add-sui-page/modal-add-sui-page.component';
import { ModalAddTestStepComponent } from '@testifi-modals/modal-add-test-step/modal-add-test-step.component';
import { ModalEditBuildingBlockComponent } from '@testifi-modals/modal-edit-building-blocks/modal-edit-building-block.component';
import { ModalEditSuiPageElementsElementChangeNameComponent } from '@testifi-modals/modal-edit-sui-page-elements-element-change-name/modal-edit-sui-page-elements-element-change-name.component';
import { ModalEditSuiPageElementsComponent } from '@testifi-modals/modal-edit-sui-page-elements/modal-edit-sui-page-elements.component';
import { ModalSuiPageElementParameterComponent } from '@testifi-modals/modal-sui-page-element-parameter/modal-sui-page-element-parameter.component';
import { ModalSuiPageSelectScreenshotComponent } from '@testifi-modals/modal-sui-page-select-screenshot/modal-sui-page-select-screenshot.component';
import { ModalTestObjectParameterComponent } from '@testifi-modals/modal-test-object-parameter/modal-test-object-parameter.component';
import { PageCustomBuildingBlocksComponent } from '@testifi-pages/page-custom-building-blocks/page-custom-building-blocks.component';
import { PageEnabledProjectsComponent } from '@testifi-pages/page-enabled-projects/page-enabled-projects.component';
import { PageHomeComponent } from '@testifi-pages/page-home/page-home.component';
import { PageProjectDeniedComponent } from '@testifi-pages/page-project-denied/page-project-denied.component';
import { PageProjectSettingsComponent } from '@testifi-pages/page-project-settings/page-project-settings.component';
import { PageViewProjectSuiPagesComponent } from '@testifi-pages/page-view-project-sui-pages/page-view-project-sui-pages.component';
import { PageViewTestScenarioComponent } from '@testifi-pages/page-view-test-scenario/page-view-test-scenario.component';
import { PAGES } from '@testifi-shared/app-constants';
import { ModalAddTestScenarioComponent } from './modals/modal-add-test-scenario/modal-add-test-scenario.component';
import { ModalEditTestStepComponent } from './modals/modal-edit-test-step/modal-edit-test-step.component';
import { PageCredentialsSettingsComponent } from './pages/page-credentials-settings/page-credentials-settings.component';
import { PageViewProjectComponent } from './pages/page-view-project/page-view-project.component';
import { PrivateRoutesResolver } from './resolvers/private-routes.resolver';
import { ModalConfirmComponent } from './shared/modal-confirm/modal-confirm.component';

/*

  user header: false to hide the header
*/

const homeRoute = '';

export const APP_ROUTES: Routes = [
	...[
		// pages
		{
			//path: 'projects/view/:projectId',
			path: homeRoute,
			component: PageHomeComponent,
			canActivate: [UserGuard],
			data: { name: PAGES.LANDING },
			resolve: {
				projectId: PrivateRoutesResolver.getProjectInfoResolver(),
				projectSettings: PrivateRoutesResolver.getProjectSettings()
			}
		},
		{
			path: 'projects/:projectId/test-scenarios',
			component: PageViewProjectComponent,
			canActivate: [UserGuard],
			data: { name: PAGES.SCENARIOS },
			resolve: {
				projectId: PrivateRoutesResolver.getProjectInfoResolver(),
				projectSettings: PrivateRoutesResolver.getProjectSettings()
			}
		},
		{
			path: 'projects/:projectId/sui-pages',
			component: PageViewProjectSuiPagesComponent,
			canActivate: [UserGuard],
			data: { name: PAGES.SUI },
			resolve: { projectId: PrivateRoutesResolver.getProjectInfoResolver() }
		},
		{
			path: 'projects/:projectId/custom-building-blocks',
			component: PageCustomBuildingBlocksComponent,
			canActivate: [UserGuard],
			data: { name: PAGES.BUILDING_BLOCK },
			resolve: { projectId: PrivateRoutesResolver.getProjectInfoResolver() }
		}
	],
	// Test Steps
	...[
		// modals
		{
			path: 'test-steps/edit/:testScenarioId',
			component: ModalEditTestStepComponent,
			canActivate: [UserGuard],
			data: { name: 'test_steps_edit' }
		},
		{
			path: 'test-steps/add/:testScenarioId',
			component: ModalAddTestStepComponent,
			canActivate: [UserGuard],
			data: { name: 'test_steps_add' }
		}
	],
	// SuiPage
	...[
		{
			path: 'sui-pages/add',
			component: ModalAddSuiPageComponent,
			canActivate: [UserGuard],
			data: { name: 'sui_pages_add' }
		},
		{
			path: 'sui-page-elements/edit/:projectId',
			component: ModalEditSuiPageElementsComponent,
			canActivate: [UserGuard],
			data: { name: 'sui_pages_elements_edit' }
		}
	],

	// SuiElements
	...[
		{
			path: 'sui-elements/change/:elementId',
			component: ModalEditSuiPageElementsElementChangeNameComponent,
			canActivate: [UserGuard],
			data: { name: 'sui_elements_change' }
		},
		{
			path: 'sui-elements/parameter/:elementId',
			component: ModalSuiPageElementParameterComponent,
			canActivate: [UserGuard],
			data: { name: 'sui_elements_parameter' }
		},
		{
			path: 'sui-elements/parameter/:elementId',
			component: ModalSuiPageSelectScreenshotComponent,
			canActivate: [UserGuard],
			data: { name: 'sui_elements_screenshot' }
		}
	],
	// Test Objects
	...[
		// modals
		{
			path: 'test-objects/parameter/:testObjectId',
			component: ModalTestObjectParameterComponent,
			canActivate: [UserGuard],
			data: { name: 'test_objects_parameter' }
		}
	],
	// Building Blocks
	...[
		// modals
		{
			path: 'building-blocks/edit/:buildingBlockId',
			component: ModalEditBuildingBlockComponent,
			canActivate: [UserGuard],
			data: { name: 'building_blocks_edit' }
		},
		{
			path: 'building-blocks/add',
			component: ModalAddBuildingBlockComponent,
			canActivate: [UserGuard],
			data: { name: 'building_blocks_add' }
		}
		// pages
	],
	// Test Scenario
	...[
		// pages
		{
			path: 'test-scenarios/view/:testScenarioId',
			component: PageViewTestScenarioComponent,
			canActivate: [UserGuard],
			data: { name: PAGES.SCENARIO },
			resolve: {
				projectId: PrivateRoutesResolver.getProjectInfoResolver(),
				projectSettings: PrivateRoutesResolver.getProjectSettings()
			}
		},
		// modals
		{
			path: 'test-scenarios/add/:projectId',
			component: ModalAddTestScenarioComponent,
			canActivate: [UserGuard],
			data: { name: 'test_scenarios_add' }
		},
		// Misc
		...[
			{
				path: 'confirm-popup',
				component: ModalConfirmComponent,
				canActivate: [UserGuard],
				data: { name: 'confirm-popup' }
			},
			{
				path: 'settings/credentials',
				component: PageCredentialsSettingsComponent,
				canActivate: [],
				data: { name: PAGES.CREDENTIALS }
			},
			{
				path: 'settings/project',
				component: PageProjectSettingsComponent,
				canActivate: [UserGuard],
				resolve: {
					projectId: PrivateRoutesResolver.getProjectInfoResolver(),
					settings: PrivateRoutesResolver.getProjectSettings()
				},
				data: { name: PAGES.SETTINGS }
			},
			{
				path: 'project-denied',
				component: PageProjectDeniedComponent,
				canActivate: [],
				data: { name: 'project-denied' }
			},
			{
				path: 'enabled-projects-admin',
				component: PageEnabledProjectsComponent,
				canActivate: [UserGuard],
				data: { name: 'enabled-projects-admin' }
			},
			{
				path: 'login',
				redirectTo: 'settings/credentials',
				data: { name: 'empty' }
			},
			{ path: '**', redirectTo: homeRoute, data: { name: 'empty' } }
		]
	]
];
