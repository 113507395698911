<section
	id="modal-sui-page-element-parameter"
	class="aui-dialog2 aui-dialog2-small"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-sui-page-element-parameter--heading"
>
	<form class="aui" [formGroup]="form" (ngSubmit)="submit()">
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-sui-page-element-parameter--heading">
				{{ isNew ? 'Add' : 'Change' }} Parameter
			</h2>
		</header>
		<!-- Main dialog content -->
		<div class="aui-dialog2-content">
			<div class="field-group">
				<label for="value">Type</label>
				<div ngSelectEvents>
					<ng-select
						#typeSelection
						[clearable]="false"
						class="dropdown"
						formControlName="type"
						[editableSearchTerm]="true"
						notFoundText="No matches"
						(change)="onTypeChange($event)"
						(search)="onSearch($event)"
						[class.is-invalid]="submitted && f.type.errors"
						[inputAttrs]="{ 'class': 'text', 'onClick': 'this.setSelectionRange(0, this.value.length)' }"
					>
						<ng-option *ngFor="let type of parameterTypes" [value]="type">{{ type }}</ng-option>
					</ng-select>
				</div>
				<app-validation-errors [control]="f.type"></app-validation-errors>
			</div>

			<ng-template *ngIf="isAttributeActive; then attributeSelection; else typeValueSelection"></ng-template>

			<ng-template #attributeSelection>
				<div class="field-group">
					<label for="attributeType">Attribute</label>
					<!-- autocomplete for custom attribute  -->
					<div class="autocomplete-text">
						<input
							#inp="matAutocompleteTrigger"
							type="text"
							title="Attribute"
							[formControl]="f.attribute"
							[matAutocomplete]="auto"
							class="text"
							(focus)="focused(inp)"
							onClick="this.setSelectionRange(0, this.value.length)"
							[class.is-invalid]="submitted && f.attribute.errors"
							[readonly]="true"
						/>
						<span class="arrow-wrapper pointer" (click)="onAutocompleteArrowClick($event)">
							<span class="arrow"></span>
						</span>
					</div>
					<mat-autocomplete #auto="matAutocomplete" class="autocomplete-panel">
						<mat-option
							[class.mat-selected-default]="f.attribute.value === type"
							*ngFor="let type of attributeTypes"
							class="autocomplete-option"
							[value]="type"
						>
							{{type}}
						</mat-option>
					</mat-autocomplete>
					<app-validation-errors [control]="f.attribute"></app-validation-errors>
				</div>
				<div class="field-group">
					<label for="value">Value</label>
					<input
						#valueInput
						class="text large-field"
						type="text"
						formControlName="value"
						title="Value"
						[class.is-invalid]="submitted && f.value.errors"
						required
					/>
					<app-validation-errors [control]="f.value"></app-validation-errors>
				</div>
			</ng-template>
			<ng-template #typeValueSelection>
				<div class="field-group">
					<label for="value">Value</label>

					<ng-template *ngIf="values.length > 1; then dropdown; else textfield"></ng-template>

					<ng-template #dropdown>
						<select
							class="select"
							id="value"
							name="value"
							formControlName="value"
							[class.is-invalid]="submitted && f.value.errors"
						>
							<option *ngFor="let value of values" value="{{ value }}">{{ value }}</option>
						</select>
					</ng-template>

					<ng-template #textfield>
						<input
							#valueInput
							class="text large-field"
							type="text"
							formControlName="value"
							title="Value"
							[class.is-invalid]="submitted && f.value.errors"
							required
						/>
					</ng-template>

					<app-validation-errors [control]="f.value"></app-validation-errors>
				</div>
			</ng-template>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<div class="aui-dialog2-footer-actions">
				<button
					id="create-submit"
					type="submit"
					class="aui-button aui-button-primary"
					[disabled]="form.invalid || submitted"
				>
					{{ isNew ? 'Create' : 'Update' }}
				</button>
				<button type="button" id="cancel" class="aui-button aui-button-subtle" (click)="onCancel()">Cancel</button>
			</div>
			<div class="aui-dialog2-footer-hint">
				<button
					id="parameter-delete"
					type="button"
					class="aui-button aui-button-link color-R300"
					*ngIf="!isNew"
					(click)="onDelete()"
				>
					Delete Parameter
				</button>
			</div>
		</footer>
	</form>
</section>
