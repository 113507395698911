import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuth } from '@testifi-models/auth.interface';
import { ICredentialsPayload } from '@testifi-models/credentials.interface';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PublicHttpClient } from '../https/instances/public.http';
import { User } from '../models/user';
import { BaseApiService } from './base-api.service';

export interface SSOPayload {
	jiraToken: string;
	jiraUrl: string;
	ssoJwtToken: string;
	groups: string[];
}

@Injectable({ providedIn: 'root' })
export class UserService {
	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private publicHttp: PublicHttpClient,
		private base: BaseApiService,
		private credentialRepository: CredentialRepository
	) {}

	// ======================================================================
	// public functions
	// ======================================================================

	login(credentials: ICredentialsPayload): Observable<IAuth> {
		return this.publicHttp.post<IAuth>('login', credentials);
	}

	loginSSO(payload: SSOPayload): Observable<IAuth> {
		return this.publicHttp.post<IAuth>('loginWithSSO', payload);
	}

	testCredentials(credentials: ICredentialsPayload): Observable<User> {
		return this.publicHttp.post<User>('user/validateUser', credentials);
	}

	/**
	 * There are some spec usage should be removed
	 * @deprecated
	 */
	validateOrRenew(user: IAuth): Observable<IAuth> {
		if (!user) {
			return this.renew();
		}

		return this.publicHttp
			.post<User>('user/validate', {
				email: user.email,
				jwt: user.jwt
			})
			.pipe(switchMap((isValid) => (isValid ? of(user) : this.renew())));
	}

	validateToken({ email, jwt }: IAuth) {
		return this.publicHttp.post<boolean>('user/validate', {
			email,
			jwt
		});
	}

	register(
		email: string,
		firstname: string,
		lastname: string,
		password: string,
		passwordRepeated: string
	): Observable<User> {
		return this.publicHttp
			.post<User>('registration', {
				email,
				firstname,
				lastname,
				password,
				passwordRepeated
			})
			.pipe(map((res) => this.base.model(res, User)));
	}

	/**
	 * Your description
	 * @deprecated
	 */
	renew(): Observable<IAuth> {
		return this.credentialRepository.credentials$.pipe(
			switchMap((credentials: ICredentialsPayload) => {
				if (credentials) {
					return this.login(credentials);
				} else {
					return throwError('Credentials not found');
				}
			}),
			catchError((err: HttpErrorResponse) => throwError(err))
		);
	}
}
