import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { THomeRepository } from '@testifi-store/home/home.repository.type';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HomeRepository {
	static readonly INITIAL_VALUE: THomeRepository = {
		counts: {
			data: {
				cbbs: 0,
				scenarios: 0,
				testPlans: 0,
				suiPages: 0
			},
			loading: false,
			error: ''
		},
		lastBrowsedBusinessJourneys: {
			loading: false,
			error: '',
			data: []
		},
		lastExecutedScenarios: {
			loading: false,
			error: '',
			data: []
		}
	};

	private readonly store = createStore(
		{ name: 'home' },
		withProps<THomeRepository>(HomeRepository.INITIAL_VALUE)
	);

	countLoading$: Observable<boolean> = this.store.pipe(
		select((state) => state.counts.loading)
	);

	lastBrowsedBusinessJourneysLoading$: Observable<boolean> = this.store.pipe(
		select((state) => state.lastBrowsedBusinessJourneys.loading)
	);

	lastExecutedScenariosLoading$: Observable<boolean> = this.store.pipe(
		select((state) => state.lastExecutedScenarios.loading)
	);

	counts$: Observable<THomeRepository['counts']['data']> = this.store.pipe(
		select((state) => state.counts.data)
	);

	lastBrowsedBusinessJourneys$: Observable<
		THomeRepository['lastBrowsedBusinessJourneys']['data']
	> = this.store.pipe(
		select((state) => state.lastBrowsedBusinessJourneys.data)
	);

	lastExecutedScenarios$: Observable<
		THomeRepository['lastExecutedScenarios']['data']
	> = this.store.pipe(select((state) => state.lastExecutedScenarios.data));

	setCountsLoading(loading: boolean) {
		this.store.update((state) => ({
			...state,
			counts: {
				...state.counts,
				loading
			}
		}));
	}

	setCounts(counts: THomeRepository['counts']['data']) {
		this.store.update((state) => ({
			...state,
			counts: {
				...state.counts,
				data: counts
			}
		}));
	}

	setLastBrowsedBusinessJourneysLoading(loading: boolean) {
		this.store.update((state) => ({
			...state,
			lastBrowsedBusinessJourneys: {
				...state.lastBrowsedBusinessJourneys,
				loading
			}
		}));
	}

	setLastBrowsedBusinessJourneys(
		data: THomeRepository['lastBrowsedBusinessJourneys']['data']
	) {
		this.store.update((state) => ({
			...state,
			lastBrowsedBusinessJourneys: {
				...state.lastBrowsedBusinessJourneys,
				data
			}
		}));
	}

	setLastExecutedScenariosLoading(loading: boolean) {
		this.store.update((state) => ({
			...state,
			lastExecutedScenarios: {
				...state.lastExecutedScenarios,
				loading
			}
		}));
	}

	setLastExecutedScenarios(
		data: THomeRepository['lastExecutedScenarios']['data']
	) {
		this.store.update((state) => ({
			...state,
			lastExecutedScenarios: {
				...state.lastExecutedScenarios,
				data
			}
		}));
	}
}
