import { NgClass } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { SvgIconComponent } from 'angular-svg-icon';

export interface LongTextExpansionState {
	id: string;
	state: boolean;
}

@Component({
	selector: 'app-long-text',
	templateUrl: './long-text.component.html',
	styleUrls: ['./long-text.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FlexModule, NgClass, ExtendedModule, SvgIconComponent]
})
export class LongTextComponent implements AfterViewInit, OnChanges {
	@Input() id = '';
	@Input() text = '';
	@Input() expansionState: LongTextExpansionState;
	@Input() shouldExpand = false; // signal to expand when there is no button
	@Input() showButton = true;
	@Input() topPadding = false;

	@Output() stateChanged = new EventEmitter<boolean>();
	@Output() longTextPresent = new EventEmitter<boolean>();

	@ViewChild('textDiv') textDiv: ElementRef<HTMLInputElement>;

	isExpanded = false;
	isLong = false;

	@HostListener('window:resize', ['$event'])
	onResize(): void {
		this.checkTextOversized();
	}

	ngAfterViewInit(): void {
		this.checkTextOversized();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['expansionState']) {
			if (this.id === this.expansionState?.id) {
				this.isExpanded = this.expansionState.state;
			}
		}
	}

	onButtonClick($event: Event): void {
		this.isExpanded = !this.isExpanded;
		this.stateChanged.emit(this.isExpanded);
		$event.stopPropagation();
	}

	checkTextOversized(): void {
		if (!this.isExpanded) {
			const element = this.textDiv.nativeElement;
			const isLong = element.scrollWidth > element.offsetWidth;
			this.longTextPresent.emit(isLong);
		}
	}
}
