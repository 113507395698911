<div
	id="readonly"
	class="multilineeditor__readonly"
	*ngIf="!editMode"
	(click)="toggleEditMode()"
	[ngClass]="{'multilineeditor__break-words': breakWords}"
>
	<span
		[ngStyle]="{'max-width.px': maxWidth ? maxWidth : ''  }"
		[style.color]="textColor"
		class="multilineeditor__label"
		>{{ (control.value || defaultText) }}</span
	>
</div>
<div *ngIf="editMode">
	<textarea
		#textAreaElement
		title="Editor"
		class="multilineeditor__text textarea"
		type="text"
		[style.color]="textColor"
		[placeholder]="defaultText"
		[style.height.px]="height"
		[ngStyle]="{'max-width.px': maxWidth ? maxWidth : ''  }"
		[formControl]="control"
		id="name"
		[autofocus]="true"
	></textarea>
	<app-validation-errors [control]="control"></app-validation-errors>
</div>
