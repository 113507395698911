import { Pipe, PipeTransform } from '@angular/core';
import { Parameter } from '@testifi-models/parameter';
import { TestStepLibrary } from '@testifi-models/test-step-library';
import { Utils } from '@testifi-utils/utils';

@Pipe({
	name: 'shouldShowAddNewTestObjectParameter',
	standalone: true
})
export class ShouldShowAddNewTestObjectParameterPipe implements PipeTransform {
	transform(
		testObjectParameters: Parameter[],
		libraryTag: string,
		libraries: Map<string, TestStepLibrary[]>
	): boolean {
		const library = Utils.getLibrary(libraryTag, libraries);
		const libraryParameters: string[] = [
			...Object.keys(library.mandatoryFields).map((field) =>
				Utils.getItemName(field)
			),
			...Object.keys(library.optionalFields).map((field) =>
				Utils.getItemName(field)
			)
		];
		return (
			libraryParameters.filter(
				(libraryParameter) =>
					!testObjectParameters.some(
						(testObjectParameter) =>
							testObjectParameter.name === libraryParameter
					)
			).length > 0
		);
	}
}
