import { Injectable } from '@angular/core';
import {
	NgbDateParserFormatter,
	NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { DATE_DELIMITER } from '@testifi-shared/app-constants';

@Injectable()
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
	readonly DELIMITER = DATE_DELIMITER;

	parse(value: string): NgbDateStruct | null {
		if (value) {
			const date = value.split(this.DELIMITER);
			return {
				day: parseInt(date[0], 10),
				month: parseInt(date[1], 10),
				year: parseInt(date[2], 10)
			};
		}
		return null;
	}

	format(date: NgbDateStruct | null): string {
		if (date) {
			const year = `${date.year}`;
			const month =
				date.month.toString().length === 1 ? `0${date.month}` : `${date.month}`;
			const day =
				date.day.toString().length === 1 ? `0${date.day}` : `${date.day}`;
			return `${day}${this.DELIMITER}${month}${this.DELIMITER}${year}`;
		} else {
			return '';
		}
	}
}
