<section
	id="modal-execute-test-plan"
	class="aui-dialog2 aui-dialog2-medium"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-test-object-parameter--heading"
>
	<form class="aui" [formGroup]="form" (ngSubmit)="change()">
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-test-object-parameter--heading">{{ data?.title }}</h2>
		</header>
		<div id="mainEditorArea">
			<div id="dotted-horizontal-bar-div">
				<svg-icon name="dotted-horizontal-bar"></svg-icon>
			</div>
			<!-- Main dialog content -->
			<div class="aui-group">
				<div class="aui-item">
					<div class="aui-dialog2-content">
						<div class="field-group">
							<label for="value">Select test plan</label>
							<ng-select
								id="value"
								name="value"
								formControlName="value"
								class="dropdown custom"
								[searchable]="false"
								[clearable]="false"
								><ng-container *ngFor="let plan of data.testPlans; let i = index">
									<ng-option [value]="plan"
										><div [ngStyle]="plan.isWithoutTestPlan && {'font-weight': 500}">
											<span class="spacer-right">-</span>
											<a
												href="{{ (appConfigRepository.jiraUrl$ | async) + '/browse/' + plan?.xrayTestPlanKey }}"
												target="_blank"
												rel="noopener"
											>
												{{ plan?.xrayTestPlanKey }}
											</a>
											{{plan.summary}}
										</div>
									</ng-option>
									<ng-option *ngIf="data.adhocExist && data.testPlans.length > 1 && i === 0" [disabled]="true">
										<span class="separator"> ------------------------------------------------ </span>
									</ng-option>
								</ng-container>
							</ng-select>
							<app-validation-errors [control]="f.value"></app-validation-errors>
						</div>
						<div class="field-group">
							<label for="device">Select device</label>
							<ng-select
								id="device"
								name="device"
								formControlName="device"
								class="dropdown custom"
								[searchable]="false"
								[clearable]="false"
								><ng-container *ngFor="let device of data.deviceList">
									<ng-option [value]="device">
										<div>{{ Utils.formatDeviceName(device) }}</div>
									</ng-option>
								</ng-container>
							</ng-select>
						</div>
						<div class="field-group margin-top">
							<label for="username">GIT Username</label>
							<input
								id="username"
								formControlName="username"
								placeholder="GIT Username"
								class="input-text"
								type="text"
							/>
							<app-validation-errors [control]="f.username"></app-validation-errors>
						</div>
						<div class="field-group">
							<label for="password">GIT Token</label>
							<input
								id="password"
								formControlName="password"
								placeholder="GIT Token"
								class="input-text"
								[ngClass]="{'input-text-security': isTokenHidden}"
								type="text"
								onCopy="return false"
								onCut="return false"
								onselectstart="return false"
								onDrag="return false"
								autocomplete="off"
							/>
							<svg-icon
								name="{{ isTokenHidden ? 'eye-on' : 'eye-off' }}"
								class="input-text-icon"
								title="Show token"
								[svgStyle]="{'width.px': 16, 'height.px': 16}"
								(click)="isTokenHidden = !isTokenHidden"
							></svg-icon>

							<app-validation-errors [control]="f.password"></app-validation-errors>
						</div>
						<div class="field-group">
							<label for="url">GIT URL</label>
							<input class="input-text" type="text" formControlName="url" id="url" placeholder="GIT URL" />
							<app-validation-errors [control]="f.url"></app-validation-errors>
						</div>
						<div class="field-group">
							<label for="url">GIT branch</label>
							<input class="input-text" type="text" formControlName="branch" id="branch" placeholder="GIT branch" />
							<app-validation-errors [control]="f.branch"></app-validation-errors>
						</div>
						<div class="field-group" fxLayout fxLayoutAlign="start center" fxLayoutGap="16px">
							<label for="skipSSL">Skip SSL verification</label>
							<input
								class="checkbox"
								type="checkbox"
								name="skipSSL"
								id="skipSSL"
								formControlName="skipSSL"
								[checked]="f.skipSSL"
							/>
						</div>
					</div>
				</div>
				<div class="aui-item">
					<div class="module" name="Parameters">
						<div class="mod-header params-margin">
							<h4>Execution Parameters</h4>
						</div>
						<app-parameter-list
							[parameterList]="parameters"
							(contentChanged)="isAnyMandatoryParameterInvalid($event)"
						></app-parameter-list>
					</div>
				</div>
			</div>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<div class="aui-dialog2-footer-actions">
				<button type="submit" class="aui-button aui-button-primary" [disabled]="form.invalid || !validParameters">
					Execute
				</button>
				<button type="button" class="aui-button aui-button-subtle" modalClose>Cancel</button>
			</div>
		</footer>
	</form>
</section>
