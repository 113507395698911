import { Injectable, NgZone } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import { ICredentialsPayload } from '@testifi-models/credentials.interface';
import { NotificationService } from '@testifi-services/notification.service';
import { RouterService } from '@testifi-services/router.service';
import { UserService } from '@testifi-services/user.service';
import { PAGES } from '@testifi-shared/app-constants';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { AuthRepository } from '@testifi-store/auth/auth.repository';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import {
	EMPTY,
	Observable,
	catchError,
	combineLatest,
	map,
	of,
	switchMap,
	take,
	withLatestFrom
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserGuard implements CanActivate {
	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private userService: UserService,
		private routerService: RouterService,
		private notificationService: NotificationService,
		private zone: NgZone,
		private appConfigRepository: AppConfigRepository,
		private authRepository: AuthRepository,
		private credentialRepository: CredentialRepository
	) {}

	// =====================================================================
	// inherit functions
	// ======================================================================

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> {
		return this.authRepository.persistInitialized$.pipe(take(1)).pipe(
			withLatestFrom(
				this.authRepository.exists$,
				this.authRepository.auth$,
				this.appConfigRepository.isCloud$
			),
			switchMap(([_, exists, auth, isCloud]) => {
				if (!exists && !isCloud) {
					return this.redirectToCredentialsPage(state.url);
				} else if (!exists && isCloud) {
					return this.tryLoginAgainWhenCloud(state.url);
				} else {
					return this.userService.validateToken(auth).pipe(
						switchMap((valid) => {
							if (valid) {
								return of(true);
							} else if (!valid && isCloud) {
								return this.tryLoginAgainWhenCloud(state.url);
							} else {
								return this.redirectToCredentialsPage(state.url);
							}
						})
					);
				}
			})
		);
	}

	get credentialsPayloadExistForCloud(): Observable<
		false | ICredentialsPayload
	> {
		return combineLatest([
			this.credentialRepository.persistInitialized$.pipe(take(1)),
			this.authRepository.persistInitialized$.pipe(take(1))
		]).pipe(
			withLatestFrom(
				this.credentialRepository.credentials$,
				this.appConfigRepository.jiraUrl$,
				this.authRepository.email$
			),
			map(([_, credentials, jiraUrl, email]) => {
				const exists =
					!!credentials.xrayClientId &&
					!!credentials.xrayClientSecret &&
					!!credentials.jiraApiToken &&
					!!jiraUrl &&
					!!email;
				return exists
					? {
							...credentials,
							jiraUrl,
							email
						}
					: false;
			})
		);
	}

	tryLoginAgainWhenCloud(initialRouteRequested: string): Observable<boolean> {
		return this.credentialsPayloadExistForCloud.pipe(
			switchMap((credentialsPayload) => {
				if (credentialsPayload) {
					return this.userService.login(credentialsPayload).pipe(
						map((auth) => {
							this.authRepository.setAuth(auth);
							return !!auth;
						}),
						catchError(() => {
							this.notificationService.warning(
								'Access denied. Try to login again!'
							);
							return this.redirectToCredentialsPage(initialRouteRequested);
						})
					);
				} else {
					return this.redirectToCredentialsPage(initialRouteRequested);
				}
			})
		);
	}

	redirectToCredentialsPage(initialRouteRequested: string): Observable<false> {
		if (!this.routerService.getBrowserRequestedRoute()) {
			this.routerService.setBrowserRequestedRoute(initialRouteRequested);
		}
		this.zone.run(() => {
			this.routerService.navigate([PAGES.CREDENTIALS]);
			return EMPTY;
		});
		return of(false);
	}
}
