/* eslint-disable functional/immutable-data */
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScenarioParameter } from '@testifi-models/scenario-parameter';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import { RouterService } from '@testifi-services/router.service';
import { TestScenarioService } from '@testifi-services/test-scenario.service';
import {
	DESCRIPTION_VALIDATORS,
	MANDATORY_PARAMETERS_KEY,
	OPTIONAL_PARAMETERS_KEY,
	PAGES,
	VALUE_VALIDATORS_200
} from '@testifi-shared/app-constants';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { produce } from 'immer';
import { Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { ModalCloseDirective } from '../../directives/modal-close.directive';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';

@Component({
	selector: 'app-modal-add-test-scenario',
	templateUrl: './modal-add-test-scenario.component.html',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ValidationErrorsComponent,
		ModalCloseDirective
	]
})
export class ModalAddTestScenarioComponent implements OnInit, OnDestroy {
	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	// ======================================================================
	// public properties
	// ======================================================================

	form: UntypedFormGroup;
	submitted = false;
	projectId: string;

	// ======================================================================
	// getter
	// ======================================================================

	get f(): { [key: string]: AbstractControl } {
		return this.form.controls;
	}

	constructor(
		private cd: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private testScenarioService: TestScenarioService,
		private notificationService: NotificationService,
		private routerService: RouterService,
		private loadingService: LoadingService,
		private modalService: ModalService,
		private projectRepository: ProjectRepository,
		@Inject(MAT_DIALOG_DATA) public data: Record<string, string>
	) {}

	ngOnInit(): void {
		this.projectId = this.data.projectId;

		this.form = this.formBuilder.group({
			name: ['', [...VALUE_VALIDATORS_200, Validators.required]],
			description: ['', DESCRIPTION_VALIDATORS]
		});
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	// ======================================================================
	// public functions
	// ======================================================================

	add(): void {
		this.submitted = true;

		// stop here if form is invalid
		if (this.form.invalid) {
			return;
		}

		this.loadingService.active();

		this.disposableBag.add(
			this.testScenarioService
				.add(
					this.f.name.value,
					this.projectId,
					this.projectRepository.projectKey,
					this.f.description.value
				)
				.pipe(
					switchMap((testScenario) =>
						this.testScenarioService.edit(
							produce(testScenario, (draft) => {
								draft.parameters.push(
									new ScenarioParameter(MANDATORY_PARAMETERS_KEY, '')
								);
								draft.parameters.push(
									new ScenarioParameter(OPTIONAL_PARAMETERS_KEY, '')
								);
							})
						)
					),
					finalize(() => this.loadingService.deactive(this.cd))
				)
				.subscribe(
					(testScenario) => {
						this.modalService.close();
						this.notificationService.info(
							'Test (Scenario): ' + testScenario.name + ' was created'
						);
						this.routerService.navigate([PAGES.SCENARIO, `${testScenario.id}`]);
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}
}
