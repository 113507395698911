import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { JSONSchema, LocalStorage } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SortDao {
	// ======================================================================
	// private properties
	// ======================================================================

	private schema: JSONSchema;

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(private storage: LocalStorage) {
		this.schema = {
			type: 'object',
			properties: {
				direction: { type: 'string' },
				active: { type: 'string' }
			}
		};
	}

	// ======================================================================
	// public functions
	// ======================================================================

	get(key: string): Observable<Sort> {
		return this.storage.getItem<Sort>(`${key}-sort`, this.schema);
	}

	save(key: string, sort: Sort): Observable<boolean> {
		return this.storage.setItem(`${key}-sort`, sort);
	}

	delete(key: string): Observable<boolean> {
		return this.storage.removeItem(`${key}-sort`);
	}
}
