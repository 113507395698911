import { JsonConvert, JsonObject, JsonProperty } from 'json2typescript';
import { SuiElement } from './sui-element';
import { TestObject } from './test-object';
import { XmlElement } from './xml-element';

@JsonObject('XmlElements')
export class XmlElements {
	@JsonProperty('elementList', [XmlElement])
	elementList: XmlElement[] = [];

	fromTestObject(testObject: TestObject): this {
		testObject.restructure();
		this.elementList = this.fromTestObjects(
			testObject.children,
			new JsonConvert()
		);

		return this;
	}

	fromSuiElement(suiElement: SuiElement): this {
		suiElement.restructure();
		this.elementList = this.fromSuiElements(
			suiElement.children,
			new JsonConvert()
		);
		return this;
	}

	private fromSuiElements(
		suiElements: SuiElement[],
		jsonConvert: JsonConvert
	): XmlElement[] {
		return suiElements.map<XmlElement>((suiElement) => {
			const xmlElement = jsonConvert.deserialize(
				{
					index: suiElement.id,
					parameter: suiElement.parameter ?? {},
					tag: suiElement.type,
					children: []
				},
				XmlElement
			) as XmlElement;

			xmlElement.children = this.fromSuiElements(
				suiElement.children,
				jsonConvert
			);

			return xmlElement;
		});
	}

	private fromTestObjects(
		testObjects: TestObject[],
		jsonConvert: JsonConvert
	): XmlElement[] {
		return testObjects.map<XmlElement>((testObject) => {
			const parameter: Record<string, string> = {};

			testObject.parameters.forEach(
				(param) => (parameter[param.name] = param.value)
			);

			const xmlElement = jsonConvert.deserialize(
				{
					index: testObject.index,
					parameter,
					tag: testObject.name,
					children: []
				},
				XmlElement
			) as XmlElement;

			xmlElement.children = this.fromTestObjects(
				testObject.children,
				jsonConvert
			);

			return xmlElement;
		});
	}
}
