<div id="page-view-test-scenario" *hideOnLoading>
	<header class="page-view-test-scenario">
		<div class="aui-page-header-inner">
			<div class="aui-page-header-main">
				<app-breadcrumb></app-breadcrumb>
				<app-single-line-editor
					#summaryEditor
					[width]="650"
					[text]="testScenario?.name"
					[textColor]="'#172b4d'"
					[confirmed]="summaryUpdated"
					[validators]="summaryValidation"
					(changedValue)="onSummaryChange($event, null)"
				></app-single-line-editor>
			</div>

			<div>
				<button
					id="new-step"
					type="button"
					class="aui-button aui-button-primary"
					[modalData]="{
						testScenarioId: testScenario?.id,
						projectId: testScenario?.projectId,
						testStepsCount: testScenario?.testSteps.length,
						hasPutUpAndTearDown
					}"
					[modalOnClose]="editTestStepOnCreateModalClose.bind(this)"
					[modalRouterName]="['test_steps_add', testScenario?.id]"
				>
					+ New Step
				</button>
				<button
					type="button"
					id="undo"
					class="aui-button"
					title="Roll back last change"
					(click)="onClickUndo()"
					[disabled]="!editTracker.canUndo"
				>
					<img src="assets/icons/icon-undo.png" alt="Undo" />Undo
				</button>
				<button
					type="button"
					id="redo"
					class="aui-button"
					title="Re-do last reverted change"
					(click)="onClickRedo()"
					[disabled]="!editTracker.canRedo"
				>
					<img src="assets/icons/icon-undo.png" class="flipHorizontally" alt="Redo" />Redo
				</button>
				<button
					id="delete"
					type="button"
					class="aui-button"
					[modalData]="{
						testScenarioId: testScenario?.id,
						type: 'warning',
						title: 'Delete Test (Scenario)',
						text: 'Do you want to permanently delete the Test (Scenario)?',
						text2: 'The Test (Scenario) will be removed from the system! This action cannot be undone!',
						confirmButton: 'Delete',
						cancelButton: 'Cancel'}"
					[modalOnClose]="popOnModalClose.bind(this)"
					[modalRouterName]="[ 'confirm-popup', testScenario?.id ]"
				>
					Delete
				</button>

				<button
					#buttonMore
					type="button"
					id="more-actions"
					class="aui-button aui-dropdown2-trigger aui-dropdown2-trigger-arrowless"
					aria-controls="compact-button-dropdown"
				>
					<span class="aui-icon aui-icon-small aui-iconfont-more">More</span>
				</button>

				<aui-dropdown-menu id="compact-button-dropdown">
					<aui-item-link (click)="activateTestExecution()">Execute</aui-item-link>
					<aui-item-link (click)="exportScenario()">Send to Review</aui-item-link>
					<aui-item-link (click)="cloneScenario()">Clone</aui-item-link>
				</aui-dropdown-menu>
			</div>
			<!-- .aui-page-header-actions -->
		</div>
	</header>

	<div class="module">
		<div class="aui-group kpis">
			<div class="aui-item">
				<app-kpi-box title="Steps" color="#ebecf0" [value]="testStepCount" valueSize="20px"></app-kpi-box>
			</div>
			<div class="aui-item">
				<app-kpi-box title="Decomp. Steps" color="#ebecf0" [value]="testObjectCount" valueSize="20px"></app-kpi-box>
			</div>
			<div class="aui-item">
				<app-kpi-box
					title="Execution Status"
					[color]="status.color"
					[value]="status.name"
					valueSize="16px"
				></app-kpi-box>
			</div>
		</div>
	</div>

	<div class="module">
		<div class="aui-group">
			<div class="aui-item">
				<app-expandable-library-panel-group>
					<div
						class="drag-zone"
						[ngClass]="{ empty: testSteps?.length <= 0 }"
						cdkDropList
						(cdkDropListDropped)="drop($event)"
					>
						<div
							*ngFor="let testStep of testSteps"
							[attr.data-id]="testStep.id"
							class="test-step validation-{{ testStep.displayValidationType }}"
							[cdkDragDisabled]="testStep.tag !== ETestStepType.TEST_STEP"
							cdkDrag
						>
							<app-expandable-library-panel
								[identifier]="testStep.id"
								[draggable]="testStep.tag === ETestStepType.TEST_STEP"
								[editable]="testStep.tag === ETestStepType.TEST_STEP"
								[deletable]="testStep.tag === ETestStepType.TEST_STEP || testStep.tag === ETestStepType.NO_XML_TEST_STEP"
								[showIndex]="testStep.tag === ETestStepType.TEST_STEP || testStep.tag === ETestStepType.NO_XML_TEST_STEP"
								[showValidation]="testStep.tag === ETestStepType.TEST_STEP || testStep.tag === ETestStepType.NO_XML_TEST_STEP"
								(expand)="testStep.testObjects.length > 0 && toggleExpanded(testStep.id)"
								[expanded]="isExpanded(testStep.id)"
								[testObjects]="testStep.testObjects"
								[editModalData]="{
									testStepId: testStep.id,
									testScenarioId: testScenarioId,
									projectId: testScenario?.projectId
								}"
								[editModalOnClose]="onEditClose.bind(this)"
								[editModalRouterName]="['test_steps_edit', testStep.id]"
								[deleteModalData]="{
									type: 'warning',
									testStepId: testStep.id,
									title: 'Delete Step ' + testStep.description,
									text: 'Do you want to permanently delete this Step?',
									text2: 'This Step will be removed from the system!',
									confirmButton: 'Delete',
									cancelButton: 'Cancel'
								}"
								[deleteModalOnClose]="onStepDeletion.bind(this)"
								[deleteModalRouterName]="['confirm-popup', testStep.id]"
								[name]="testStep.index + (hasPutUpAndTearDown ? 0 : 1) | number: '2.'"
								[description]="testStep.description"
							></app-expandable-library-panel>
						</div>
					</div>
				</app-expandable-library-panel-group>
			</div>
			<div class="aui-item details-column">
				<div class="module" name="xRay Ticket">
					<div class="mod-header">
						<h4>xRay Ticket</h4>
					</div>
					<div class="mod-content" *ngIf="testScenario?.xrayTestKey">
						<a
							[href]="(appConfigRepository.jiraUrl$ | async) + '/browse/' + testScenario?.xrayTestKey"
							target="_blank"
							rel="noopener"
						>
							{{ testScenario?.xrayTestKey }}
						</a>
					</div>
					<div class="mod-content no-ticket" *ngIf="!testScenario?.xrayTestKey">No ticket has been created</div>
				</div>
				<div class="module" name="Description">
					<div class="mod-header">
						<h4>Description</h4>
					</div>
					<div class="mod-content">
						<app-multi-line-editor-legacy
							#descriptionEditor
							class="mod-content"
							[defaultText]="'Add description...'"
							[width]="250"
							[height]="125"
							[text]="testScenario?.description"
							[validators]="descriptionValidation"
							[confirmed]="descriptionUpdated"
							(changedValue)="onDescriptionChange($event)"
						></app-multi-line-editor-legacy>
					</div>
				</div>
				<div class="module" [app-relation-values] name="Test Plan/s" [empty]="testScenario?.testPlans?.length <= 0">
					<ul class="plan-list">
						<li *ngFor="let testPlan of testScenario?.testPlans">
							<div class="plan-list_item">
								<a
									href="{{ (appConfigRepository.jiraUrl$ | async) + '/browse/' + testPlan?.xrayTestPlanKey }}"
									target="_blank"
									rel="noopener"
								>
									<span class="plan-list_item-link">{{ testPlan?.xrayTestPlanKey }}</span>
								</a>
								<span class="plan-list_item-description" title="{{ testPlan?.summary }}">{{ testPlan?.summary }}</span>
							</div>
						</li>
					</ul>
				</div>
				<div class="module flexWidth" name="Parameters" *ngIf="testScenario?.parameters?.length">
					<div class="mod-header">
						<h4>Parameters</h4>
					</div>
					<app-parameter-list
						[parameterList]="testScenario?.parameters"
						(contentChanged)="onScenarioParamChanged($event)"
					></app-parameter-list>
				</div>
				<div class="module flexWidth" name="Labels">
					<div class="mod-header">
						<h4>Labels</h4>
					</div>
					<app-label-list
						[labelItemList]="testScenario?.labelItems"
						(contentChanged)="onLabelItemListChanged($event)"
					></app-label-list>
				</div>
			</div>
		</div>
	</div>
</div>
