import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Use IAuth instead
 * @deprecated
 */
@JsonObject('User')
export class User {
	@JsonProperty('email', String)
	email: string = undefined;

	@JsonProperty('jwt', String)
	token: string = undefined;
}
