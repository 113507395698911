import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Use IProject instead of Project
 * @deprecated
 */
@JsonObject('Project')
export class Project {
	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('description', String, true)
	description: string = undefined;
}

@JsonObject('AvatarUrls')
export class AvatarUrls {
	@JsonProperty('16x16', String)
	'16x16': string = undefined;

	@JsonProperty('24x24', String)
	'24x24': string = undefined;

	@JsonProperty('32x32', String)
	'32x32': string = undefined;

	@JsonProperty('16x16', String)
	'48x48': string = undefined;
}

@JsonObject('Lead')
export class Lead {
	@JsonProperty('avatarUrls', AvatarUrls, true)
	avatarUrls: AvatarUrls = undefined;

	@JsonProperty('displayName', String)
	displayName: string = undefined;
}

@JsonObject('Properties')
export class Properties {
	@JsonProperty('pitstopDisabled', Boolean)
	pitstopDisabled: boolean = undefined;
}

@JsonObject('JiraProject')
export class JiraProject {
	@JsonProperty('avatarUrls', AvatarUrls, true)
	avatarUrls: AvatarUrls = undefined;

	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('key', String)
	key: string = undefined;

	@JsonProperty('lead', Lead)
	lead: Lead = undefined;

	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('jiraProperties', Properties)
	properties: Properties = undefined;
}

@JsonObject('ProjectsPage')
export class ProjectsPage {
	@JsonProperty('totalNumberofPages', Number)
	totalNumberOfPages: number = undefined;

	@JsonProperty('totalNumberofElements', Number)
	totalNumberofElements: number = undefined;

	@JsonProperty('jiraProjectList', [JiraProject])
	jiraProjectList: JiraProject[] = [];
}

/**
 * Use IProjectSettings instead
 * @deprecated
 */
@JsonObject('ProjectSettings')
export class ProjectSettings {
	@JsonProperty('todoStatus', String, true)
	todoStatus: string = undefined;

	@JsonProperty('executingStatus', String, true)
	executingStatus: string = undefined;

	@JsonProperty('passStatus', String, true)
	passStatus: string = undefined;

	@JsonProperty('failStatus', String, true)
	failStatus: string = undefined;

	@JsonProperty('abortedStatus', String, true)
	abortedStatus: string = undefined;

	@JsonProperty('mismatchStatus', String, true)
	mismatchStatus: string = undefined;

	@JsonProperty('todoStepStatus', String, true)
	todoStepStatus: string = undefined;

	@JsonProperty('executingStepStatus', String, true)
	executingStepStatus: string = undefined;

	@JsonProperty('passStepStatus', String, true)
	passStepStatus: string = undefined;

	@JsonProperty('failStepStatus', String, true)
	failStepStatus: string = undefined;

	@JsonProperty('abortedStepStatus', String, true)
	abortedStepStatus: string = undefined;

	@JsonProperty('mismatchStepStatus', String, true)
	mismatchStepStatus: string = undefined;

	@JsonProperty('projectId', String, true)
	projectId: string = undefined;

	@JsonProperty('gitUrl', String, true)
	gitUrl: string = undefined;

	@JsonProperty('gitBranch', String, true)
	gitBranch: string = undefined;
}
