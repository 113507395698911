import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { TypedParameter } from '@testifi-models/typed-Item';
import { LabelTagDisplayComponent } from '@testifi-shared/label-tag-display/label-tag-display.component';
import { SortPipe } from '../../pipes/sort.pipe';

@Component({
	selector: 'app-comma-separated-list',
	templateUrl: './comma-separated-list.component.html',
	styleUrls: ['./comma-separated-list.component.less'],
	standalone: true,
	imports: [FlexModule, NgFor, LabelTagDisplayComponent, SortPipe]
})
export class CommaSeparatedListComponent {
	@Input() typedItems: TypedParameter[] = [];
	@Input() clickable = false;

	@Output() deleteClicked = new EventEmitter<TypedParameter>();
	@Output() clicked = new EventEmitter<TypedParameter>();
	@Output() addClicked = new EventEmitter<void>();

	onAddClick(): void {
		this.addClicked.emit();
	}

	removeItem(event: TypedParameter): void {
		this.deleteClicked.emit(event);
	}

	itemClicked($event: TypedParameter) {
		if (!this.clickable) {
			return;
		}
		this.clicked.emit($event);
	}
}
