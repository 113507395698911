import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Screenshot')
export class Screenshot {
	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('contentType', String)
	contentType: string = undefined;

	@JsonProperty('fileContent', String)
	fileContent: string = undefined;
}
