import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { NotificationService } from '@testifi-services/notification.service';
import { ProjectService } from '@testifi-services/project.service';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { TProjectRepository } from '@testifi-store/project/project.repository.type';
import {
	catchError,
	filter,
	finalize,
	map,
	of,
	switchMap,
	take,
	tap,
	throwError,
	withLatestFrom
} from 'rxjs';

export class PrivateRoutesResolver {
	static getProjectInfoResolver(): ResolveFn<void> {
		return () => {
			const appConfigRepository = inject(AppConfigRepository);
			const projectService = inject(ProjectService);
			const notificationService = inject(NotificationService);
			const projectRepository = inject(ProjectRepository);
			return appConfigRepository.state$.pipe(
				filter((state) => Boolean(state.configInitialized)),
				take(1),
				withLatestFrom(
					projectRepository.projectKey$,
					projectRepository.projectExists$
				),
				switchMap(([state, projectKey, projectExists]) => {
					if (projectExists) {
						return of(true);
					} else {
						projectRepository.setLoading(true);
						return projectService
							.info(
								state.config.applicationId,
								projectKey,
								state.config.jiraURL
							)
							.pipe(
								tap((projectInfo) => {
									projectRepository.setProject({
										name: projectInfo.name,
										id: projectInfo.id,
										description: projectInfo.description
									});
								})
							);
					}
				}),
				map(() => void 0),
				catchError((err) => {
					const projectIsNotFound =
						err instanceof HttpErrorResponse &&
						err.status === 424 &&
						(err?.error as { message: string }).message.includes(
							'not be found'
						);
					if (projectIsNotFound) {
						return projectRepository.projectKey$.pipe(
							switchMap((projectKey) =>
								projectService.add(projectKey, projectKey, projectKey)
							),
							tap((projectInfo) => {
								if (!projectInfo.id) {
									notificationService.error('Project ID is not found');
								} else {
									projectRepository.setProject({
										name: projectInfo.name,
										id: projectInfo.id,
										description: projectInfo.description
									});
								}
							})
						);
					}
					notificationService.error('Error getting project info (ProjectID).');
					return throwError(() => err as unknown);
				}),
				finalize(() => {
					projectRepository.setLoading(false);
				})
			);
		};
	}

	// Resolved getProjectInfoResolver is required before getProjectSettings
	static getProjectSettings(): ResolveFn<void> {
		return () => {
			const projectService = inject(ProjectService);
			const notificationService = inject(NotificationService);
			const projectRepository = inject(ProjectRepository);

			const handleProjectSettings = (state: TProjectRepository) => {
				if (!state.projectSettings) {
					projectRepository.setLoading(true);
					return projectService.getProjectSettings(state.project.id).pipe(
						tap((projectSettings) =>
							projectRepository.setProjectSettings(projectSettings)
						),
						map(() => void 0),
						catchError((err) => handleGetProjectSettingsError(err))
					);
				} else {
					return of(void 0);
				}
			};

			const handleGetProjectSettingsError = (err) => {
				notificationService.error(
					`Error getting project settings of ${projectRepository.state.project.name}.`
				);
				return throwError(() => err as unknown);
			};

			return projectRepository.state$.pipe(
				filter((state) => !!state.project.id),
				take(1),
				switchMap(handleProjectSettings),
				catchError((err) => throwError(() => err as unknown)),
				finalize(() => projectRepository.setLoading(false))
			);
		};
	}
}
