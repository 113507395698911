import { NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
	selector: 'app-validation-errors',
	templateUrl: './validation-errors.component.html',
	styleUrls: ['./validation-errors.component.less'],
	standalone: true,
	imports: [NgIf]
})
export class ValidationErrorsComponent {
	@Input() control: AbstractControl;
	@Input() smallerFont = false;

	@HostBinding('class') get c1(): string {
		return this.control?.invalid ? 'error' : '';
	}
}
