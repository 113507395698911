import { inject, Injectable } from '@angular/core';
import { MOCK_BUSINESS_JOURNEY_BMW } from '@testifi-mocks/tes-plan.mock';
import { BusinessJourney } from '@testifi-models/business-journey.model';
import { delay, Observable, of } from 'rxjs';
import { PrivateHttpClient } from '../https/instances/private.http';

@Injectable({
	providedIn: 'root'
})
export class TestPlanApiService {
	private readonly privateHttp = inject(PrivateHttpClient);

	getLastBrowsedBusinessJourneys(
		projectId: string
	): Observable<BusinessJourney[]> {
		return of([MOCK_BUSINESS_JOURNEY_BMW]).pipe(delay(1000));
		// return this.privateHttp.get<BusinessJourney[]>(
		// 	`user-histories/${projectId}`
		// );
	}
}
