/* eslint-disable functional/immutable-data */
import { AsyncPipe, JsonPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { BuildingBlock } from '@testifi-models/building-block';
import { Parameter } from '@testifi-models/parameter';
import { TestObject } from '@testifi-models/test-object';
import { TestStep } from '@testifi-models/test-step';
import { TestStepLibrary } from '@testifi-models/test-step-library';
import { XmlElements } from '@testifi-models/xml-elements';
import { BuildingBlockService } from '@testifi-services/building-block.service';
import { ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import { TestStepService } from '@testifi-services/test-step.service';
import {
	ID_VALIDATORS,
	TIMEOUT_VALIDATORS,
	VALUE_VALIDATORS
} from '@testifi-shared/app-constants';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { DataEffect } from '@testifi-store/data/data.effect';
import { DataRepository } from '@testifi-store/data/data.repository';
import { Utils } from '@testifi-utils/utils';
import { SvgIconComponent } from 'angular-svg-icon';
import { produce } from 'immer';
import { has } from 'lodash';
import { Observable, Subscription, filter, take } from 'rxjs';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { ModalCloseDirective } from '../../directives/modal-close.directive';
import { DataFileParserPipe } from '../../pipes/data-file-parser.pipe';
import {
	ItemDefaultValuePipe,
	ItemNamePipe
} from '../../pipes/item-name-default-value.pipe';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';

export enum TestObjectType {
	BuildingBlock,
	TestObject
}

export interface ModalTestObjectParameterData {
	testStepLibrary: TestStepLibrary;
	testObject: TestObject;
	parameter: Parameter;
	testObjectType: TestObjectType;
	testScenarioId: string;
	testStepId: string;
	values: string[];
	searchableDropdown: boolean;
	rootTestObject: TestObject;
}

type Normalized = {
	name: string;
	label: string;
	values: string[];
	isMandatory: boolean;
	hasDataFile: boolean;
};

@Component({
	selector: 'app-modal-test-object-parameter',
	templateUrl: './modal-test-object-parameter.component.html',
	styleUrls: ['./modal-test-object-parameter.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		NgIf,
		NgSelectModule,
		NgClass,
		ExtendedModule,
		NgFor,
		ValidationErrorsComponent,
		AutofocusDirective,
		ModalCloseDirective,
		ItemDefaultValuePipe,
		ItemNamePipe,
		JsonPipe,
		SvgIconComponent,
		AsyncPipe,
		DataFileParserPipe
	]
})
export class ModalTestObjectParameterComponent implements OnInit, OnDestroy {
	// ======================================================================
	// public properties
	// ======================================================================

	form: UntypedFormGroup;
	submitted = false;
	values: string[] = [];
	testObject: TestObject;
	rootItem: TestObject;
	parameter: Parameter;
	type: TestObjectType;
	scenarioId: string;
	stepId: string;
	library: TestStepLibrary;
	searchableDropdown = false;
	isMandatoryField = false;
	types: string[] = [];
	valueType = '';
	forbiddenError = new EventEmitter<void>();
	normalizedLibraryFields: Record<string, Normalized>;
	protected readonly Utils = Utils;

	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private cd: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private buildingBlockService: BuildingBlockService,
		private testStepService: TestStepService,
		private notificationService: NotificationService,
		private modalService: ModalService,
		private appConfigRepository: AppConfigRepository,
		private dataEffect: DataEffect,
		public dataRepository: DataRepository,
		@Inject(MAT_DIALOG_DATA)
		public data: ModalTestObjectParameterData
	) {}

	// ======================================================================
	// getters
	// ======================================================================

	get f(): { [key: string]: AbstractControl } {
		return this.form.controls;
	}

	get isNew(): boolean {
		return this.parameter === null || this.parameter === undefined;
	}

	// ======================================================================
	// inherit functions
	// ======================================================================
	ngOnInit(): void {
		this.rootItem = this.data.rootTestObject;

		this.library = this.data.testStepLibrary;
		this.normalizedLibraryFields = this.normalizeLibraryFields();

		this.testObject = this.data.testObject;
		this.parameter = this.data.parameter;

		this.type = this.data.testObjectType;
		this.scenarioId = this.data.testScenarioId;
		this.stepId = this.data.testStepId;

		this.initializeTypes();
		const initialType = this.identifyInitialType();
		const initialTypeHasDataFile =
			this.normalizedLibraryFields[initialType].hasDataFile;
		this.initializeValues(initialType);
		this.sortValues();
		if (!this.isNew) {
			this.isMandatoryField = this.identifyIsMandatoryField();
		}
		const initialValue = this.identifyInitialValue();
		const initialValidators = initialTypeHasDataFile
			? []
			: this.getValueValidators(initialType);

		this.form = this.formBuilder.group({
			type: new UntypedFormControl(
				{ value: initialType, disabled: !this.isNew },
				[Validators.required]
			),
			value: new UntypedFormControl(
				{
					value: initialValue,
					disabled: initialType === 'pageId' && !this.searchableDropdown
				},
				initialValidators
			)
		});

		this.valueType = initialType;
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	// ======================================================================
	// public functions
	// ======================================================================

	onDelete(): void {
		const parameters = produce(this.createParameters(), (draft) => {
			delete draft[this.parameter.name];
		});
		this.update(parameters);
	}

	submit(): void {
		this.update(this.createParameters());
	}

	onTypeChange(type: string): void {
		const normalizedType = this.normalizedLibraryFields[type];
		if (normalizedType.hasDataFile) {
			this.searchableDropdown = true;
			this.getSelectorValues(type);
			this.sortValues();
		} else {
			this.values = this.normalizedLibraryFields[type].values;
			this.searchableDropdown = this.values.length > 1;
			this.sortValues();

			if (this.values.length) {
				this.f.value.setValue(this.values[0]);
			} else if (has(this.library.standardValues, type)) {
				this.f.value.setValue(this.library.standardValues[type]);
			} else {
				this.f.value.setValue('');
			}

			this.f.value.setValidators(this.getValueValidators(type));
		}

		this.cd.detectChanges();
	}

	// ======================================================================
	// private functions
	// ======================================================================
	private createParameters(): Record<string, string> {
		let parameters: Record<string, string> = {};

		for (const parameter of this.testObject.parameters) {
			parameters = produce(parameters, (draft) => {
				draft[parameter.name] = parameter.value;
			});
		}

		const type = this.f.type.value as string;
		const typeValue = this.f.value.value as string;

		return produce(parameters, (draft) => {
			draft[this.normalizedLibraryFields[type].label] = typeValue;
		});
	}

	private update(parameters: Record<string, string>): void {
		this.rootItem = produce(this.rootItem, (draft) => {
			draft.children = this.rootItem.updateParameter(
				this.testObject.structureId,
				parameters
			);
		});

		this.submitted = true;

		let updateTestObjectsOrBuildingBlocks: Observable<TestStep | BuildingBlock>;
		switch (this.type) {
			case TestObjectType.TestObject:
				updateTestObjectsOrBuildingBlocks =
					this.testStepService.updateTestObjects(
						this.stepId,
						new XmlElements().fromTestObject(this.rootItem)
					);
				break;
			case TestObjectType.BuildingBlock:
				updateTestObjectsOrBuildingBlocks =
					this.buildingBlockService.updateTestObjects(
						this.stepId,
						new XmlElements().fromTestObject(this.rootItem)
					);
				break;
		}

		this.disposableBag.add(
			updateTestObjectsOrBuildingBlocks.subscribe(
				(v) => {
					this.notificationService.info('Parameter was saved');
					this.modalService.close(v);
				},
				(err: HttpErrorResponse) => {
					this.notificationService.httpError(err);
				}
			)
		);
	}

	private initializeTypes(): void {
		const nonMandatoryFields = Object.keys(this.normalizedLibraryFields).filter(
			(key) => !this.normalizedLibraryFields[key].isMandatory
		);
		this.types = !this.isMandatoryField
			? this.removeExistingParametersInTestObject(nonMandatoryFields)
			: [];
	}

	private identifyInitialType(): string {
		if (this.isNew) {
			return this.types.length ? this.types[0] : '';
		} else {
			const normalizedParameterName = this.findNameFromLabel(
				this.parameter.name
			);
			return this.normalizedLibraryFields[normalizedParameterName].name;
		}
	}

	private initializeValues(initialType: string) {
		const normalizedType = this.normalizedLibraryFields[initialType];
		if (normalizedType.hasDataFile) {
			this.getSelectorValues(initialType);
			this.searchableDropdown = true;
		} else {
			this.values =
				this.data.values.length > 0
					? this.data.values
					: this.normalizedLibraryFields[initialType].values;
			// NOTE: if values were set in dialog data property, being searchable is dependant of the length of the data.values array
			// (HOW: in sui page values are set explicitly from consumer component)
			// When values were not set explicitly, being searchable dependent on if library field has more than one value. (Description has only one value in values - standard values)
			this.searchableDropdown =
				this.data.values.length > 0 ? true : this.values.length > 1;
		}
	}

	private sortValues() {
		this.values?.sort((a, b) => a.localeCompare(b));
	}

	private identifyInitialValue(): string {
		if (this.isNew) {
			return this.values.length ? this.values[0] : '';
		} else {
			return this.parameter.value;
		}
	}

	private identifyIsMandatoryField(): boolean {
		return this.normalizedLibraryFields[
			this.findNameFromLabel(this.parameter.name)
		].isMandatory;
	}

	private normalizeLibraryFields(): Record<string, Normalized> {
		const isLibraryCBB = this.library.group === 'blocks';
		const libraryFields: Record<string, Normalized> = {};
		for (const key in {
			...this.library.mandatoryFields,
			...this.library.optionalFields
		}) {
			const isMandatory = key in this.library.mandatoryFields;
			libraryFields[key] = {
				label: Utils.getItemName(key),
				name: key,
				values: isMandatory
					? this.library.mandatoryFields[key]
					: this.library.optionalFields[key],
				isMandatory,
				hasDataFile: isLibraryCBB && Utils.parameterHasDataFile(key)
			};
		}
		return libraryFields;
	}

	private removeExistingParametersInTestObject(types: string[]): string[] {
		let parameters: string[] = [];
		for (const parameter of this.testObject.parameters) {
			parameters = produce(parameters, (draft) => {
				draft.push(this.findNameFromLabel(parameter.name));
			});
		}

		return types
			.filter((type) => !parameters.includes(type))
			.sort((a, b) => a.localeCompare(b));
	}

	private getSelectorValues(selector: string) {
		const { dataFileName, selectorName } =
			Utils.parseCBBParameterValueFromDataFile(selector);
		this.dataEffect.getValuesInSelector(dataFileName, selectorName);
		this.dataRepository
			.values$(dataFileName, selectorName)
			.pipe(filter(Boolean), take(1))
			.subscribe((values) => {
				this.values = values;
			});
	}

	private findNameFromLabel(parameterName: string): string {
		return Object.keys(this.normalizedLibraryFields).find(
			(key) => this.normalizedLibraryFields[key].label === parameterName
		);
	}

	private getValueValidators(type: string) {
		switch (type) {
			case 'id':
			case 'name':
				return ID_VALIDATORS;
			case 'timeout':
				return TIMEOUT_VALIDATORS;
			default:
				return VALUE_VALIDATORS;
		}
	}
}
