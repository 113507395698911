<form class="aui top-label" [formGroup]="form">
	<div fxLayout="column">
		<header class="aui-page-header">
			<div class="aui-page-header-inner">
				<div class="aui-page-header-main">
					<h3>Jira Credentials</h3>
				</div>
			</div>
		</header>
		<div class="module form-body">
			<p *ngIf="ssoAuthService.loggedIn">
				You are logged in as {{ ssoAuthService.givenName }} {{ ssoAuthService.familyName }}.
			</p>
			<div *ngIf="!ssoAuthService.loggedIn" fxLayout fxLayoutAlign="start baseline">
				<p>You are not logged in.</p>
				<button type="button" class="aui-button aui-button-link ms-3" (click)="ssoAuthService.login()">Login</button>
			</div>
			<div class="field-group">
				<label class="label d-flex align-items-end" for="token"
					>Token (how to generate it
					<svg-icon
						class="ml-1 pointer"
						name="external-link"
						[svgStyle]="{'width.px':16, 'height.px': 16}"
						(click)="openLink()"
					></svg-icon>
					)</label
				>
				<input
					class="input-text"
					[class.input-text-security]="isHidden"
					type="text"
					formControlName="token"
					id="token"
					placeholder="Token"
					onCopy="return false"
					onCut="return false"
					onselectstart="return false"
					onDrag="return false"
					autocomplete="off"
				/>
				<svg-icon
					name="{{ isHidden ? 'eye-on' : 'eye-off' }}"
					class="input-text-icon"
					title="Show password"
					[svgStyle]="{'width.px': 16, 'height.px': 16}"
					(click)="isHidden = !isHidden"
				></svg-icon>
				<app-validation-errors [control]="form.get('token')"></app-validation-errors>
			</div>
		</div>
		<div class="test-connection mt-4">
			<app-test-connection [state]="testConnectionState" (click)="testConnection()"></app-test-connection>
		</div>
	</div>
</form>
