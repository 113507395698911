import { Injectable } from '@angular/core';
import { JSONSchema, LocalStorage } from '@ngx-pwa/local-storage';
import { PAGES } from '@testifi-shared/app-constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UrlDao {
	// ======================================================================
	// private properties
	// ======================================================================

	private key = 'lastVisitData';
	private schema: JSONSchema;

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(private storage: LocalStorage) {
		this.schema = {
			type: 'array',
			items: {
				type: 'array',
				items: [
					{ type: 'string' },
					{ type: 'array', items: { type: 'string' } }
				]
			}
		};
	}

	// ======================================================================
	// public functions
	// ======================================================================

	get(): Observable<Map<string, PAGES[] | string[]>> {
		return this.storage
			.getItem<Map<string, PAGES[] | string[]>>(this.key, this.schema)
			.pipe(map((data) => new Map(data)));
	}

	save(url: Map<string, PAGES[] | string[]>): Observable<boolean> {
		return this.storage.setItem(this.key, Array.from(url));
	}

	delete(): Observable<boolean> {
		return this.storage.removeItem(this.key);
	}
}
