/* eslint-disable functional/immutable-data */
import {
	CdkDrag,
	CdkDragDrop,
	CdkDragEnd,
	CdkDragMove,
	CdkDragPlaceholder,
	CdkDragPreview,
	CdkDragStart,
	CdkDropList
} from '@angular/cdk/drag-drop';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TestObjectType } from '@testifi-modals/modal-test-object-parameter/modal-test-object-parameter.component';
import { BuildingBlock } from '@testifi-models/building-block';
import { Parameter } from '@testifi-models/parameter';
import { TestObject } from '@testifi-models/test-object';
import { TestStep } from '@testifi-models/test-step';
import { TestStepLibrary } from '@testifi-models/test-step-library';
import { XmlElements } from '@testifi-models/xml-elements';
import { BuildingBlockService } from '@testifi-services/building-block.service';
import { LoadingService } from '@testifi-services/loading.service';
import { NotificationService } from '@testifi-services/notification.service';
import { TestStepService } from '@testifi-services/test-step.service';
import { Utils } from '@testifi-utils/utils';
import { SvgIconComponent } from 'angular-svg-icon';
import { produce } from 'immer';
import { cloneDeep, has } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppendDragThumbDirective } from '../../directives/append-drag-thumb.directive';
import { ModalRouterNameDirective } from '../../directives/modal-router-name.directive';
import { ShouldShowAddNewTestObjectParameterPipe } from '../../pipes/should-show-add-new-test-object-parameter.pipe';
import { SortTestObjectParameterPipe } from '../../pipes/sort-test-object-parameter.pipe';
import { SortPipe } from '../../pipes/sort.pipe';
import { UiTagPipe } from '../../pipes/ui-tag.pipe';

export interface LibraryDragDropPageElementParameterClickData {
	testObject: TestObject;
	parameter: Parameter;
}

@Component({
	selector: 'app-library-drag-drop-page-element',
	templateUrl: './library-drag-drop-page-element.component.html',
	styleUrls: ['./library-drag-drop-page-element.component.less'],
	standalone: true,
	imports: [
		CdkDropList,
		CdkScrollable,
		NgClass,
		ExtendedModule,
		NgFor,
		CdkDrag,
		MatExpansionModule,
		AppendDragThumbDirective,
		NgIf,
		MatIconModule,
		SvgIconComponent,
		ModalRouterNameDirective,
		CdkDragPreview,
		CdkDragPlaceholder,
		UiTagPipe,
		ShouldShowAddNewTestObjectParameterPipe,
		SortPipe,
		SortTestObjectParameterPipe
	]
})
export class LibraryDragDropPageElementComponent
	implements OnDestroy, OnChanges
{
	@Input() item: TestObject;
	@Input() connectedLists: string[];
	@Input() intersectedItemIds: string[];
	@Input() expandedItemIds: string[];
	@Input() root = false;
	@Input() tags = [];
	@Input() isDragging = false;
	@Input() parameters = new Map<string, string[]>();
	@Input() libraries = new Map<string, TestStepLibrary[]>();
	@Input() type: TestObjectType;
	@Input() rootId: string;
	@Input() rootItem: TestObject;
	@Output() dragDrop = new EventEmitter<CdkDragDrop<TestObject>>();
	@Output() dragMove = new EventEmitter<CdkDragMove<TestObject>>();
	@Output() dragEnded = new EventEmitter<CdkDragEnd<TestObject>>();
	@Output() dragStarted = new EventEmitter<CdkDragStart<TestObject>>();
	@Output() expandedClick = new EventEmitter<string>();
	@Output() refreshOnModalClose = new EventEmitter<{
		structureId: string;
		action?: string;
		isChanged: boolean;
	}>();
	@Output()
	parameterClick =
		new EventEmitter<LibraryDragDropPageElementParameterClickData>();
	TestObjectType = TestObjectType;

	private disposableBag = new Subscription();

	constructor(
		private notificationService: NotificationService,
		private buildingBlockService: BuildingBlockService,
		private testStepService: TestStepService,
		private loadingService: LoadingService,
		private cd: ChangeDetectorRef
	) {}

	get isIntersected(): boolean {
		return this.intersectedItemIds.includes(this.item.structureId);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.item) {
			this.item = produce(this.item, (draft) => {
				draft.children.forEach((child) => {
					const childLibrary = this.getTestStepLibrary(child.name);
					if (
						childLibrary?.group === 'pages' ||
						childLibrary?.group === 'blocks'
					) {
						child.libid = childLibrary.id;
						child.libgroup = childLibrary.group;
					}
				});
			});
		}
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	canDrop(item: TestObject, intersectedItemIds: string[]): () => boolean {
		return () =>
			(Utils.getLibrary(this.item.name, this.libraries) === null ||
				Utils.getLibrary(this.item.name, this.libraries)?.isNode) &&
			(intersectedItemIds.length <= 0 ||
				item.children.length <= 0 ||
				intersectedItemIds.includes(item.structureId));
	}

	filteredConnectedListIds(item: TestObject): Array<string> {
		return this.connectedLists.filter(
			(structureId) => structureId !== item.structureId
		);
	}

	onDragDrop(event: CdkDragDrop<TestObject, TestObject>): void {
		this.dragDrop.emit(event);
	}

	onDragMove(event: CdkDragMove<TestObject>): void {
		this.dragMove.emit(event);
	}

	onDragEnded(event: CdkDragEnd<TestObject>): void {
		this.dragEnded.emit(event);
	}

	onDragStarted(event: CdkDragStart<TestObject>): void {
		this.dragStarted.emit(event);
	}

	onEventParameterClick(
		$event: Event,
		testObject: TestObject,
		parameter: Parameter = null
	): void {
		if (this.getTestStepLibrary(testObject.name, false)) {
			$event.stopPropagation();
			this.parametersCheck(testObject, parameter);
		} else {
			this.notificationService.error(
				`The page object cannot be updated because it does not exist`
			);
		}
	}

	onParameterClick(data: LibraryDragDropPageElementParameterClickData): void {
		this.parameterClick.emit(data);
	}

	onToggleExpanded(event: MouseEvent | string): void {
		if (event instanceof MouseEvent) {
			const element = event.currentTarget as HTMLElement;

			event.stopPropagation();
			this.expandedClick.emit(element.id);
		} else {
			this.expandedClick.emit(event);
		}
	}

	onRefreshOnModalClose(data: {
		structureId: string;
		isChanged: boolean;
		parentId?: string;
	}): void {
		if (has(data, 'parentId')) {
			let rootItem = cloneDeep(this.rootItem);
			this.loadingService.active();

			rootItem = rootItem.removeChild(data.structureId).updated;
			let updateTestObjectOrBuildingBlock: Observable<BuildingBlock | TestStep>;
			switch (this.type) {
				case TestObjectType.TestObject:
					updateTestObjectOrBuildingBlock =
						this.testStepService.updateTestObjects(
							data.parentId,
							new XmlElements().fromTestObject(rootItem)
						);
					break;
				case TestObjectType.BuildingBlock:
					updateTestObjectOrBuildingBlock =
						this.buildingBlockService.updateTestObjects(
							data.parentId,
							new XmlElements().fromTestObject(rootItem)
						);
			}

			this.disposableBag.add(
				updateTestObjectOrBuildingBlock
					.pipe(finalize(() => this.loadingService.deactive(this.cd)))
					.subscribe(
						() => {
							this.rootItem = rootItem;
							this.item = Utils.findChildByStructureId(
								this.rootItem,
								this.item.structureId
							);

							this.notificationService.info('Test object was removed');

							// reminder: emit must come the last
							this.refreshOnModalClose.emit({
								structureId: data.structureId,
								action: 'removal',
								isChanged: true
							});
						},
						(err: HttpErrorResponse) => {
							console.error(err);
							this.notificationService.httpError(err);
						}
					)
			);
		} else if (typeof data === 'string') {
			this.refreshOnModalClose.emit({ structureId: '', isChanged: false });
		} else {
			this.refreshOnModalClose.emit(data);
		}
	}

	private getTestStepLibrary(
		name: string,
		showNotification = true
	): TestStepLibrary | null {
		if (name !== 'Root') {
			const library = Utils.getLibrary(name, this.libraries);

			if (library) {
				return library;
			} else if (showNotification) {
				this.notificationService.warning(
					`This test step uses deleted page or custom block "${name}"`
				);
			}
		}

		return null;
	}

	private parametersCheck(testObject: TestObject, parameter: Parameter): void {
		const libraryObject = Utils.getLibrary(testObject.name, this.libraries);

		if (Object.keys(libraryObject?.optionalFields)?.length || parameter) {
			this.onParameterClick({
				testObject,
				parameter
			});
		} else {
			this.notificationService.warning(
				`The test object does not have optional parameters`
			);
		}
	}
}
