import { NgClass } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { gitFormFields } from '@testifi-modals/modal-execute-test-plan/modal-execute-test-plan.component';
import { GitConnection } from '@testifi-services/git.service';
import { ModalData, ModalService } from '@testifi-services/modal.service';
import { CredentialRepository } from '@testifi-store/credentials/credential.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { Utils } from '@testifi-utils/utils';
import { SvgIconComponent } from 'angular-svg-icon';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ModalCloseDirective } from '../../directives/modal-close.directive';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';

@Component({
	selector: 'app-modal-git-information',
	templateUrl: './modal-git-information.component.html',
	styleUrls: ['./modal-git-information.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ValidationErrorsComponent,
		NgClass,
		ExtendedModule,
		SvgIconComponent,
		FlexModule,
		ModalCloseDirective
	]
})
export class ModalGitInformationComponent implements OnInit {
	form: UntypedFormGroup;
	submitted = false;
	isTokenHidden = true;

	private disposableBag = new Subscription();

	constructor(
		private formBuilder: UntypedFormBuilder,
		private modalService: ModalService,
		private credentialRepository: CredentialRepository,
		private projectRepository: ProjectRepository,
		@Inject(MAT_DIALOG_DATA)
		private data: ModalData
	) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group(gitFormFields);

		this.disposableBag.add(
			this.credentialRepository.credentials$
				.pipe(
					tap((credentials) => {
						this.form.get('username').setValue(credentials.gitUsername);
						this.form.get('password').setValue(credentials.gitToken);
					}),
					switchMap(() => this.projectRepository.projectSettings$)
				)
				.subscribe((settings) => {
					this.form.get('url').setValue(settings.gitUrl);
					this.form.get('branch').setValue(settings.gitBranch);
					Utils.enableInvalidFormControls(this.form);
				})
		);
	}

	send(): void {
		const payload: GitConnection = {
			username: this.form.get('username').value as string,
			password: this.form.get('password').value as string,
			skipSSLVerify: this.form.get('skipSSL').value as boolean,
			repoURL: this.form.get('url').value as string,
			branch: this.form.get('branch').value as string
		};

		this.modalService.close(payload);
	}
}
