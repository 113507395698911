<section
	id="modal-edit-test-step"
	class="aui-dialog2"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-delete-test-step--heading"
	*hideOnLoading
>
	<app-library-drag-drop-page
		[projectId]="data.projectId"
		[testScenarioId]="data.testScenarioId"
		[testStepId]="data.testStepId"
		[item]="testStep"
		[type]="testObjectType.TestObject"
		[bottomOffset]="223"
		(changePosition)="onChangePosition($event)"
		(refreshOnModalClose)="onRefreshOnModalClose($event)"
		(libraryDrop)="onLibraryDrop($event)"
		[forbiddenEvent]="forbiddenEvent.asObservable()"
		[editTracker]="editTracker"
	>
	</app-library-drag-drop-page>
</section>
