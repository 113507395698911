<section
	id="modal-sui-page-select-screenshot"
	class="aui-dialog2 aui-dialog2-xlarge"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-sui-page-select-screenshot--heading"
>
	<form class="aui" (ngSubmit)="submit()">
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-sui-page-select-screenshot--heading">
				Select or upload screenshot
			</h2>
			<div>
				<input
					type="range"
					min="1"
					max="5"
					[(ngModel)]="columns"
					id="thumbnailSize"
					name="thumbnailSize"
					title="Number of columns"
					(change)="onColumnsChanged()"
				/>
			</div>
			<div>
				<mat-paginator
					appStylePaginator
					#paginator
					[length]="totalItems"
					[pageSize]="pageSize"
					[pageSizeOptions]="[4, 10, 20, 50, 100]"
					[pageIndex]="currentPage"
					(page)="handlePage($event)"
				>
				</mat-paginator>
			</div>
		</header>
		<!-- Main dialog content -->
		<div class="aui-dialog2-content">
			<div class="screenshotGrid">
				<div *ngIf="!loadingService.active && !screenshots.length" class="center-text">
					<h3>No screenshots have been uploaded so far</h3>
				</div>
				<div
					class="screenshot columns{{columns}}"
					*ngFor="let screenshot of screenshots"
					[ngClass]="{'selectedScreenshot': selectedScreenshot?.id === screenshot.id}"
					(click)="onClickScreenshot(screenshot)"
				>
					<img [alt]="screenshot.name" [src]="Utils.getScreenshotImageSource(sanitizer, screenshot)" />
					<span
						class="deleteButton svg"
						[modalData]="{
							screenshotId: screenshot.id,
							type: 'warning',
							title: 'Delete Screenshot',
							text: 'This screenshot will be removed from the system!',
							text2: 'This cannot be undone.',
							confirmButton: 'Delete',
							cancelButton: 'Cancel'
						}"
						[modalOnClose]="onDelete.bind(this)"
						[modalRouterName]="['confirm-popup', screenshot.id]"
					>
						<svg-icon name="icon-delete"></svg-icon>
					</span>
				</div>
			</div>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<form [formGroup]="tncForm" class="tnc">
				<div fxLayout fxLayoutAlign="end start">
					<input
						type="checkbox"
						name="termAck"
						title="Accept Terms and Conditions"
						formControlName="terms"
						(click)="blur()"
					/>
					<label #label for="termAck"
						>My images meet the
						<a
							href="https://jira-testifi.atlassian.net/wiki/spaces/CTF/pages/8786083865/Content+Guidelines"
							target="_blank"
							title="Content Guidelines"
							rel="noopener"
							>Content Guidelines
						</a>
						and I have read and accept the
						<a
							href="https://jira-testifi.atlassian.net/wiki/spaces/CTF/pages/8786378778/Terms+and+Conditions"
							target="_blank"
							title="Terms and Conditions"
							rel="noopener"
							>Terms and Conditions
						</a>
					</label>
				</div>
			</form>
			<div class="aui-dialog2-footer-actions">
				<input
					#fileUpload
					type="file"
					name="screenshotFile"
					title="Upload Screenshot"
					class="hidden"
					(click)="fileUpload.value = null"
					(change)="onFilesSelected($event)"
					multiple
				/>
				<input
					type="button"
					name="uploadFile"
					[disabled]="!tncForm.value.terms"
					(click)="fileUpload.click()"
					title="Upload Screenshot File"
					value="Upload New"
					class="aui-button vAlign9px"
				/>
				<button
					id="create-submit"
					type="submit"
					class="aui-button aui-button-primary vAlign9px"
					[disabled]="!selectedScreenshot || submitted"
				>
					Apply Selected
				</button>
				<button type="button" id="cancel" class="aui-button aui-button-subtle vAlign9px" (click)="onCancel()">
					Cancel
				</button>
			</div>
			<div class="aui-dialog2-footer-hint">
				<button
					type="button"
					class="aui-button aui-button-link color-R300"
					*ngIf="data.suiElement?.parameter[SCREENSHOT_PARAM_KEY] && !submitted"
					(click)="removeSelection()"
					title="Remove selection from the zone"
				>
					Remove selection
				</button>
			</div>
		</footer>
	</form>
</section>
