<div id="page-view-sui-component" *hideOnLoading>
	<header class="aui-page-header">
		<div class="aui-page-header-inner">
			<div class="aui-page-header-main">
				<app-breadcrumb></app-breadcrumb>
				<h1>SUI Pages</h1>
			</div>

			<div class="aui-page-header-actions">
				<button
					id="new-sui-page"
					type="button"
					class="aui-button aui-button-primary"
					[modalOnClose]="editSuiPageOnModalClose.bind(this)"
					[modalRouterName]="['sui_pages_add', projectId]"
					[modalData]="{ projectId: projectId }"
				>
					+ New SUI Page
				</button>
			</div>
			<!-- .aui-page-header-actions -->
		</div>
	</header>

	<div class="module" *ngIf="projectView">
		<div class="aui-group kpis" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="12px">
			<app-kpi-box title="Total" color="#c1c7d0" value="{{totalItems}}"></app-kpi-box>
		</div>
	</div>

	<div class="module">
		<div class="command-bar">
			<div class="aui-toolbar2">
				<div class="aui-toolbar2-inner">
					<div class="aui-toolbar2-primary">
						<form class="aui navigator-search query-component generic-styled">
							<ul class="filters">
								<li class="text-query">
									<div class="text-query-container">
										<input
											class="search-entry text medium-field"
											id="searcher-query"
											(input)="onSearchInput($event)"
											(keyup.enter)="onSearch()"
											type="search"
											name="search"
											placeholder="Text"
										/>
									</div>
								</li>
								<li>
									<button id="search-submit" class="aui-button search-button" type="button" (click)="onSearch()">
										Search
									</button>
								</li>
							</ul>
						</form>
					</div>
					<div class="aui-toolbar2-secondary">
						<div class="aui-buttons">
							<button
								type="button"
								id="project-components-delete-disabled"
								class="aui-button"
								title="Delete"
								*ngIf="!checkedItems.size"
								aria-disabled="true"
								disabled
							>
								<span class="svg">
									<svg-icon name="icon-delete" class="disabled"></svg-icon>
								</span>
							</button>

							<button
								type="button"
								id="project-components-delete"
								class="aui-button"
								title="Delete"
								[modalOnClose]="deleteOnModalConfirm.bind(this)"
								[modalData]="{
					title: 'SUI Page(s) Deletion',
					text: 'Are you sure you want delete selection?',
					text2: 'This cannot be undone!!!',
					confirmButton: 'Delete',
					cancelButton: 'Cancel',
					type: 'warning'
				}"
								[modalRouterName]="['confirm-popup']"
								*ngIf="checkedItems.size"
							>
								<span class="svg">
									<svg-icon name="icon-delete" [svgStyle]="{ 'fill':'#42526E' }"></svg-icon>
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="module">
		<div class="table-container col-group">
			<div class="col-mb-24 col-24 col-dt-24 col-ld-24">
				<table
					matSort
					[matSortActive]="defaultSortedColumn"
					[matSortDirection]="defaultSort"
					matSortDisableClear
					(matSortChange)="onSort($event)"
					aria-describedby="SUI pages list"
				>
					<thead>
						<tr class="header">
							<th id="select">
								<app-checkbox name="select_all" (stateChanged)="selectAll($event)" #selectAllCheckbox></app-checkbox>
							</th>
							<ng-container *ngFor="let th of tableHead | unordedkeyvalue">
								<th id="title-nosort" *ngIf="!th.value.sortable" class="theader">{{ th.value.title }}</th>
								<th id="title-sort" *ngIf="th.value.sortable" class="theader" [mat-sort-header]="th.key">
									{{ th.value.title }}
								</th>
							</ng-container>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let suiPage of suiPages">
							<tr class="border-bottom pointer" (click)="openEditDialog(suiPage.id)">
								<td class="checkbox">
									<app-checkbox
										[checked]="checkedItems.has(suiPage.id)"
										(stateChanged)="checkItem(suiPage)"
										name="select_sui_page_{{suiPage.id}}"
									></app-checkbox>
								</td>
								<td class="col-name">
									<div class="col-name">
										<app-long-text
											[id]="suiPage.id"
											[text]="suiPage.name"
											[expansionState]="toggleState"
											[shouldExpand]="expandButtonState.get(suiPage.id) || false"
											(stateChanged)="onLongTextToggle($event, suiPage)"
											(longTextPresent)="onLongTextPresent($event, suiPage)"
										>
										</app-long-text>
									</div>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</div>
		<mat-paginator
			appStylePaginator
			#paginator
			[length]="totalItems"
			[pageSize]="pageSize"
			[pageSizeOptions]="[5, 10, 50, 100]"
			[pageIndex]="currentPage"
			(page)="handlePage($event)"
		>
		</mat-paginator>
	</div>
</div>
