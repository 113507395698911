import { SecurityContext } from '@angular/core';
import {
	AbstractControl,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Screenshot } from '@testifi-models/Screenshot';
import { SuiElement } from '@testifi-models/sui-element';
import { TestObject } from '@testifi-models/test-object';
import { TestStepLibrary } from '@testifi-models/test-step-library';
import { isEqual } from 'lodash-es';

export const Utils = Object.freeze({
	compare(a: number | string, b: number | string, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	},
	compareStrings(a: string, b: string, isAsc: boolean) {
		if (a.toLowerCase() === b.toLowerCase()) {
			return (a.charAt(0) > b.charAt(0) ? 1 : -1) * (isAsc ? 1 : -1);
		}

		return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
	},
	slugify(v: string, replace: string) {
		return v.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, replace);
	},
	replaceTemplateString(
		parameters: Record<string, string>,
		template: string
	): string {
		Object.keys(parameters).forEach((key) => {
			if (parameters[key]) {
				template = template.replace(`{${key}}`, parameters[key]);
			}
		});
		return template;
	},
	getActionsStructureLevels(structureId: string): string[] {
		let levels: string[] = [];
		const prefix = structureId.split('_')[0] + '_';
		const helper = structureId.split('_').pop().split('.');

		if (helper.length) {
			for (let i = 0; i < helper.length; i++) {
				levels.push(i > 0 ? `${levels[i - 1]}.${helper[i]}` : helper[i]);
			}
			levels.splice(0, 1);
			levels = levels.map((lv) => `${prefix}${lv}`);
		}

		return levels;
	},
	pickTextColorBasedOnBgColor(
		bgColor: string,
		lightColor: string,
		darkColor: string
	) {
		const color = bgColor.startsWith('#') ? bgColor.substring(1, 7) : bgColor;
		const r = parseInt(color.substring(0, 2), 16); // hexToR
		const g = parseInt(color.substring(2, 4), 16); // hexToG
		const b = parseInt(color.substring(4, 6), 16); // hexToB
		const uicolors = [r / 255, g / 255, b / 255];
		const c = uicolors.map((col) => {
			if (col <= 0.03928) {
				return col / 12.92;
			}
			return Math.pow((col + 0.055) / 1.055, 2.4);
		});
		const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];

		return L > 0.25 ? darkColor : lightColor; // originally it was 0.179
	},
	formatDeviceName(original: string): string {
		if (!original) {
			return '';
		}
		let result = original.substring(0, 1).toUpperCase();
		const isLowerCase = /[a-z]/;
		const isUpperCase = /[A-Z]/;
		for (let index = 1; index < original.length; index++) {
			const prevChar = original.charAt(index - 1);
			const thisChar = original.charAt(index);
			const nextChar =
				index < original.length - 1 ? original.charAt(index + 1) : '';
			if (
				((isLowerCase.test(prevChar) || !isUpperCase.test(prevChar)) && // non uppercase
					isUpperCase.test(thisChar)) || // followed by upper case
				((isLowerCase.test(prevChar) || isUpperCase.test(prevChar)) && // char
					!isUpperCase.test(thisChar) && // followed by non-char
					!isLowerCase.test(thisChar)) ||
				(isUpperCase.test(prevChar) &&
					isUpperCase.test(thisChar) &&
					isLowerCase.test(nextChar)) // Two upper case followed by one lower case
			)
				result += ' ';
			result += thisChar;
		}

		return result;
	},
	getScreenshotImageSource(
		sanitizer: DomSanitizer,
		screenshot: Screenshot
	): SafeUrl {
		return screenshot && sanitizer
			? sanitizer.bypassSecurityTrustUrl(
					'data:' +
						screenshot.contentType +
						';base64, ' +
						sanitizer.sanitize(SecurityContext.HTML, screenshot.fileContent)
				)
			: sanitizer.bypassSecurityTrustUrl(
					`data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==`
				); // empty transparent image of size 1x1
	},
	enableInvalidFormControls(form: UntypedFormGroup): void {
		const validNames = ['username', 'password', 'url', 'branch'];
		Object.entries(form.controls).forEach((param) => {
			if (!validNames.includes(param[0])) {
				return;
			}

			const control = param[1];
			control.enable(); // validaty can't be checked over disabled control
			if (control.valid) {
				control.disable();
			}
		});
	},
	differenceInObj<T>(firstObj: T, secondObj: T): string[] {
		let differences: string[] = [];
		for (const key in firstObj) {
			if (!isEqual(firstObj[key], secondObj[key])) {
				differences = [...differences, key];
			}
		}

		return differences;
	},
	findChildByStructureId<T>(parentItem: T, id: string): T {
		if (parentItem['structureId'] === id) {
			return parentItem;
		} else {
			let item: T = null;
			(parentItem['children'] as T[]).forEach((child) => {
				if (child['structureId'] === id) {
					item = child;
				} else if (!item) {
					item = Utils.findChildByStructureId(child, id);
				}
			});
			return item;
		}
	},
	getStructureIdList(structureId: string): number[] {
		return structureId
			.split('_')[1] // all prefixes should include '_'
			.split('.')
			.map((item) => parseInt(item, 10));
	},
	findUpdatedStructureId(
		oldStructureId: string,
		transitions: string[][]
	): undefined | string {
		return transitions.find(
			(transition) => transition[0] === oldStructureId
		)?.[1];
	},
	extractTagName(xml: string): string {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(xml, 'text/xml');
		const rootElement = xmlDoc?.documentElement;
		const tagName = rootElement?.tagName;
		if (!tagName || tagName === 'parsererror') {
			throw new Error(`Invalid XML: ${xml}`);
		}
		return tagName;
	},
	searchUITagOrName<T extends { name: string; uiTag: string }>(
		items: T[],
		term: string
	): T[] {
		return items.filter((item) => {
			const uiTagFound = item.uiTag.toLowerCase().includes(term.toLowerCase());
			const nameFound = item.name.toLowerCase().includes(term.toLowerCase());
			return uiTagFound || nameFound;
		});
	},
	conditionalValidator(
		condition: () => boolean,
		validators: ValidatorFn[]
	): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.parent) {
				return null;
			}

			if (!condition()) {
				return null;
			}

			const errors = validators
				.map((validator) => validator(control))
				.filter((error) => error !== null);

			return errors.length
				? (Object.assign({}, ...errors) as ValidationErrors)
				: null;
		};
	},

	getItemName(text: string): string {
		const separator = Utils.parameterHasDataFile(text) ? '#' : '!';
		return text?.split(separator)[0];
	},

	getItemDefaultValue(text: string): string {
		if (text?.includes('!')) {
			return text?.split('!')[1].slice(1, -1);
		} else return undefined;
	},

	createCBBParameterValueFromDataFile(
		parameterName: string,
		dataFileName: string,
		selectorName: string,
		defaultValue?: string
	): string {
		if (!parameterName || !dataFileName || !selectorName) {
			console.error('Invalid parameterName, dataFileName or selectorName');
		}
		let result = `${parameterName}#${dataFileName}#${selectorName}`;
		if (defaultValue) {
			result += `!'${defaultValue}'`;
		}
		return result;
	},

	parseCBBParameterValueFromDataFile(parameterValue: string): {
		parameterName: string;
		dataFileName: string;
		selectorName: string;
		defaultValue?: string;
	} {
		const [parameterName, dataFileName, selectorName] =
			parameterValue.split('#');
		const [selector, defaultValue] =
			Utils.deriveInitialValueFromSelector(selectorName);
		return {
			parameterName,
			dataFileName,
			selectorName: selector,
			defaultValue
		};
	},

	deriveInitialValueFromSelector(
		selector: string
	): [string, string | undefined] {
		if (selector.includes("!'")) {
			const tuple = selector.split("!'");
			return [tuple[0], tuple[1].substring(0, tuple[1].length - 1)];
		} else {
			return [selector, undefined];
		}
	},

	fixSameDayDate(date: string): string {
		return date.replace('00:00:00', '23:59:59');
	},

	parameterHasDataFile(parameter: string): boolean {
		return parameter.split('#').length === 3;
	},

	getLibrary(
		libraryTag: string,
		libraries: Map<string, TestStepLibrary[]>
	): TestStepLibrary {
		for (const libraryName of Array.from(libraries.keys())) {
			for (const library of libraries.get(libraryName)) {
				if (library.tag === libraryTag) {
					return library;
				}
			}
		}

		return null;
	},

	getChildrenStructureIdsIfChildHasChildren(
		children: (SuiElement | TestObject)[]
	): string[] {
		const structureIds: string[] = [];
		children.forEach((child) => {
			if (child.children.length) {
				// eslint-disable-next-line functional/immutable-data
				structureIds.push(child.structureId);
				const childrenStructureIds =
					Utils.getChildrenStructureIdsIfChildHasChildren(child.children);
				if (childrenStructureIds.length) {
					// eslint-disable-next-line functional/immutable-data
					structureIds.push(...childrenStructureIds);
				}
			}
		});
		return structureIds;
	},

	getExpandAllButtonDisabled(
		allStructureIdsWithChildren: string[],
		expandedItemIds: string[]
	) {
		if (!allStructureIdsWithChildren.length) {
			return true;
		} else {
			const collapsedStructureIds = allStructureIdsWithChildren.filter(
				(id) => !expandedItemIds.includes(id)
			);
			return !collapsedStructureIds.length;
		}
	},

	getCollapseAllButtonDisabled(
		allStructureIdsWithChildren: string[],
		expandedItemIds: string[]
	) {
		if (!allStructureIdsWithChildren.length) {
			return true;
		} else {
			const expandedStructureIds = allStructureIdsWithChildren.filter((id) =>
				expandedItemIds.includes(id)
			);
			return !expandedStructureIds.length;
		}
	}
});
