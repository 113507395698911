import { Parameter } from '@testifi-models/parameter';
import {
	ICreateTestScenarioWithStepsObjectRequest,
	TestObject
} from '@testifi-models/test-object';
import {
	ICreateTestScenarioWithStepsRequest,
	TestScenario
} from '@testifi-models/test-scenario';
import {
	ICreateTestScenarioWithStepsStepRequest,
	TestStep
} from '@testifi-models/test-step';

export class ScenarioUtils {
	static convertToCreateTestScenarioWithTestStepsRequest(
		scenario: TestScenario
	): ICreateTestScenarioWithStepsRequest {
		return {
			parameters: scenario.parameters,
			name: scenario.name,
			projectId: scenario.projectId,
			description: scenario.description,
			path: '',
			projectKey: scenario.projectKey,
			xmlId: '',
			steps: scenario.testSteps.map(
				ScenarioUtils.convertToCreateTestScenarioWithTestStepsStepRequest
			),
			labelItems: scenario.labelItems
		};
	}

	static convertToCreateTestScenarioWithTestStepsStepRequest(
		testStep: TestStep,
		index: number
	): ICreateTestScenarioWithStepsStepRequest {
		return {
			childData: '',
			data: testStep.data,
			description: testStep.description,
			elementList: {
				elementList: testStep.testObjects.map(
					ScenarioUtils.convertToCreateTestScenarioWithTestStepsObjectRequest
				)
			},
			index,
			result: testStep.expectedResult,
			scenarioId: '',
			tag: testStep.tag
		};
	}

	static convertToCreateTestScenarioWithTestStepsObjectRequest(
		testObject: TestObject,
		index: number
	): ICreateTestScenarioWithStepsObjectRequest {
		return {
			children: testObject.children.map(
				ScenarioUtils.convertToCreateTestScenarioWithTestStepsObjectRequest
			),
			index,
			parameter: ScenarioUtils.convertParameterArrayToObject(
				testObject.parameters
			),
			tag: testObject.name,
			uiTag: testObject.name // this should be tag name instead of name
		};
	}

	static convertParameterArrayToObject(
		parameters: Parameter[]
	): Record<string, string> {
		return parameters.reduce((acc, parameter) => {
			acc[parameter.name] = parameter.value;
			return acc;
		}, {});
	}
}
