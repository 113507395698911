<button
	#menubutton
	type="button"
	class="aui-button aui-dropdown2-trigger aui-button-subtle multiSelectDropDownButton"
	[title]="buttonTitle"
	[textContent]="buttonLabel"
></button>

<aui-dropdown-menu #menu class="dropdown">
	<aui-section [attr.label]="label">
		<ng-template *ngIf="buttonLabel.startsWith('JIRA'); then jira; else execution"></ng-template>
	</aui-section>
</aui-dropdown-menu>

<ng-template #execution>
	<aui-item-checkbox interactive checked *ngFor="let stat of values" data-name="{{stat.status}}">
		<app-execution-status [status]="stat.status" [color]="stat.color" [count]="stat.count.toString()">
		</app-execution-status>
	</aui-item-checkbox>
</ng-template>

<ng-template #jira>
	<aui-item-checkbox interactive checked *ngFor="let stat of values" data-name="{{stat.status}}">
		<div>
			<span
				class="aui-lozenge aui-lozenge-subtle"
				[ngClass]="{
							'aui-lozenge-default': stat.color !== 'green' && stat.color !== 'yellow',
							'aui-lozenge-success': stat.color === 'green',
							'aui-lozenge-inprogress': stat.color === 'yellow'
							}"
				>{{stat.status}}</span
			>
			<span class="filter-counter">({{ stat.count }})</span>
		</div>
	</aui-item-checkbox>
</ng-template>
