export enum Type {
	NONE = 'none',
	GENERAL = 'general',
	DESCRIPTION = 'description',
	MANDATORY = 'mandatory',
	OPTIONAL = 'optional',
	OUTPUT = 'output',
	MODULES = 'modules',
	DATA = 'data',
	EXPECTED_RESULT = 'expected_result'
}

export class TypedItem<T = unknown> {
	constructor(
		public label: string,
		public type?: T
	) {}
}

export class TypedParameter extends TypedItem<Type> {
	constructor(label: string, type = Type.NONE) {
		super(label, type);
	}
}
