import { NgClass, NgIf, NgStyle } from '@angular/common';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidatorFn
} from '@angular/forms';
import { SubmitEvent } from '@testifi-shared/singleline-editor/single-line-editor.component';
import { ValidationErrorsComponent } from '@testifi-shared/validation-errors/validation-errors.component';
import { AutofocusDirective } from '../../directives/autofocus.directive';

@Component({
	selector: 'app-multi-line-editor-legacy',
	templateUrl: './multi-line-editor-legacy.component.html',
	styleUrls: ['./multi-line-editor-legacy.component.less'],
	standalone: true,
	imports: [
		NgIf,
		NgClass,
		ExtendedModule,
		NgStyle,
		FormsModule,
		ReactiveFormsModule,
		AutofocusDirective,
		ValidationErrorsComponent
	]
})
export class MultiLineEditorLegacyComponent implements OnInit, OnChanges {
	@Input() text: string;
	@Input() height = 250;
	@Input() maxWidth = 0;
	@Input() defaultText: string;
	@Input() textColor = '#6B778C';
	/**
	 * Maybe not needed at all.
	 * @deprecated
	 */
	@Input() confirmed = false;
	@Input() validators: ValidatorFn[] = [];
	@Input() breakWords = false;

	@Output() changedValue = new EventEmitter<string>();

	@ViewChild('editor') editorElement: ElementRef;

	form: UntypedFormGroup;
	editMode = false;

	private lastValue: string;

	get f(): { [key: string]: AbstractControl } {
		return this.form?.controls;
	}

	constructor(private formBuilder: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			name: new UntypedFormControl(this.text, this.validators)
		});

		this.lastValue = this.text;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.text) {
			this.lastValue = changes.text.currentValue as string;
			this.f?.name?.setValue(this.lastValue);
		}

		if (changes.confirmed?.currentValue) {
			this.f.name.setValue(this.lastValue);
			this.confirmed = false;
			this.editMode = false;
		}
	}

	onSubmit(event: SubmitEvent | null): void {
		if (
			event !== null &&
			event?.submitter?.name === 'Save' &&
			!this.form.invalid
		) {
			const text = this.f.name.value as string;
			this.changedValue.emit(text);
		}
	}

	onBlur(): void {
		// delay to give a chance the form registers button click before we deel with lost of the focus
		setTimeout(() => {
			this.editMode = false;
			this.resetForm();
		}, 300);
	}

	resetForm(): void {
		if (!this.confirmed) {
			this.f.name.setValue(this.lastValue);
		}
	}
}
