/* eslint-disable functional/immutable-data */
import {
	CdkDragDrop,
	CdkDragEnd,
	CdkDragMove,
	CdkDragStart,
	CdkDropList,
	transferArrayItem
} from '@angular/cdk/drag-drop';
import {
	CdkFixedSizeVirtualScroll,
	CdkVirtualForOf,
	CdkVirtualScrollViewport
} from '@angular/cdk/scrolling';
import { KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ValidatorFn,
	Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
	IModalFreeTextData,
	ModalFreeTextComponent
} from '@testifi-modals/modal-free-text/modal-free-text.component';
import {
	ModalTestObjectParameterComponent,
	ModalTestObjectParameterData,
	TestObjectType
} from '@testifi-modals/modal-test-object-parameter/modal-test-object-parameter.component';
import {
	IModalTypedParameterData,
	ModalTypedParameterComponent
} from '@testifi-modals/modal-typed-parameter/modal-typed-parameter.component';
import { BuildingBlock } from '@testifi-models/building-block';
import { Parameter } from '@testifi-models/parameter';
import { TestObject } from '@testifi-models/test-object';
import { TestStep, UpdateType } from '@testifi-models/test-step';
import { TestStepLibrary } from '@testifi-models/test-step-library';
import { Type, TypedItem, TypedParameter } from '@testifi-models/typed-Item';
import { ValidationType } from '@testifi-models/validation-type';
import { XmlElements } from '@testifi-models/xml-elements';
import { BuildingBlockService } from '@testifi-services/building-block.service';
import { LibraryObjectService } from '@testifi-services/library-object.service';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import { NestedDragNDropService } from '@testifi-services/nested-drag-n-drop.service';
import { NotificationService } from '@testifi-services/notification.service';
import { SuiPageService } from '@testifi-services/sui-page.service';
import { TestStepService } from '@testifi-services/test-step.service';
import {
	DESCRIPTION_VALIDATORS,
	ID_VALIDATORS,
	VALUE_RESTRICTED_VALIDATORS,
	existingParameterNameValidator
} from '@testifi-shared/app-constants';
import { IMultiLineEditorComponentMetadata } from '@testifi-shared/multi-line-editor-2/multi-line-editor.component';
import { MultiLineEditorGroupComponent } from '@testifi-shared/multi-line-editor-group/multi-line-editor-group.component';
import {
	MultiLineEditorLegacyComponent as MultiLineEditorComponent_1,
	MultiLineEditorLegacyComponent
} from '@testifi-shared/multi-line-editor/multi-line-editor-legacy.component';
import { EditTracker, State } from '@testifi-utils/edit.tracker';
import { copyArrayItem } from '@testifi-utils/ngrx-cdk-drag-utils';
import { Utils } from '@testifi-utils/utils';
import { AngularSplitModule } from 'angular-split';
import { SvgIconComponent } from 'angular-svg-icon';
import { produce } from 'immer';
import { cloneDeep, has, isEqual } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { LibrariesSortPipe } from '../../pipes/libraries-sort.pipe';
import { CommaSeparatedListComponent } from '../comma-separated-list/comma-separated-list.component';
import { LibraryDragDropElementComponent } from '../library-drag-drop-element/library-drag-drop-element.component';
import { LibraryDragDropPageElementComponent } from '../library-drag-drop-page-element/library-drag-drop-page-element.component';
import { LongTextComponent } from '../long-text/long-text.component';

export interface TestStepCloseEvent {
	step: TestStep | BuildingBlock;
	onClose: boolean;
}

@Component({
	selector: 'app-library-drag-drop-page',
	templateUrl: './library-drag-drop-page.component.html',
	styleUrls: ['./library-drag-drop-page.component.less'],
	standalone: true,
	imports: [
		HideOnLoadingDirective,
		FormsModule,
		NgFor,
		AutofocusDirective,
		CdkDropList,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		LibraryDragDropElementComponent,
		NgIf,
		LongTextComponent,
		NgClass,
		ExtendedModule,
		LibraryDragDropPageElementComponent,
		SvgIconComponent,
		FlexModule,
		MultiLineEditorComponent_1,
		CommaSeparatedListComponent,
		KeyValuePipe,
		LibrariesSortPipe,
		AngularSplitModule,
		MultiLineEditorGroupComponent,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LibraryDragDropPageComponent
	implements OnChanges, OnDestroy, OnInit, AfterViewInit
{
	// ======================================================================
	// public properties
	// ======================================================================

	@ViewChildren('setupTabGroup')
	setupTabGroup: QueryList<MatTabGroup>;
	@Input() projectId: string;
	@Input() testScenarioId: string;
	@Input() testStepId: string;
	@Input() blockId: string;
	@Input() item: TestStep | BuildingBlock;
	@Input() type: TestObjectType;
	@Input() bottomOffset = 0;
	@Input() editTracker: EditTracker<TestStep | BuildingBlock>;
	@Input() forbiddenEvent: Observable<void>;

	@Output() libraryDrop = new EventEmitter<TestObject>();
	@Output() changePosition = new EventEmitter<TestObject>();

	libraries = new Map<string, TestStepLibrary[]>();
	initialLibraries = new Map<string, TestStepLibrary[]>();
	filteredLibraries: TestStepLibrary[] = [];
	activeLibrary = '';
	libraryFilterSearch = '';
	sidebarCollapsed = false;
	DESCRIPTION_VALIDATORS: ValidatorFn[] = DESCRIPTION_VALIDATORS;
	ACTION_VALIDATORS = [...DESCRIPTION_VALIDATORS, Validators.required];
	ID_VALIDATORS = ID_VALIDATORS;
	LEFT_SECTION_DEFAULT_WIDTH = 25;
	LEFT_SECTION_MAX_WIDTH = 40;
	RIGHT_SECTION_DEFAULT_WIDTH = 100 - this.LEFT_SECTION_DEFAULT_WIDTH;
	RIGHT_SECTION_MIN_WIDTH = 100 - this.LEFT_SECTION_MAX_WIDTH;
	@ViewChild('descriptionEditor')
	descriptionEditorComponent: MultiLineEditorLegacyComponent;
	@ViewChild('resultEditor')
	resultEditorComponent: MultiLineEditorLegacyComponent;
	@ViewChild('dataEditor')
	dataEditorComponent: MultiLineEditorLegacyComponent;
	@ViewChild('blockDescriptionEditor')
	blockDescriptionEditorComponent: MultiLineEditorLegacyComponent;
	@ViewChild('testObjectMetadataFormElement')
	testObjectMetadataFormElement: MultiLineEditorGroupComponent;

	isChanged = false;
	loaded = false;
	testObjectType = TestObjectType;
	expandAllButtonDisabled = false;
	collapseAllButtonDisabled = false;
	private allStructureIdsWithChildren: string[] = [];
	private _parentItem: TestObject;

	get parentItem(): TestObject {
		return this._parentItem;
	}

	set parentItem(parentItem: TestObject) {
		this.allStructureIdsWithChildren =
			Utils.getChildrenStructureIdsIfChildHasChildren(parentItem.children);
		this.expandAllButtonDisabled = Utils.getExpandAllButtonDisabled(
			this.allStructureIdsWithChildren,
			this.expandedItemIds
		);
		this.collapseAllButtonDisabled = Utils.getCollapseAllButtonDisabled(
			this.allStructureIdsWithChildren,
			this.expandedItemIds
		);
		this._parentItem = parentItem;
	}

	testObjectMetadataForm: FormGroup<{
		action: FormControl<string>;
		data: FormControl<string>;
		expectedResult: FormControl<string>;
	}>;
	testObjectMetadataFormProperties: Record<
		string,
		IMultiLineEditorComponentMetadata
	> = {
		action: {
			inputLabel: 'Action (Step Description)',
			height: 80,
			maxWidth: 280,
			defaultText: 'Add action...',
			breakWords: true
		},
		data: {
			inputLabel: 'Data',
			height: 80,
			maxWidth: 280,
			defaultText: 'Add data...',
			breakWords: true
		},
		expectedResult: {
			inputLabel: 'Expected Result',
			height: 80,
			maxWidth: 280,
			defaultText: 'Add expected result...',
			breakWords: true
		}
	};

	// ======================================================================
	// private properties
	// ======================================================================
	private disposableBag = new Subscription();
	private isSidebarChanged = false;
	private testObjects: TestObject[];
	protected readonly ParameterType = Type;

	constructor(
		public loadingService: LoadingService,
		private cd: ChangeDetectorRef,
		private notificationService: NotificationService,
		private libraryObjectService: LibraryObjectService,
		private testStepService: TestStepService,
		private buildingBlockService: BuildingBlockService,
		private modalService: ModalService,
		private suiPageService: SuiPageService,
		private nestedDragnDropService: NestedDragNDropService,
		private formBuilder: FormBuilder
	) {
		this.nestedDragnDropService.expandedItemIds$
			.pipe(takeUntilDestroyed())
			.subscribe((expandedItemIds) => {
				this.expandAllButtonDisabled = Utils.getExpandAllButtonDisabled(
					this.allStructureIdsWithChildren,
					expandedItemIds
				);
				this.collapseAllButtonDisabled = Utils.getCollapseAllButtonDisabled(
					this.allStructureIdsWithChildren,
					expandedItemIds
				);
			});
	}

	get connectedDropListsIds(): string[] {
		return this.nestedDragnDropService.connectedDropListsIds;
	}

	get intersectedItemIds(): string[] {
		return this.nestedDragnDropService.intersectedItemIds;
	}

	get expandedItemIds(): string[] {
		return this.nestedDragnDropService.expandedItemIds;
	}

	get isDragging(): boolean {
		return this.nestedDragnDropService.isDragging;
	}

	ngAfterViewInit() {
		this.cd.detectChanges();
	}

	ngOnInit(): void {
		if (this.item instanceof TestStep) {
			this.isChanged = this.item.updateType === UpdateType.Create;
			const { expectedResult, data, description } = this.item;
			this.initializeTestObjectMetadataForm(description, data, expectedResult);
		}

		this.disposableBag.add(
			this.forbiddenEvent.subscribe(() => this.closeDialog())
		);
		this.testObjects = this.item?.testObjects;
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.checkForChanges(changes)) {
			return;
		}

		this.testObjects = this.item?.testObjects;
		this.parentItem = this.createParentItem();

		//auto refresh libraries when either projectId is set up first time or when testObjects passed again
		if (
			(changes['projectId']?.isFirstChange() ||
				!changes['testObjects']?.isFirstChange()) &&
			this.projectId
		) {
			// reconsider later refresh library startegy
			if (this.libraries.size) {
				return;
			}

			this.loadingService.active();

			this.disposableBag.add(
				this.libraryObjectService
					.libraries(this.projectId)
					.pipe(finalize(() => this.loadingService.deactive(this.cd)))
					.subscribe(
						(libraries) => {
							// if it is building block then do not show itself
							if (this.item instanceof BuildingBlock) {
								libraries.set(
									'blocks',
									libraries
										.get('blocks')
										.filter((block) => block.id !== this.item.id)
								);
							}
							this.activeLibrary = 'all';
							this.libraries = this.sortLibraries(libraries);
							this.initialLibraries = this.cloneLibraries(this.libraries);
							this.filteredLibraries = this.filterLibraries(
								this.cloneLibraries(this.libraries)
							);
							this.nestedDragnDropService.libraries = this.libraries;
							this.loaded = true;
						},
						(err: HttpErrorResponse) => {
							this.notificationService.httpError(err);
						}
					)
			);
		}
	}

	closeDialog(): void {
		this.nestedDragnDropService.expandedItemIds = [];
		this.modalService.close(this.isChanged ? this.item : null);
	}

	/**
	 * Only usage in tests
	 * @deprecated
	 */
	onEdit(value: string, type: string): void {
		this.item = produce(this.item as TestStep, (draft) => {
			switch (type) {
				case 'description':
					draft.description = value;
					break;
				case 'expected result':
					draft.expectedResult = value;
					break;
				case 'data':
					draft.data = value;
					break;
			}
		});

		this.editTracker.addState(new State(this.item));
		this.saveTestStepSidebar(this.item);
	}

	onRefreshOnModalClose(event: {
		structureId: string;
		action?: string;
		isChanged: boolean;
	}): void {
		if (!event) {
			return;
		}

		if (!(this.item instanceof TestStep) || !this.isChanged) {
			this.isChanged = event.isChanged;
		}

		if (!this.isChanged) {
			return;
		}

		if (event.action === 'removal') {
			const { updated, structureIdTransitions } = cloneDeep(
				this.parentItem
			).removeChild(event.structureId);
			this.parentItem = updated;

			this.item = produce(this.item, (draft) => {
				draft.testObjects = this.parentItem.children;
			});

			this.nestedDragnDropService.handleExpandOnRemove(
				event.structureId,
				this.parentItem.children,
				structureIdTransitions
			);
		}

		this.editTracker.addState(
			new State(this.item, this.nestedDragnDropService.expandedItemIds)
		);
	}

	onParameterClick(data: {
		testObject: TestObject;
		parameter: Parameter;
	}): void {
		const parameter = data.parameter;
		const testObject = data.testObject;

		if (parameter?.name === 'pageId') {
			const lastAddedSuiPage = this.findLastAddedSuiPage(testObject);

			if (lastAddedSuiPage) {
				this.loadingService.active();
				this.disposableBag.add(
					this.suiPageService
						.ids(lastAddedSuiPage.id)
						.pipe(finalize(() => this.loadingService.deactive(this.cd)))
						.subscribe(
							(suiPageElementIds) => {
								this.openTestObjectParameterModal(
									testObject,
									parameter,
									suiPageElementIds.ids
								);
							},
							(err: HttpErrorResponse) => {
								this.notificationService.httpError(err);
							}
						)
				);
				return;
			}
		}
		this.openTestObjectParameterModal(testObject, parameter);
	}

	openTestObjectParameterModal(
		testObject: TestObject,
		parameter: Parameter,
		values: string[] = []
	): void {
		const dialogRef = this.modalService.open(
			ModalTestObjectParameterComponent,
			(result) => {
				if (result) {
					this.isChanged = true;
					const to = result as TestStep;
					const parentItem = new TestObject();
					parentItem.name = 'Root';
					parentItem.description = to.description;
					parentItem.children = to.testObjects;
					parentItem.parameters = [];
					this.parentItem = parentItem.restructure();
					this.editTracker.addState(new State(to, this.expandedItemIds));
				}
			},
			{
				parameter,
				testObject,
				testStepLibrary: this.library(testObject.name),
				testScenarioId: this.testScenarioId,
				testStepId: this.testStepId || this.blockId,
				testObjectType: this.type,
				values,
				rootTestObject: this.parentItem.restructure()
			} as ModalTestObjectParameterData
		);
		this.disposableBag.add(
			(
				dialogRef.componentInstance as ModalTestObjectParameterComponent
			).forbiddenError.subscribe(() => this.closeDialog())
		);
	}

	toggleCollapsedSideBar(): void {
		this.sidebarCollapsed = !this.sidebarCollapsed;
	}

	cloneLibraries(
		mapIn: Map<string, TestStepLibrary[]>
	): Map<string, TestStepLibrary[]> {
		const mapCloned: Map<string, TestStepLibrary[]> = new Map<
			string,
			TestStepLibrary[]
		>();

		mapIn.forEach((items: TestStepLibrary[], key: string) => {
			mapCloned.set(key, items.slice(0));
		});

		return this.sortLibraries(mapCloned);
	}

	library(tag: string): TestStepLibrary {
		for (const libraryName of Array.from(this.libraries.keys())) {
			for (const library of this.libraries.get(libraryName)) {
				if (library.tag === tag) {
					return library;
				}
			}
		}

		return null;
	}

	onLibraryChange(event: Event): void {
		const selected = event.target['value'] as string;
		this.libraryFilterSearch = '';
		this.activeLibrary = selected;
		this.filteredLibraries = [];
		this.cd.detectChanges();

		setTimeout(() => {
			this.filteredLibraries = this.filterLibraries(
				this.cloneLibraries(this.initialLibraries)
			);
			this.cd.detectChanges();
		}, 750);
	}

	onSearchLibrary(event: Event): void {
		this.libraryFilterSearch = event.target['value'] as string;
		this.filteredLibraries = this.filterLibraries(
			this.cloneLibraries(this.initialLibraries)
		);
	}

	onDragDrop(event: CdkDragDrop<TestObject>): void {
		const libraryName = event.item.element.nativeElement.dataset.library;
		const containerChanged = event.container !== event.previousContainer;
		const positionChanged = event.currentIndex !== event.previousIndex;

		// if library is available -> new testObject with library has to be created
		if (libraryName) {
			const testStepLibraryTag = event.item.element.nativeElement.dataset.tag;

			this.libraries = this.sortLibraries(this.initialLibraries);

			const testStepModules = this.libraries.get(libraryName);
			const testStepModule = testStepModules.find(
				(e) => e.tag === testStepLibraryTag
			);
			const targetContainer = event.container.data;

			const targetArray = this.transferNewModuleToTestObject(
				testStepModule,
				targetContainer,
				event.currentIndex
			);

			const structureIdTransitions =
				this.restructureWithUpdatedContainerChildren(
					targetArray,
					targetContainer.structureId
				);

			const structureId = `${event.container.data.structureId}.${event.currentIndex}`;
			this.nestedDragnDropService.handleExpandOnAdd(
				structureId,
				structureIdTransitions
			);

			this.libraryDrop.emit(this.parentItem);
		} else if (positionChanged || containerChanged) {
			const parentItem = cloneDeep(this.parentItem);
			event.previousContainer.data = Utils.findChildByStructureId<TestObject>(
				parentItem,
				event.previousContainer.data.structureId
			);
			event.container.data = Utils.findChildByStructureId<TestObject>(
				parentItem,
				event.container.data.structureId
			);

			transferArrayItem(
				event.previousContainer.data.children,
				event.container.data.children,
				event.previousIndex,
				event.currentIndex
			);

			const { updated, structureIdTransitions } = produce(
				this.parentItem,
				(draft) => {
					draft.children = parentItem.children;
				}
			).restructureAndGetStructureIdTransitions();
			this.parentItem = updated;

			this.nestedDragnDropService.handleExpandOnMove(
				event,
				structureIdTransitions
			);

			this.changePosition.emit(this.parentItem);
		}
	}

	onShortcutClick(module: TestStepLibrary): void {
		const parentItem = cloneDeep(this.parentItem);
		const targetTestObject = Utils.findChildByStructureId<TestObject>(
			parentItem,
			parentItem.structureId
		);

		const targetArray = this.transferNewModuleToTestObject(
			module,
			targetTestObject,
			this.parentItem.children.length // to be added as last item
		);

		this.restructureWithUpdatedContainerChildren(
			targetArray,
			targetTestObject.structureId
		);

		this.libraryDrop.emit(this.parentItem);
	}

	onToggleExpanded(id: string, isRealClick = false): void {
		this.nestedDragnDropService.onToggleExpanded(id);
		if (isRealClick) {
			this.editTracker.updateStatus(
				this.nestedDragnDropService.expandedItemIds
			);
		}
	}

	onLibDragEnded(): void {
		this.nestedDragnDropService.onLibDragEnded();
	}

	onLibDragMove(event: CdkDragMove): void {
		this.nestedDragnDropService.onLibDragMove(event);
	}

	onLibDragStarted(event: CdkDragStart): void {
		this.nestedDragnDropService.onLibDragStarted(event);
	}

	onDragEnded(event: CdkDragEnd<TestObject>): void {
		this.nestedDragnDropService.onDragEnded(event);
	}

	onDragStarted(event: CdkDragStart<TestObject>): void {
		this.nestedDragnDropService.onDragStarted(event);
	}

	onDragMove(event: CdkDragMove<TestObject>): void {
		this.nestedDragnDropService.onDragMove(event);
	}

	onClickUndo(): void {
		this.applyState(this.editTracker.undo(), true);
	}

	onClickRedo(): void {
		this.applyState(this.editTracker.redo(), false);
	}

	onCBBDescriptionChange(value: string): void {
		const item = produce(this.item as BuildingBlock, (draft) => {
			draft.description = value;
		});
		this.saveBuildingBlockSidebar(item, null);
	}

	onAddModuleClicked(): void {
		this.modalService.open(
			ModalFreeTextComponent,
			(data: string) => {
				if (!data) {
					return;
				}
				const item = produce(this.item as BuildingBlock, (draft) => {
					draft.modules.push(data);
				});
				this.saveBuildingBlockSidebar(item, null);
			},
			{
				validators: VALUE_RESTRICTED_VALIDATORS,
				modalTitle: 'Add Module',
				fieldLabel: 'Value'
			} as IModalFreeTextData
		);
	}

	onParameterClicked(parameter: TypedItem): void {
		this.modalService.open<IModalTypedParameterData>(
			ModalTypedParameterComponent,
			() => void 0,
			{
				parameter: parameter as TypedParameter
			}
		);
	}

	onAddParameterClicked(): void {
		const selectedItem = this.item as BuildingBlock;
		const existingParameterNames = [
			...selectedItem.mandatoryParameters.map((p) => Utils.getItemName(p)),
			...selectedItem.optionalParameters.map((p) => Utils.getItemName(p))
		];
		this.modalService.open(
			ModalTypedParameterComponent,
			(data: TypedParameter) => {
				if (!data) {
					return;
				}
				const item = produce(selectedItem, (draft) => {
					if (data.type === Type.MANDATORY) {
						draft.mandatoryParameters.push(data.label);
					} else if (data.type === Type.OPTIONAL) {
						draft.optionalParameters.push(data.label);
					}
				});
				this.saveBuildingBlockSidebar(item, null);
			},
			{
				parameterNameValidators: [
					...ID_VALIDATORS,
					existingParameterNameValidator(existingParameterNames)
				]
			} as IModalTypedParameterData
		);
	}

	onAddOutputParameterClicked(): void {
		this.modalService.open(
			ModalFreeTextComponent,
			(data: string) => {
				if (!data) {
					return;
				}
				const item = produce(this.item as BuildingBlock, (draft) => {
					draft.outputParameters.push(data);
				});
				this.saveBuildingBlockSidebar(item, null);
			},
			{
				validators: VALUE_RESTRICTED_VALIDATORS,
				modalTitle: 'Add Output Parameter',
				fieldLabel: 'Value'
			} as IModalFreeTextData
		);
	}

	onDeleteModuleClicked(module: TypedItem): void {
		const item = produce(this.item as BuildingBlock, (draft) => {
			draft.modules = draft.modules.filter((m) => m !== module.label);
		});
		this.saveBuildingBlockSidebar(item, null);
	}

	onDeleteParameterClicked(parameter: TypedItem): void {
		const item = produce(this.item as BuildingBlock, (draft) => {
			if (parameter.type === Type.MANDATORY) {
				draft.mandatoryParameters = draft.mandatoryParameters.filter(
					(m) => m !== parameter.label
				);
			} else if (parameter.type === Type.OPTIONAL) {
				draft.optionalParameters = draft.optionalParameters.filter(
					(m) => m !== parameter.label
				);
			}
		});
		this.saveBuildingBlockSidebar(item, null);
	}

	onDeleteOutputParameterClicked(outputParameter: TypedItem): void {
		const item = produce(this.item as BuildingBlock, (draft) => {
			draft.outputParameters = draft.outputParameters.filter(
				(m) => m !== outputParameter.label
			);
		});
		this.saveBuildingBlockSidebar(item, null);
	}

	// ======================================================================
	// private functions
	// ======================================================================

	private restructureWithUpdatedContainerChildren(
		targetArray: TestObject[],
		containerStructureId: string
	): string[][] {
		const { updated, structureIdTransitions } = produce(
			this.parentItem,
			(draft) => {
				const container = Utils.findChildByStructureId(
					draft,
					containerStructureId
				);
				container.children = targetArray;
			}
		).restructureAndGetStructureIdTransitions();
		this.parentItem = updated;
		return structureIdTransitions;
	}

	private findLastAddedSuiPage(testObject: TestObject): TestStepLibrary {
		let lastAddedSuiPage: TestStepLibrary;

		if (testObject.nested) {
			for (const child of testObject.parent.children) {
				const suiPage = this.getPageFromLibrary(child);

				if (suiPage && testObject.index > child.index) {
					lastAddedSuiPage = suiPage;
				}
			}
		} else {
			for (let i = testObject.index - 1; i >= 0; --i) {
				const suiPage = this.getPageFromLibrary(testObject.parent.children[i]);

				if (suiPage) {
					lastAddedSuiPage = suiPage;
					break;
				}
			}
		}

		return lastAddedSuiPage;
	}

	private getPageFromLibrary(testObject: TestObject): TestStepLibrary {
		return this.libraries.get('pages').find((e) => e.name === testObject.name);
	}

	private saveBuildingBlockSidebar(
		item: BuildingBlock,
		isUndo: boolean | null
	): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.buildingBlockService
				.edit(item.id, {
					description: item.description,
					name: item.name,
					documentation: '',
					projectId: this.projectId,
					modules: item.modules,
					mandatoryParameters: item.mandatoryParameters ?? [],
					optionalParameters: item.optionalParameters ?? [],
					outputParameters: item.outputParameters ?? []
				})
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(block) => {
						this.item = block;
						this.editTracker.addState(new State(this.item));
						this.isChanged = true;
						if (isUndo === null) {
							this.notificationService.info(
								`Building block details were updated`
							);
						} else {
							this.notificationService.info(
								`${isUndo ? 'Undo' : 'Redo'} successfully performed`
							);
						}
					},
					(err: HttpErrorResponse) => {
						this.blockDescriptionEditorComponent.resetForm();
						this.notificationService.httpError(err);
					}
				)
		);
	}

	private saveTestStepSidebar(item: TestStep, isUndo?: boolean): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.testStepService
				.edit(
					this.item.id,
					item.description,
					item.expectedResult,
					item.data,
					item.scenarioId,
					item.index
				)
				.pipe(
					finalize(() => {
						this.loadingService.deactive(this.cd);
					})
				)
				.subscribe(
					(step) => {
						this.item = step;
						this.editTracker.addState(new State(this.item));
						const { expectedResult, description, data } = this.item;
						this.initializeTestObjectMetadataForm(
							description,
							data,
							expectedResult
						);
						this.isChanged = true;
						if (isUndo === undefined) {
							this.notificationService.info(
								`The test step metadata was updated`
							);
							this.testObjectMetadataFormElement.editMode = false;
						} else {
							this.notificationService.info(
								`${isUndo ? 'Undo' : 'Redo'} successfully performed`
							);
						}
					},
					(err: HttpErrorResponse) => {
						if (isUndo !== undefined) {
							this.notificationService.error(
								`Failed to perform ${isUndo ? 'Undo' : 'Redo'}`
							);
						}
						this.notificationService.httpError(err);
						this.testObjectMetadataForm.reset();
					}
				)
		);
	}

	private saveSteps(parentItem: TestObject, isUndo: boolean): void {
		this.loadingService.active();

		parentItem = produce(parentItem, (draft) => {
			draft.children = cloneDeep(parentItem.children);
		});

		this.disposableBag.add(
			this.testStepService
				.updateTestObjects(
					this.testStepId,
					new XmlElements().fromTestObject(parentItem)
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(testStep) => {
						this.testObjects = testStep.testObjects;
						this.parentItem = parentItem;
						this.notificationService.info(
							`${isUndo ? 'Undo' : 'Redo'} successfully performed`
						);
					},
					(err: HttpErrorResponse) => {
						this.notificationService.error(
							`Failed to perform ${isUndo ? 'Undo' : 'Redo'}`
						);
						this.notificationService.httpError(err);
					}
				)
		);
	}

	private createParentItem(): TestObject {
		const parentItem = produce(new TestObject(), (draft) => {
			draft.name = 'Root';
			draft.description =
				'This element is just a placeholder and defines the root';
			draft.children = this.testObjects;
			draft.parameters = [];
			draft.validationType = ValidationType.success;
		}).restructure();
		this.nestedDragnDropService.parentItem = parentItem;

		return parentItem;
	}

	private checkForChanges(changes: SimpleChanges): boolean {
		let hasChanges = false;
		for (const property in changes) {
			if (
				property.indexOf('Event') < 0 &&
				!isEqual(
					changes[property].currentValue,
					changes[property].previousValue
				)
			) {
				hasChanges = true;
				if (changes[property].previousValue) {
					this.isChanged = true;
				}
			}
		}
		return hasChanges;
	}

	private updateSidebarChangedField(
		targetValue: string,
		sourceValue: string
	): string {
		this.isSidebarChanged ||= targetValue !== sourceValue;
		return sourceValue;
	}

	private updateSidebarChangedFieldArray(
		targetValue: string[],
		sourceValue: string[]
	): string[] {
		this.isSidebarChanged ||= !isEqual(targetValue, sourceValue);
		return sourceValue;
	}

	private applyState(
		nextState: State<TestStep | BuildingBlock>,
		isUndo: boolean
	): void {
		this.updateStateFields(nextState);
		const parentItem = this.createParentItem();

		// Update backend
		if (nextState.stateObj instanceof TestStep) {
			this.isSidebarChanged
				? this.saveTestStepSidebar(cloneDeep(this.item) as TestStep, isUndo)
				: this.saveSteps(parentItem, isUndo);
		} else if (nextState.stateObj instanceof BuildingBlock) {
			this.isSidebarChanged
				? this.saveBuildingBlockSidebar(
						cloneDeep(this.item) as BuildingBlock,
						isUndo
					)
				: this.saveBuildingBlock(nextState.stateObj.id, parentItem, isUndo);
		}

		this.nestedDragnDropService.expandedItemIds =
			nextState.expandedStateElements;
	}

	private updateStateFields(nextState: State<TestStep | BuildingBlock>) {
		this.isSidebarChanged = false;
		this.testObjects = nextState.stateObj.testObjects;
		this.item = produce(this.item, (draft) => {
			draft.testObjects = this.testObjects;
			draft.description = this.updateSidebarChangedField(
				this.item.description,
				nextState.stateObj.description
			);

			if (nextState.stateObj instanceof TestStep && draft instanceof TestStep) {
				draft.expectedResult = this.updateSidebarChangedField(
					draft.expectedResult,
					nextState.stateObj.expectedResult
				);
				draft.data = this.updateSidebarChangedField(
					draft.data,
					nextState.stateObj.data
				);
			} else if (
				nextState.stateObj instanceof BuildingBlock &&
				draft instanceof BuildingBlock
			) {
				draft.modules = this.updateSidebarChangedFieldArray(
					draft.modules,
					nextState.stateObj.modules
				);
				draft.mandatoryParameters = this.updateSidebarChangedFieldArray(
					draft.mandatoryParameters,
					nextState.stateObj.mandatoryParameters
				);
				draft.optionalParameters = this.updateSidebarChangedFieldArray(
					draft.optionalParameters,
					nextState.stateObj.optionalParameters
				);
				draft.outputParameters = this.updateSidebarChangedFieldArray(
					draft.outputParameters,
					nextState.stateObj.outputParameters
				);
			}
		});
	}

	private saveBuildingBlock(
		id: string,
		parentItem: TestObject,
		isUndo: boolean
	) {
		this.loadingService.active();

		parentItem = produce(parentItem, (draft) => {
			draft.children = cloneDeep(parentItem.children);
		});

		this.disposableBag.add(
			this.buildingBlockService
				.updateTestObjects(id, new XmlElements().fromTestObject(parentItem))
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(buildingBlock) => {
						this.testObjects = buildingBlock.testObjects;
						this.parentItem = parentItem;
						this.notificationService.info(
							`${isUndo ? 'Undo' : 'Redo'} successfully performed`
						);
					},
					(err: HttpErrorResponse) => {
						// refresh after error in order that order is correct
						this.notificationService.httpError(err);
					}
				)
		);
	}

	private transferNewModuleToTestObject(
		testStepModule: TestStepLibrary,
		containerData: TestObject,
		targetIndex: number
	): TestObject[] {
		const items = containerData.children;
		let testObject = new TestObject();
		const parameters = this.getParameters(testStepModule);

		testObject = produce(testObject, (draft) => {
			draft.name = testStepModule.tag;
			draft.description = testStepModule.description;
			draft.children = [];
			draft.parameters = Object.keys(parameters).map((key) => {
				const parameter = new Parameter();
				const libraryIsCBB = testStepModule.group === 'blocks';
				parameter.name = libraryIsCBB ? Utils.getItemName(key) : key;
				parameter.value = parameters[key];
				parameter.validationType = ValidationType.success;

				return parameter;
			});
		});

		const { targetArray } = copyArrayItem([testObject], items, 0, targetIndex);

		return targetArray;
	}

	private sortLibraries(libraries: Map<string, TestStepLibrary[]>) {
		for (const [key, value] of libraries) {
			libraries.set(
				key,
				[...value].sort((a, b) => Utils.compare(a.count, b.count, false))
			);
		}

		return libraries;
	}

	private getAllLibraries(
		libraries: Map<string, TestStepLibrary[]>
	): TestStepLibrary[] {
		const allLibraries: TestStepLibrary[] = [];
		libraries.forEach((libraryList) => {
			allLibraries.push(...libraryList);
		});
		return allLibraries;
	}

	private filterLibraries(
		libraries: Map<string, TestStepLibrary[]>
	): TestStepLibrary[] {
		let filteredLibraries: TestStepLibrary[] =
			this.activeLibrary === 'all'
				? this.getAllLibraries(libraries)
				: libraries.get(this.activeLibrary);

		if (this.libraryFilterSearch != null && this.libraryFilterSearch !== '') {
			filteredLibraries = Utils.searchUITagOrName(
				filteredLibraries,
				this.libraryFilterSearch
			);
		}

		filteredLibraries = [...filteredLibraries].sort((a, b) =>
			Utils.compare(a.count, b.count, false)
		);

		return filteredLibraries;
	}

	private getParameters(module: TestStepLibrary): Record<string, string> {
		const parameters = {};

		for (const key of Object.keys(module.mandatoryFields)) {
			const field = module.mandatoryFields[key];
			let parameterValue = '';

			if (has(module.standardValues, key)) {
				parameterValue = module.standardValues[key];
			} else if (field.length > 0) {
				parameterValue = field[0];
			}

			parameters[key] = parameterValue;
		}

		return parameters;
	}

	onEditTestStepMetadata() {
		const item = produce(this.item as TestStep, (draft) => {
			draft.description = this.testObjectMetadataForm.controls.action.value;
			draft.expectedResult =
				this.testObjectMetadataForm.controls.expectedResult.value;
			draft.data = this.testObjectMetadataForm.controls.data.value;
		});

		this.saveTestStepSidebar(item);
	}

	expandAll() {
		const allStructureIds = Utils.getChildrenStructureIdsIfChildHasChildren(
			this.parentItem.children
		);
		this.nestedDragnDropService.expand(allStructureIds);
	}

	collapseAll() {
		const allStructureIds = Utils.getChildrenStructureIdsIfChildHasChildren(
			this.parentItem.children
		);
		this.nestedDragnDropService.collapse(allStructureIds);
	}

	private initializeTestObjectMetadataForm(
		description: string,
		data: string,
		expectedResult: string
	) {
		this.testObjectMetadataForm = this.formBuilder.nonNullable.group({
			action: [description, [...DESCRIPTION_VALIDATORS, Validators.required]],
			data: [data, [...DESCRIPTION_VALIDATORS]],
			expectedResult: [expectedResult, [...DESCRIPTION_VALIDATORS]]
		});
	}
}
