<section
	id="modal-confirm"
	class="aui-dialog2 aui-dialog2-small"
	[ngClass]="{'aui-dialog2-warning': data.type==='warning'}"
	role="dialog"
	tabindex="-1"
	aria-labelledby="modal-confirm--heading"
>
	<!-- Dialog header -->
	<header class="aui-dialog2-header">
		<h2 class="aui-dialog2-header-main" id="modal-confirm--heading">{{ data.title }}</h2>
	</header>

	<!-- Main dialog content -->
	<div class="aui-dialog2-content">
		<p>{{ data.text }}</p>
		<p>{{ data.text2 }}</p>
	</div>

	<!-- Dialog footer -->
	<footer class="aui-dialog2-footer">
		<div class="aui-dialog2-footer-actions">
			<button
				id="popup-accept"
				type="button"
				*ngIf="data.confirmButton"
				class="aui-button aui-button-primary"
				(click)="confirm()"
			>
				{{ data.confirmButton }}
			</button>
			<button
				id="popup-decline"
				type="button"
				*ngIf="data.cancelButton"
				class="aui-button aui-button-subtle"
				modalClose
			>
				{{ data.cancelButton }}
			</button>
		</div>
	</footer>
</section>
