import { Injectable } from '@angular/core';
import { IDataResponse } from '@testifi-models/data.model';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PrivateHttpClient } from '../https/instances/private.http';

@Injectable({
	providedIn: 'root'
})
export class DataService {
	private deviceList: string[] = null;

	constructor(private privateHttp: PrivateHttpClient) {}

	getDataFiles(projectId: string): Observable<IDataResponse[]> {
		return this.privateHttp.get<IDataResponse[]>(`project/${projectId}/data`);
	}

	getSelectors(dataFileId: string): Observable<string[]> {
		return this.privateHttp.get<string[]>(`data/${dataFileId}`);
	}

	getValues(
		projectId: string,
		dataFileName: string,
		selector: string
	): Observable<string[]> {
		return this.privateHttp.get<string[]>(
			`project/${projectId}/data/${dataFileName}/selectors/${selector}`
		);
	}

	getDeviceList(projectId: string): Observable<string[]> {
		return this.deviceList
			? of(this.deviceList)
			: this.privateHttp
					.get<string[]>(`data/deviceList/${projectId}`)
					.pipe(tap((list) => (this.deviceList = list)));
	}
}
