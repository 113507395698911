export class ScenarioFilter {
	static readonly FILTER_STORAGE_KEY = 'scenario.filters';
	static readonly FilterFields = {
		reporterFilter: 'reporter',
		textFilter: 'text'
	};
	reporter: string = undefined;
	text: string = undefined;

	constructor(reporter?: string, text?: string) {
		this.reporter = reporter;
		this.text = text;
	}
}
