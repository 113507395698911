import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { HomeEffect } from '@testifi-store/home/home.effect';
import { HomeRepository } from '@testifi-store/home/home.repository';

@Component({
	selector: 'app-last-executed-scenarios',
	standalone: true,
	imports: [CommonModule, MatIconModule],
	templateUrl: './last-executed-scenarios.component.html',
	styleUrls: ['./last-executed-scenarios.component.less']
})
export class LastExecutedScenariosComponent implements OnInit {
	private readonly homeRepository = inject(HomeRepository);
	private readonly homeEffect = inject(HomeEffect);

	lastExecutedScenarios = this.homeRepository.lastExecutedScenarios$;
	lastExecutedScenariosLoading$ =
		this.homeRepository.lastExecutedScenariosLoading$;

	ngOnInit() {
		this.homeEffect.getLastExecutedScenarios();
	}
}
