/* eslint-disable functional/immutable-data */
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestObjectType } from '@testifi-modals/modal-test-object-parameter/modal-test-object-parameter.component';
import { TestObject } from '@testifi-models/test-object';
import { TestStep, UpdateType } from '@testifi-models/test-step';
import { XmlElements } from '@testifi-models/xml-elements';
import { LoadingService } from '@testifi-services/loading.service';
import {
	NestedDragNDropService,
	STRUCTURE_ID_PREFIX
} from '@testifi-services/nested-drag-n-drop.service';
import { NotificationService } from '@testifi-services/notification.service';
import { TestStepService } from '@testifi-services/test-step.service';
import { TestStepCloseEvent } from '@testifi-shared/library-drag-drop-page/library-drag-drop-page.component';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { EditTracker, State } from '@testifi-utils/edit.tracker';
import { produce } from 'immer';
import { cloneDeep } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { LibraryDragDropPageComponent } from '../../shared/library-drag-drop-page/library-drag-drop-page.component';

@Component({
	selector: 'app-modal-edit-test-step',
	templateUrl: './modal-edit-test-step.component.html',
	styleUrls: ['./modal-edit-test-step.component.less'],
	providers: [
		NestedDragNDropService,
		{
			provide: STRUCTURE_ID_PREFIX,
			multi: true,
			useValue: TestObject.STRUCTURE_ID_PREFIX
		}
	],
	standalone: true,
	imports: [HideOnLoadingDirective, LibraryDragDropPageComponent]
})
export class ModalEditTestStepComponent implements OnInit, OnDestroy {
	// ======================================================================
	// public properties
	// ======================================================================

	@Output() testStepUpdateEmmiter = new EventEmitter<TestStep>();
	forbiddenEvent = new Subject<void>();
	testStep: TestStep;
	editTracker = new EditTracker<TestStep>(this.appConfigRepository);
	testObjectType = TestObjectType;

	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	constructor(
		private cd: ChangeDetectorRef,
		private testStepService: TestStepService,
		private nestedDnDService: NestedDragNDropService,
		private notificationService: NotificationService,
		private loadingService: LoadingService,
		private appConfigRepository: AppConfigRepository,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			testStepId: string;
			testScenarioId: string;
			projectId: string;
			isNew: boolean;
		}
	) {}

	ngOnInit(): void {
		this.refresh();
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	onRefreshOnModalClose(event: TestStepCloseEvent): void {
		if (event.onClose) {
			this.testStepUpdateEmmiter.emit(event.step as TestStep);
		}
	}

	onLibraryDrop(root: TestObject): void {
		this.updateBlock(
			produce(root, (draft) => {
				draft.children = cloneDeep(root.children);
			}),
			'Test object was added'
		);
	}

	onChangePosition(root: TestObject): void {
		this.updateBlock(root, 'Test Step was updated');
	}

	// ======================================================================
	// private functions
	// ======================================================================
	private updateBlock(root: TestObject, notificationMessage: string): void {
		this.loadingService.active();

		this.disposableBag.add(
			this.testStepService
				.updateTestObjects(
					this.data.testStepId,
					new XmlElements().fromTestObject(cloneDeep(root.restructure()))
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					() => {
						this.notificationService.info(notificationMessage);
						this.refresh();
					},
					(err: HttpErrorResponse) => {
						// refresh after error in order that order is correct
						this.refresh();
						this.notificationService.httpError(err);
					}
				)
		);
	}

	private refresh() {
		this.loadingService.active();

		this.testStepService
			.view(this.data.testStepId)
			.pipe(finalize(() => this.loadingService.deactive(this.cd)))
			.subscribe(
				(stepView) => {
					stepView.setUpdateType(
						this.data.isNew ? UpdateType.Create : UpdateType.Update
					);
					this.testStep = stepView;
					this.editTracker.addState(
						new State<TestStep>(stepView, this.nestedDnDService.expandedItemIds)
					);
				},
				(err: HttpErrorResponse) => {
					this.notificationService.httpError(err);
				}
			);
	}
}
