import { DOCUMENT, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	HostListener,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import {
	MatLegacyPaginatorModule,
	MatLegacyPaginator as MatPaginator,
	LegacyPageEvent as PageEvent
} from '@angular/material/legacy-paginator';
import { MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { ModalEditSuiPageElementsComponent } from '@testifi-modals/modal-edit-sui-page-elements/modal-edit-sui-page-elements.component';
import { ProjectView } from '@testifi-models/project-view';
import { SuiPage } from '@testifi-models/sui-page';
import { BreadcrumbService } from '@testifi-services/breadcrumb.service';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalData, ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import { ProjectService } from '@testifi-services/project.service';
import { RouterService } from '@testifi-services/router.service';
import { SuiPageService } from '@testifi-services/sui-page.service';
import { PAGES } from '@testifi-shared/app-constants';
import { CheckboxComponent } from '@testifi-shared/checkbox/checkbox.component';
import { LongTextExpansionState } from '@testifi-shared/long-text/long-text.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { concatAll, finalize, switchMap, tap } from 'rxjs/operators';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { ModalRouterNameDirective } from '../../directives/modal-router-name.directive';
import { StylePaginatorDirective } from '../../directives/style-paginator.directive';
import { UnorderdKeyValuePipe } from '../../pipes/unorder-key-value.pipe';
import { BreadcrumbComponent } from '../../shared/breadcrumb/breadcrumb.component';
import { CheckboxComponent as CheckboxComponent_1 } from '../../shared/checkbox/checkbox.component';
import { KpiBoxComponent } from '../../shared/kpi-box/kpi-box.component';
import { LongTextComponent } from '../../shared/long-text/long-text.component';

enum ColNames {
	NAME = 'NAME'
}

const PAGE_SIZE_ITEM_NAME = 'suiPage.pageSize';

@Component({
	selector: 'app-page-view-project-sui-pages',
	templateUrl: './page-view-project-sui-pages.component.html',
	styleUrls: ['./page-view-project-sui-pages.component.less'],
	standalone: true,
	imports: [
		HideOnLoadingDirective,
		BreadcrumbComponent,
		ModalRouterNameDirective,
		NgIf,
		FlexModule,
		KpiBoxComponent,
		FormsModule,
		SvgIconComponent,
		MatSortModule,
		CheckboxComponent_1,
		NgFor,
		LongTextComponent,
		MatLegacyPaginatorModule,
		StylePaginatorDirective,
		UnorderdKeyValuePipe
	]
})
export class PageViewProjectSuiPagesComponent implements OnInit, OnDestroy {
	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();
	private window: Window;

	// ======================================================================
	// public properties
	// ======================================================================
	@ViewChild('paginator') paginator: MatPaginator;
	@ViewChild('selectAllCheckbox')
	selectAllCheckbox: CheckboxComponent;

	suiPages: SuiPage[] = [];
	sortedSuiPages: SuiPage[] = [];
	projectView: ProjectView;
	projectId: string;
	searchText = '';
	checkedItems = new Map<string, SuiPage>();

	defaultSortedColumn = ColNames.NAME;
	defaultSort: SortDirection = 'asc';
	tableHead = new Map<ColNames, { title: string; sortable: boolean }>([
		[
			this.defaultSortedColumn,
			{
				title: 'SUI Page Name',
				sortable: true
			}
		]
	]);

	lastSort: Sort;
	pageSize = 5;
	totalItems = 0;
	currentPage = 0;
	sortColumn: string = ColNames.NAME.toUpperCase();

	expandButtonState = new Map<string, boolean>();
	toggleState: LongTextExpansionState = { id: '', state: false };

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private cd: ChangeDetectorRef,
		private suiPageService: SuiPageService,
		private loadingService: LoadingService,
		private notificationService: NotificationService,
		private breadcrumbService: BreadcrumbService,
		private projectService: ProjectService,
		private route: ActivatedRoute,
		private routerService: RouterService,
		private modalService: ModalService,
		@Inject(DOCUMENT) document: Document
	) {
		this.window = document.defaultView;
	}

	@HostListener('window:popstate', ['$event'])
	onPopState(): void {
		this.breadcrumbService.set(
			new Map([
				['Projects', null],
				[
					this.projectView.key,
					{ navigateTo: [PAGES.LANDING, `${this.projectView.key}`] }
				]
			])
		);
		this.routerService.navigate([PAGES.LANDING, `${this.projectView.key}`]);
	}

	// ======================================================================
	// inherit functions
	// ======================================================================

	ngOnInit(): void {
		if (this.window.localStorage.getItem(PAGE_SIZE_ITEM_NAME)) {
			this.pageSize = parseInt(
				this.window.localStorage.getItem(PAGE_SIZE_ITEM_NAME)
			);
		}

		if (!this.loadingService.isActive) {
			this.loadingService.active();
		}

		this.disposableBag.add(
			this.route.paramMap
				.pipe(
					tap((params) => {
						this.projectId = params.get('projectId');
					}),
					switchMap(() => this.projectService.getById(this.projectId))
				)
				.subscribe(
					(projectView) => {
						this.projectView = projectView;
						this.breadcrumbService.set(
							new Map([
								['Projects', null],
								[
									this.projectView.name,
									{ navigateTo: [PAGES.LANDING, `${this.projectView.id}`] }
								],
								['SUI Pages', null]
							])
						);
						this.refresh(null, false);
					},
					() => this.routerService.navigate([PAGES.LANDING])
				)
		);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	@HostListener('window:resize', ['$event'])
	onResize(): void {
		this.expandButtonState.clear();
	}

	// ======================================================================
	// public functions
	// ======================================================================

	refreshOnModalClose(suiPage: SuiPage): void {
		if (suiPage) {
			this.refresh();
		}
	}

	editSuiPageOnModalClose(suiPage: SuiPage): void {
		if (suiPage) {
			this.modalService.open(
				ModalEditSuiPageElementsComponent,
				() => this.refreshOnModalClose(suiPage),
				{
					suiPageId: suiPage.id
				}
			);
		}
	}

	deleteOnModalConfirm(confirm: ModalData): void {
		if (confirm) {
			const observers: Observable<SuiPage>[] = [];

			this.checkedItems.forEach((page: SuiPage, key: string) => {
				observers.push(
					this.suiPageService.delete(key).pipe(switchMap(() => of(page)))
				);
			});

			const source = forkJoin(observers);
			const deleteObserver = source.pipe(concatAll());

			this.disposableBag.add(
				deleteObserver.subscribe(
					(page) => {
						this.checkSelectAllCheckBox(false);
						this.checkedItems.clear();
						this.notificationService.info(
							`SUI page "${page.name}" deleted successfully`
						);
						this.refresh(null, true);
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
			);
		}
	}

	onSearchInput(event: Event): void {
		this.selectAllCheckbox.checked = false;
		this.searchText = (event.target as HTMLInputElement).value;
		this.currentPage = 0;

		if (!this.searchText) {
			this.onSearch();
		}
	}

	onSearch(): void {
		this.refresh(() => this.paginator?._changePageSize(this.pageSize), true);
	}

	handlePage(event: PageEvent): void {
		const isPageSizeReallyChanged = this.pageSize !== event.pageSize;
		const isPageReallyChanged = this.currentPage !== event.pageIndex;
		this.selectAllCheckbox.checked = false;
		this.checkedItems.clear();
		this.pageSize = event.pageSize;
		this.window.localStorage.setItem(PAGE_SIZE_ITEM_NAME, `${event.pageSize}`);
		this.currentPage = event.pageIndex;
		if (isPageSizeReallyChanged || isPageReallyChanged) {
			this.refresh(null, true);
		}
	}

	onSort(sort: Sort): void {
		this.lastSort = sort;
		this.currentPage = 0;
		this.refresh(() => this.paginator?._changePageSize(this.pageSize), true);
	}

	checkItem(suiPage: SuiPage): void {
		if (this.checkedItems.has(suiPage.id)) {
			this.checkedItems.delete(suiPage.id);
		} else {
			this.checkedItems.set(suiPage.id, suiPage);
		}
		if (!this.checkedItems.size) {
			this.selectAllCheckbox.checked = false;
		}
	}

	selectAll(selected: boolean): void {
		if (selected) {
			this.suiPages.forEach((suiPage) => {
				if (!this.checkedItems.has(suiPage.id)) {
					this.checkedItems.set(suiPage.id, suiPage);
				}
			});
		} else {
			this.checkedItems.clear();
		}
	}

	openEditDialog(id: string): void {
		this.modalService.open(ModalEditSuiPageElementsComponent, void 0, {
			suiPageId: id
		});
	}

	onLongTextToggle(event: boolean, suiPage: SuiPage): void {
		this.toggleState = { id: suiPage.id, state: event };
	}

	onLongTextPresent(event: boolean, suiPage: SuiPage): void {
		let state = this.expandButtonState.get(suiPage.id) || false;
		state ||= event;
		this.expandButtonState.set(suiPage.id, state);
	}

	// ======================================================================
	// private functions
	// ======================================================================

	private refresh(callback: CallableFunction = null, showLoading = true) {
		if (showLoading) {
			this.loadingService.active();
		}
		this.disposableBag.add(
			this.suiPageService
				.getAll(
					this.projectView.id,
					this.currentPage,
					this.pageSize,
					this.lastSort?.active,
					this.lastSort?.direction.toUpperCase(),
					this.searchText
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe(
					(page) => {
						this.suiPages = page.content;
						this.sortedSuiPages = page.content.slice();
						this.totalItems = page.totalNumberOfElements;

						if (callback) {
							setTimeout(() => {
								callback();
							});
						}
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}

	private checkSelectAllCheckBox(checked: boolean) {
		if (this.selectAllCheckbox?.checked) {
			this.selectAllCheckbox.checked = checked;
		}
	}
}
