import { immerable } from 'immer';
import { JsonObject, JsonProperty } from 'json2typescript';
import { TestScenario } from './test-scenario';

@JsonObject('Statistic')
export class Statistic {
	@JsonProperty('status', String)
	status: string = undefined;

	@JsonProperty('count', Number)
	count: number = undefined;

	@JsonProperty('color', String)
	color: string = undefined;
}

@JsonObject('ProjectView')
export class ProjectView {
	[immerable] = true;

	@JsonProperty('id', String)
	id: string = undefined;

	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('totalNumberofElements', Number)
	totalNumberOfElements: number = undefined;

	@JsonProperty('totalNumberofPages', Number)
	totalNumberofPages: number = undefined;

	@JsonProperty('projectKey', String)
	key: string = undefined;

	@JsonProperty('description', String, true)
	description: string = undefined;

	@JsonProperty('scenarioList', [TestScenario])
	testScenarios: Array<TestScenario> = [];

	@JsonProperty('statistics', [Statistic])
	statistics: Array<Statistic> = [];

	@JsonProperty('projectStatistics', [Statistic])
	projectStatistics: Array<Statistic> = [];
}
