<form class="aui top-label form" [formGroup]="form" *ngIf="form">
	<div fxLayout>
		<div fxLayout="column" fxFlex>
			<header class="aui-page-header">
				<div class="aui-page-header-inner">
					<div class="aui-page-header-main">
						<h3>Scenario Level Statuses</h3>
					</div>
				</div>
			</header>
			<div class="module">
				<div class="field-group">
					<label class="label" for="scenarioTodo">To do</label>
					<input class="input-text" type="text" formControlName="scenarioTodo" id="scenarioTodo" placeholder="To do" />
					<app-validation-errors [control]="form.get('scenarioTodo')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="scenarioExecuting">Executing</label>
					<input
						class="input-text"
						type="text"
						formControlName="scenarioExecuting"
						id="scenarioExecuting"
						placeholder="Executing"
					/>
					<app-validation-errors [control]="form.get('scenarioExecuting')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="scenarioPass">Pass</label>
					<input class="input-text" type="text" formControlName="scenarioPass" id="scenarioPass" placeholder="Pass" />
					<app-validation-errors [control]="form.get('scenarioPass')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="scenarioFail">Fail</label>
					<input class="input-text" type="text" formControlName="scenarioFail" id="scenarioFail" placeholder="Fail" />
					<app-validation-errors [control]="form.get('scenarioFail')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="scenarioAborted">Aborted</label>
					<input
						class="input-text"
						type="text"
						formControlName="scenarioAborted"
						id="scenarioAborted"
						placeholder="Aborted"
					/>
					<app-validation-errors [control]="form.get('scenarioAborted')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="scenarioMismatch">Mismatch</label>
					<input
						class="input-text"
						type="text"
						formControlName="scenarioMismatch"
						id="scenarioMismatch"
						placeholder="Mismatch"
					/>
					<app-validation-errors [control]="form.get('scenarioMismatch')"></app-validation-errors>
				</div>
			</div>
		</div>

		<div fxLayout="column" fxFlex>
			<header class="aui-page-header">
				<div class="aui-page-header-inner">
					<div class="aui-page-header-main">
						<h3>Step Level Statuses</h3>
					</div>
				</div>
			</header>
			<div class="module">
				<div class="field-group">
					<label class="label" for="stepTodo">To do</label>
					<input class="input-text" type="text" formControlName="stepTodo" id="stepTodo" placeholder="To do" />
					<app-validation-errors [control]="form.get('stepTodo')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="stepExecuting">Executing</label>
					<input
						class="input-text"
						type="text"
						formControlName="stepExecuting"
						id="stepExecuting"
						placeholder="Executing"
					/>
					<app-validation-errors [control]="form.get('stepExecuting')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="stepPass">Pass</label>
					<input class="input-text" type="text" formControlName="stepPass" id="stepPass" placeholder="Pass" />
					<app-validation-errors [control]="form.get('stepPass')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="stepFail">Fail</label>
					<input class="input-text" type="text" formControlName="stepFail" id="stepFail" placeholder="Fail" />
					<app-validation-errors [control]="form.get('stepFail')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="stepAborted">Aborted</label>
					<input class="input-text" type="text" formControlName="stepAborted" id="stepAborted" placeholder="Aborted" />
					<app-validation-errors [control]="form.get('stepAborted')"></app-validation-errors>
				</div>
				<div class="field-group">
					<label class="label" for="stepMismatch">Mismatch</label>
					<input
						class="input-text"
						type="text"
						formControlName="stepMismatch"
						id="stepMismatch"
						placeholder="Mismatch"
					/>
					<app-validation-errors [control]="form.get('stepMismatch')"></app-validation-errors>
				</div>
			</div>
		</div>
	</div>
</form>
