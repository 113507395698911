<div
	class="ltd__container"
	[style.background]="parameter.bgColor"
	fxLayout="row nowrap"
	fxLayoutAlign="center center"
	fxLayoutGap="2px"
>
	<div id="label-{{ divId }}" class="ltd__label" [ngStyle]="{'cursor': 'pointer'}">{{ label }}</div>
	<button type="button" class="ltd__remove-btn" (click)="onRemove()" title="Click to delete.">
		<svg-icon name="icon-x-light"></svg-icon>
	</button>
</div>
