import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { Type, TypedItem } from '@testifi-models/typed-Item';
import { Utils } from '@testifi-utils/utils';

@Component({
	selector: 'app-label-tag-display',
	templateUrl: './label-tag-display.component.html',
	styleUrls: ['./label-tag-display.component.less'],
	standalone: true,
	imports: [FlexModule, NgClass]
})
export class LabelTagDisplayComponent implements OnInit {
	@Input() item = new TypedItem('');
	@Input() clickable = false;

	@Output() remove = new EventEmitter<TypedItem>();
	@Output() clicked = new EventEmitter<TypedItem>();

	title: string;
	label: string;

	onRemove(event: MouseEvent): void {
		event.stopPropagation();
		this.remove.emit(this.item);
	}

	protected readonly Type = Type;

	ngOnInit(): void {
		this.label = Utils.getItemName(this.item.label);
		this.title = this.label;
		if (this.item.type === Type.MANDATORY || this.item.type === Type.OPTIONAL) {
			this.title = this.label + ' (' + this.item.type + ')';
		}
	}

	itemClicked() {
		if (!this.clickable) {
			return;
		}
		this.clicked.emit(this.item);
	}
}
