<dialog
	id="modal-free-text"
	class="aui-dialog2 aui-dialog2-small"
	tabindex="-1"
	aria-labelledby="modal-free-text--heading"
>
	<form
		class="aui"
		[formGroup]="form"
		(ngSubmit)="addParameter()"
		*ngIf="{dataFileSelectorOptions : dataFileSelectorOptions$ | async, dataFileOptions: dataFileOptions$ | async} as obs"
	>
		<!-- Dialog header -->
		<header class="aui-dialog2-header">
			<h2 class="aui-dialog2-header-main" id="modal-test-object-parameter--heading">Add Parameter</h2>
		</header>
		<!-- Main dialog content -->
		<div class="aui-dialog2-content">
			<app-settings-toggle
				[options]="useDataFileToggleOptions"
				[activeFirst]="notUseDataFile"
				(firstActivated)="onToggleUseDataFile($event)"
				[disabled]="readonly"
			></app-settings-toggle>
			<ng-container *ngIf="form.controls.useDataFile.value">
				<div class="field-group mb-2">
					<label for="dataFileSelect">Data File</label>
					<ng-select
						id="dataFileSelect"
						[formControl]="dataFileIdFormControl"
						class="dropdown custom"
						[searchable]="true"
						[clearable]="false"
						><ng-container *ngFor="let option of obs.dataFileOptions">
							<ng-option [value]="option.value">
								<div>{{option.name}}</div>
							</ng-option>
						</ng-container>
					</ng-select>
				</div>
				<div class="field-group">
					<label for="dataFileSelectorsSelect">Data File Selectors</label>
					<ng-select
						id="dataFileSelectorsSelect"
						[formControl]="dataFileSelectorFormControl"
						class="dropdown custom"
						[searchable]="true"
						[clearable]="false"
						><ng-container *ngFor="let option of obs.dataFileSelectorOptions">
							<ng-option [value]="option.value">
								<div>{{option.name}}</div>
							</ng-option>
						</ng-container>
					</ng-select>
				</div>
				<hr />
			</ng-container>
			<div class="field-group">
				<label for="text">Name</label>
				<input id="text" class="text large-field" type="text" formControlName="text" required />
				<app-validation-errors [control]="form.controls.text"></app-validation-errors>
			</div>
			<div class="field-group">
				<label for="parameterType">Type</label>
				<select class="select" id="parameterType" name="parameterType" formControlName="parameterType">
					<option [value]="ParameterType.MANDATORY" selected>Mandatory</option>
					<option [value]="ParameterType.OPTIONAL">Optional</option>
				</select>
			</div>
			<div class="field-group" *ngIf="form.controls.parameterType.value === ParameterType.OPTIONAL">
				<label for="defaultValue">Default Value</label>
				<input id="defaultValue" class="text large-field" type="text" formControlName="defaultValue" required />
				<app-validation-errors [control]="form.controls.defaultValue"></app-validation-errors>
			</div>
		</div>
		<!-- Dialog footer -->
		<footer class="aui-dialog2-footer">
			<div class="aui-dialog2-footer-actions" *ngIf="!readonly">
				<button id="create-submit" type="submit" class="aui-button aui-button-primary" [disabled]="!form.valid">
					Add
				</button>
				<button id="cancel-submit" type="button" class="aui-button aui-button-subtle" modalClose>Cancel</button>
			</div>
			<div class="aui-dialog2-footer-actions" *ngIf="readonly">
				<button id="close-submit" type="button" class="aui-button aui-button-subtle" modalClose>Close</button>
			</div>
		</footer>
	</form>
</dialog>
