import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { IProjectSettings } from '@testifi-models/project-settings.interface';
import { IProject } from '@testifi-models/project.interface';
import { TProjectRepository } from '@testifi-store/project/project.repository.type';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ProjectRepository {
	static readonly INITIAL_VALUE: TProjectRepository = {
		loading: false,
		projectKey: '',
		project: {
			id: '',
			name: '',
			description: ''
		},
		projectSettings: undefined
	};

	private store = createStore(
		{ name: 'project' },
		withProps<TProjectRepository>(ProjectRepository.INITIAL_VALUE)
	);

	loading$: Observable<boolean> = this.store.pipe(
		select((state) => state.loading)
	);
	state$ = this.store;
	projectKey$: Observable<string> = this.store.pipe(
		select((state) => state.projectKey)
	);
	project$ = this.store.pipe(select((state) => state.project));
	projectExists$ = this.store.pipe(select((state) => !!state.project.id));
	projectSettings$ = this.store.pipe(select((state) => state.projectSettings));

	get state(): TProjectRepository {
		return this.store.getValue();
	}

	get projectKey(): string {
		return this.state.projectKey;
	}

	get project(): IProject {
		return this.state.project;
	}

	get projectSettings(): IProjectSettings {
		return this.state.projectSettings;
	}

	setProject(project: IProject): void {
		this.store.update((state) => ({
			...state,
			project
		}));
	}

	setLoading(loading: boolean): void {
		this.store.update((state) => ({
			...state,
			loading
		}));
	}

	setProjectSettings(projectSettings: IProjectSettings): void {
		this.store.update((state) => ({
			...state,
			projectSettings
		}));
	}

	setProjectKey(projectKey: string): void {
		this.store.update((state) => ({
			...state,
			projectKey
		}));
	}
}
