import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Screenshot } from '@testifi-models/Screenshot';
import { ScreenshotsPage } from '@testifi-models/paginated-responses';
import { Observable, Subscriber, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PrivateHttpClient } from '../https/instances/private.http';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class ScreenshotService {
	private screenshotsCache = new Map<string, Screenshot>();
	private screenshotDeleteSubscriber: Subscriber<string> = null;

	constructor(
		private privateHttp: PrivateHttpClient,
		private base: BaseApiService
	) {}

	getScreenshotsByIds(ids: string[]): Observable<Screenshot[]> {
		const notCachedIds = ids.filter((id) => !this.screenshotsCache.get(id));
		if (!notCachedIds.length) {
			return of(ids.map((id) => this.screenshotsCache.get(id)));
		}

		return this.privateHttp
			.post<ScreenshotsPage>('screenshot/getByIds', { ids: notCachedIds })
			.pipe(
				map((res) => this.base.model(res, ScreenshotsPage)),
				map((screenshotsPage) => {
					screenshotsPage.screenShotViewList.forEach((screenshot) =>
						this.screenshotsCache.set(screenshot.id, screenshot)
					);

					return ids
						.filter((id) => this.screenshotsCache.get(id))
						.map((id) => this.screenshotsCache.get(id));
				})
			);
	}

	getScreenshotList(
		projectId: string,
		searchText = '',
		limit = 10,
		page = 0
	): Observable<ScreenshotsPage> {
		const params = new HttpParams()
			.set('pageNumber', `${page}`)
			.set('limit', `${limit}`)
			.set('sortBy', 'DATE')
			.set('direction', 'DESC')
			.set('text', searchText);
		return this.privateHttp
			.get<ScreenshotsPage>(`project/${projectId}/screenshots`, {
				params
			})
			.pipe(
				map((res) => this.base.model(res, ScreenshotsPage)),
				tap((screenshotPage: ScreenshotsPage) => {
					screenshotPage.screenShotViewList.forEach((screenshot) =>
						this.screenshotsCache.set(screenshot.id, screenshot)
					);
				})
			);
	}

	uploadScreenshot(
		projectId: string,
		files: FileList | File
	): Observable<ScreenshotsPage> {
		const formData = new FormData();

		if (files instanceof FileList) {
			for (let idx = 0; idx < files.length; idx++) {
				formData.append('files', files.item(idx));
			}
		} else {
			formData.append('files', files);
		}

		return this.privateHttp
			.post('screenshots', formData, {
				params: {
					projectId
				}
			})
			.pipe(map((res) => this.base.model(res, ScreenshotsPage)));
	}

	deleteScreenshot(id: string): Observable<void> {
		this.screenshotsCache.delete(id);

		return this.privateHttp.delete<void>(`screenshot/${id}`).pipe(
			tap(() => {
				this.screenshotDeleteSubscriber.next(id);
			})
		);
	}

	screenshotDeleted(): Observable<string> {
		return new Observable((subscriber) => {
			this.screenshotDeleteSubscriber = subscriber;
		});
	}
}
