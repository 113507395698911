<div id="csl-container" fxLayout class="csl__container" fxLayoutGap="8px">
	<div>
		<button type="button" class="csl__add-button" (click)="onAddClick()">+</button>
	</div>
	<div>
		<div fxLayout="row wrap" fxLayoutGap="6px grid">
			<div *ngFor="let item of typedItems | sort : 'label'">
				<app-label-tag-display
					[clickable]="clickable"
					[item]="item"
					(remove)="removeItem($event)"
					(clicked)="itemClicked($event)"
				></app-label-tag-display>
			</div>
		</div>
	</div>
</div>
