import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrivateHttpClient } from '../https/instances/private.http';
import { BaseApiService } from './base-api.service';

export interface GitConnection {
	password: string;
	repoURL: string;
	skipSSLVerify: boolean;
	username: string;
	branch?: string;
}

export interface GitExportPayload {
	gitConnection: GitConnection;
	ids: string[];
	projectKey: string;
}

export interface ExportScenarioPayload {
	gitConnection: GitConnection;
	ids: number[];
	projectKey: string;
}

@Injectable({
	providedIn: 'root'
})
export class GitService {
	constructor(
		private privateHttp: PrivateHttpClient,
		private base: BaseApiService
	) {}

	exportScenario(payload: ExportScenarioPayload): Observable<unknown> {
		return this.privateHttp.post<unknown>(
			'repository/scenario/export',
			payload
		);
	}

	pushAllFiles(payload: GitExportPayload): Observable<unknown> {
		return this.privateHttp.post<unknown>(`repository/push`, payload);
	}
}
