/* eslint-disable functional/immutable-data */
import {
	CdkDrag,
	CdkDragDrop,
	CdkDragEnd,
	CdkDragMove,
	CdkDragPlaceholder,
	CdkDragPreview,
	CdkDragStart,
	CdkDropList
} from '@angular/cdk/drag-drop';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { SafeUrl } from '@angular/platform-browser';
import { SuiElement } from '@testifi-models/sui-element';
import { SuiPage } from '@testifi-models/sui-page';
import { Tag } from '@testifi-models/tag';
import { XmlElements } from '@testifi-models/xml-elements';
import { ModalData, ModalService } from '@testifi-services/modal.service';
import { NestedDragNDropService } from '@testifi-services/nested-drag-n-drop.service';
import { NotificationService } from '@testifi-services/notification.service';
import { SuiPageService } from '@testifi-services/sui-page.service';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { EditTracker } from '@testifi-utils/edit.tracker';
import { Utils } from '@testifi-utils/utils';
import { SvgIconComponent } from 'angular-svg-icon';
import { Subscription } from 'rxjs';
import { AppendDragThumbDirective } from '../../directives/append-drag-thumb.directive';
import { ModalRouterNameDirective } from '../../directives/modal-router-name.directive';
import { FilterOutKeyValuePipe } from '../../pipes/filter-out-key-value.pipe';
import { UiTagPipe } from '../../pipes/ui-tag.pipe';

export class SafeScreenshot {
	name: string;
	safeUrl: SafeUrl;

	constructor(name: string, safeUrl: SafeUrl) {
		this.name = name;
		this.safeUrl = safeUrl;
	}
}

@Component({
	selector: 'app-modal-edit-sui-page-elements-element',
	templateUrl: './modal-edit-sui-page-elements-element.component.html',
	styleUrls: ['./modal-edit-sui-page-elements-element.component.less'],
	standalone: true,
	imports: [
		CdkDropList,
		CdkScrollable,
		NgClass,
		ExtendedModule,
		NgFor,
		CdkDrag,
		MatExpansionModule,
		AppendDragThumbDirective,
		NgIf,
		MatIconModule,
		SvgIconComponent,
		ModalRouterNameDirective,
		CdkDragPreview,
		CdkDragPlaceholder,
		FilterOutKeyValuePipe,
		UiTagPipe
	]
})
export class ModalEditSuiPageElementsElementComponent implements OnDestroy {
	@Input() item: SuiElement;
	@Input() connectedLists: string[];
	@Input() intersectedItemIds: string[];
	@Input() expandedItemIds: string[];
	@Input() root = false;
	@Input() rootItem: SuiElement;
	@Input() isDragging = false;
	@Input() editTracker: EditTracker<SuiPage>;
	@Input() screenshots: Map<string, SafeScreenshot>;
	@Input() suiPage: SuiPage;
	@Input() parameters = new Map<string, Array<string>>();
	@Input() tagGroups = new Map<string, Array<Tag>>();

	@Output()
	dragDrop = new EventEmitter<CdkDragDrop<SuiElement>>();
	@Output() dragMove = new EventEmitter<CdkDragMove<SuiElement>>();
	@Output() dragEnded = new EventEmitter<CdkDragEnd<SuiElement>>();
	@Output() dragStarted = new EventEmitter<CdkDragStart<SuiElement>>();
	@Output() expandedClick = new EventEmitter<string>();
	@Output() refreshOnModalClose = new EventEmitter<{
		structureId: string;
		isChanged: boolean;
	}>();

	Utils = Utils;
	screenshotToPreview: SafeScreenshot;

	private disposableBag = new Subscription();

	constructor(
		private modalService: ModalService,
		private suiPageService: SuiPageService,
		private notificationService: NotificationService,
		private appConfigRepository: AppConfigRepository,
		private nestedDnDService: NestedDragNDropService
	) {}

	get isIntersected(): boolean {
		// We reverse ids here to respect items nesting hierarchy
		return this.intersectedItemIds.includes(this.item.structureId);
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	canDrop(item: SuiElement, intersectedItemIds: string[]): () => boolean {
		return () =>
			intersectedItemIds.length <= 0 ||
			item.children.length <= 0 ||
			intersectedItemIds.includes(item.structureId);
	}

	validExpandable(item: SuiElement): boolean {
		if (item.children.length > 0) {
			return true;
		}

		return this.intersectedItemIds.length > 0;
	}

	isExpanded(item: SuiElement): boolean {
		return this.expandedItemIds?.includes(item.structureId);
	}

	filteredConnectedListIds(item: SuiElement): string[] {
		return this.connectedLists.filter(
			(structureId) => structureId !== item.structureId
		);
	}

	onDragDrop(event: CdkDragDrop<SuiElement, SuiElement>): void {
		this.dragDrop.emit(event);
	}

	onDragMove(event: CdkDragMove<SuiElement>): void {
		this.dragMove.emit(event);
	}

	onDragEnded(event: CdkDragEnd<SuiElement>): void {
		this.dragEnded.emit(event);
	}

	onDragStarted(event: CdkDragStart<SuiElement>): void {
		this.dragStarted.emit(event);
	}

	onToggleExpanded(event: MouseEvent | string): void {
		if (event instanceof MouseEvent) {
			const element = event.currentTarget as HTMLElement;

			event.stopPropagation();
			this.expandedClick.emit(element.id);
		} else {
			this.expandedClick.emit(event);
		}
	}

	onRefreshOnModalClose(
		item: number | string | Record<string, string>,
		event: boolean
	): void {
		if (typeof item === 'string') {
			this.refreshOnModalClose.emit({ structureId: item, isChanged: event });
		} else if (typeof item === 'object') {
			this.refreshOnModalClose.emit({
				structureId: item.structureId,
				isChanged: event
			});
		} else if (this.appConfigRepository.isServer && item === 403) {
			this.modalService.close();
		} else {
			this.refreshOnModalClose.emit({
				structureId: null,
				isChanged: item !== 0
			});
		}
	}

	onElementDelete(data: ModalData): void {
		if (!data) {
			return;
		}
		const { structureIdTransitions } = data.suiRootElement.removeChild(
			data.suiElementStructureId
		);

		this.disposableBag.add(
			this.suiPageService
				.updateElements(
					data.suiPageId,
					new XmlElements().fromSuiElement(data.suiRootElement)
				)
				.subscribe(
					() => {
						this.nestedDnDService.handleExpandOnRemove(
							data.suiElementStructureId,
							data.suiRootElement.children,
							structureIdTransitions
						);
						this.onRefreshOnModalClose(data.suiElementStructureId, true);
						this.notificationService.info('SUI element was removed');
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}
}
