import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	Renderer2
} from '@angular/core';
import { ModalService } from '../services/modal.service';
import { RouterService } from '../services/router.service';

@Directive({
	selector: '[modalRouterName], [modalOnClose], [modalData]', // eslint-disable-line
	standalone: true
})
export class ModalRouterNameDirective implements OnInit {
	@Input() modalRouterName: string[];
	@Input() modalOnClose = null;
	@Input() modalData = null;

	constructor(
		renderer: Renderer2,
		hostElement: ElementRef,
		private modalService: ModalService,
		private routerService: RouterService
	) {
		renderer.addClass(hostElement.nativeElement, 'clickable');
	}

	ngOnInit(): void {
		this.routerService.validate(this.modalRouterName);
	}

	@HostListener('click', ['$event'])
	onClick($event: Event): void {
		$event.stopPropagation();
		this.modalService.open(
			this.routerService.getComponentByPathName(this.modalRouterName),
			this.modalOnClose,
			this.modalData
		);
	}
}
