import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	MatLegacyPaginatorModule,
	LegacyPageEvent as PageEvent
} from '@angular/material/legacy-paginator';
import { JiraProject, ProjectsPage } from '@testifi-models/project';
import { AdminService } from '@testifi-services/admin.service';
import { LoadingService } from '@testifi-services/loading.service';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { UiSwitchModule } from 'ngx-ui-switch';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { StylePaginatorDirective } from '../../directives/style-paginator.directive';
import { UnorderdKeyValuePipe } from '../../pipes/unorder-key-value.pipe';

enum ColNames {
	NAME = 'NAME',
	KEY = 'KEY',
	LEAD = 'LEAD',
	PITSTOP = 'PITSTOP'
}

@Component({
	templateUrl: './page-enabled-projects.component.html',
	styleUrls: ['./page-enabled-projects.component.less'],
	standalone: true,
	imports: [
		NgIf,
		NgFor,
		NgClass,
		ExtendedModule,
		FlexModule,
		UiSwitchModule,
		MatLegacyPaginatorModule,
		StylePaginatorDirective,
		UnorderdKeyValuePipe
	]
})
export class PageEnabledProjectsComponent implements OnInit, OnDestroy {
	private disposableBag = new Subscription();

	tableHeaders = new Map<
		ColNames,
		{ title: string; sortable: boolean; center?: boolean }
	>([
		[
			ColNames.NAME,
			{
				title: 'Project Name',
				sortable: false
			}
		],
		[ColNames.KEY, { title: 'Project Key', sortable: false }],
		[ColNames.LEAD, { title: 'Project Lead', sortable: false }],
		[ColNames.PITSTOP, { title: 'Enabled', sortable: false, center: true }]
	]);

	checkedItems = new Map<string, JiraProject>();

	projectsPage: ProjectsPage = null;

	pageSize = 10;
	totalItems = 0;
	currentPage = 0;

	constructor(
		private adminService: AdminService,
		private loadingService: LoadingService,
		private cd: ChangeDetectorRef,
		private appConfigRepository: AppConfigRepository
	) {}

	ngOnInit(): void {
		this.load();
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	checkItem(project: JiraProject): void {
		this.disposableBag.add(
			this.adminService
				.setJiraProperty({
					projectKey: project.key,
					value: !project.properties.pitstopDisabled,
					production:
						this.appConfigRepository.config.applicationId.indexOf('qa') < 0
				})
				.subscribe(() => {
					if (this.checkedItems.has(project.id)) {
						this.checkedItems.delete(project.id);
					} else {
						this.checkedItems.set(project.id, project);
					}
				})
		);
	}

	handlePage(event: PageEvent): void {
		const isPageSizeReallyChanged = this.pageSize !== event.pageSize;
		const isPageReallyChanged = this.currentPage !== event.pageIndex;
		this.pageSize = event.pageSize;
		this.currentPage = event.pageIndex;
		if (isPageSizeReallyChanged || isPageReallyChanged) {
			this.load();
		}
	}

	private load(): void {
		this.loadingService.active();
		this.disposableBag.add(
			this.adminService
				.getJiraProjects(
					this.appConfigRepository.config.applicationId.indexOf('qa') < 0,
					`${this.currentPage}`,
					`${this.pageSize}`
				)
				.pipe(finalize(() => this.loadingService.deactive(this.cd)))
				.subscribe((projectsPage) => {
					this.checkedItems.clear();
					this.projectsPage = projectsPage;
					this.totalItems = projectsPage.totalNumberofElements;
					this.projectsPage.jiraProjectList.forEach((project) => {
						if (!project.properties.pitstopDisabled) {
							this.checkedItems.set(project.id, project);
						}
					});
				})
		);
	}
}
