import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { TAppConfiguration } from '@testifi-models/app-configuration.interface';
import { ELoginType } from '@testifi-models/login-type.enum';
import { ConfigApiService } from '@testifi-services/config.api.service';
import { AppConfigRepository } from '@testifi-store/app-config/app-config.repository';
import { AppStatusRepository } from '@testifi-store/app-status/app-status.repository';
import { ProjectRepository } from '@testifi-store/project/project.repository';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

declare const AP: {
	_data: {
		options: {
			origin: string;
		};
	};
	context: {
		getContext: (
			callback: (context: {
				jira: {
					project: {
						key: string;
					};
				};
			}) => void
		) => void;
	};
};

const identifyProjectKeyAndAppUrl = async (
	appJsonProjectKey: string
): Promise<{ projectKey: string; appUrl: string }> => {
	// Check if the Jira app context is available
	//eslint-disable-next-line
	if (AP?.context) {
		return new Promise((resolve, reject) => {
			//eslint-disable-next-line
			AP.context.getContext((context) => {
				if (context?.jira?.project?.key) {
					resolve({
						projectKey: context.jira.project.key,
						appUrl: AP._data.options.origin
					});
				} else {
					reject(new Error('Project key not found in Jira context.'));
				}
			});
		});
	} else {
		// Use the fallback project key
		return Promise.resolve({
			projectKey: appJsonProjectKey,
			appUrl: document.baseURI
		});
	}
};

const mainInitializer = async (
	configApiService: ConfigApiService,
	appConfigRepository: AppConfigRepository,
	projectRepository: ProjectRepository
): Promise<void> =>
	configApiService
		.loadAppConfiguration()
		.then(({ projectKey, environment, ...appConfig }) => {
			appConfigRepository.setAppConfig(appConfig);
			appConfigRepository.setEnvironment(environment);
			return identifyProjectKeyAndAppUrl(projectKey);
		})
		.then(({ projectKey, appUrl }) => {
			appConfigRepository.setAppUrl(appUrl);
			projectRepository.setProjectKey(projectKey);
			return configApiService.getVersion();
		})
		.then((apiVersion) => {
			appConfigRepository.setApiVersion(apiVersion);
		});

const authCodeFlowConfig: AuthConfig = {
	issuer: '',
	redirectUri: document.baseURI + 'settings/credentials',
	clientId: '',
	responseType: 'id_token token',
	scope: '',
	showDebugInformation: true,
	strictDiscoveryDocumentValidation: false,
	oidc: true,
	requestAccessToken: true
};

const ssoInitializer = async (
	oAuthService: OAuthService,
	appConfiguration: TAppConfiguration
): Promise<void> => {
	oAuthService.configure({
		...authCodeFlowConfig,
		issuer: appConfiguration.ssoIssuer,
		clientId: appConfiguration.ssoClientId,
		redirectUri: appConfiguration.ssoRedirectUri,
		scope: appConfiguration.ssoScope,
		logoutUrl: appConfiguration.ssoRedirectUri
	});
	// save oAuthService state to redirect the user to the same uri (keyword = this.oAuthService.state)
	return oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
		if (oAuthService.hasValidAccessToken()) {
			oAuthService.loadUserProfile().then();
		}
	});
};

export const APPLICATION_INITIALIZER: FactoryProvider = {
	provide: APP_INITIALIZER,
	deps: [
		AppConfigRepository,
		ConfigApiService,
		AppStatusRepository,
		OAuthService,
		ProjectRepository
	],
	multi: true,
	useFactory: (
		appConfigRepository: AppConfigRepository,
		configService: ConfigApiService,
		appStatusRepository: AppStatusRepository,
		oAuthService: OAuthService,
		projectRepository: ProjectRepository
	) => {
		const initializeApp = async () => {
			try {
				await mainInitializer(
					configService,
					appConfigRepository,
					projectRepository
				);
				const ssoLogin =
					appConfigRepository.state.loginType === ELoginType.SERVER_SSO ||
					appConfigRepository.state.loginType === ELoginType.GOOGLE_SSO;
				if (ssoLogin) {
					await ssoInitializer(oAuthService, appConfigRepository.state.config);
				}
			} catch (err) {
				console.error(err);
				appStatusRepository.markAppAsUnhealthy([err]);
			}
		};

		return () => initializeApp();
	}
};
