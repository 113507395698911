import { NgStyle } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { Utils } from '@testifi-utils/utils';

@Component({
	selector: 'app-kpi-box',
	templateUrl: './kpi-box.component.html',
	styleUrls: ['./kpi-box.component.less'],
	standalone: true,
	imports: [FlexModule, NgStyle, ExtendedModule]
})
export class KpiBoxComponent implements OnInit, OnChanges {
	@Input() title: string;
	@Input() value: string;
	@Input() color: string;
	@Input() valueSize = '24px';

	textColor = '#000';

	ngOnInit(): void {
		this.updateColor();
	}

	ngOnChanges(): void {
		this.updateColor();
	}

	private updateColor() {
		this.textColor = Utils.pickTextColorBasedOnBgColor(
			this.color,
			'#fff',
			'#000'
		);
	}
}
