import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuildingBlock } from '@testifi-models/building-block';
import { BuildingBlockPage } from '@testifi-models/paginated-responses';
import { XmlElements } from '@testifi-models/xml-elements';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrivateHttpClient } from '../https/instances/private.http';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class BuildingBlockService {
	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private privateHttp: PrivateHttpClient,
		private base: BaseApiService
	) {}

	// ======================================================================
	// public functions
	// ======================================================================

	add(
		projectId: string,
		description: string,
		name: string,
		documentation: string
	): Observable<BuildingBlock> {
		return this.privateHttp
			.post<BuildingBlock>('building-block', {
				projectId,
				description,
				documentation,
				name
			})
			.pipe(map((res) => this.base.model(res, BuildingBlock)));
	}

	edit(
		id: string,
		params: {
			description: string;
			name: string;
			documentation: string;
			projectId: string;
			modules: string[];
			mandatoryParameters: string[];
			optionalParameters: string[];
			outputParameters: string[];
		}
	): Observable<BuildingBlock> {
		return this.privateHttp
			.put<BuildingBlock>(`building-block/${id}`, params)
			.pipe(map((res) => this.base.model(res, BuildingBlock)));
	}

	updateTestObjects(
		id: string,
		elementList: XmlElements
	): Observable<BuildingBlock> {
		return this.privateHttp
			.post<BuildingBlock>(`building-block/${id}/updateTestObject`, elementList)
			.pipe(map((res) => this.base.model(res, BuildingBlock)));
	}

	delete(id: string): Observable<boolean> {
		return this.privateHttp
			.delete<boolean>(`building-block/${id}`)
			.pipe(map((res) => res));
	}

	view(id: string): Observable<BuildingBlock> {
		return this.privateHttp
			.get<BuildingBlock>(`building-block/${id}`)
			.pipe(map((res) => this.base.model(res, BuildingBlock)));
	}

	getAll(
		projectId: string,
		page = 0,
		limit = 10,
		sort = 'NAME',
		direction = 'ASC',
		searchText = ''
	): Observable<BuildingBlockPage> {
		const params = new HttpParams()
			.set('pageNumber', `${page}`)
			.set('limit', `${limit}`)
			.set('sortBy', sort)
			.set('direction', direction)
			.set('text', searchText);

		return this.privateHttp
			.get<BuildingBlockPage>(`project/${projectId}/building-blocks`, {
				params
			})
			.pipe(map((res) => this.base.model(res, BuildingBlockPage)));
	}
}
