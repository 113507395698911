import { Injectable, OnDestroy } from '@angular/core';
import { TestScenario } from '@testifi-models/test-scenario';
import { TestScenarioService } from '@testifi-services/test-scenario.service';
import { Observable, Subject, timer } from 'rxjs';
import { retry, share, switchMap, takeUntil } from 'rxjs/operators';

const POLLING_TIME = 20000;

@Injectable({
	providedIn: 'root'
})
export class ScenarioExecutionMonitorService implements OnDestroy {
	private stopPolling = new Subject();
	private exec$: Observable<TestScenario>;

	constructor(private scenarioService: TestScenarioService) {}

	start(id: string): Observable<TestScenario> {
		if (this.stopPolling.observers.length) {
			this.stop();
		}

		this.exec$ = timer(POLLING_TIME, POLLING_TIME).pipe(
			switchMap(() => this.scenarioService.get(id)),
			retry(),
			share(),
			takeUntil(this.stopPolling)
		);
		return this.exec$;
	}

	stop(): void {
		this.stopPolling.next(null);
	}

	ngOnDestroy(): void {
		this.stop();
	}
}
