import { Directive, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ICredentialsPayload } from '@testifi-models/credentials.interface';
import { NotificationService } from '@testifi-services/notification.service';
import { UserService } from '@testifi-services/user.service';
import { TestConnectionState } from '@testifi-shared/test-connection/test-connection.component';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Directive()
export abstract class BaseCredentials implements OnDestroy {
	@Output() formData = new EventEmitter<UntypedFormGroup>();
	@Output() dataState = new EventEmitter<TestConnectionState>();

	form: UntypedFormGroup;
	testConnectionState = TestConnectionState.Disabled;

	protected disposableBag = new Subscription();

	protected constructor(
		protected userService: UserService,
		protected notificationService: NotificationService
	) {}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	protected testConnection(credentials: ICredentialsPayload): void {
		this.disposableBag.add(
			this.userService
				.testCredentials(credentials)
				.pipe(
					finalize(() => {
						this.dataState.emit(this.testConnectionState);
					})
				)
				.subscribe(
					() => (this.testConnectionState = TestConnectionState.TestedOk),
					(err) => {
						this.notificationService.httpError(err);
						this.testConnectionState = TestConnectionState.TestedWrong;
					}
				)
		);
	}

	protected subscribeToFormChanges(): void {
		this.disposableBag.add(
			this.form.statusChanges.subscribe(() => {
				if (this.form.valid) {
					this.testConnectionState = TestConnectionState.NotTested;
				} else {
					this.testConnectionState = TestConnectionState.Disabled;
				}
			})
		);
	}
}
