import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'app-relation-value',
	templateUrl: './relation-value.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class RelationValueComponent {
	@Input() name = '';
}
