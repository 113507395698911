<div class="module">
	<div class="mod-header">
		<h4>{{ name }}</h4>
	</div>
	<div class="mod-content">
		<div *ngIf="empty; then thenBlock; else elseBlock"></div>
		<ng-template #thenBlock>
			<span class="aui-lozenge aui-lozenge-subtle">None</span>
		</ng-template>
		<ng-template #elseBlock>
			<ng-content></ng-content>
		</ng-template>
	</div>
</div>
