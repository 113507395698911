import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TestPlan')
export class TestPlan {
	@JsonProperty('description', String, true)
	description: string = undefined;

	@JsonProperty('summary', String)
	summary: string = undefined;

	@JsonProperty('xrayId', String, true)
	xrayTestPlanKey: string = undefined;

	isWithoutTestPlan: boolean = undefined;

	constructor(summary?: string, isWithoutTestPlan = false) {
		this.summary = summary;
		this.isWithoutTestPlan = isWithoutTestPlan;
	}
}
