import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-library-navigation-item',
	standalone: true,
	imports: [CommonModule, MatIconModule, RouterLink],
	templateUrl: './library-navigation-item.component.html',
	styleUrls: ['./library-navigation-item.component.less']
})
export class LibraryNavigationItemComponent {
	@Input() itemTitle = '';
	@Input() description = '';
	@Input() disabled = false;
	@Input() route: string[];
}
