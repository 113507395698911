/* eslint-disable functional/immutable-data */
import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateType } from '@testifi-models/test-step';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import { TestScenarioService } from '@testifi-services/test-scenario.service';
import { TestStepService } from '@testifi-services/test-step.service';
import { DESCRIPTION_VALIDATORS } from '@testifi-shared/app-constants';
import { produce } from 'immer';
import { Subscription, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ModalCloseDirective } from '../../directives/modal-close.directive';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';

@Component({
	selector: 'app-modal-add-test-step',
	templateUrl: './modal-add-test-step.component.html',
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ValidationErrorsComponent,
		ModalCloseDirective
	]
})
export class ModalAddTestStepComponent implements OnInit, OnDestroy {
	// ======================================================================
	// private properties
	// ======================================================================

	private disposableBag = new Subscription();

	// ======================================================================
	// public properties
	// ======================================================================

	form: UntypedFormGroup;
	submitted = false;
	testScenarioId: string;
	projectId: string;

	// ======================================================================
	// getter
	// ======================================================================

	get f(): { [key: string]: AbstractControl } {
		return this.form.controls;
	}

	constructor(
		private cd: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private testStepService: TestStepService,
		private testScenarioService: TestScenarioService,
		private notificationService: NotificationService,
		private loadingService: LoadingService,
		private modalService: ModalService,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			testScenarioId: string;
			projectId: string;
			testStepsCount: number;
			hasPutUpAndTearDown: boolean;
		}
	) {}

	ngOnInit(): void {
		this.testScenarioId = this.data.testScenarioId;
		this.projectId = this.data.projectId;

		this.form = this.formBuilder.group({
			description: ['', [Validators.required, ...DESCRIPTION_VALIDATORS]],
			data: ['', DESCRIPTION_VALIDATORS],
			result: ['', DESCRIPTION_VALIDATORS]
		});
	}

	ngOnDestroy(): void {
		this.disposableBag.unsubscribe();
	}

	// ======================================================================
	// public functions
	// ======================================================================

	add(): void {
		this.submitted = true;

		// stop here if form is invalid
		if (this.form.invalid) {
			return;
		}

		this.loadingService.active();

		this.disposableBag.add(
			this.testStepService
				.add(
					this.testScenarioId,
					this.f.description.value,
					this.f.data.value,
					this.f.result.value,
					this.data.testStepsCount
				)
				.pipe(
					switchMap((testStep) => {
						if (this.data.hasPutUpAndTearDown) {
							return this.testScenarioService
								.changePosition(
									this.testScenarioId,
									testStep.id,
									testStep.index - 1
								)
								.pipe(
									map((scenario) => scenario.testSteps[testStep.index - 1])
								);
						}
						return of(testStep);
					}),
					map((testStep) =>
						produce(testStep, (draft) => {
							draft.updateType = UpdateType.Create;
						})
					),
					finalize(() => this.loadingService.deactive(this.cd))
				)
				.subscribe(
					(testStep) => {
						this.notificationService.info('Step was created');
						this.modalService.close(testStep);
					},
					(err: HttpErrorResponse) => this.notificationService.httpError(err)
				)
		);
	}
}
