import {
	HTTP_INTERCEPTORS,
	HttpBackend,
	HttpClient,
	HttpInterceptor
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InterceptingHandler } from '../intercepting-handler';

@Injectable({
	providedIn: 'root'
})
export class AssetsHttpClient extends HttpClient {
	constructor(
		backend: HttpBackend,
		@Inject(HTTP_INTERCEPTORS) interceptors: HttpInterceptor[]
	) {
		super(new InterceptingHandler(backend, interceptors));
	}
}
