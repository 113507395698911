/* eslint-disable functional/immutable-data */
import { RestructureResponseInterface } from '@testifi-models/restructure-response.interface';
import { immerable, produce } from 'immer';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SuiElement')
export class SuiElement {
	static readonly STRUCTURE_ID_PREFIX = 'suielement_';
	[immerable] = true;
	structureId: string = undefined;

	@JsonProperty('index', Number)
	id: number = undefined;

	@JsonProperty('parentId', String, true)
	parentid: string = undefined;

	@JsonProperty('type', String)
	type: string = undefined;

	@JsonProperty('parameter', Object, true)
	parameter: Record<string, string> = undefined;

	@JsonProperty('children', [SuiElement])
	children: SuiElement[] = [];

	@JsonProperty('content', String, true)
	content: string = undefined;

	optionalFields = new Map<string, string[]>();

	group = '';

	removeChild(
		elementStructureId: string
	): RestructureResponseInterface<SuiElement> {
		this.children = this.findAndRemoveChild(elementStructureId, this.children);
		return this.restructureAndGetStructureIdTransitions();
	}

	updateParameter(
		structureId: string,
		parameters: Record<string, string>
	): SuiElement[] {
		return this.findAndUpdateParameter(structureId, this.children, parameters);
	}

	restructure(): SuiElement {
		return this.applyElementStructureIds(this, 0, '0', []).updated;
	}

	restructureAndGetStructureIdTransitions(): RestructureResponseInterface<SuiElement> {
		return this.applyElementStructureIds(this, 0, '0', []);
	}

	private findAndUpdateParameter(
		structureId: string,
		items: SuiElement[],
		parameters: Record<string, string>
	): SuiElement[] {
		return items.map((item) => {
			if (structureId === item.structureId) {
				return produce(item, (draft) => {
					draft.parameter = parameters;
				});
			} else {
				return produce(item, (draft) => {
					draft.children = this.findAndUpdateParameter(
						structureId,
						item.children,
						parameters
					);
				});
			}
		});
	}

	private findAndRemoveChild(
		structureId: string,
		items: SuiElement[]
	): SuiElement[] {
		let filteredItems = items.filter((el) => el.structureId !== structureId);
		if (filteredItems.length === items.length) {
			filteredItems = items.map((item) =>
				produce(item, (draft) => {
					draft.children = this.findAndRemoveChild(structureId, item.children);
				})
			);
		}

		return filteredItems;
	}

	private applyElementStructureIds(
		item: this | SuiElement,
		id: number,
		structureId: string,
		structureIdTransitions: string[][]
	): RestructureResponseInterface<SuiElement> {
		let subId = 0;
		const updated = produce(item, (draft) => {
			draft.id = id;
			const updatedStructureId = `${SuiElement.STRUCTURE_ID_PREFIX}${structureId}`;
			if (draft.structureId !== updatedStructureId) {
				structureIdTransitions = [
					...structureIdTransitions,
					[draft.structureId, updatedStructureId]
				];
				draft.structureId = updatedStructureId;
			}
			draft.children = item.children.map((child) => {
				const {
					updated,
					structureIdTransitions: updatedStructureIdTransitions
				} = this.applyElementStructureIds(
					produce(child, (draft) => {
						draft.group = draft.parameter['schema']
							? draft.parameter['schema']
							: 'html';
					}),
					subId++,
					`${structureId}.${subId - 1}`,
					structureIdTransitions
				);
				structureIdTransitions = updatedStructureIdTransitions;
				return updated;
			});
		});
		return { updated, structureIdTransitions };
	}
}
