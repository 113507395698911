<div class="editor">
	<form class="aui" [formGroup]="form">
		<div class="p-table">
			<div class="p-table-row">
				<div class="p-table-cell padding-right-8">
					<input
						class="text"
						type="text"
						formControlName="parameterKey"
						id="parameterKey"
						placeholder="Parameter Name"
						(keyup.escape)="cancel.emit()"
						[autofocus]="!form.get('parameterKey').value.length"
					/>
					<app-validation-errors [control]="form.get('parameterKey')" [smallerFont]="true"></app-validation-errors>
				</div>
				<div class="p-table-cell padding-right-8">=</div>
				<div class="p-table-cell padding-right-8">
					<input
						class="text"
						type="text"
						formControlName="parameterValue"
						id="parameterValue"
						placeholder="Parameter Value"
						(keyup.escape)="cancel.emit()"
						(keyup.enter)="onSubmit()"
						[autofocus]="form.get('parameterKey').value.length"
					/>
					<app-validation-errors [control]="form.get('parameterValue')" [smallerFont]="true"></app-validation-errors>
				</div>
				<div class="p-table-cell padding-right-8">
					<button
						type="button"
						id="okButton"
						class="aui-button"
						title="Submit Edit"
						[attr.aria-disabled]="!form.valid"
						(click)="onSubmit()"
					>
						Ok
					</button>
				</div>
				<div class="p-table-cell">
					<button type="button" class="cancel-btn" (click)="cancel.emit()" title="Click to cancel edit.">
						<svg-icon name="icon-x-dark"></svg-icon>
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
