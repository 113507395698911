import { NgClass, NgIf } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import {
	AbstractControl,
	FormsModule,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidatorFn
} from '@angular/forms';
import { ValidationErrorsComponent } from '../validation-errors/validation-errors.component';

interface SubmitterHTMLElement extends HTMLElement {
	name?: string;
}

export interface SubmitEvent extends Event {
	submitter: SubmitterHTMLElement;
}

@Component({
	selector: 'app-single-line-editor',
	templateUrl: './single-line-editor.component.html',
	styleUrls: ['./single-line-editor.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		NgIf,
		FlexModule,
		ValidationErrorsComponent,
		NgClass,
		ExtendedModule
	]
})
export class SingleLineEditorComponent implements OnInit, OnChanges {
	@Input() text: string;
	@Input() width = 600;
	@Input() textColor = '#6B778C';
	@Input() link: string;
	@Input() URL: string;
	@Input() confirmed = false;
	@Input() validators: ValidatorFn[] = [];
	@Input() placeholder = '';

	@Output() changedValue = new EventEmitter<string>();

	form: UntypedFormGroup;
	focused = false;

	private lastValue: string;

	get f(): { [key: string]: AbstractControl } {
		return this.form?.controls;
	}

	constructor(private formBuilder: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			name: new UntypedFormControl(this.text, this.validators)
		});
		document.getElementById('name').addEventListener('keyup', function (e) {
			if (e.key === 'Enter' || e.key === 'Escape') this.blur();
		});

		this.lastValue = this.text;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.text &&
			(changes?.confirmed?.currentValue || changes?.text?.currentValue)
		) {
			this.lastValue = changes.text.currentValue as string;
			this.f?.name?.setValue(this.lastValue);
			this.confirmed = false;
		}
	}

	saveOnBlur(event: FocusEvent) {
		const cancelClicked =
			(event?.relatedTarget as HTMLElement)?.id === 'cancel';
		const valueNotChanged = this.f.name.value === this.lastValue;

		if (!cancelClicked && !this.form.invalid && !valueNotChanged) {
			this.changedValue.emit(this.f.name.value);
			this.confirmed = true;
		}

		// delay to give a chance the form registers button click before we deel with lost of the focus
		setTimeout(() => {
			this.isFocused(false);
			this.resetForm();
		}, 200);
	}

	cancel() {
		// delay to give a chance the form registers button click before we deel with lost of the focus
		setTimeout(() => {
			this.isFocused(false);
			this.resetForm();
		}, 200);
	}

	resetForm(): void {
		if (!this.confirmed) {
			this.f.name.setValue(this.lastValue);
		}
	}

	isFocused(focused: boolean): void {
		this.focused = focused;
	}
}
