import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-pro-toggle-button',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './pro-toggle-button.component.html',
	styleUrls: ['./pro-toggle-button.component.less']
})
export class ProToggleButtonComponent {
	@Input() proActive = false;

	@Output() essentialClicked = new EventEmitter<void>();
	@Output() proClicked = new EventEmitter<void>();
}
