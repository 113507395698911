import {
	JsonConverter,
	JsonCustomConvert,
	JsonObject,
	JsonProperty
} from 'json2typescript';

@JsonConverter
class DateConverter implements JsonCustomConvert<Date> {
	serialize(date: Date): string {
		return date.toUTCString();
	}
	deserialize(date: string): Date {
		return new Date(date);
	}
}

@JsonObject('TestRunStatus')
export class TestRunStatus {
	@JsonProperty('color', String)
	color: string = undefined;

	@JsonProperty('description', String, true)
	description: string = undefined;

	@JsonProperty('name', String)
	name: string = undefined;
}

@JsonObject('TestRun')
export class TestRun {
	@JsonProperty('id', String, true)
	id: string = undefined;

	@JsonProperty('comment', String, true)
	comment: string = undefined;

	@JsonProperty('status', TestRunStatus)
	status: TestRunStatus = undefined;

	@JsonProperty('startedOn', DateConverter)
	startedOn: Date = undefined;

	@JsonProperty('finishedOn', DateConverter, true)
	finishedOn: Date = undefined;
}
