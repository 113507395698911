import {
	AsyncPipe,
	JsonPipe,
	NgForOf,
	NgIf,
	NgTemplateOutlet
} from '@angular/common';
import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexModule } from '@angular/flex-layout';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidatorFn
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { Type, TypedParameter } from '@testifi-models/typed-Item';
import {
	SettingsToggleComponent,
	SettingsToggleOptions
} from '@testifi-pages/page-credentials-settings/settings-toggle/settings-toggle.component';
import { ModalService } from '@testifi-services/modal.service';
import {
	VALUE_RESTRICTED_VALIDATORS,
	VALUE_VALIDATORS
} from '@testifi-shared/app-constants';
import { CheckboxComponent } from '@testifi-shared/checkbox/checkbox.component';
import { DataEffect } from '@testifi-store/data/data.effect';
import { DataRepository } from '@testifi-store/data/data.repository';
import { Utils } from '@testifi-utils/utils';
import {
	EMPTY,
	Observable,
	distinctUntilChanged,
	filter,
	map,
	merge,
	of,
	switchMap
} from 'rxjs';
import { ModalCloseDirective } from '../../directives/modal-close.directive';
import { ValidationErrorsComponent } from '../../shared/validation-errors/validation-errors.component';

interface IOption {
	name: string;
	value: string;
}

export interface IModalTypedParameterData {
	parameter?: TypedParameter;
	parameterNameValidators?: ValidatorFn[];
}

@Component({
	templateUrl: './modal-typed-parameter.component.html',
	styleUrls: ['./modal-typed-parameter.component.less'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ValidationErrorsComponent,
		ModalCloseDirective,
		NgIf,
		NgForOf,
		FlexModule,
		CheckboxComponent,
		AsyncPipe,
		NgSelectModule,
		JsonPipe,
		SettingsToggleComponent,
		NgTemplateOutlet
	]
})
export class ModalTypedParameterComponent implements OnInit {
	parameterNameValidators = VALUE_VALIDATORS;

	form: FormGroup<{
		text: FormControl<string>;
		parameterType: FormControl<Type>;
		defaultValue?: FormControl<string>;
		useDataFile: FormControl<boolean>;
		dataFile?: FormControl<string>;
		dataFileSelector?: FormControl<string>;
	}>;
	readonly = false;
	defaultValueFormControl = new FormControl<string>('', {
		validators: VALUE_RESTRICTED_VALIDATORS
	});
	dataFileIdFormControl = new FormControl<string>(null);
	dataFileSelectorFormControl = new FormControl<string>('');
	dataFileOptions$: Observable<IOption[]> = this.dataRepository.dataFiles$.pipe(
		map((dataFiles) =>
			Object.keys(dataFiles).map((dataFileName) => ({
				name: dataFiles[dataFileName].name,
				value: dataFiles[dataFileName].id
			}))
		)
	);
	dataFileSelectorOptions$: Observable<IOption[]>;
	submitted = false;
	destroyRef = inject(DestroyRef);

	protected readonly ParameterType = Type;
	useDataFileToggleOptions: SettingsToggleOptions = {
		firstOption: '',
		secondOption: 'Data File'
	};

	notUseDataFile = true;
	disabledToggle = false;

	constructor(
		private formBuilder: FormBuilder,
		private modalService: ModalService,
		private dataRepository: DataRepository,
		private dataEffect: DataEffect,
		@Inject(MAT_DIALOG_DATA)
		public data: IModalTypedParameterData
	) {}

	ngOnInit(): void {
		this.readonly = !!this.data.parameter;
		this.parameterNameValidators =
			this.data.parameterNameValidators ?? this.parameterNameValidators;
		this.initializeForm();
		if (!this.readonly) {
			this.initializeDataFileSelectorOptions();
			this.toggleDataFeature();
			this.toggleDefaultValue();
			this.initializeDataFileChange();
			this.handleAutofill();
		} else {
			this.fillFormWithInitialData();
		}
	}

	addParameter(): void {
		this.submitted = true;

		const parameterType = this.form.controls.parameterType.value;
		const text = this.form.controls.text.value;
		const defaultValue = this.defaultValueFormControl.value;

		const useDataFile = this.form.controls.useDataFile.value;

		let parameterValue = '';
		if (useDataFile) {
			const dataFileName =
				this.dataRepository.getDataFromId(this.dataFileIdFormControl.value)
					?.name ?? '';
			parameterValue = Utils.createCBBParameterValueFromDataFile(
				text,
				dataFileName,
				this.dataFileSelectorFormControl.value,
				defaultValue
			);
		} else {
			parameterValue =
				parameterType === Type.OPTIONAL ? `${text}!'${defaultValue}'` : text;
		}

		const newParameter = new TypedParameter(parameterValue, parameterType);

		this.modalService.close(newParameter);
	}

	onToggleUseDataFile(notUseDataFile: boolean) {
		this.form.controls.useDataFile.patchValue(!notUseDataFile);
	}

	private toggleDataFeature(): void {
		this.form.controls.useDataFile.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((useDataFile) => {
				if (useDataFile) {
					this.form.addControl('dataFile', this.dataFileIdFormControl);
					this.form.addControl(
						'dataFileSelector',
						this.dataFileSelectorFormControl
					);
					this.enableDataFileSelectorIfDataFilePresent();
					this.dataEffect.getDataFilesIfNotPresent();
				} else {
					this.form.removeControl('dataFile');
					this.form.removeControl('dataFileSelector');
				}
				this.resetNameAndDefaultValue();
			});
	}

	private toggleDefaultValue(): void {
		this.form.controls.parameterType.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				if (value === Type.OPTIONAL) {
					this.form.addControl('defaultValue', this.defaultValueFormControl);
				} else {
					this.form.removeControl('defaultValue');
				}
			});
	}

	private initializeForm(): void {
		this.form = this.formBuilder.group({
			text: ['', this.parameterNameValidators],
			parameterType: [Type.MANDATORY],
			useDataFile: [!this.notUseDataFile]
		});
	}

	private initializeDataFileChange() {
		this.dataFileIdFormControl.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((selectedFileId) => {
				this.dataFileSelectorFormControl.reset();
				this.resetNameAndDefaultValue();
				if (selectedFileId && this.form.controls.useDataFile.value) {
					this.dataEffect.getSelectorsInDataFile(selectedFileId);
					this.dataFileSelectorFormControl.enable();
				} else {
					this.dataFileSelectorFormControl.disable();
				}
			});
	}

	private enableDataFileSelectorIfDataFilePresent() {
		if (this.dataFileIdFormControl.value) {
			this.dataFileSelectorFormControl.enable();
		} else {
			this.dataFileSelectorFormControl.disable();
		}
	}

	private initializeDataFileSelectorOptions() {
		this.dataFileSelectorOptions$ = merge(
			this.dataFileIdFormControl.valueChanges,
			this.form.controls.useDataFile.valueChanges
		).pipe(
			switchMap(() => of(this.dataFileIdFormControl.value)),
			distinctUntilChanged(),
			switchMap((dataFileId) =>
				!dataFileId ? EMPTY : this.dataRepository.getDataFromId$(dataFileId)
			),
			map((data) =>
				data?.selectors?.map((selector) => ({
					name: selector.selector,
					value: selector.selector
				}))
			)
		);
	}

	private handleAutofill() {
		merge(
			this.form.controls.useDataFile.valueChanges.pipe(filter(Boolean)), // when useDataFile become true OR ...
			this.dataFileSelectorFormControl.valueChanges.pipe(filter(Boolean)) // when dataFileSelector has a value
		)
			.pipe(
				switchMap(() =>
					of(
						!!this.form.controls.useDataFile.value &&
							!!this.dataFileIdFormControl.value
					)
				),
				filter(Boolean),
				switchMap(() =>
					this.dataRepository.getDataFromId$(this.dataFileIdFormControl.value)
				),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((res) => {
				const selector = res.selectors.find(
					(data) => data.selector === this.dataFileSelectorFormControl.value
				);
				const defaultValue = selector?.defaultValue;
				if (defaultValue) {
					this.defaultValueFormControl.patchValue(defaultValue);
					this.defaultValueFormControl.disable();
				} else {
					this.defaultValueFormControl.patchValue('');
					this.defaultValueFormControl.enable();
				}
				if (selector.selector) {
					this.form.controls.text.patchValue(selector.selector);
				} else {
					this.form.controls.text.patchValue('');
				}
			});
	}

	private resetNameAndDefaultValue() {
		this.form.controls.text.patchValue('');
		this.defaultValueFormControl.patchValue('');
	}

	private fillFormWithInitialData() {
		const hasDataFile = Utils.parameterHasDataFile(this.data.parameter.label);
		if (hasDataFile) {
			const { parameterName, dataFileName, selectorName, defaultValue } =
				Utils.parseCBBParameterValueFromDataFile(this.data.parameter.label);

			this.form.controls.useDataFile.patchValue(true);

			this.form.controls.text.patchValue(parameterName);

			this.form.controls.parameterType.patchValue(this.data.parameter.type);

			if (this.data.parameter.type === Type.OPTIONAL) {
				this.form.addControl('defaultValue', this.defaultValueFormControl);
				this.defaultValueFormControl.patchValue(defaultValue);
			}

			this.dataFileIdFormControl.patchValue(dataFileName);
			this.form.addControl('dataFile', this.dataFileIdFormControl);

			this.dataFileSelectorFormControl.patchValue(selectorName);
			this.form.addControl(
				'dataFileSelector',
				this.dataFileSelectorFormControl
			);
		} else {
			const parameterName = Utils.getItemName(this.data.parameter.label);
			const defaultValue = Utils.getItemDefaultValue(this.data.parameter.label);

			this.form.controls.useDataFile.patchValue(false);

			this.form.controls.text.patchValue(parameterName);

			this.form.controls.parameterType.patchValue(this.data.parameter.type);

			if (this.data.parameter.type === Type.OPTIONAL) {
				this.form.addControl('defaultValue', this.defaultValueFormControl);
				this.defaultValueFormControl.patchValue(defaultValue);
			}
		}

		this.notUseDataFile = !this.form.controls.useDataFile.value;

		this.disabledToggle = true;
		this.form.disable();
	}
}
