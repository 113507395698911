<div fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
	<span class="settings-toggle-text" [ngClass]="{'settings-toggle-active': activeFirst}">{{options?.firstOption}}</span>
	<svg-icon
		[class]="!disabled ? 'pointer' : ''"
		name="{{ activeFirst ? (!disabled ? 'switch-left' : 'switch-left-disabled') : (!disabled ? 'switch-right' : 'switch-right-disabled') }}"
		(click)="click()"
	></svg-icon>
	<span class="settings-toggle-text" [ngClass]="{'settings-toggle-active': !activeFirst}"
		>{{options?.secondOption}}</span
	>
</div>
