import {
	CdkDrag,
	CdkDragEnd,
	CdkDragMove,
	CdkDragPlaceholder,
	CdkDragStart
} from '@angular/cdk/drag-drop';
import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-library-drag-drop-element',
	templateUrl: './library-drag-drop-element.component.html',
	styleUrls: ['./library-drag-drop-element.component.less'],
	standalone: true,
	imports: [CdkDrag, NgIf, CdkDragPlaceholder]
})
export class LibraryDragDropElementComponent {
	@Input() name: string;
	@Input() tag: string;
	@Input() group: string;
	@Input() usePlaceholder = false;

	@Output() dragMove: EventEmitter<CdkDragMove<HTMLDivElement>>;
	@Output() dragEnded: EventEmitter<CdkDragEnd<HTMLDivElement>>;
	@Output() dragStarted: EventEmitter<CdkDragStart<HTMLDivElement>>;
	@Output() shortcutClick: EventEmitter<PointerEvent>;

	constructor() {
		this.dragMove = new EventEmitter<CdkDragMove<HTMLDivElement>>();
		this.dragEnded = new EventEmitter<CdkDragEnd<HTMLDivElement>>();
		this.dragStarted = new EventEmitter<CdkDragStart<HTMLDivElement>>();
		this.shortcutClick = new EventEmitter<PointerEvent>();
	}

	onDragMove(event: CdkDragMove<HTMLDivElement>): void {
		this.dragMove.emit(event);
	}

	onDragEnded(event: CdkDragEnd<HTMLDivElement>): void {
		this.dragEnded.emit(event);
	}

	onDragStarted(event: CdkDragStart<HTMLDivElement>): void {
		this.dragStarted.emit(event);
	}

	onShortcutClick(event: PointerEvent): void {
		this.shortcutClick.emit(event);
	}
}
