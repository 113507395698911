<div class="clt_container" fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="4px">
	<div
		#textDiv
		[ngClass]="{ 'clt_cropped-text': !isExpanded, 'clt_expanded-text': isExpanded, 'clt_padTop': topPadding }"
		title="{{ isExpanded ? '' : text }}"
	>
		{{ text }}
	</div>
	<button
		type="button"
		class="clt_button"
		(click)="onButtonClick($event)"
		title="{{ isExpanded ? 'Show less' : 'Show all' }}"
		[style.visibility]="showButton && shouldExpand ? 'visible' : 'hidden'"
	>
		<svg-icon name="{{ isExpanded ? 'icon-arrow-up-bold' : 'icon-arrow-down-bold' }}"></svg-icon>
	</button>
</div>
