import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ScenarioParameter')
export class ScenarioParameter {
	@JsonProperty('key', String)
	key: string = undefined;

	@JsonProperty('value', String)
	value: string = undefined;

	constructor(
		key: string,
		value: string,
		public mandatory = false,
		public optional = false,
		public bgColor = '#393843'
	) {
		this.key = key;
		this.value = value;
	}
}
