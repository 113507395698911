import { NgIf, NgStyle } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { ScenarioParameter } from '@testifi-models/scenario-parameter';
import {
	MANDATORY_PARAMETERS_KEY,
	OPTIONAL_PARAMETERS_KEY,
	RESERVED_PARAMETER_NAMES
} from '@testifi-shared/app-constants';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
	selector: 'app-parameter-label',
	templateUrl: './parameter-label.component.html',
	styleUrls: ['./parameter-label.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FlexModule, NgStyle, ExtendedModule, NgIf, SvgIconComponent]
})
export class ParameterLabelComponent implements OnInit {
	@Input() parameter: ScenarioParameter;

	@Output() remove = new EventEmitter<ScenarioParameter>();
	@Output() edit = new EventEmitter<ScenarioParameter>();

	label: string;
	divId: string;
	isReadOnly: boolean;

	constructor(private renderer: Renderer2) {}

	ngOnInit(): void {
		this.label = `${this.parameter.key}="${this.parameter.value}"`;
		this.divId = this.parameter.key.replace('\\W', '_');
		this.isReadOnly = RESERVED_PARAMETER_NAMES.includes(
			this.parameter.key.toLowerCase()
		);
		if (this.parameter.key === MANDATORY_PARAMETERS_KEY) {
			this.parameter.mandatory = true;
		}
		if (this.parameter.key === OPTIONAL_PARAMETERS_KEY) {
			this.parameter.optional = true;
		}
	}

	onRemove(): void {
		this.remove.emit(this.parameter);
	}

	onEdit(): void {
		if (!this.isReadOnly) {
			this.edit.emit(this.parameter);
		}
	}
}
