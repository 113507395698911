<form class="aui top-label" [formGroup]="form" *ngIf="form">
	<div fxLayout="column">
		<header class="aui-page-header">
			<div class="aui-page-header-inner">
				<div class="aui-page-header-main">
					<h3>GIT credentials</h3>
				</div>
			</div>
		</header>
		<div class="module form-body">
			<div class="field-group" [ngClass]="{'aux-space': auxSpace}">
				<label class="label" for="gitUsername">GIT Username</label>
				<input class="input-text" type="text" formControlName="username" id="gitUsername" placeholder="GIT Username" />
				<app-validation-errors [control]="form.get('username')"></app-validation-errors>
			</div>
			<div class="field-group">
				<label class="label" for="token">GIT Token</label>
				<input
					class="input-text"
					[ngClass]="{'input-text-security': isTokenHidden}"
					type="text"
					formControlName="token"
					id="token"
					placeholder="GIT Token"
					onCopy="return false"
					onCut="return false"
					onselectstart="return false"
					onDrag="return false"
					autocomplete="off"
				/>
				<svg-icon
					name="{{ isTokenHidden ? 'eye-on' : 'eye-off' }}"
					class="input-text-icon"
					title="Show token"
					[svgStyle]="{'width.px': 16, 'height.px': 16}"
					(click)="isTokenHidden = !isTokenHidden"
				></svg-icon>
				<app-validation-errors [control]="form.get('token')"></app-validation-errors>
			</div>
		</div>
		<div class="test-connection">
			<app-test-connection [state]="testConnectionState" (click)="testConnectionClick()"></app-test-connection>
		</div>
	</div>
</form>
