<form
	[style.width.px]="width"
	class="single-line-form"
	role=""
	[formGroup]="form"
	autocomplete="off"
>
	<a *ngIf="link" class="link" href="{{ URL }}" target="_blank" rel="noopener">{{ link }}</a>

	<div class="w-100" fxLayout="column" fxLayoutGap="4px">
		<input
			#textInput
			class="text"
			type="text"
			[style.color]="textColor"
			formControlName="name"
			placeholder="{{ placeholder }}"
			id="name"
			(focus)="isFocused(true)"
			(blur)="saveOnBlur($event)"
			(keyup.enter)="textInput.blur()"
		/>
		<app-validation-errors [control]="f.name"></app-validation-errors>
	</div>

	<div class="button-group" [ngClass]="{'button-group-error': form.invalid}" [style.width.px]="width" *ngIf="focused">
		<button
			id="update-submit"
			class="aui-button comp-button"
			name="Apply"
			title="Apply"
			[disabled]="!form.valid"
			(click)="textInput.blur()"
		>
			<span class="aui-icon aui-icon-small aui-iconfont-check"></span>
		</button>
		<button id="cancel" class="aui-button comp-button" name="Cancel" title="Cancel" (click)="cancel()">
			<span class="aui-icon aui-icon-small aui-iconfont-cross"></span>
		</button>
	</div>
</form>
