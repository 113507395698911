/* eslint-disable functional/immutable-data */
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { Statistic } from '@testifi-models/project-view';
import { produce } from 'immer';
import { ExecutionStatusComponent } from '../execution-status/execution-status.component';

export enum MultiSelectDropdownElementIds {
	JIRA_STATUS_FILTER = 'jiraStatusesFilter',
	EXECUTION_STATUS_FILTER = 'excecutionStatusesFilter'
}

@Component({
	selector: 'app-multi-select-dropdown',
	templateUrl: './multi-select-dropdown.component.html',
	styleUrls: ['./multi-select-dropdown.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgFor, ExecutionStatusComponent, NgClass, ExtendedModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MultiSelectDropdownComponent implements OnInit, AfterViewInit {
	@Input() label: string;
	@Input() values: Statistic[];

	@ViewChild('menu') dropdownMenu: ElementRef<HTMLElement>;
	@ViewChild('menubutton') menuButton: ElementRef<HTMLButtonElement>;

	selectedValues: string[] = [];

	buttonLabel: string;
	buttonTitle: string;

	private valueSelectedMap = new Map<string, boolean>();

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.values?.forEach((stat) => {
			this.valueSelectedMap.set(stat.status, true); // initially all selected
		});

		this.updateButton();
	}

	ngAfterViewInit(): void {
		this.dropdownMenu.nativeElement.id = this.label.startsWith('Execution')
			? MultiSelectDropdownElementIds.EXECUTION_STATUS_FILTER
			: MultiSelectDropdownElementIds.JIRA_STATUS_FILTER;

		this.menuButton.nativeElement.setAttribute(
			'aria-controls',
			this.dropdownMenu.nativeElement.id
		);

		this.dropdownMenu?.nativeElement?.addEventListener('change', (e: Event) => {
			if (e.target instanceof Element) {
				const isChecked = e.target.hasAttribute('checked');
				const count = this.selectedValues.length + (isChecked ? 1 : 0);
				const item = (e.target as HTMLInputElement).name;
				if (count > 1) {
					this.valueSelectedMap.set(item, isChecked);
					this.updateButton();
				} else {
					(e.target as HTMLInputElement).setAttribute('checked', '');
				}
			}
		});
	}

	private getSelectedValues(): void {
		this.selectedValues = [];
		this.valueSelectedMap.forEach((value, key) => {
			if (value) {
				this.selectedValues = produce(this.selectedValues, (draft) => {
					draft.push(key);
				});
			}
		});
	}

	private updateButton(): void {
		this.getSelectedValues();
		const isAllSelected =
			this.selectedValues.length === this.valueSelectedMap.size;
		this.buttonLabel =
			this.label +
			(isAllSelected ? ': All' : ': ' + this.selectedValues.join(', '));
		this.buttonTitle = this.label + ': ' + this.selectedValues.join(', ');
		this.cdr.detectChanges();
	}
}
