import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { SvgIconComponent } from 'angular-svg-icon';

export interface SettingsToggleOptions {
	firstOption: string;
	secondOption: string;
}

@Component({
	selector: 'app-settings-toggle',
	templateUrl: './settings-toggle.component.html',
	styleUrls: ['./settings-toggle.component.less'],
	standalone: true,
	imports: [FlexModule, NgClass, ExtendedModule, SvgIconComponent]
})
export class SettingsToggleComponent {
	@Output() firstActivated = new EventEmitter<boolean>();

	@Input() options: SettingsToggleOptions = {
		firstOption: '',
		secondOption: ''
	};
	@Input() activeFirst = true;
	@Input() disabled = false;

	click(): void {
		if (this.disabled) {
			return;
		}
		this.activeFirst = !this.activeFirst;
		this.firstActivated.emit(this.activeFirst);
	}
}
