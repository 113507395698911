<dialog
	id="modal-edit-building-block"
	class="aui-dialog2"
	tabindex="-1"
	aria-labelledby="modal-delete-building-block--heading"
	*hideOnLoading
>
	<app-library-drag-drop-page
		[projectId]="buildingBlock.projectId"
		[blockId]="data.buildingBlockId"
		[item]="buildingBlock"
		[type]="testObjectType.BuildingBlock"
		[bottomOffset]="223"
		(changePosition)="onChangePosition($event)"
		(libraryDrop)="onLibraryDrop($event)"
		[forbiddenEvent]="forbiddenEvent.asObservable()"
		[editTracker]="editTracker"
	>
	</app-library-drag-drop-page>
</dialog>
