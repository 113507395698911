import { Injectable } from '@angular/core';
import { ProjectsPage } from '@testifi-models/project';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrivateHttpClient } from '../https/instances/private.http';
import { BaseApiService } from './base-api.service';

@Injectable({
	providedIn: 'root'
})
export class AdminService {
	constructor(
		private privateHttp: PrivateHttpClient,
		private base: BaseApiService
	) {}

	getJiraProjects(
		production: boolean,
		pageNumber = '1',
		limit = '10'
	): Observable<ProjectsPage> {
		return this.privateHttp
			.get<ProjectsPage>('admin/getProject', {
				params: { pageNumber, limit, production }
			})
			.pipe(map((project) => this.base.model(project, ProjectsPage)));
	}

	setJiraProperty(payload: {
		projectKey: string;
		value: boolean;
		production: boolean;
	}): Observable<void> {
		return this.privateHttp.put<void>('admin/setProjectProperty', payload);
	}
}
