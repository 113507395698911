<div id="modal-edit-sui-page-elements" *hideOnLoading>
	<div class="sidebar">
		<div class="inner" *hideOnLoading>
			<div class="filter">
				<form class="aui">
					<div class="field">
						<select title="Group" class="select" (change)="onTagGroupChange($event.target.value)">
							<option
								*ngFor="let tagGroup of tagGroups | keyvalue"
								[attr.selected]="activeTagGroup === tagGroup.key ? '' : null"
								value="{{ tagGroup.key }}"
							>
								{{ tagGroup.key }}
							</option>
						</select>
					</div>
					<div class="field">
						<input
							placeholder="Search"
							type="search"
							class="text"
							value="{{ tagGroupFilter }}"
							(input)="onSearchTagGroup($event.target.value)"
							id="libraryFilter"
							[autofocus]="true"
						/>
					</div>
				</form>
			</div>
			<div class="modules" #modules>
				<div class="module-items">
					<div
						class="module-items-inner"
						cdkDropListSortingDisabled
						cdkDropList
						id="module-items-tags {{ activeTagGroup }}"
						[cdkDropListData]="tags"
						[cdkDropListConnectedTo]="connectedDropListsIds"
					>
						<cdk-virtual-scroll-viewport itemSize="5">
							<app-library-drag-drop-element
								*cdkVirtualFor="let tag of filteredTags; templateCacheSize: 0"
								[name]="tag.uiTag"
								[tag]="tag.name"
								[group]="activeTagGroup"
								(dragEnded)="onLibDragEnded($event)"
								(dragMove)="onLibDragMove($event)"
								(dragStarted)="onLibDragStarted($event)"
								(shortcutClick)="onShortcutClick(tag)"
								[usePlaceholder]="true"
							>
							</app-library-drag-drop-element>
						</cdk-virtual-scroll-viewport>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="special-parent">
		<div class="col-group header">
			<header class="aui-dialog2-header">
				<h2 class="aui-dialog2-header-main">
					<app-long-text [text]="suiPage.name" [topPadding]="true"></app-long-text>
				</h2>
				<div id="top-right-buttons">
					<button
						type="button"
						id="undo"
						class="aui-button"
						title="Roll back last change"
						(click)="onClickUndo()"
						[disabled]="!editTracker.canUndo"
					>
						<img src="assets/icons/icon-undo.png" alt="Undo" />Undo
					</button>
					<button
						type="button"
						id="redo"
						class="aui-button"
						title="Re-do last reverted change"
						(click)="onClickRedo()"
						[disabled]="!editTracker.canRedo"
					>
						<img src="assets/icons/icon-undo.png" class="flipHorizontally" alt="Redo" />Redo
					</button>
				</div>
			</header>
		</div>
		<div class="content background-N0">
			<div class="inner">
				<div class="col-group setup">
					<div class="col-mb-24 col-24 col-dt-24 col-ld-24">
						<div class="aui-tabs horizontal-tabs">
							<ul class="tabs-menu">
								<li class="menu-item active-tab">
									<a>Page Elements</a>
								</li>
								<button
									id="expandAllObjectsButton"
									class="expand-all-button"
									mat-icon-button
									[disabled]="expandAllButtonDisabled"
									aria-label="Expand all"
									(click)="expandAll()"
									matTooltip="Expand all"
								>
									<mat-icon svgIcon="expandAll"></mat-icon>
								</button>
								<button
									id="collapseAllObjectsButton"
									class="collapse-all-button"
									mat-icon-button
									[disabled]="collapseAllButtonDisabled"
									aria-label="Collapse all"
									(click)="collapseAll()"
									matTooltip="Collapse all"
								>
									<mat-icon svgIcon="collapseAll"></mat-icon>
								</button>
							</ul>
							<div class="tabs-pane active-pane" id="tabs-setup">
								<app-modal-edit-sui-page-elements-element
									[parameters]="parentItem.optionalFields"
									[isDragging]="isDragging"
									[item]="parentItem"
									[rootItem]="parentItem"
									[connectedLists]="connectedDropListsIds"
									[intersectedItemIds]="intersectedItemIds"
									[expandedItemIds]="expandedItemIds"
									[suiPage]="suiPage"
									[root]="true"
									[tagGroups]="tagGroups"
									[editTracker]="editTracker"
									[screenshots]="screenshots"
									(dragDrop)="onDragDrop($event)"
									(dragEnded)="onDragEnded($event)"
									(dragStarted)="onDragStarted($event)"
									(dragMove)="onDragMove($event)"
									(expandedClick)="onToggleExpanded($event, true)"
									(refreshOnModalClose)="onRefreshOnModalClose($event)"
								></app-modal-edit-sui-page-elements-element>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-button">
			<footer class="aui-dialog2-footer">
				<div class="aui-dialog2-footer-actions btnCloseHolder">
					<button type="button" id="close-submit" class="aui-button" (click)="close()">Close</button>
				</div>
			</footer>
		</div>
	</div>
</div>
