import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SUIPagesPage } from '@testifi-models/paginated-responses';
import { SuiPage } from '@testifi-models/sui-page';
import { SuiPageElementIds } from '@testifi-models/sui-page-element-ids';
import { XmlElements } from '@testifi-models/xml-elements';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrivateHttpClient } from '../https/instances/private.http';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class SuiPageService {
	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private privateHttp: PrivateHttpClient,
		private base: BaseApiService
	) {}

	// ======================================================================
	// public functions
	// ======================================================================

	view(id: string): Observable<SuiPage> {
		return this.privateHttp
			.get<SuiPage>(`sui-page/${id}`)
			.pipe(map((res) => this.base.model(res, SuiPage)));
	}

	ids(id: string): Observable<SuiPageElementIds> {
		return this.privateHttp
			.get<SuiPageElementIds>(`sui-page/getId/${id}`)
			.pipe(map((res) => this.base.model(res, SuiPageElementIds)));
	}

	getAll(
		projectId: string,
		page = 0,
		limit = 10,
		sort = 'NAME',
		direction = 'ASC',
		searchText = ''
	): Observable<SUIPagesPage> {
		const params = new HttpParams()
			.set('pageNumber', `${page}`)
			.set('limit', `${limit}`)
			.set('sortBy', sort)
			.set('direction', direction)
			.set('text', searchText);

		return this.privateHttp
			.get<SUIPagesPage>(`project/${projectId}/sui-pages`, {
				params
			})
			.pipe(map((res) => this.base.model(res, SUIPagesPage)));
	}

	add(name: string, projectId: string): Observable<SuiPage> {
		return this.privateHttp
			.post<SuiPage>('sui-page', { name, projectId })
			.pipe(map((res) => this.base.model(res, SuiPage)));
	}

	updateElements(id: string, elementList: XmlElements): Observable<void> {
		return this.privateHttp.post<void>(
			`sui-page/${id}/updateTestObject`,
			elementList
		);
	}

	delete(id: string): Observable<boolean> {
		return this.privateHttp
			.delete<boolean>(`sui-page/${id}`)
			.pipe(map((res) => res));
	}
}
