<div class="table-wrapper">
	<ul>
		<ng-container *ngIf="!(lastBrowsedBusinessJourneysLoading$ | async);else loadingSkeleton">
			<li *ngFor="let bj of lastBrowsedBusinessJourneys$ | async">
				<div class="bj-summary">
					<mat-icon [style.margin-right]="'10px'" svgIcon="git-merge" />
					<span>{{bj.name}}</span>
				</div>
				<div class="created-by">{{bj.reporter}}</div>
				<div class="created-at">{{bj.createDate | date : 'YYYY.MM.dd'}}</div>
				<div class="status" [style.color]="bj.statusColor">{{bj.status}}</div>
				<div class="description">{{bj.description}}</div>
			</li>
		</ng-container>
		<ng-template #loadingSkeleton>
			<li class="skeleton-item"></li>
			<li class="skeleton-item"></li>
			<li class="skeleton-item"></li>
		</ng-template>
	</ul>
</div>
