import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Notification, NotificationType } from '@testifi-models/notification';
import { Observable, Subject } from 'rxjs';

declare const AJS;

@Injectable({ providedIn: 'root' })
export class NotificationService {
	// ======================================================================
	// private properties
	// ======================================================================

	private subject = new Subject<Notification>();
	private keepAfterNavigationChange = false;
	private notificationQueue = new Map();

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(private router: Router) {
		// clear notifications message on route change
		router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				if (this.keepAfterNavigationChange) {
					// only keep for a single location change
					this.keepAfterNavigationChange = false;
				} else {
					// clear notifications
					this.subject.next(null);
				}
			}
		});

		this.getNotification().subscribe((event) => {
			if (event) {
				const mapping = new Map([
					[NotificationType.Info, 'info'],
					[NotificationType.Error, 'error'],
					[NotificationType.Warning, 'warning'],
					[NotificationType.Success, 'success']
				]);

				// eslint-disable-next-line
				const notification = AJS.flag({
					type: mapping.get(event.type),
					body: event.message
				});

				const timeout = setTimeout(() => {
					this.notificationQueue.delete(notification);
					notification.close(); // eslint-disable-line
				}, 5000);

				this.notificationQueue.set(notification, timeout);
			}
		});
	}

	// ======================================================================
	// public functions
	// ======================================================================

	notification(
		notification: Notification,
		keepAfterNavigationChange = false
	): void {
		this.keepAfterNavigationChange = keepAfterNavigationChange;
		this.subject.next(notification);
	}

	getNotification(): Observable<Notification> {
		return this.subject.asObservable();
	}

	error(message: string): void {
		if (isDevMode()) {
			console.error(message);
		}

		this.notification(new Notification(NotificationType.Error, message));
	}

	httpError(errorResponse: HttpErrorResponse): void {
		this.error(
			errorResponse?.error?.error || // eslint-disable-line
				errorResponse?.error?.message || // eslint-disable-line
				errorResponse?.message ||
				errorResponse
		);
	}

	success(message: string): void {
		this.notification(new Notification(NotificationType.Success, message));
	}

	warning(message: string): void {
		this.notification(new Notification(NotificationType.Warning, message));
	}

	info(message: string): void {
		this.notification(new Notification(NotificationType.Info, message));
	}
}
