<div id="page-view-project-component" *hideOnLoading>
	<header class="aui-page-header">
		<div class="aui-page-header-inner">
			<div class="aui-page-header-main">
				<app-breadcrumb></app-breadcrumb>
				<h1>{{ projectView?.name }}</h1>
			</div>

			<div class="aui-page-header-actions">
				<button
					id="new-test-scenario"
					type="button"
					class="aui-button aui-button-primary"
					[modalData]="{ projectId: projectView?.id }"
					[modalRouterName]="['test_scenarios_add', projectView?.id]"
				>
					+ New Test (Scenario)
				</button>
			</div>
			<!-- .aui-page-header-actions -->
		</div>
	</header>

	<div class="module" *ngIf="projectView">
		<div class="aui-group kpis" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="12px">
			<app-kpi-box title="Tests (Scenarios)" color="#c1c7d0" [value]="totalTests"></app-kpi-box>
			<div *ngFor="let stat of projectView.statistics">
				<app-kpi-box [title]="stat.status" [color]="stat.color" [value]="stat.count"></app-kpi-box>
			</div>
		</div>
	</div>

	<div class="module">
		<div class="command-bar">
			<div class="aui-toolbar2">
				<div class="aui-toolbar2-inner">
					<div class="aui-toolbar2-primary">
						<form class="aui navigator-search query-component generic-styled">
							<ul class="filters">
								<li>
									<app-multi-select-dropdown
										#executionStatusesFilter
										label="Execution Status"
										[values]="executionStatistics"
									>
									</app-multi-select-dropdown>
								</li>
								<li *ngIf="showJiraStatus">
									<app-multi-select-dropdown #jiraStatusesFilter label="JIRA Status" [values]="jiraStatistics">
									</app-multi-select-dropdown>
								</li>
								<li>
									<div class="text-query-container">
										<input
											#reporterFilter
											class="search-entry text medium-field"
											id="reporter"
											type="search"
											(search)="onClear()"
											[formControl]="searchReporterControl"
											[matAutocomplete]="auto"
											placeholder="All Reporters"
										/>
										<mat-autocomplete
											class="autocomplete-panel"
											#auto="matAutocomplete"
											(optionSelected)="onOptionSelected($event)"
										>
											<mat-option class="autocomplete-option" [value]="ALL_REPORTERS_VALUE">All Reporters</mat-option>
											<mat-option
												class="autocomplete-option"
												*ngFor="let option of filteredReporterList$ | async"
												[value]="option"
											>
												{{option}}
											</mat-option>
										</mat-autocomplete>
									</div>
								</li>
								<li>
									<div class="text-query-container">
										<input
											#labelItemFilter
											class="search-entry text medium-field"
											id="labelItemList"
											type="search"
											(search)="onLabelItemClear()"
											[formControl]="searchLabelItemsControl"
											[matAutocomplete]="autoLabel"
											placeholder="All Labels"
										/>
										<mat-autocomplete
											class="autocomplete-panel"
											#autoLabel="matAutocomplete"
											(optionSelected)="onLabelItemOptionSelected($event)"
										>
											<mat-option class="autocomplete-option" [value]="ALL_LABELS_VALUE">All Labels</mat-option>
											<mat-option
												class="autocomplete-option"
												*ngFor="let option of filteredLabelItemList$ | async"
												[value]="option"
											>
												{{option}}
											</mat-option>
										</mat-autocomplete>
									</div>
								</li>
								<li>
									<div class="input-group custom-picker">
										<input
											class="form-control date-picker-input"
											placeholder="Create Date Starts"
											name="dp"
											[(ngModel)]="dateModelCreatedStart"
											ngbDatepicker
											#d="ngbDatepicker"
											[maxDate]="currentDate"
											onkeydown="return false"
											type="search"
											[weekdays]="TranslationWidth.Abbreviated"
											autocomplete="off"
										/>
										<button
											class="btn btn-outline-secondary bi bi-calendar3 date-picker-button"
											(click)="d.toggle()"
											type="button"
										></button>
									</div>
								</li>
								<li>
									<div class="input-group">
										<input
											class="form-control date-picker-input"
											placeholder="Create Date Ends"
											name="dp2"
											[(ngModel)]="dateModelCreatedEnd"
											ngbDatepicker
											#d2="ngbDatepicker"
											[maxDate]="currentDate"
											onkeydown="return false"
											type="search"
											[weekdays]="TranslationWidth.Abbreviated"
											autocomplete="off"
										/>
										<button
											class="btn btn-outline-secondary bi bi-calendar3 date-picker-button"
											(click)="d2.toggle()"
											type="button"
										></button>
									</div>
								</li>
								<li>
									<div class="input-group">
										<input
											class="form-control date-picker-input"
											placeholder="Update Date Starts"
											name="dp3"
											[(ngModel)]="dateModelUpdatedStart"
											ngbDatepicker
											#d3="ngbDatepicker"
											[maxDate]="currentDate"
											onkeydown="return false"
											type="search"
											[weekdays]="TranslationWidth.Abbreviated"
											autocomplete="off"
										/>
										<button
											class="btn btn-outline-secondary bi bi-calendar3 date-picker-button"
											(click)="d3.toggle()"
											type="button"
										></button>
									</div>
								</li>
								<li>
									<div class="input-group">
										<input
											class="form-control date-picker-input"
											placeholder="Update Date Ends"
											name="dp4"
											[(ngModel)]="dateModelUpdatedEnd"
											ngbDatepicker
											#d4="ngbDatepicker"
											[maxDate]="currentDate"
											onkeydown="return false"
											type="search"
											[weekdays]="TranslationWidth.Abbreviated"
											autocomplete="off"
										/>
										<button
											class="btn btn-outline-secondary bi bi-calendar3 date-picker-button"
											(click)="d4.toggle()"
											type="button"
										></button>
									</div>
								</li>
								<li class="text-query">
									<div class="text-query-container">
										<input
											class="search-entry text medium-field"
											id="searcher-query"
											[value]="searchText"
											(input)="onSearchInput($event.target.value)"
											(keyup.enter)="onSearch()"
											type="search"
											name="search"
											placeholder="Text"
										/>
									</div>
								</li>
								<li>
									<button id="search-submit" class="aui-button search-button" type="button" (click)="onSearch()">
										Search
									</button>
								</li>
							</ul>
						</form>
					</div>
					<div class="aui-toolbar2-secondary">
						<div class="aui-buttons" fxLayout fxLayoutGap="8px">
							<button
								type="button"
								id="project-components-refresh"
								class="aui-button"
								title="Refresh"
								(click)="refresh()"
							>
								Refresh
							</button>

							<button
								type="button"
								id="project-components-execute"
								class="aui-button"
								title="Execute Scenario(s)"
								[disabled]="!checkedTestScenarios.size"
								(click)="activateTestsExecution()"
							>
								Execute Scenario{{ checkedTestScenarios.size > 1 ? 's' : '' }}
							</button>

							<button
								type="button"
								id="project-components-execute-testplans"
								class="aui-button"
								title="Execute Test Plan"
								(click)="onClickExecuteTestPlan()"
								[disabled]="!testPlans.length"
							>
								Execute Test Plan
							</button>

							<button
								type="button"
								[disabled]="!checkedTestScenarios.size"
								id="project-components-delete"
								class="aui-button"
								[ngClass]="{'disabled': !checkedTestScenarios.size}"
								title="Delete"
								[modalOnClose]="deleteOnModalConfirm.bind(this)"
								[modalData]="{
									title: 'Delete Test/s (Scenario/s)',
									text: 'Do you want to permanently delete Test/s (Scenario/s)?',
									text2: 'Test/s (Scenario/s) will be removed from the system! This action cannot be undone!',
									type: 'warning',
									confirmButton: 'Delete',
									cancelButton: 'Cancel'}"
								[modalRouterName]="['confirm-popup']"
							>
								<span class="svg">
									<svg-icon name="icon-delete" [svgStyle]="{ 'fill':'#42526E' }"></svg-icon>
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="module">
		<div class="table-container">
			<table
				mat-table
				[dataSource]="projectViewComponents"
				matSort
				(matSortChange)="onSort($event)"
				aria-describedby="Scenario list"
			>
				<ng-container [matColumnDef]="ColNames.SELECT">
					<th mat-header-cell *matHeaderCellDef id="select" class="th-select">
						<app-checkbox
							name="select_all"
							[isCenter]="projectViewComponents?.length > 0"
							(stateChanged)="selectAll($event)"
							#selectAllCheckbox
						></app-checkbox>
					</th>
					<td mat-cell *matCellDef="let row" class="td-select">
						<app-checkbox
							[checked]="checkedTestScenarios.has(row.id)"
							(stateChanged)="checkTestScenario(row)"
							name="select_test_scenario_{{row.id}}"
						></app-checkbox>
					</td>
				</ng-container>

				<ng-container [matColumnDef]="ColNames.XRAY_ID">
					<th
						class="th-xray-id"
						mat-header-cell
						id="title-sort-{{header}}"
						*matHeaderCellDef="let header"
						[mat-sort-header]="header"
					>
						Xray Ticket
					</th>
					<td class="td-xray-id" mat-cell *matCellDef="let row">
						<a
							href="{{ (appConfigRepository.jiraUrl$ | async) + '/browse/' + row.xrayTestKey }}"
							target="_blank"
							(click)="$event.stopPropagation();"
							rel="noopener"
							>{{ row.xrayTestKey }}</a
						>
					</td>
				</ng-container>

				<ng-container [matColumnDef]="ColNames.NAME">
					<th
						mat-header-cell
						*matHeaderCellDef="let header"
						id="title-sort-{{header}}"
						[mat-sort-header]="header"
						class="th-name"
					>
						Name of Test (Scenario)
					</th>
					<td class="td-name" mat-cell *matCellDef="let row; let index = index">
						<div class="td-inner-wrapper-row" #nameCell>
							<app-table-cell-show-more
								#nameCellShowMore
								(moreChanged)="nameCellMoreChanged($event, index)"
								[width.px]="nameCell.clientWidth"
								[text]="row.name"
							/>
						</div>
					</td>
				</ng-container>

				<ng-container [matColumnDef]="ColNames.REPORTER">
					<th mat-header-cell *matHeaderCellDef="let header" id="title-nosort-{{header}}" class="th-reporter">
						Reporter
					</th>
					<td mat-cell *matCellDef="let row" class="td-reporter">{{row.jira?.reporter}}</td>
				</ng-container>

				<ng-container [matColumnDef]="ColNames.XRAY_STATUS">
					<th mat-header-cell *matHeaderCellDef="let header" id="title-nosort-{{header}}" class="th-xray-status">
						Execution Status
					</th>
					<td mat-cell *matCellDef="let row" class="td-xray-status">
						<app-execution-status
							[status]="row.testRun?.status?.name"
							[color]="row.testRun?.status?.color"
						></app-execution-status>
					</td>
				</ng-container>

				<ng-container [matColumnDef]="ColNames.CREATE_DATE">
					<th mat-header-cell id="title-nosort-{{header}}" *matHeaderCellDef="let header" class="th-create-date">
						Create Date
					</th>
					<td mat-cell *matCellDef="let row" class="td-create-date">{{ row.createdAt | date : dateFormat }}</td>
				</ng-container>

				<ng-container [matColumnDef]="ColNames.UPDATE_DATE">
					<th mat-header-cell id="title-nosort-{{header}}" *matHeaderCellDef="let header" class="th-update-date">
						Update Date
					</th>
					<td mat-cell *matCellDef="let row" class="td-update-date">{{ row.updatedAt | date : dateFormat }}</td>
				</ng-container>

				<ng-container [matColumnDef]="ColNames.DESCRIPTION">
					<th mat-header-cell id="title-nosort-{{header}}" *matHeaderCellDef="let header" class="th-description">
						Description
					</th>
					<td mat-cell [id]="row.id" *matCellDef="let row; let index = index" class="td-description">
						<div class="td-inner-wrapper-row" #descriptionCell>
							<app-table-cell-show-more
								#descriptionCellShowMore
								(moreChanged)="descriptionCellMoreChanged($event, index)"
								[width.px]="descriptionCell.clientWidth"
								[text]="row.description"
							/>
						</div>
					</td>
				</ng-container>

				<tr class="header" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: displayedColumns;"
					[appRouterName]="['test_scenarios_view', '' + row.id]"
				></tr>
			</table>
		</div>
		<mat-paginator
			appStylePaginator
			#paginator
			[length]="totalItems"
			[pageSize]="pageSize"
			[pageSizeOptions]="[5, 10, 50, 100]"
			[pageIndex]="currentPage"
			(page)="handlePage($event)"
		>
		</mat-paginator>
	</div>
</div>
