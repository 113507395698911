import { Injectable } from '@angular/core';
import { IApiVersion } from '@testifi-models/api-version.interface';
import { IAppJsonConfiguration } from '@testifi-models/app-configuration.interface';
import { lastValueFrom } from 'rxjs';
import { AssetsHttpClient } from '../https/instances/assets.http';
import { PublicHttpClient } from '../https/instances/public.http';

@Injectable({
	providedIn: 'root'
})
export class ConfigApiService {
	constructor(
		private assetHttp: AssetsHttpClient,
		private publicHttp: PublicHttpClient
	) {}

	loadAppConfiguration(): Promise<IAppJsonConfiguration> {
		return lastValueFrom(
			this.assetHttp.get<IAppJsonConfiguration>('assets/app-config.json')
		);
	}

	getVersion(): Promise<IApiVersion> {
		return lastValueFrom(this.publicHttp.get<IApiVersion>('version'));
	}
}
