import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('XmlElement')
export class XmlElement {
	@JsonProperty('children', [XmlElement])
	children: Array<XmlElement> = [];

	@JsonProperty('index', Number)
	index: number = undefined;

	@JsonProperty('parameter', Object, true)
	parameter: Record<string, string> = undefined;

	@JsonProperty('tag', String)
	tag: string = undefined;
}
