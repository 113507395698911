<form class="aui top-label" [formGroup]="form">
	<div fxLayout="column">
		<div class="module form-body">
			<div class="field-group">
				<label class="label" for="gitUsername">GIT URL</label>
				<input class="input-text" type="text" formControlName="url" id="gitURL" placeholder="GIT URL" />
				<app-validation-errors [control]="form.get('url')"></app-validation-errors>
			</div>
			<div class="field-group">
				<label class="label" for="gitPassword">GIT branch</label>
				<input class="input-text" type="text" formControlName="branch" id="branch" placeholder="GIT branch" />
				<app-validation-errors [control]="form.get('branch')"></app-validation-errors>
			</div>
		</div>
	</div>
</form>
