<div id="page-credentials-settings-component" *hideOnLoading>
	<mat-tab-group color="transparent">
		<mat-tab label="Credentials">
			<h2 class="title-margin">Add your credentials</h2>
			<div class="form-box form-area" fxLayout="column">
				<div fxLayout fxLayoutGap="24px">
					<div fxFlex="50" *ngIf="(appConfigRepository.isCloud$ | async) === true">
						<app-jira-xray-credentials
							(formData)="onJiraFormData($event)"
							(dataState)="onJiraDataState($event)"
						></app-jira-xray-credentials>
					</div>
					<div
						fxFlex="50"
						*ngIf="(appConfigRepository.isCloud$ | async) === false && (appConfigRepository.isSSO$ | async) === false"
					>
						<app-server-credentials
							(formData)="onJiraFormData($event)"
							(dataState)="onJiraDataState($event)"
							(mode)="onServerMode($event)"
						></app-server-credentials>
					</div>
					<div
						fxFlex="50"
						*ngIf="(appConfigRepository.isCloud$ | async) === false && appConfigRepository.isSSO$ | async"
					>
						<app-sso-credentials
							(formData)="onJiraFormData($event)"
							(dataState)="onJiraDataState($event)"
						></app-sso-credentials>
					</div>
					<div fxFlex *ngIf="projectId">
						<app-git-credentials
							[auxSpace]="(appConfigRepository.isCloud$ | async) === false"
							[testConnectionState]="testConnectionStateGit"
							(testConnection)="testConnectionGit()"
							(formData)="onGitFormData($event)"
						></app-git-credentials>
					</div>
				</div>
				<div class="buttons mt-4">
					<button
						type="button"
						class="aui-button aui-button-primary aui-button-link submit"
						(click)="savePersonal()"
						[disabled]="(formPersonal?.invalid ?? false) || ((appConfigRepository.isSSO$ | async) && !ssoAuthService.loggedIn) || (formGIT?.invalid ?? false)"
					>
						<img src="assets/icons/check.svg" alt="cancel" />
						Save changes
					</button>
					<button
						type="button"
						class="aui-button aui-button-link cancel"
						(click)="close()"
						[disabled]="(appConfigRepository.isSSO$ | async) && !ssoAuthService.loggedIn"
					>
						<img src="assets/icons/cancel.svg" alt="cancel" />Cancel
					</button>
				</div>
			</div>
		</mat-tab>
	</mat-tab-group>
</div>
