import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import localForage from 'localforage';

const forbiddenStringValidator = (
	control: UntypedFormControl
): { forbiddenString: string } => {
	const value = control.value as string;
	if (value?.toLowerCase().startsWith('xml')) {
		return { forbiddenString: value };
	}
	return null;
};

export const URLStringValidator = (
	control: UntypedFormControl
): { invalidURL: string } => {
	const value = control.value as string;
	try {
		new URL(value);
	} catch {
		return value?.length ? { invalidURL: value } : null;
	}

	return null;
};

export const RESERVED_PARAMETER_NAMES = [
	'id',
	'device',
	'projectkey',
	'testid',
	'testplankey',
	'testsummary',
	'testplansummary',
	'testdescription',
	'testplansescription',
	'testifi:serviceurl',
	'externalid'
];

export const reservedParameterNameValidator = (
	control: UntypedFormControl
): { reservedName: string | null } => {
	const value = (control.value as string).toLowerCase();
	return RESERVED_PARAMETER_NAMES.includes(value)
		? { reservedName: value }
		: null;
};

export const existingParameterNameValidator =
	(existingParameterNames: string[]) =>
	(control: UntypedFormControl): { existingParameter: string | null } => {
		const value = control.value as string;
		return existingParameterNames.includes(value)
			? { existingParameter: value }
			: null;
	};

export function duplicateStringValidator(existingNames: string[]): (
	control: UntypedFormControl
) => {
	existingName: string;
} {
	return (control: UntypedFormControl): { existingName: string } => {
		const value = control.value as string;
		for (const existingKey of existingNames) {
			if (value?.toLowerCase() === existingKey.toLowerCase()) {
				return { existingName: value };
			}
		}
		return null;
	};
}

export const positiveWholeNumberValidator = (
	control: UntypedFormControl
): { positiveWholeInt: string | null } => {
	const value = control.value as string;
	return !RegExp(POSITIVE_WHOLE_INT).test(value)
		? { positiveWholeInt: value }
		: null;
};

const ID_PATTERN = '[a-zA-Z][A-Za-z0-9:._-]*';

const NAME_PATTERN = '[a-zA-Z][A-Za-z0-9-]*';

const TOKEN_PATTERN = '[a-zA-Z][A-Za-z0-9_+-]*';

const BRANCH_NAME_PATTERN =
	'[a-zA-Z0-9][A-Za-z0-9,/£$@&#%(){}<>|;÷`\'"!+=§\\]]*';

const XRAY_STATUS_PATTERN = '[A-Za-z0-9_-]*';

const POSITIVE_WHOLE_INT = '^[1-9]+[0-9]*$';

// everything without comma and whitespace
const VALUE_RESTRICTED = '[^,\\s]*';

export const ID_VALIDATORS: ValidatorFn[] = [
	Validators.pattern(ID_PATTERN),
	forbiddenStringValidator,
	Validators.minLength(1),
	Validators.maxLength(200),
	Validators.required
];

export const NAME_VALIDATORS: ValidatorFn[] = [
	Validators.pattern(NAME_PATTERN),
	forbiddenStringValidator,
	Validators.minLength(1),
	Validators.maxLength(200),
	Validators.required
];

export const DESCRIPTION_VALIDATORS: ValidatorFn[] = [
	Validators.minLength(0),
	Validators.maxLength(1000)
];

export const VALUE_VALIDATORS: ValidatorFn[] = [
	Validators.minLength(0),
	Validators.maxLength(255)
];

export const VALUE_RESTRICTED_VALIDATORS: ValidatorFn[] = [
	Validators.pattern(VALUE_RESTRICTED),
	Validators.minLength(1),
	Validators.maxLength(255)
];

export const VALUE_VALIDATORS_200: ValidatorFn[] = [
	Validators.minLength(0),
	Validators.maxLength(200)
];

export const XRAY_STATUS_VALIDATORS: ValidatorFn[] = [
	Validators.pattern(XRAY_STATUS_PATTERN),
	Validators.minLength(2),
	Validators.maxLength(18)
];

export const GIT_USERNAME_VALIDATORS: ValidatorFn[] = [
	Validators.pattern(XRAY_STATUS_PATTERN),
	Validators.minLength(1),
	Validators.maxLength(39)
];

export const GIT_TOKEN_VALIDATORS: ValidatorFn[] = [
	Validators.pattern(TOKEN_PATTERN),
	Validators.maxLength(255)
];

export const GIT_BRANCH_VALIDATORS: ValidatorFn[] = [
	Validators.pattern(BRANCH_NAME_PATTERN),
	Validators.maxLength(250)
];

export const TIMEOUT_VALIDATORS: ValidatorFn[] = [
	positiveWholeNumberValidator,
	Validators.minLength(1),
	Validators.maxLength(255),
	Validators.required
];

export const SVG_ICONS: { fileName: string; iconName: string }[] = [
	{ fileName: 'icon-group', iconName: 'icon-group' },
	{ fileName: 'icon-delete', iconName: 'icon-delete' },
	{ fileName: 'icon-edit', iconName: 'icon-edit' },
	{ fileName: 'icon-arrow-down', iconName: 'icon-arrow-down' },
	{ fileName: 'icon-arrow-down-bold', iconName: 'icon-arrow-down-bold' },
	{ fileName: 'icon-arrow-up-bold', iconName: 'icon-arrow-up-bold' },
	{ fileName: 'icon-arrow-right', iconName: 'icon-arrow-right' },
	{ fileName: 'icon-test-case', iconName: 'icon-test-case' },
	{ fileName: 'icon-test-suite', iconName: 'icon-test-suite' },
	{ fileName: 'icon-double-arrow-down', iconName: 'icon-double-arrow-down' },
	{ fileName: 'icon-x-light', iconName: 'icon-x-light' },
	{ fileName: 'icon-x-dark', iconName: 'icon-x-dark' },
	{ fileName: 'dotted-horizontal-bar', iconName: 'dotted-horizontal-bar' },
	{
		fileName: 'test-connection-disabled',
		iconName: 'test-connection-disabled'
	},
	{
		fileName: 'test-connection-not-tested',
		iconName: 'test-connection-not-tested'
	},
	{
		fileName: 'test-connection-tested-ok',
		iconName: 'test-connection-tested-ok'
	},
	{
		fileName: 'test-connection-tested-wrong',
		iconName: 'test-connection-tested-wrong'
	},
	{ fileName: 'icon-help', iconName: 'icon-help' },
	{ fileName: 'switch-left', iconName: 'switch-left' },
	{ fileName: 'switch-right', iconName: 'switch-right' },
	{ fileName: 'switch-left-disabled', iconName: 'switch-left-disabled' },
	{ fileName: 'switch-right-disabled', iconName: 'switch-right-disabled' },
	{ fileName: 'eye-on', iconName: 'eye-on' },
	{ fileName: 'eye-off', iconName: 'eye-off' },
	{ fileName: 'external-link', iconName: 'external-link' },
	{ fileName: 'filter', iconName: 'filter' },
	{ fileName: 'link', iconName: 'link' }
];

export const MAT_SVG_ICONS: { fileName: string; iconName: string }[] = [
	{ fileName: 'collapseAll', iconName: 'collapseAll' },
	{ fileName: 'expandAll', iconName: 'expandAll' },
	{ fileName: 'icon-help', iconName: 'icon-help' },
	{ fileName: 'git-merge', iconName: 'git-merge' },
	{ fileName: 'hamburger', iconName: 'hamburger' }
];

export enum PAGES {
	LANDING = 'projects_view',
	SUI = 'projects_sui_pages',
	SCENARIOS = 'projects_scenarios',
	SCENARIO = 'test_scenarios_view',
	BUILDING_BLOCK = 'projects_custom_building_blocks',
	CREDENTIALS = 'settings_credentials',
	SETTINGS = 'project_settings',
	DENIED = 'project-denied',
	ENABLED_PROJECTS = 'enabled-projects-admin'
}

export const MANDATORY_PARAMETERS_KEY = 'mandatoryParameters';
export const OPTIONAL_PARAMETERS_KEY = 'optionalParameters';

export const DATE_DELIMITER = '-';

export const AUTH_REPOSITORY_STORAGE_KEY = 'auth';
export const CREDENTIALS_REPOSITORY_STORAGE_KEY = 'credentials';
export const APP_STATUS_STORAGE_KEY = 'appStatus';

export const INDEXED_DB_STORAGE = localForage.createInstance({
	driver: localForage.INDEXEDDB,
	name: 'INDEXED_DB'
});
