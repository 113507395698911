<div fxLayout class="pl__container" fxLayoutGap="8px">
	<div>
		<button type="button" id="addParameterButton" class="pl__add-button" title="Add Parameter" (click)="onAddClick()">
			+
		</button>
	</div>
	<div class="pl__parameter-list" fxLayout="row wrap">
		<div [ngClass]="{'pl__parameter-list-item': isAddNew}">
			<app-parameter-editor
				*ngIf="isAddNew"
				[existingKeys]="existingKeys"
				(submitEdit)="onSubmitEdit($event)"
				(cancel)="onCancelEdit()"
			></app-parameter-editor>
		</div>
		<ng-container *ngFor="let item of parameterList">
			<div class="pl__parameter-list-item">
				<app-parameter-label
					*ngIf="editedKey !== item.key"
					[parameter]="item"
					(edit)="onEditItem($event)"
					(remove)="onRemoveItem($event)"
				></app-parameter-label>
				<app-parameter-editor
					*ngIf="editedKey === item.key"
					[parameter]="item"
					[existingKeys]="existingKeys"
					(submitEdit)="onSubmitEdit($event)"
					(cancel)="onCancelEdit()"
				></app-parameter-editor>
			</div>
		</ng-container>
	</div>
</div>
