<div
	id="readonly"
	class="multilineeditor__readonly"
	*ngIf="!editMode"
	(click)="editMode = true"
	[ngClass]="{'multilineeditor__break-words': breakWords}"
>
	<span
		[ngStyle]="{'max-width.px': maxWidth ? maxWidth : ''  }"
		[style.color]="textColor"
		class="multilineeditor__label"
		>{{ (text || defaultText) }}</span
	>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit($event)" autocomplete="off" *ngIf="editMode">
	<textarea
		title="Editor"
		class="multilineeditor__text"
		type="text"
		[style.color]="textColor"
		[placeholder]="defaultText"
		[style.height.px]="height"
		[ngStyle]="{'max-width.px': maxWidth ? maxWidth : ''  }"
		formControlName="name"
		id="name"
		[autofocus]="true"
		(blur)="onBlur()"
	></textarea>
	<app-validation-errors [control]="f.name"></app-validation-errors>
	<div class="multilineeditor__button-group" *ngIf="editMode">
		<button
			id="update-submit"
			class="aui-button multilineeditor__comp-button aui-button-primary"
			name="Save"
			type="submit"
			[disabled]="!form.valid"
		>
			Save
		</button>
		<button id="cancel" class="aui-button aui-button-subtle multilineeditor__comp-button" name="Cancel" type="submit">
			Cancel
		</button>
	</div>
</form>
