import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';

@Injectable({ providedIn: 'root' })
export class BaseApiService {
	private jsonConvert = new JsonConvert();

	// ======================================================================
	// public functions
	// ======================================================================

	// eslint-disable-next-line
	model<T extends object>(data: unknown, type: new () => T | null = null): T {
		return this.jsonConvert.deserializeObject(data, type);
	}

	// eslint-disable-next-line
	models<T extends object>(
		data: Array<T>,
		type: new () => T | null = null
	): T[] {
		const objects: T[] = [];

		data.forEach((value) => {
			objects.push(this.jsonConvert.deserializeObject(value, type));
		});

		return objects;
	}

	// eslint-disable-next-line
	modelsMapArray<T extends object>(
		datas: Map<string, T[]>,
		type: new () => T
	): Map<string, T[]> {
		const objects = new Map<string, T[]>();

		for (const key of Object.keys(datas)) {
			objects.set(key, this.models(datas[key], type));
		}
		return objects;
	}
}
