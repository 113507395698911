import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('LabelItemView')
export class LabelItemView {
	@JsonProperty('idLabelItem', String)
	idLabelItem: string = undefined;

	@JsonProperty('labelText', String)
	labelText: string = undefined;

	constructor(
		idLabelItem: string,
		labelText: string,
		public bgColor = '#393843'
	) {
		this.idLabelItem = idLabelItem;
		this.labelText = labelText;
	}
}
