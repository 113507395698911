import { NgIf, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
	selector: 'app-execution-status',
	templateUrl: './execution-status.component.html',
	styleUrls: ['./execution-status.component.less'],
	standalone: true,
	imports: [FlexModule, NgStyle, ExtendedModule, NgIf]
})
export class ExecutionStatusComponent {
	@Input() status: string;
	@Input() color = '#ccc';
	@Input() count = '';
}
