import {
	HTTP_INTERCEPTORS,
	HttpBackend,
	HttpClient,
	HttpInterceptor
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InterceptingHandler } from '../intercepting-handler';
import {
	BASE_URL_INTERCEPTOR,
	PRIVATE_INTERCEPTOR
} from '../interceptors-injection-tokens';

@Injectable({
	providedIn: 'root'
})
export class PrivateHttpClient extends HttpClient {
	constructor(
		backend: HttpBackend,
		@Inject(HTTP_INTERCEPTORS) interceptors: HttpInterceptor[],
		@Inject(BASE_URL_INTERCEPTOR) baseUrlInterceptors: HttpInterceptor[],
		@Inject(PRIVATE_INTERCEPTOR) privateInterceptors: HttpInterceptor[]
	) {
		super(
			new InterceptingHandler(
				backend,
				[interceptors, privateInterceptors, baseUrlInterceptors].flat()
			)
		);
	}
}
