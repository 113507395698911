import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TestObjectType } from '@testifi-modals/modal-test-object-parameter/modal-test-object-parameter.component';
import { BuildingBlock } from '@testifi-models/building-block';
import { Parameter } from '@testifi-models/parameter';
import { ScenarioParameter } from '@testifi-models/scenario-parameter';
import { SuiElement } from '@testifi-models/sui-element';
import { TestObject } from '@testifi-models/test-object';
import { TestPlan } from '@testifi-models/test-plan';
import { TestStep } from '@testifi-models/test-step';
import { TestStepLibrary } from '@testifi-models/test-step-library';
import { TypedParameter } from '@testifi-models/typed-Item';
import { ExecutionParameters } from '@testifi-pages/page-view-test-scenario/page-view-test-scenario.component';
import { EditTracker } from '@testifi-utils/edit.tracker';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExportScenarioPayload, GitConnection } from './git.service';

type OnCloseType = (
	result:
		| TestStep
		| string
		| ExportScenarioPayload
		| GitConnection
		| ExecutionParameters
		| null
		| TypedParameter
) => void;

export type GenericModalData =
	| ModalData
	| boolean
	| number
	| TestStep
	| BuildingBlock
	| TypedParameter
	| GitConnection;

export interface ModalData {
	testStepId?: string;
	isNew?: boolean;
	testScenarioId?: string;
	projectId?: string;
	buildingBlockId?: string;
	suiElement?: SuiElement;
	suiPageId?: string;
	tags?: string[];
	parameter?: Parameter;
	testObject?: TestObject;
	testStepLibrary?: TestStepLibrary;
	parentId?: string;
	testObjectType?: TestObjectType;
	values?: string[];
	searchableDropdown?: boolean;
	testPlans?: TestPlan[];
	deviceList?: string[];
	suiElementParameterIndex?: number;
	suiElementParameters?: Map<string, string[]>;
	suiElementStructureId?: string;
	structureId?: string;
	title?: string;
	text?: string;
	confirmButton?: string;
	cancelButton?: string;
	rootTestObject?: TestObject;
	suiRootElement?: SuiElement;
	adhocExist?: boolean;
	scenarioParameters?: ScenarioParameter[];
	editTracker?: EditTracker<unknown>;
	screenshotId?: string;
	testPlan?: string;
	device?: string;
	gitConnection?: GitConnection;
}
@Injectable({ providedIn: 'root' })
export class ModalService {
	activeModals: MatDialogRef<any, any>[] = []; // eslint-disable-line

	private isOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private data: GenericModalData;

	// eslint-disable-next-line
	get activeModal(): MatDialogRef<any, any> {
		return this.activeModals.length > 0
			? this.activeModals[this.activeModals.length - 1]
			: null;
	}

	constructor(public dialog: MatDialog) {}

	get(): Observable<boolean> {
		return this.isOpen.asObservable();
	}

	get onCloseData(): GenericModalData {
		return this.data;
	}

	open<DATA_MODEL = ModalData>(
		type: any, // eslint-disable-line
		onClose: OnCloseType,
		data: DATA_MODEL
		// eslint-disable-next-line
	): MatDialogRef<any, any> {
		this.activeModals.push(
			this.dialog.open(type, {
				panelClass: 'modal-wrapper',
				data,
				disableClose: true
			})
		);

		this.isOpen.next(true);

		this.activeModal.afterClosed().subscribe((result) => {
			if (onClose != null) {
				onClose(result);
			}
			this.isOpen.next(false);
		});

		return this.activeModal;
	}

	close(data: GenericModalData = undefined): void {
		if (this.activeModals.length > 0) {
			this.data = data;
			this.activeModal.close(data);
			this.activeModals.pop();
		}
	}
}
