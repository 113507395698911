import { JsonConverter, JsonCustomConvert } from 'json2typescript';

export enum ValidationType {
	invalid,
	success,
	error,
	failedValidation,
	notValidated
}

@JsonConverter
export class ValidationTypeConverter
	implements JsonCustomConvert<ValidationType> {
	serialize(validationType: ValidationType): string {
		return ValidationType[validationType].toString();
	}

	deserialize(validationType: string): ValidationType {
		return ValidationType[validationType as keyof typeof ValidationType];
	}
}
