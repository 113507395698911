import { inject, Injectable } from '@angular/core';
import { PrivateHttpClient } from '../https/instances/private.http';

@Injectable({
	providedIn: 'root'
})
export class HomeApiService {
	private readonly privateHttp = inject(PrivateHttpClient);

	getCounts(projectId: string) {
		return this.privateHttp.get<{
			testPlanCount: number;
			scenarioCount: number;
			cbbCount: number;
			suiPageCount: number;
		}>(`project/${projectId}/object-count`);
	}
}
