import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { IApiVersion } from '@testifi-models/api-version.interface';
import { TAppConfiguration } from '@testifi-models/app-configuration.interface';
import { EEnvironment } from '@testifi-models/environment.enum';
import { ELoginType } from '@testifi-models/login-type.enum';
import { filter } from 'rxjs';
import packageInfo from '../../../../package.json';
import { TAppConfigRepository } from './app-config.repository.type';

export type TEnvironment = 'cloud' | 'server' | 'server-sso';

const ENVIRONMENTS: Record<TEnvironment, EEnvironment> = {
	cloud: EEnvironment.CLOUD,
	server: EEnvironment.SERVER,
	'server-sso': EEnvironment.SERVER_SSO
};

@Injectable({
	providedIn: 'root'
})
export class AppConfigRepository {
	static readonly INITIAL_VALUE: TAppConfigRepository = {
		configInitialized: false,
		config: {
			jiraURL: '',
			applicationId: '',
			apiURL: '',
			gitTag: '',
			gitBranch: '',
			ssoClientId: '',
			ssoIssuer: '',
			ssoRedirectUri: '',
			ssoScope: '',
			timestamp: '',
			debugMode: false,
			qaWarning: false,
			polUrl: '',
			tokenConfluenceUrl: ''
		},
		apiVersionInitialized: false,
		apiVersion: {
			version: '',
			buildVersion: '',
			buildTime: '',
			commitId: ''
		},
		environment: null,
		loginType: null,
		appUrl: null
	};

	private store = createStore(
		{ name: 'appConfig' },
		withProps<TAppConfigRepository>(AppConfigRepository.INITIAL_VALUE)
	);

	state$ = this.store.pipe(select((state) => state));
	apiUrl$ = this.store
		.pipe(select((state) => state.config.apiURL))
		.pipe(filter(Boolean));
	environment$ = this.store.pipe(select((state) => state.environment));
	isCloud$ = this.environment$.pipe(
		select((environment) => environment === EEnvironment.CLOUD)
	);
	isSSO$ = this.environment$.pipe(
		select((environment) => environment === EEnvironment.SERVER_SSO)
	);
	loginType$ = this.store.pipe(select((state) => state.loginType));
	configInitialized$ = this.store.pipe(
		select((state) => state.configInitialized)
	);
	apiVersionInitialized$ = this.store.pipe(
		select((state) => state.apiVersionInitialized)
	);
	config$ = this.store.pipe(select((state) => state.config));
	apiVersion$ = this.store.pipe(select((state) => state.apiVersion.version));
	uiVersion$ = this.store.pipe(
		select((state) => `${packageInfo.version}_${state.config.gitTag}`)
	);
	qaWarning$ = this.store.pipe(select((state) => state.config.qaWarning));
	isServerOrServerSSO$ = this.store.pipe(
		select(
			(state) =>
				state.environment === EEnvironment.SERVER ||
				state.environment === EEnvironment.SERVER_SSO
		)
	);
	jiraUrl$ = this.store.pipe(select((state) => state.config.jiraURL));
	appUrl$ = this.store.pipe(select((state) => state.appUrl));

	get state(): TAppConfigRepository {
		return this.store.getValue();
	}

	get config(): TAppConfiguration {
		return this.state.config;
	}

	get uiVersion(): string {
		return `${packageInfo.version}_${this.state.config.gitTag}`;
	}

	get isCloud(): boolean {
		return this.state.environment === EEnvironment.CLOUD;
	}

	get isServer(): boolean {
		return this.state.environment === EEnvironment.SERVER;
	}

	get isServerOrServerSSO(): boolean {
		return (
			this.state.environment === EEnvironment.SERVER ||
			this.state.environment === EEnvironment.SERVER_SSO
		);
	}

	get isSSO(): boolean {
		return this.state.environment === EEnvironment.SERVER_SSO;
	}

	get loginType(): ELoginType {
		return this.state.loginType;
	}

	get appUrl(): string {
		return this.state.appUrl;
	}

	setApiVersion(apiVersion: IApiVersion): void {
		this.store.update((state) => ({
			...state,
			apiVersionInitialized: true,
			apiVersion
		}));
	}

	setAppConfig(appConfig: TAppConfiguration): void {
		this.store.update((state) => ({
			...state,
			configInitialized: true,
			config: {
				...appConfig,
				apiURL: `${appConfig.apiURL}/pitstop/api/v1/`
			}
		}));
	}

	setEnvironment(environmentString: TEnvironment) {
		this.store.update((state) => {
			const environment = this.environment(environmentString);
			return {
				...state,
				environment,
				loginType: this.detectLoginType(environment)
			};
		});
	}

	private detectLoginType(environment: EEnvironment): ELoginType {
		let loginType: ELoginType;
		switch (environment) {
			case EEnvironment.CLOUD:
				loginType = ELoginType.JIRA_XRAY;
				break;
			case EEnvironment.SERVER_SSO:
				loginType = ELoginType.SERVER_SSO;
				break;
			case EEnvironment.SERVER:
				loginType = ELoginType.SERVER;
				break;
		}
		if (!loginType) {
			throw Error('Login type is not defined');
		}
		return loginType;
	}

	private environment(environmentRaw: TEnvironment): EEnvironment {
		if (!environmentRaw) {
			throw Error('Environment is not defined');
		}
		if (!ENVIRONMENTS[environmentRaw]) {
			throw Error(`Environment ${environmentRaw} is unknown`);
		}
		return ENVIRONMENTS[environmentRaw];
	}

	setAppUrl(appUrl: string) {
		this.store.update((state) => ({
			...state,
			appUrl
		}));
	}
}
