<ol class="aui-nav aui-nav-breadcrumbs">
	<li
		*ngFor="let breadcrumb of (breadcrumbs$ | async) | unordedkeyvalue; let isLast = last"
		[ngClass]="{ 'aui-nav-selected last-item': isLast }"
	>
		<div
			*ngIf="breadcrumb.value === null || !breadcrumb.value.navigateTo; then thenBlock; else navigateToBlock"
		></div>
		<ng-template #thenBlock>
			<span class="breadcrumb-text">{{ breadcrumb.key | trim }}</span>
			<svg-icon
				#tooltip="matTooltip"
				class="copy-link-icon"
				(click)="copyLinkToClipboard($event, breadcrumb?.value?.copyLink, tooltip)"
				(keyup.space)="copyLinkToClipboard($event, breadcrumb?.value?.copyLink, tooltip)"
				(keyup.enter)="copyLinkToClipboard($event, breadcrumb?.value?.copyLink, tooltip)"
				*ngIf="breadcrumb?.value?.copyLink"
				matTooltip="Copied to clipboard!"
				[matTooltipDisabled]="true"
				src="link"
				tabindex="0"
			></svg-icon>
		</ng-template>
		<ng-template #navigateToBlock>
			<a [id]="breadcrumb.value.navigateTo[0]" [appRouterName]="breadcrumb.value.navigateTo"
				>{{ breadcrumb.key | trim }}</a
			>
		</ng-template>
	</li>
</ol>
