import { Injectable } from '@angular/core';
import { TBreadcrumb } from '@testifi-models/breadcrumb.type';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
	private breadcrumbs = new BehaviorSubject(new Map<string, TBreadcrumb>());

	get(): Observable<Map<string, TBreadcrumb>> {
		return this.breadcrumbs.asObservable();
	}

	set(breadcrumbs: Map<string, null | TBreadcrumb>): void {
		this.breadcrumbs.next(breadcrumbs);
	}
}
