import { DOCUMENT, Location, NgFor, NgIf } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	HostListener,
	Inject,
	OnInit,
	ViewChild
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormsModule } from '@angular/forms';
import {
	MatLegacyPaginatorModule,
	MatLegacyPaginator as MatPaginator,
	LegacyPageEvent as PageEvent
} from '@angular/material/legacy-paginator';
import { MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { ModalEditBuildingBlockComponent } from '@testifi-modals/modal-edit-building-blocks/modal-edit-building-block.component';
import { BuildingBlock } from '@testifi-models/building-block';
import { ProjectView } from '@testifi-models/project-view';
import { BreadcrumbService } from '@testifi-services/breadcrumb.service';
import { LoadingService } from '@testifi-services/loading.service';
import { ModalService } from '@testifi-services/modal.service';
import { NotificationService } from '@testifi-services/notification.service';
import { ProjectService } from '@testifi-services/project.service';
import { RouterService } from '@testifi-services/router.service';
import { PAGES } from '@testifi-shared/app-constants';
import { CheckboxComponent } from '@testifi-shared/checkbox/checkbox.component';
import { ExpandableLibraryPanelType } from '@testifi-shared/expandable-library-panel/expandable-library-panel.component';
import { LongTextExpansionState } from '@testifi-shared/long-text/long-text.component';
import { SvgIconComponent } from 'angular-svg-icon';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { concatAll, finalize, switchMap, tap } from 'rxjs/operators';
import { HideOnLoadingDirective } from '../../directives/hide-on-loading.directive';
import { ModalRouterNameDirective } from '../../directives/modal-router-name.directive';
import { StylePaginatorDirective } from '../../directives/style-paginator.directive';
import { UnorderdKeyValuePipe } from '../../pipes/unorder-key-value.pipe';
import { BreadcrumbComponent } from '../../shared/breadcrumb/breadcrumb.component';
import { CheckboxComponent as CheckboxComponent_1 } from '../../shared/checkbox/checkbox.component';
import { KpiBoxComponent } from '../../shared/kpi-box/kpi-box.component';
import { LongTextComponent } from '../../shared/long-text/long-text.component';
import { BuildingBlockService } from './../../services/building-block.service';

enum ColNames {
	NAME = 'NAME',
	MODULES = 'MODULES',
	DESCRIPTION = 'description'
}

const PAGE_SIZE_ITEM_NAME = 'buildBlock.pageSize';

@Component({
	selector: 'app-page-custom-building-blocks',
	templateUrl: './page-custom-building-blocks.component.html',
	styleUrls: ['./page-custom-building-blocks.component.less'],
	standalone: true,
	imports: [
		HideOnLoadingDirective,
		BreadcrumbComponent,
		ModalRouterNameDirective,
		NgIf,
		FlexModule,
		KpiBoxComponent,
		FormsModule,
		SvgIconComponent,
		MatSortModule,
		CheckboxComponent_1,
		NgFor,
		LongTextComponent,
		MatLegacyPaginatorModule,
		StylePaginatorDirective,
		UnorderdKeyValuePipe
	]
})
export class PageCustomBuildingBlocksComponent implements OnInit {
	private disposableBag = new Subscription();
	private window: Window;

	// ======================================================================
	// public properties
	// ======================================================================
	@ViewChild('paginator') paginator: MatPaginator;
	@ViewChild('selectAllCheckbox')
	selectAllCheckbox: CheckboxComponent;

	weightedScore = '-';
	projectId: string;
	buildingBlocks: Array<BuildingBlock> = [];
	expandedBuildingBlocks = [];
	types = ExpandableLibraryPanelType;
	projectView: ProjectView;
	checkedItems = new Map<string, BuildingBlock>();
	searchText = '';

	defaultSortedColumn = ColNames.NAME;
	defaultSort: SortDirection = 'asc';
	tableHead = new Map<ColNames, { title: string; sortable: boolean }>([
		[
			this.defaultSortedColumn,
			{
				title: 'Custom Block Name',
				sortable: true
			}
		],
		[ColNames.MODULES, { title: 'Modules', sortable: false }],
		[ColNames.DESCRIPTION, { title: 'Description', sortable: false }]
	]);

	lastSort: Sort;
	pageSize = 5;
	totalItems = 0;
	currentPage = 0;
	sortColumn: string = ColNames.NAME.toUpperCase();
	expandButtonState = new Map<string, boolean>();
	toggleState: LongTextExpansionState = { id: '', state: false };

	// ======================================================================
	// constructor
	// ======================================================================

	constructor(
		private cd: ChangeDetectorRef,
		private location: Location,
		private route: ActivatedRoute,
		private modalService: ModalService,
		private loadingService: LoadingService,
		private notificationService: NotificationService,
		private projectService: ProjectService,
		private buildingBlockService: BuildingBlockService,
		private breadcrumbService: BreadcrumbService,
		private routerService: RouterService,
		@Inject(DOCUMENT) document: Document
	) {
		this.window = document.defaultView;
	}

	@HostListener('window:popstate', ['$event'])
	onPopState(): void {
		this.breadcrumbService.set(
			new Map([
				['Projects', null],
				[
					this.projectView.key,
					{
						navigateTo: [PAGES.LANDING, `${this.projectView.key}`]
					}
				]
			])
		);
		this.routerService.navigate([PAGES.LANDING, `${this.projectView.key}`]);
	}

	@HostListener('window:resize', ['$event'])
	onResize(): void {
		this.expandButtonState.clear();
	}

	// ======================================================================
	// inherit functions
	// ======================================================================

	ngOnInit(): void {
		if (this.window.localStorage.getItem(PAGE_SIZE_ITEM_NAME)) {
			this.pageSize = parseInt(
				this.window.localStorage.getItem(PAGE_SIZE_ITEM_NAME)
			);
		}

		this.disposableBag.add(
			this.route.paramMap
				.pipe(
					tap((params) => {
						this.projectId = params.get('projectId');
					}),
					switchMap(() => this.projectService.getById(this.projectId))
				)
				.subscribe(
					(projectView) => {
						this.projectView = projectView;
						this.breadcrumbService.set(
							new Map([
								['Projects', null],
								this.projectView !== null
									? [
											this.projectView.name,
											{ navigateTo: [PAGES.LANDING, `${this.projectView.id}`] }
										]
									: ['', null],
								['Custom Blocks', null]
							])
						);
					},
					() => this.routerService.navigate([PAGES.LANDING])
				)
		);
		this.disposableBag.add(this.reload(null, true));
	}

	toggleExpanded(buildingBlockId: string): void {
		if (this.expandedBuildingBlocks.includes(buildingBlockId)) {
			this.expandedBuildingBlocks = this.expandedBuildingBlocks.filter(
				(id) => id !== buildingBlockId
			);
		} else {
			this.expandedBuildingBlocks.push(buildingBlockId);
		}
	}

	popOnModalClose(pop: boolean): void {
		if (pop) {
			this.location.back();
		}
	}

	refreshOnModalClose(): void {
		this.disposableBag.add(this.reload());
	}

	editBuildingBlockOnModalClose(buildingBlock: BuildingBlock): void {
		if (buildingBlock) {
			// reload here cause on TestStepEdit cancel it will not refresh
			this.disposableBag.add(
				this.reload(() => {
					this.modalService.open(
						ModalEditBuildingBlockComponent,
						(updatedTestStep) => {
							if (updatedTestStep) {
								this.disposableBag.add(this.reload());
							}
						},
						{
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							buildingBlockId: buildingBlock.id
						}
					);
				})
			);
		}
	}

	handlePage(event: PageEvent): void {
		const isPageSizeReallyChanged = this.pageSize !== event.pageSize;
		const isPageReallyChanged = this.currentPage !== event.pageIndex;
		this.selectAllCheckbox.checked = false;
		this.checkedItems.clear();
		this.pageSize = event.pageSize;
		this.window.localStorage.setItem(PAGE_SIZE_ITEM_NAME, `${event.pageSize}`);
		this.currentPage = event.pageIndex;
		if (isPageSizeReallyChanged || isPageReallyChanged) {
			this.reload(null, true);
		}
	}

	onSort(sort: Sort): void {
		this.lastSort = sort;
		this.currentPage = 0;
		this.reload(() => this.paginator?._changePageSize(this.pageSize), true);
	}

	openEditDialog(id: string): void {
		this.modalService.open(
			ModalEditBuildingBlockComponent,
			() => this.disposableBag.add(this.reload(null, true)),
			{
				buildingBlockId: id
			}
		);
	}

	checkItem(block: BuildingBlock): void {
		if (this.checkedItems.has(block.id)) {
			this.checkedItems.delete(block.id);
		} else {
			this.checkedItems.set(block.id, block);
		}
		if (!this.checkedItems.size) {
			this.selectAllCheckbox.checked = false;
		}
	}

	selectAll(selected: boolean): void {
		if (selected) {
			this.buildingBlocks.forEach((block) => {
				if (!this.checkedItems.has(block.id)) {
					this.checkedItems.set(block.id, block);
				}
			});
		} else {
			this.checkedItems.clear();
		}
	}

	deleteOnModalConfirm(confirm: boolean): void {
		if (confirm) {
			const observers: Observable<BuildingBlock>[] = [];

			this.checkedItems.forEach((block: BuildingBlock, key: string) => {
				observers.push(
					this.buildingBlockService.delete(key).pipe(switchMap(() => of(block)))
				);
			});

			const source = forkJoin(observers);
			const finalObserver = source.pipe(concatAll());

			this.disposableBag.add(
				finalObserver.subscribe(
					(block) => {
						this.selectAllCheckbox.checked = false;
						this.checkedItems.clear();
						this.notificationService.info(
							`Building block "${block.name}" deleted successfully`
						);
						this.reload(null, true);
					},
					(err) => this.notificationService.httpError(err)
				)
			);
		}
	}

	onSearchInput(event: Event): void {
		this.selectAllCheckbox.checked = false;
		this.searchText = (event.target as HTMLInputElement).value;
		this.currentPage = 0;

		if (!this.searchText) {
			this.onSearch();
		}
	}

	onSearch(): void {
		this.reload(() => this.paginator?._changePageSize(this.pageSize), true);
	}

	onLongTextToggle(event: boolean, block: BuildingBlock): void {
		this.toggleState = { id: block.id, state: event };
	}

	onLongTextPresent(event: boolean, block: BuildingBlock): void {
		let state = this.expandButtonState.get(block.id) || false;
		state ||= event;
		this.expandButtonState.set(block.id, state);
	}

	private reload(
		callback: CallableFunction = null,
		showLoading = true
	): Subscription {
		if (showLoading) {
			this.loadingService.active();
		}

		return this.buildingBlockService
			.getAll(
				this.projectId,
				this.currentPage,
				this.pageSize,
				this.lastSort?.active,
				this.lastSort?.direction.toUpperCase(),
				this.searchText
			)
			.pipe(
				finalize(() =>
					showLoading ? this.loadingService.deactive(this.cd) : void 0
				)
			)
			.subscribe(
				(buildingBlockPage) => {
					this.buildingBlocks = buildingBlockPage.content;
					this.totalItems = buildingBlockPage.totalNumberOfElements;

					if (callback) {
						setTimeout(() => {
							callback();
						});
					}
				},
				(err) => this.notificationService.httpError(err)
			);
	}
}
