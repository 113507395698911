<div
	[ngClass]="{ intersected: isIntersected, root: root, singleChild: item.children.length <= 1 }"
	cdkDropList
	cdkScrollable
	class="item-dropzone"
	[id]="item.structureId"
	[cdkDropListData]="item"
	[cdkDropListEnterPredicate]="canDrop(item, intersectedItemIds)"
	[cdkDropListConnectedTo]="filteredConnectedListIds(item)"
	(cdkDropListDropped)="onDragDrop($event)"
>
	<div
		class="test-object  validation-{{ testObject.displayValidationType }}"
		*ngFor="let testObject of item.children"
		cdkDrag
		[id]="testObject.structureId"
		[cdkDragData]="testObject"
		(cdkDragEnded)="onDragEnded($event)"
		(cdkDragMoved)="onDragMove($event)"
		(cdkDragStarted)="onDragStarted($event)"
		(click)="(testObject.children.length || intersectedItemIds.length) && onToggleExpanded($event)"
	>
		<mat-expansion-panel
			[@.disabled]="isDragging"
			[expanded]="expandedItemIds.includes(testObject.structureId)"
			[disabled]="true"
			[hideToggle]="true"
			class="background-N10 draggable test-object-inner"
			[togglePosition]="'before'"
		>
			<mat-expansion-panel-header
				collapsedHeight="*"
				expandedHeight="*"
				appendDragThumb
				[html]="'<span class=\'drag-thumb validation-{{ testObject.displayValidationType }}\'></span>'"
			>
				<div class="rel">
					<div class="main">
						<h4 class="title">{{ testObject.name | uiTag :libraries}}</h4>
						<mat-icon
							*ngIf="testObject.children.length || intersectedItemIds.length"
							class="transition"
							[ngClass]="expandedItemIds.includes(testObject.structureId) ? ['rotate180'] : ['rotate0']"
						>
							<svg-icon class="fill-primary01" name="icon-arrow-down"></svg-icon>
						</mat-icon>
					</div>
					<div class="suffix">
						<ul class="row-actions inline-right">
							<li *ngIf="testObject.libgroup === 'pages'">
								<span
									[modalData]="{ suiPageId: testObject.libid }"
									[modalOnClose]="onRefreshOnModalClose.bind(this, testObject.structureId)"
									[modalRouterName]="[ 'sui_pages_elements_edit', testObject.libid ]"
								>
									<svg-icon name="icon-edit"></svg-icon>
								</span>
							</li>
							<li *ngIf="testObject.libgroup === 'blocks'">
								<span
									[modalData]="{ buildingBlockId: testObject.libid }"
									[modalOnClose]="onRefreshOnModalClose.bind(this, testObject.structureId)"
									[modalRouterName]="[ 'building_blocks_edit', testObject.libid ]"
								>
									<svg-icon name="icon-edit"></svg-icon>
								</span>
							</li>
							<li>
								<span
									[modalData]="{
										structureId: testObject.structureId,
										parentId: rootId,
										rootItem: rootItem,
										type: 'warning',
										title: 'Delete Action',
										text: 'Do you want to delete this Action?',
										text2: 'This Action will be removed from the ' + (type === TestObjectType.TestObject ? 'test step' : 'building block') +'!',
										confirmButton: 'Delete',
										cancelButton: 'Cancel'
									}"
									[modalOnClose]="onRefreshOnModalClose.bind(this)"
									[modalRouterName]="[ 'confirm-popup', testObject.structureId ]"
								>
									<svg-icon name="icon-delete"></svg-icon>
								</span>
							</li>
						</ul>
					</div>
					<div class="parameters">
						<ul>
							<li
								*ngFor="let parameter of (testObject.parameters | sortTestObjectParameter : testObject.name : libraries)"
								class="parameter validation-{{ parameter.displayValidationType }}"
								(click)="onEventParameterClick($event, testObject, parameter)"
								id="testObjectParameter"
							>
								<span class="test-object__parameter">{{ parameter.name }}="{{ parameter.value }}"</span>
							</li>
							<li
								*ngIf="testObject.parameters | shouldShowAddNewTestObjectParameter: testObject.name : libraries"
								(click)="onEventParameterClick($event, testObject)"
								class="add-parameter parameter validation-optional"
							>
								+
							</li>
						</ul>
					</div>
				</div>
			</mat-expansion-panel-header>
			<div class="draggable">
				<app-library-drag-drop-page-element
					[item]="testObject"
					[rootItem]="rootItem"
					[connectedLists]="connectedLists"
					[intersectedItemIds]="intersectedItemIds"
					[expandedItemIds]="expandedItemIds"
					[isDragging]="isDragging"
					[type]="type"
					[rootId]="rootId"
					[libraries]="libraries"
					(dragDrop)="onDragDrop($event)"
					(dragEnded)="onDragEnded($event)"
					(dragStarted)="onDragStarted($event)"
					(dragMove)="onDragMove($event)"
					(expandedClick)="onToggleExpanded($event)"
					(refreshOnModalClose)="onRefreshOnModalClose($event)"
					(parameterClick)="onParameterClick($event)"
				></app-library-drag-drop-page-element>
			</div>
		</mat-expansion-panel>
		<div class="item-drag-preview ps-4" *cdkDragPreview>
			<span class="drag-thumb"></span>
			<h4 class="mt-2 item-drag-preview-title">{{ testObject.name }}</h4>
		</div>
		<div class="item-placeholder" *cdkDragPlaceholder></div>
	</div>
</div>
